import React, { useState } from 'react';
import moment from 'moment';
import ShowDataEventModal from '../ShowDataEventModal/ShowDataEventModal';

const CalendarGrid = (props: any) => {
    const [showDataEvent, setShowDataEvent] = useState<boolean>(false);
    const [dataEvent, setDataEvent] = useState<any>(null);
    const day = props.startDay.clone().subtract(1, 'day');
    const daysArray = [...Array(42)].map(() => day.add(1, 'day').clone());

    const onShowDataEvent = (item: any) => {
        setDataEvent(item);
        setShowDataEvent(!showDataEvent);
    };

    return (
        <>
            <div className="grid-wrapper cell-wrapper-header">
                {[...Array(7)].map((_, i) => (
                    <div
                        key={i}
                        className="cell-wrapper-header"
                        style={{ paddingRight: '5px', textAlign: 'right' }}
                    >
                        {moment()
                            .day(i + 1)
                            .format('ddd')}
                    </div>
                ))}
            </div>
            <div className="grid-wrapper">
                {daysArray.map(dayItem => {
                    return (
                        <div
                            key={dayItem.unix()}
                            className={
                                dayItem.day() === 6 || dayItem.day() === 0
                                    ? 'cell-wrapper-weekend'
                                    : 'cell-wrapper'
                            }
                        >
                            <div className="row-in-cell">
                                <div className="show-day-wrapper">
                                    <div className="day-wrapper">
                                        <div
                                            className={
                                                moment().isSame(dayItem, 'day')
                                                    ? 'current-day'
                                                    : ''
                                            }
                                        >
                                            <div
                                                className={
                                                    props.today.isSame(
                                                        dayItem,
                                                        'month',
                                                    )
                                                        ? ''
                                                        : 'other-month'
                                                }
                                            >
                                                {dayItem.format('D')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ul className="event-list-wrapper">
                                    <div
                                        className={
                                            props.today.isSame(dayItem, 'month')
                                                ? ''
                                                : 'other-month'
                                        }
                                    >
                                        {props.events
                                            .filter(
                                                (event: any) =>
                                                    moment(event.date).unix() >=
                                                        dayItem.format('X') &&
                                                    moment(event.date).unix() <=
                                                        dayItem
                                                            .clone()
                                                            .endOf('day')
                                                            .format('X'),
                                            )
                                            .map((item: any) => (
                                                <li key={item.id}>
                                                    <button
                                                        className={
                                                            item.status === 2
                                                                ? 'event-item-wrapper-done'
                                                                : item.importance ===
                                                                  1
                                                                ? 'event-item-wrapper-low'
                                                                : item.importance ===
                                                                  2
                                                                ? 'event-item-wrapper-medium'
                                                                : 'event-item-wrapper-high'
                                                        }
                                                        onClick={() => {
                                                            onShowDataEvent(
                                                                item,
                                                            );
                                                        }}
                                                    >
                                                        {item.title}
                                                    </button>
                                                </li>
                                            ))}
                                    </div>
                                </ul>
                            </div>
                        </div>
                    );
                })}
            </div>
            <ShowDataEventModal
                onShowDataEvent={onShowDataEvent}
                showDataEvent={showDataEvent}
                dataEvent={dataEvent}
            />
        </>
    );
};

export default CalendarGrid;
