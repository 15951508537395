import { Button } from 'antd';
import React, { useState } from 'react';
import CloseCaseModal from './CloseCaseModal/CloseCaseModal';

const CloseCase = (props: any) => {
    const [showCloseCaseModal, setShowCloseCaseModal] =
        useState<boolean>(false);

    const onShowCloseCaseModal = () => {
        setShowCloseCaseModal(true);
    };

    const onCloseCloseCaseModal = () => {
        setShowCloseCaseModal(false);
    };

    return (
        <div className="messages-pending-case-details">
            <div style={{ textAlign: 'right' }}>
                <Button
                    style={{
                        backgroundColor: '#6bd15e',
                        color: 'white',
                        fontWeight: 'bolder',
                    }}
                    onClick={onShowCloseCaseModal}
                >
                    Close Case
                </Button>
            </div>
            <CloseCaseModal
                showCloseCaseModal={showCloseCaseModal}
                onCloseCloseCaseModal={onCloseCloseCaseModal}
                id={props.data?.id}
            />
        </div>
    );
};

export default CloseCase;
