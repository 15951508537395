//img
import topHeader from '../../assets/images/dashboard/top-header.webp';
import criminalLaw from '../../assets/images/dashboard/criminal-law.webp';
import civilLaw from '../../assets/images/dashboard/civil-law.webp';
import familyLaw from '../../assets/images/dashboard/family-law.webp';
import maritimeLaw from '../../assets/images/dashboard/maritime-law.webp';
import labourLaw from '../../assets/images/dashboard/labour-law.webp';
import realEstate from '../../assets/images/dashboard/real-estate.webp';
import insurance from '../../assets/images/dashboard/insurance.webp';
import finance from '../../assets/images/dashboard/finance.webp';
import commercial from '../../assets/images/dashboard/commercial-law.webp';
import investment from '../../assets/images/dashboard/investment.webp';

import { useLocation } from 'react-router-dom';
import { DashboardRouteNames } from '../../router/Dashboard';
import { useEffect, useState } from 'react';

const SubHeader = () => {
    const location = useLocation();
    const [image, setImage] = useState('');

    useEffect(() => {
        switch (location.pathname) {
            case DashboardRouteNames.SERVICE_CRIMINAL_LAW:
                setImage(criminalLaw);
                break;
            case DashboardRouteNames.SERVICE_CIVIL_LAW:
                setImage(civilLaw);
                break;
            case DashboardRouteNames.SERVICE_FAMILY_LAW:
                setImage(familyLaw);
                break;
            case DashboardRouteNames.SERVICE_MARITIME_LAW:
                setImage(maritimeLaw);
                break;
            case DashboardRouteNames.SERVICE_LABOUR_LAW:
                setImage(labourLaw);
                break;
            case DashboardRouteNames.SERVICE_REAL_ESTATE:
                setImage(realEstate);
                break;
            case DashboardRouteNames.SERVICE_INSURANCE:
                setImage(insurance);
                break;
            case DashboardRouteNames.SERVICE_FINANCE:
                setImage(finance);
                break;
            case DashboardRouteNames.SERVICE_COMMERCIAL_LAW:
                setImage(commercial);
                break;
            case DashboardRouteNames.SERVICE_INVESTMENT:
                setImage(investment);
                break;
            default:
                setImage(topHeader);
        }
    }, [location]);

    return (
        <div className="iq-navbar-header" style={{ height: '215px' }}>
            <div className="iq-header-img">
                <img
                    src={image}
                    alt="header"
                    className="img-fluid w-100 h-100"
                />
            </div>
        </div>
    );
};

export default SubHeader;
