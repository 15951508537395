import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import { Button, message, Upload, Modal, notification } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import '../../open-case/styles/openCase.css';

import { useTypedSelector } from '../../../../hooks/useTypedSelector';

import { GetServices } from '../../../../apis/user';
import {
    PostOpenPricesWithDocuments,
    PostOpenPricesWithOutDocuments,
    PostPayPrices,
    GetPrices,
} from '../../../../apis/prices';

import { notificationError } from '../../../../plugins/requestNotification';

import { ICause } from '../../../../models/IOpenCase';
import { DocumentType } from '../../../../models/IPrice';
import { DashboardRouteNames } from '../../../../router/Dashboard';

import OpenCaseCustomerDescriptionModal from './OpenCaseCustomerDescription/OpenCaseCustomerDescriptionModal';
import OpenCaseCustomerPayModal from './OpenCaseCustomerPay/OpenCaseCustomerPayModal';
import pendingCasesService from '../../../../helpers/menuServicePendingCaseLawyer';
import cardsImg from '../../../../assets/images/cards.png';
import preloaderImg from '../../../../assets/images/preloader.gif';

const OpenCaseCustomer = (props: any) => {
    const userRoleId: number | undefined = useTypedSelector(
        state => state.user.user?.role?.id,
    );
    const { user } = useTypedSelector(state => state.user);
    const [paymentCases, setPaymentCases] = useState<ICause[]>([]);
    const [isModalDescriprionVisible, setIsModalDescriprionVisible] =
        useState<boolean>(false);
    const [emptyInput, setEmptyInput] = useState<boolean>(false);
    const [description, setDescription] = useState<string | null>(null);
    const [descriptionValue, setDescriptionValue] = useState<string>('');
    const [nameSelectedCase, setNameSelectedCase] = useState<string>('');
    const [idSelectedCase, setIdSelectedCase] = useState<number>(0);
    const [idUser, setIdUser] = useState<number>(0);
    const [randomCaseId, setRandomCaseId] = useState<number>(0);
    const [isModalPayVisible, setIsModalPayVisible] = useState<boolean>(false);
    const [fullName, setFullName] = useState<string>('');
    const [numberCard, setNumberCard] = useState<number | null>(null);
    const [cvvCard, setCvvCard] = useState<string>('');
    const [billing, setBilling] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [postcode, setPostcode] = useState<string>('');
    const [fullNameValue, setFullNameValue] = useState<string>('');
    const [endDateCard, setEndDateCard] = useState<number>(0);
    const [statusPay, setStatusPay] = useState<boolean | null | string>(null);
    const [endDateCardValue, setEndDateCardValue] = useState<string>('');
    const [numberCardValue, setNumberCardValue] = useState<string>('');
    const [billingValue, setBillingValue] = useState<string>('');
    const [cityCardValue, setCityCardValue] = useState<string>('');
    const [postcodeCardValue, setPostcodeCardValue] = useState<string>('');
    const [cvvCardValue, setCvvCardValue] = useState<string>('');
    const [emptyNumberCard, setEmptyNumberCard] = useState<boolean>(false);
    const [emptyFullNameCard, setEmptyFullNameCard] = useState<boolean>(false);
    const [emptyCvvCard, setEmptyCvvCard] = useState<boolean>(false);
    const [emptyEndDateCard, setEmptyEndDateCard] = useState<boolean>(false);
    const [emptyBillingCard, setEmptyBillingCard] = useState<boolean>(false);
    const [emptyCityCard, setEmptyCityCard] = useState<boolean>(false);
    const [emptyPostcodeCard, setEmptyPostcodeCard] = useState<boolean>(false);
    const [errorFullName, setErrorFullName] = useState(true);
    const [errorCity, setErrorCity] = useState(true);
    const [documentsMessageValue, setDocumentsMessageValue] =
        useState<DocumentType | null>(null);
    const [servicePrice, setServicePrice] = useState(0);
    const [imageDocumentArray, setImageDocumentArray] = useState<any[]>([]);
    const [valueForTable, setValueForTable] = useState<any[]>([]);
    const [documentsFileList, setDocumentsFileList] = useState<any[]>([]);
    const [preloader, setPreloader] = useState<boolean>(false);
    const [preloaderModal, setPreloaderModal] = useState<boolean>(false);
    const [showCyrillic, setShowCyrillic] = useState<boolean>(false);
    const [showCyrillicFullName, setShowCyrillicFullName] =
        useState<boolean>(false);
    const [showCyrillicBilling, setShowCyrillicBilling] =
        useState<boolean>(false);
    const [showCyrillicCity, setShowCyrillicCity] = useState<boolean>(false);
    const [showCyrillicDocuments, setShowCyrillicDocuments] =
        useState<boolean>(false);

    let history = useHistory();
    const { confirm } = Modal;
    const cyrillicPatternDocuments = /[\u0400-\u04FF]/;

    useEffect(() => {
        window.onbeforeunload = () => {
            window.sessionStorage.setItem(
                'lastRoute',
                JSON.stringify(window.location.pathname),
            );
        };
    }, []);

    useEffect(() => {
        fetchLawyerCase();
        if (user) {
            setIdUser(user.user_id);
        }
    }, []);

    useEffect(() => {
        let year: number = Number(20 + endDateCardValue.split('/')[1]);
        let month: number = Number(endDateCardValue.split('/')[0]);
        let newDate = new Date(year, month, 0).getTime();
        setEndDateCard(newDate);
    }, [endDateCardValue]);

    useEffect(() => {
        if (statusPay === true) {
            setIsModalPayVisible(false);
            setDescription(null);
            setDescriptionValue('');
            setNumberCard(null);
            setNumberCardValue('');
            setCvvCard('');
            setCvvCardValue('');
            setEndDateCard(0);
            setEndDateCardValue('');
            setDocumentsMessageValue(null);
            setStatusPay(null);
            history.push(`${DashboardRouteNames.PENDING_CASES}`);
        } else if (statusPay === false || statusPay === undefined) {
            setStatusPay(null);
            notification.error({
                message: 'Unsuccessful payment',
                description:
                    "We haven't received your payment. Check that your card information is correct and try to pay again.",
                duration: 5,
            });
        }
    }, [statusPay]);

    useEffect(() => {
        let newObjDocuments: any = {};
        let count = 0;
        imageDocumentArray.forEach(item => {
            for (let value of Object.values(item)) {
                newObjDocuments[String(count++)] = value;
            }
        });
        setDocumentsMessageValue(newObjDocuments);
    }, [imageDocumentArray]);

    useEffect(() => {
        let tempArrayDocumentsSend: any = [];
        valueForTable?.forEach((item: any, index: number) => {
            if (cyrillicPatternDocuments.test(item.description)) {
                setShowCyrillicDocuments(true);
            }
            let tempObjDocumentsSend: any = {};
            tempObjDocumentsSend[String(index)] = {
                url: item.urlFile,
                description: item.description,
            };
            tempArrayDocumentsSend.push(tempObjDocumentsSend);
        });
        setImageDocumentArray(tempArrayDocumentsSend);
    }, [valueForTable]);

    useEffect(() => {
        let tempArrayValueForTable: any[] = [];
        documentsFileList.forEach((item: any) => {
            if (!item.response.code) {
                let tempObjValueForTable: any = {};
                tempObjValueForTable.key = item.uid;
                tempObjValueForTable.id = item.uid;
                tempObjValueForTable.uploadedFile = item.name;
                tempObjValueForTable.urlFile = item.response.data[0];
                if (item.description) {
                    tempObjValueForTable.description = item.description;
                } else {
                    tempObjValueForTable.description = '';
                }
                tempObjValueForTable.deleteFile = item;
                tempArrayValueForTable.push(tempObjValueForTable);
            }
        });
        setValueForTable(tempArrayValueForTable);
    }, [documentsFileList]);

    const fetchLawyerCase = async () => {
        setPreloader(true);
        try {
            const { data } = await GetServices();
            setPaymentCases(data.data);
            setPreloader(false);
        } catch (e) {
            notificationError(e);
        }
    };

    const onShowDescriptionModal = (value: ICause) => {
        setNameSelectedCase(value.name);
        setIdSelectedCase(value.id);
        setIsModalDescriprionVisible(true);
    };

    const handleDescriptionModalOk = (): void => {
        GetPrices().then(({ data }) => {
            const { price } = data.data.find(
                (i: any) => i.id === idSelectedCase,
            );
            setServicePrice(price);
        });

        if (
            description?.length !== 0 &&
            description !== undefined &&
            description !== null &&
            valueForTable.length > 0 &&
            showCyrillic !== true &&
            showCyrillicDocuments !== true
        ) {
            PostOpenPricesWithDocuments(
                idSelectedCase,
                idUser,
                description,
                documentsMessageValue,
            ).then(res => setRandomCaseId(res.data.data.case_id));
            setIsModalDescriprionVisible(false);
            setIsModalPayVisible(true);
            setDocumentsFileList([]);
        } else if (
            description?.length !== 0 &&
            description !== undefined &&
            description !== null &&
            valueForTable.length === 0 &&
            showCyrillic !== true &&
            showCyrillicDocuments !== true
        ) {
            PostOpenPricesWithOutDocuments(
                idSelectedCase,
                idUser,
                description,
            ).then(res => setRandomCaseId(res.data.data.case_id));
            setDocumentsMessageValue(null);
            setIsModalDescriprionVisible(false);
            setIsModalPayVisible(true);
            setDocumentsFileList([]);
        } else {
            if (showCyrillicDocuments !== true) {
                setEmptyInput(true);
                setInterval(() => {
                    setEmptyInput(false);
                }, 2000);
            }
        }
    };

    const handleDescriptionModalCancel = (): void => {
        setIsModalDescriprionVisible(false);
        setDocumentsMessageValue(null);
        setDescription(null);
        setDescriptionValue('');
        setShowCyrillic(false);
        setDocumentsFileList([]);
    };

    const onDescription = (event: any): void => {
        setDescription(event.target.value);
        setDescriptionValue(event.target.value);
        const cyrillicPattern = /[\u0400-\u04FF]/;
        if (cyrillicPattern.test(event.target.value)) {
            setShowCyrillic(true);
        } else {
            setShowCyrillic(false);
        }
    };

    const onDescriptionDocuments = (event: any, file: any): void => {
        let index = documentsFileList.indexOf(file.deleteFile);
        documentsFileList[index].description = event.target.value;
        file.description = event.target.value;
        if (cyrillicPatternDocuments.test(event.target.value)) {
            setShowCyrillicDocuments(true);
        } else {
            setShowCyrillicDocuments(false);
        }
        setDocumentsFileList([...documentsFileList]);
        setValueForTable([...valueForTable]);
    };

    const validateFullName = (value: string) => {
        const FULL_NAME_REGEXP = /^[a-zA-Z-'` ]+$/;
        return FULL_NAME_REGEXP.test(value);
    };

    const onFullNameCard = (event: any): void => {
        setFullName(event.target.value);
        setFullNameValue(event.target.value);
        const cyrillicPattern = /[\u0400-\u04FF]/;
        if (cyrillicPattern.test(event.target.value)) {
            setShowCyrillicFullName(true);
        } else {
            setShowCyrillicFullName(false);
        }
        validateFullName(event.target.value)
            ? setErrorFullName(false)
            : setErrorFullName(true);
    };

    const handlePayOk = (): void => {
        if (
            fullName.trim().length > 1 &&
            errorFullName === false &&
            numberCard !== null &&
            cvvCard.trim().length > 2 &&
            endDateCard !== 0 &&
            billing.trim().length !== 0 &&
            city.trim().length > 1 &&
            errorCity === false &&
            postcode.trim().length === 5 &&
            showCyrillicFullName !== true &&
            showCyrillicBilling !== true &&
            showCyrillicCity !== true
        ) {
            setPreloaderModal(true);
            PostPayPrices(
                randomCaseId,
                fullName,
                numberCard,
                endDateCard,
                cvvCard,
                billing,
                city,
                postcode,
            )
                .then(res => setStatusPay(res.data.data.status))
                .then(() => {
                    setPreloaderModal(false);
                });
        } else if (fullName.trim().length < 2 || errorFullName === true) {
            setEmptyFullNameCard(true);
            setInterval(() => {
                setEmptyFullNameCard(false);
            }, 2000);
        } else if (numberCard === 0 || numberCard === null) {
            setEmptyNumberCard(true);
            setInterval(() => {
                setEmptyNumberCard(false);
            }, 2000);
        } else if (isNaN(endDateCard) === true) {
            setEmptyEndDateCard(true);
            setInterval(() => {
                setEmptyEndDateCard(false);
            }, 2000);
        } else if (cvvCard.trim().length < 3) {
            setEmptyCvvCard(true);
            setInterval(() => {
                setEmptyCvvCard(false);
            }, 2000);
        } else if (billing.trim().length === 0) {
            setEmptyBillingCard(true);
            setInterval(() => {
                setEmptyBillingCard(false);
            }, 2000);
        } else if (city.trim().length < 2 || errorCity === true) {
            setEmptyCityCard(true);
            setInterval(() => {
                setEmptyCityCard(false);
            }, 2000);
        } else if (postcode.trim().length !== 5) {
            setEmptyPostcodeCard(true);
            setInterval(() => {
                setEmptyPostcodeCard(false);
            }, 2000);
        }
    };

    const handlePayCancel = (): void => {
        setIsModalPayVisible(false);
        setFullName('');
        setFullNameValue('');
        setBilling('');
        setBillingValue('');
        setCity('');
        setCityCardValue('');
        setPostcode('');
        setPostcodeCardValue('');
        setEndDateCardValue('');
        setNumberCardValue('');
        setCvvCardValue('');
        setDocumentsMessageValue(null);
        setDescription(null);
        setDescriptionValue('');
        setDocumentsFileList([]);
    };

    const onNumberCard = (event: any): void => {
        let temp = event.target.value.replace(/[^\d]/g, '').substring(0, 16);
        temp = temp.match(/.{1,4}/g);
        if (temp === null) {
            setNumberCardValue('');
        } else {
            temp = temp.join(' ');
            setNumberCardValue(temp);
        }
        setNumberCard(Number(event.target.value.split(' ').join('')));
    };

    const onCvvCard = (event: any): void => {
        let temp = event.target.value.replace(/[^\d]/g, '');
        setCvvCard(temp);
        setCvvCardValue(temp);
    };

    const onEndDate = (event: any): void => {
        let temp = event.target.value.replace(/[^\d]/g, '');
        if (temp.length === 4 && temp.indexOf('/') === -1) {
            setEndDateCardValue(temp.slice(0, 2) + '/' + temp.slice(2, 4));
        } else if (temp.length === 4 && temp.indexOf('/') !== -1) {
            let date: string = temp.replace('/', '');
            setEndDateCardValue(date);
        } else if (temp.length < 4) {
            setEndDateCardValue(temp);
        }
    };

    const onBillingCard = (event: any): void => {
        setBilling(event.target.value);
        setBillingValue(event.target.value);
        const cyrillicPattern = /[\u0400-\u04FF]/;
        if (cyrillicPattern.test(event.target.value)) {
            setShowCyrillicBilling(true);
        } else {
            setShowCyrillicBilling(false);
        }
    };

    const validateCity = (value: string) => {
        const FULL_CITY_REGEXP = /^[a-zA-Z- ]+$/;
        return FULL_CITY_REGEXP.test(value);
    };

    const onCity = (event: any): void => {
        setCity(event.target.value);
        setCityCardValue(event.target.value);
        const cyrillicPattern = /[\u0400-\u04FF]/;
        if (cyrillicPattern.test(event.target.value)) {
            setShowCyrillicCity(true);
        } else {
            setShowCyrillicCity(false);
        }
        validateCity(event.target.value)
            ? setErrorCity(false)
            : setErrorCity(true);
    };

    const onPostcodeCard = (event: any): void => {
        let temp = event.target.value.replace(/[^\d]/g, '');
        setPostcode(temp);
        setPostcodeCardValue(temp);
    };

    const propsUpload = {
        name: 'documents',
        action: `${process.env.REACT_APP_BASE_URL}/save-documents`,
        headers: {
            authorization: 'documents',
        },
        beforeUpload: (file: any) => {
            const isJPG = file.type === 'image/jpg';
            const isJPEG = file.type === 'image/jpeg';
            const isPNG = file.type === 'image/png';
            const isPDF = file.type === 'application/pdf';
            const isDOC = file.type === 'application/msword';
            const isDOCX =
                file.type ===
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!(isJPG || isJPEG || isPNG || isPDF || isDOC || isDOCX)) {
                message.error(`jpg, jpeg, png, pdf, doc, docx allowed`);
            } else if (!isLt2M) {
                message.error('File must not be bigger than 5 Mb');
            }
            return (
                isJPG ||
                isJPEG ||
                isPNG ||
                isPDF ||
                isDOC ||
                isDOCX ||
                Upload.LIST_IGNORE
            );
        },
        onChange: (info: any) => {
            if (info.file?.status !== 'uploading') {
                setDocumentsFileList([
                    ...documentsFileList,
                    info.fileList[info.fileList.length - 1],
                ]);
            }
            if (info.file?.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file?.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onRemove: (file: any) => {
            const index = documentsFileList.indexOf(file);
            const newFileList = documentsFileList.slice();
            newFileList.splice(index, 1);
            setDocumentsFileList(newFileList);
            setShowCyrillicDocuments(false);
        },
    };

    const showConfirm = () => {
        confirm({
            title: 'Do you want to close this order?',
            icon: <ExclamationCircleOutlined />,
            content: 'The entered data will be deleted',
            cancelButtonProps: {
                type: 'primary',
            },
            onOk() {
                handleDescriptionModalCancel();
                handlePayCancel();
            },
            onCancel() {
                return;
            },
        });
    };

    if (preloader) {
        return (
            <div className="preloader-box">
                <img
                    className="preloader-img"
                    src={preloaderImg}
                    alt="Loading..."
                />
            </div>
        );
    }

    if (userRoleId === 3) {
        return (
            <div className="service-container service-page">
                <div className="service__title">Open New Case</div>
                <Row className="justify-content-md-center row">
                    {paymentCases?.map((item: ICause) => (
                        <Col key={item.id} sm="3">
                            <Card className="br-8">
                                <button
                                    className="service-block"
                                    onClick={() => {
                                        onShowDescriptionModal(item);
                                    }}
                                >
                                    <Card.Body className="p-10 d-flex justify-content-center align-items-center flex-column">
                                        {item.id === 1 ? (
                                            <div className="service-block__title">
                                                {pendingCasesService[0].icon}
                                            </div>
                                        ) : item.id === 2 ? (
                                            <div className="service-block__title">
                                                {pendingCasesService[1].icon}
                                            </div>
                                        ) : item.id === 3 ? (
                                            <div className="service-block__title">
                                                {pendingCasesService[2].icon}
                                            </div>
                                        ) : item.id === 4 ? (
                                            <div className="service-block__title">
                                                {pendingCasesService[3].icon}
                                            </div>
                                        ) : item.id === 5 ? (
                                            <div className="service-block__title">
                                                {pendingCasesService[4].icon}
                                            </div>
                                        ) : item.id === 6 ? (
                                            <div className="service-block__title">
                                                {pendingCasesService[5].icon}
                                            </div>
                                        ) : item.id === 7 ? (
                                            <div className="service-block__title">
                                                {pendingCasesService[6].icon}
                                            </div>
                                        ) : item.id === 8 ? (
                                            <div className="service-block__title">
                                                {pendingCasesService[7].icon}
                                            </div>
                                        ) : item.id === 9 ? (
                                            <div className="service-block__title">
                                                {pendingCasesService[8].icon}
                                            </div>
                                        ) : item.id === 10 ? (
                                            <div className="service-block__title">
                                                {pendingCasesService[9].icon}
                                            </div>
                                        ) : null}
                                        <div className="service-block__title">
                                            {item.name}
                                        </div>
                                    </Card.Body>
                                </button>
                                {item.id === 1 ? (
                                    <div className="open-case-customer-button">
                                        <Button type="link">
                                            <Link
                                                style={{ fontSize: '12px' }}
                                                to={{
                                                    pathname: `${DashboardRouteNames.SERVICE_CRIMINAL_LAW}`,
                                                }}
                                            >
                                                Read more
                                            </Link>
                                        </Button>
                                    </div>
                                ) : item.id === 2 ? (
                                    <div className="open-case-customer-button">
                                        <Button type="link">
                                            <Link
                                                style={{ fontSize: '12px' }}
                                                to={{
                                                    pathname: `${DashboardRouteNames.SERVICE_CIVIL_LAW}`,
                                                }}
                                            >
                                                Read more
                                            </Link>
                                        </Button>
                                    </div>
                                ) : item.id === 3 ? (
                                    <div className="open-case-customer-button">
                                        <Button type="link">
                                            <Link
                                                style={{ fontSize: '12px' }}
                                                to={{
                                                    pathname: `${DashboardRouteNames.SERVICE_REAL_ESTATE}`,
                                                }}
                                            >
                                                Read more
                                            </Link>
                                        </Button>
                                    </div>
                                ) : item.id === 4 ? (
                                    <div className="open-case-customer-button">
                                        <Button type="link">
                                            <Link
                                                style={{ fontSize: '12px' }}
                                                to={{
                                                    pathname: `${DashboardRouteNames.SERVICE_COMMERCIAL_LAW}`,
                                                }}
                                            >
                                                Read more
                                            </Link>
                                        </Button>
                                    </div>
                                ) : item.id === 5 ? (
                                    <div className="open-case-customer-button">
                                        <Button type="link">
                                            <Link
                                                style={{ fontSize: '12px' }}
                                                to={{
                                                    pathname: `${DashboardRouteNames.SERVICE_INSURANCE}`,
                                                }}
                                            >
                                                Read more
                                            </Link>
                                        </Button>
                                    </div>
                                ) : item.id === 6 ? (
                                    <div className="open-case-customer-button">
                                        <Button type="link">
                                            <Link
                                                style={{ fontSize: '12px' }}
                                                to={{
                                                    pathname: `${DashboardRouteNames.SERVICE_FAMILY_LAW}`,
                                                }}
                                            >
                                                Read more
                                            </Link>
                                        </Button>
                                    </div>
                                ) : item.id === 7 ? (
                                    <div className="open-case-customer-button">
                                        <Button type="link">
                                            <Link
                                                style={{ fontSize: '12px' }}
                                                to={{
                                                    pathname: `${DashboardRouteNames.SERVICE_MARITIME_LAW}`,
                                                }}
                                            >
                                                Read more
                                            </Link>
                                        </Button>
                                    </div>
                                ) : item.id === 8 ? (
                                    <div className="open-case-customer-button">
                                        <Button type="link">
                                            <Link
                                                style={{ fontSize: '12px' }}
                                                to={{
                                                    pathname: `${DashboardRouteNames.SERVICE_LABOUR_LAW}`,
                                                }}
                                            >
                                                Read more
                                            </Link>
                                        </Button>
                                    </div>
                                ) : item.id === 9 ? (
                                    <div className="open-case-customer-button">
                                        <Button type="link">
                                            <Link
                                                style={{ fontSize: '12px' }}
                                                to={{
                                                    pathname: `${DashboardRouteNames.SERVICE_FINANCE}`,
                                                }}
                                            >
                                                Read more
                                            </Link>
                                        </Button>
                                    </div>
                                ) : item.id === 10 ? (
                                    <div className="open-case-customer-button">
                                        <Button type="link">
                                            <Link
                                                style={{ fontSize: '12px' }}
                                                to={{
                                                    pathname: `${DashboardRouteNames.SERVICE_INVESTMENT}`,
                                                }}
                                            >
                                                Read more
                                            </Link>
                                        </Button>
                                    </div>
                                ) : null}
                            </Card>
                        </Col>
                    ))}
                </Row>
                <OpenCaseCustomerDescriptionModal
                    showConfirm={showConfirm}
                    isModalDescriprionVisible={isModalDescriprionVisible}
                    handleDescriptionModalOk={handleDescriptionModalOk}
                    emptyInput={emptyInput}
                    onDescription={onDescription}
                    descriptionValue={descriptionValue}
                    propsUpload={propsUpload}
                    onDescriptionDocuments={onDescriptionDocuments}
                    nameSelectedCase={nameSelectedCase}
                    idSelectedCase={idSelectedCase}
                    valueForTable={valueForTable}
                    showCyrillic={showCyrillic}
                    showCyrillicDocuments={showCyrillicDocuments}
                />
                <OpenCaseCustomerPayModal
                    showConfirm={showConfirm}
                    nameSelectedCase={nameSelectedCase}
                    isModalPayVisible={isModalPayVisible}
                    handlePayOk={handlePayOk}
                    emptyFullNameCard={emptyFullNameCard}
                    emptyNumberCard={emptyNumberCard}
                    fullNameValue={fullNameValue}
                    numberCardValue={numberCardValue}
                    onFullNameCard={onFullNameCard}
                    onNumberCard={onNumberCard}
                    emptyCvvCard={emptyCvvCard}
                    cvvCardValue={cvvCardValue}
                    onCvvCard={onCvvCard}
                    emptyEndDateCard={emptyEndDateCard}
                    endDateCardValue={endDateCardValue}
                    billingValue={billingValue}
                    onBillingCard={onBillingCard}
                    onEndDate={onEndDate}
                    pricePay={servicePrice}
                    emptyBillingCard={emptyBillingCard}
                    emptyCityCard={emptyCityCard}
                    cityCardValue={cityCardValue}
                    onCity={onCity}
                    emptyPostcodeCard={emptyPostcodeCard}
                    postcodeCardValue={postcodeCardValue}
                    onPostcodeCard={onPostcodeCard}
                    cardsImg={cardsImg}
                    showCyrillicFullName={showCyrillicFullName}
                    showCyrillicBilling={showCyrillicBilling}
                    showCyrillicCity={showCyrillicCity}
                    preloaderModal={preloaderModal}
                    preloaderImg={preloaderImg}
                />
            </div>
        );
    } else if (userRoleId === 2) {
        return null;
    } else {
        return null;
    }
};

export default OpenCaseCustomer;
