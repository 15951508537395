import React, { useState } from 'react';
import AssignCustomer from './AssignCustomer/AssignCustomer';
import '../../../pending-cases/styles/pendingCases.css';
import { IHistoryMessagesPending } from '../../../../../models/IPendingCaseLawyer';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';

type TProps = {
    data: IHistoryMessagesPending[];
    onReloadTableAfterSend: () => void;
};

const HeaderPendingCaseLawyerDetails = ({
    data,
    onReloadTableAfterSend,
}: TProps) => {
    let history = useHistory();
    const [showAssignModal, setShowAssignModal] = useState<boolean>(false);

    const onShowAssignCustomer = () => {
        setShowAssignModal(true);
    };

    return (
        <>
            <div className="header-pending-case-details">
                <div className="header-pending-case-details-titles">
                    <Button
                        type="primary"
                        style={{ marginRight: '10px' }}
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        Back
                    </Button>
                    Case Number # {data[0]?.id}
                </div>
                <div className="header-pending-case-details-titles">
                    {data[0]?.service.name}
                </div>
                <div className="header-pending-case-details-titles">
                    {data[0]?.user_name}
                </div>
                <div className="header-pending-case-details-titles">
                    {data[0]?.date_create_human}
                </div>
                {data[0]?.lawyer_created === true ? (
                    <div className="header-pending-case-details-titles">
                        <Button type="primary" onClick={onShowAssignCustomer}>
                            Assign a customer
                        </Button>
                    </div>
                ) : null}
            </div>
            <AssignCustomer
                showAssignModal={showAssignModal}
                setShowAssignModal={setShowAssignModal}
                id={data[0]?.id}
                onReloadTableAfterSend={onReloadTableAfterSend}
            />
        </>
    );
};

export default HeaderPendingCaseLawyerDetails;
