import { spawn } from 'redux-saga/effects';
import { watchAuth } from './auth';
import { watchUser } from './user';
import { watchProfile } from './profile';

export function* rootWatcher() {
    yield spawn(watchAuth);
    yield spawn(watchUser);
    yield spawn(watchProfile);
}
