import { FC } from 'react';

interface StepProps {
    step: number;
}

const steps = [
    {
        title: 1,
        description:
            'Please enter your email and password to sign up with My Emirates.Law',
    },
    {
        title: 2,
        description:
            'After pressing the "Agree" button, you agree to the first service and payment of the fee.',
    },
    {
        title: 3,
        description:
            'Please enter your legal first and last name. Our Service does not address anyone under the age of 18.',
    },
];

const FieldBlock: FC<StepProps> = ({ step }: StepProps) => {
    let activeKey: number | null = null;
    activeKey = step;

    return (
        <>
            <ul className="log-step d-flex justify-content-between">
                {steps.map((step, index) => (
                    <li
                        key={index}
                        className={`${
                            activeKey === step.title ? 'active' : '12'
                        }`}
                    >
                        {step.title}
                    </li>
                ))}
            </ul>
            <h2 className="mb-2 text-center">Sign Up</h2>
            <p className="text-center step__description">
                {activeKey === 1
                    ? steps[0].description
                    : activeKey === 2
                    ? steps[1].description
                    : steps[2].description}
            </p>
        </>
    );
};

export default FieldBlock;
