import { Axios } from '../plugins/helpers';
import { notificationError } from '../plugins/requestNotification';

export const GetArchiveCustomerCases = async () => {
    try {
        const { data } = await Axios().get(
            `${process.env.REACT_APP_BASE_URL}/get-user-cases?status=3`,
        );
        return data;
    } catch (e: any) {
        notificationError(e);
        return e?.response;
    }
};
