import { useState, useEffect, useRef } from 'react';
import { Row, Col, Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Card from '../../../components/Card';
import auth5 from '../../../assets/images/auth/01.webp';
import logoSign from '../../../assets/images/logo-sign.png';
import { useActions } from '../../../hooks/useActions';
import { GetFields } from '../../../apis/fields';
import { UserRegisterStep2 } from '../../../apis/user';
import { AuthRouteNames } from '../../../router/Auth';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { IRoles } from '../../../models/IRoles';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { ICategoryField, IFieldFront } from '../../../models/IField';
import FieldWithValidation from '../../../components/uikit/FieldWithValidation';
import FieldBlock from '../../../components/uikit/FieldBlock';
import Steps from '../../../components/uikit/Steps';
import { handleDisableButton } from '../../../plugins/helpers';
import Helmet from 'react-helmet';

const SignUp = () => {
    // Action
    const { register, setAuth, handleLoader, setTotalStep, setStepOfRegist } =
        useActions();

    // useSelector
    const { user } = useTypedSelector(state => state.user);
    const { stepOfRegist, totalStep } = useTypedSelector(state => state.auth);
    const { errors, modalValue } = useTypedSelector(state => state.app);

    // useState
    const [firstStepFields, setFirstStepFields] = useState<any>(null);
    const [initialValues, setInitialValues] = useState<any>(null);
    const [validationSchema, setValidationSchema] = useState<any>(null);
    const [fields, setFields] = useState<IFieldFront[] | null>(null);
    const [fieldsByCategories, setFieldsByCategories] = useState<
        ICategoryField[] | null
    >(null);
    const [formikActions, setFormicActions] = useState<any>(null);
    const [disabledAgreed, setDisabledAgreed] = useState<boolean>(true);

    // useRef
    const formButtonRef = useRef(null);

    useEffect(() => {
        handleLoader(true);
    }, []);

    useEffect(() => {
        if (formikActions && errors && Object.values(errors).length) {
            formikActions.setErrors(errors);
        }
    }, [errors]);

    useEffect(() => {
        if (totalStep && stepOfRegist > totalStep) {
            setAuth();
            return;
        }
        fetchFields();
    }, [stepOfRegist]);

    useEffect(() => {
        if (!firstStepFields || !modalValue) return;
        register({
            step: stepOfRegist,
            role_id: IRoles.USER,
            fields: {
                ...firstStepFields,
                mail_code: modalValue,
            },
        });
    }, [modalValue]);

    const fetchFields = async () => {
        const res = await GetFields(stepOfRegist);
        if (!res) {
            return;
        }
        if (stepOfRegist === 2) {
            setFieldsByCategories(res.sortedFieldsByCategory);
        } else {
            setFields(res.sortedFields);
        }
        setValidationSchema(res.validations);
        setTotalStep(res.totalStep);
        setInitialValues(res.initialValues);
        formikActions && formikActions.resetForm({ values: res.initialValues });
        handleLoader(false);
    };

    const handleRegister = async (values: any, actions: any) => {
        setFormicActions(actions);
        handleDisableButton(formButtonRef);
        if (stepOfRegist === 1) {
            setFirstStepFields(values);
            register({
                step: stepOfRegist,
                role_id: IRoles.USER,
                fields: values,
            });
        } else if (stepOfRegist === 3) {
            if ('agreement_pdf' in values) {
                delete values.agreement_pdf;
            }
            register({
                step: stepOfRegist,
                role_id: IRoles.USER,
                fields: {
                    ...values,
                    hash: user?.hash,
                },
                user_id: user?.user_id,
            });
        }
    };

    const onSendStep2 = async () => {
        let secondStep = {
            step: 2,
            role_id: 3,
            fields: {
                user_id: user?.user_id,
                hash: user?.hash,
                agreement_pdf: 1,
            },
        };
        try {
            handleLoader(true);
            const res = await UserRegisterStep2(secondStep);
            if (res.status === 200) {
                setDisabledAgreed(true);
                setStepOfRegist(3);
                handleLoader(false);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const schema = Yup.object().shape(validationSchema);

    return (
        <>
            <Helmet>
                <title>MY.EMIRATES.LAW - Sign Up</title>
            </Helmet>
            <section className="login-content">
                <Row className="m-0 bg-white vh-100">
                    <div className="col-md-6 d-md-block d-none p-0 mt-n1 vh-100 overflow-hidden relative">
                        <Image
                            src={logoSign}
                            alt="logo"
                            className="logo-sign"
                        />
                        <Image
                            src={auth5}
                            className="Image-fluid gradient-main animated-scaleX"
                            alt="images"
                        />
                    </div>
                    <Col md="6">
                        <Row className="justify-content-center step align-items-center vh-100">
                            <Col md="12">
                                <Card className="card-transparent auth-card shadow-none d-flex justify-content-center mb-0">
                                    <Card.Body>
                                        <Steps step={stepOfRegist} />
                                        {initialValues && (
                                            <Formik
                                                initialValues={initialValues}
                                                validationSchema={schema}
                                                onSubmit={(values, actions) =>
                                                    handleRegister(
                                                        values,
                                                        actions,
                                                    )
                                                }
                                            >
                                                <Form>
                                                    {stepOfRegist === 3 ? (
                                                        <Row>
                                                            {fields &&
                                                                fields.map(
                                                                    (
                                                                        field: IFieldFront,
                                                                    ) => (
                                                                        <FieldWithValidation
                                                                            key={
                                                                                field.id
                                                                            }
                                                                            field={
                                                                                field
                                                                            }
                                                                        />
                                                                    ),
                                                                )}
                                                        </Row>
                                                    ) : stepOfRegist === 1 ? (
                                                        <Row>
                                                            {fields &&
                                                                fields.map(
                                                                    (
                                                                        field: IFieldFront,
                                                                    ) => (
                                                                        <FieldWithValidation
                                                                            key={
                                                                                field.id
                                                                            }
                                                                            field={
                                                                                field
                                                                            }
                                                                        />
                                                                    ),
                                                                )}
                                                        </Row>
                                                    ) : (
                                                        <Row>
                                                            {fieldsByCategories &&
                                                                fieldsByCategories.map(
                                                                    (
                                                                        category: ICategoryField,
                                                                    ) => (
                                                                        <FieldBlock
                                                                            key={
                                                                                category.id
                                                                            }
                                                                            category={
                                                                                category
                                                                            }
                                                                            setDisabledAgreed={
                                                                                setDisabledAgreed
                                                                            }
                                                                        />
                                                                    ),
                                                                )}
                                                        </Row>
                                                    )}
                                                    <div className="d-flex justify-content-center mt-3">
                                                        <Button
                                                            type="submit"
                                                            className="step__button"
                                                            variant="primary"
                                                            ref={formButtonRef}
                                                            disabled={
                                                                stepOfRegist ===
                                                                2
                                                                    ? disabledAgreed
                                                                    : false
                                                            }
                                                            onClick={
                                                                stepOfRegist ===
                                                                2
                                                                    ? onSendStep2
                                                                    : () => {}
                                                            }
                                                        >
                                                            {stepOfRegist === 3
                                                                ? 'Confirm'
                                                                : stepOfRegist ===
                                                                  2
                                                                ? 'Agreed'
                                                                : 'Next'}
                                                        </Button>
                                                    </div>
                                                </Form>
                                            </Formik>
                                        )}
                                        <p className="mt-3 text-center">
                                            Already have an Account?{' '}
                                            <Link
                                                to={AuthRouteNames.SIGN_IN}
                                                className="text-underline"
                                            >
                                                Click here to Sign In
                                            </Link>
                                        </p>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </section>
        </>
    );
};

export default SignUp;
