import { ObjectShape } from 'yup/lib/object';
import { IFieldFront } from '../../../models/IField';
import { ITwoFactor, ITwoFactorRequest } from '../../../models/IProfile';
import {
    ICompTransaction,
    IUserChangePassword,
    IUserInfo,
} from '../../../models/IUser';

export interface IProfileFields {
    validation: ObjectShape;
    fields: IFieldFront[];
}

export interface ProfileState {
    profileFields: null | IFieldFront[];
    profileValidation: null | ObjectShape;
    profileValues: null | IUserInfo;
    securityFields: null | IProfileFields;
    token: string;
    repeatingTransaction: null | ICompTransaction;
}

export enum ProfileActionEnum {
    SET_FIELDS = 'SET_FIELDS',
    SET_VALIDATION = 'SET_VALIDATION',
    SET_VALUES = 'SET_VALUES',
    UPDATE_PROFILE = 'UPDATE_PROFILE',
    SET_SECURITY_FIELDS = 'SET_SECURITY_FIELDS',
    REQUEST_CHANGE_2_AUTH = 'REQUEST_CHANGE_2_AUTH',
    CHANGE_2_AUTH = 'CHANGE_2_AUTH',
    SET_TOKEN = 'SET_TOKEN',
    CHANGE_PASSWORD = 'CHANGE_PASSWORD',
    FETCH_PAYMENT_SYSTEMS = 'FETCH_PAYMENT_SYSTEMS',
    SET_PAYMENT_SYSTEMS = 'SET_PAYMENT_SYSTEMS',
    SET_REPEATING_TRANSACTION = 'SET_REPEATING_TRANSACTION',
}

export interface SetFields {
    type: ProfileActionEnum.SET_FIELDS;
    payload: IFieldFront[] | null;
}
export interface SetValidation {
    type: ProfileActionEnum.SET_VALIDATION;
    payload: ObjectShape;
}
export interface SetValues {
    type: ProfileActionEnum.SET_VALUES;
    payload: IUserInfo | null;
}
export interface UpdateProfile {
    type: ProfileActionEnum.UPDATE_PROFILE;
    payload: IUserInfo;
}
export interface ChangePassword {
    type: ProfileActionEnum.CHANGE_PASSWORD;
    payload: {
        data: IUserChangePassword;
        callback: () => void;
    };
}
export interface SetSecurityFields {
    type: ProfileActionEnum.SET_SECURITY_FIELDS;
    payload: IProfileFields | null;
}
export interface Change2Auth {
    type: ProfileActionEnum.CHANGE_2_AUTH;
    payload: ITwoFactor;
}
export interface RequestChange2Auth {
    type: ProfileActionEnum.REQUEST_CHANGE_2_AUTH;
    payload: ITwoFactorRequest;
}
export interface SetToken {
    type: ProfileActionEnum.SET_TOKEN;
    payload: string;
}
export interface FetchPaymentSystems {
    type: ProfileActionEnum.FETCH_PAYMENT_SYSTEMS;
}

export interface SetRepeatingTransaction {
    type: ProfileActionEnum.SET_REPEATING_TRANSACTION;
    payload: ICompTransaction | null;
}

export type ProfileAction =
    | SetFields
    | SetValidation
    | SetValues
    | SetSecurityFields
    | SetToken
    | SetRepeatingTransaction;
