import React, { FC, useState, useEffect } from "react";
import { useFormikContext, FieldInputProps } from "formik"; // I deleted useFormikContext !!!
import { IField } from "../../../models/IField";

interface PhoneProps {
  field: FieldInputProps<any>;
  input: IField,
}

const Phone: FC<PhoneProps> = ({
  field // { name, value, onChange, onBlur }  // i deleted 'input'  
}) => {
  const { setFieldValue, values } = useFormikContext() ?? {}; // i deleted 'errors'
  
 
  const [phoneValue, setPhoneValue] = useState('');

  useEffect(() => {
    setPhoneValue((values as any)['phone'])
  }, [values])

  const onPhoneValue = (event:any) => {
     let value = event.target.value.replace(/[^\+\d]/g, '');
     setFieldValue('phone', value);
  }
 
  // const [phoneCode, setPhoneCode] = useState('+1');

  // const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   setPhoneCode(e.target.value)
  //   setFieldValue('phone_code', e.target.value)
  // }

  // useEffect(() => {
  //   setPhoneCode((values as any)['phone_code'])
  // }, [])
  
  return (
    <>
      <div className="input input--phone">
        {/* <select
          {...field}
          name="phone_code"
          className="input__field input__field--select"
          onChange={handleChange}
          value={phoneCode}
        > 
          {input.options && input.options.map((option, index) =>
            index > 0 ? <option key={option.key} value={option.key}>{option.value}</option> : null
          )}
        </select> */}
        <input
          {...field}
          className="input__field input__field--input"
          maxLength={16}
          onChange={onPhoneValue}
          value={phoneValue}
        />
      </div>
      {/* {!(errors as any)['phone'] && (errors as any)['phone_code'] &&
        <p className="error">
          {(errors as any)['phone_code']}
        </p>
      } */}
    </>
  )
}


export default Phone;