export enum TypesOfDatePickers {
    EXPIRATION_DATE = 'expiration_date',
    ADULTHOOD = 'adulthood',
}

export enum TypesOfInput {
    TEXT = 'text',
    PASSWORD = 'password',
    FILE = 'file',
    CHECKBOX = 'checkbox',
    SELECT = 'select',
    SELECT_REGIONS = 'select_regions',
    TEXTAREA = 'textarea',
    DATEPICKER = 'datepicker',
    PHONE = 'phone',
    RADIO = 'radio',
}
export interface IFieldOption {
    key: string,
    value: string,
}
export interface IField {
    type: TypesOfInput // тип инпута
    title?: string, // имя поля, которое будет отображаться над инпутом
    validation?: string, // правило валидации (в регулярке)
    validation_message?: string, // сообщение при провально валидации
    options?: IFieldOption[], // массив опшинов для выбора (если тип инпута 'select')
    category_id?: number, // id блока в котором находится инпут
    accept?: '', // разрешенные типы файлов (если тип инпута 'file')
    size: number, // относительный размер поля (1-12)
    value?: string | number | Date,
    dependency?: string,
}
export interface IFieldBack extends IField {
    order: number, // позиция поля в списке
    required: number, // правило обязательности поля
}
export interface IFieldFront extends IField {
    id: string, // это для фронта на беке не хранить!!!
    required?: boolean, // правило обязательности поля
    disabled?: boolean,
    placeholder?: string,
    customOnChange?: (value: string | number) => void,
    formatter?: (event: React.ChangeEvent<HTMLInputElement>) => string,
    dependencies?: unknown[],
    title?: string,
    url?: string, 
    
}
export interface ICategoryField {
    id: number | string,
    name: string,
    fields: IFieldFront[]
}

export interface IFieldError {
    [key: string]: string[]
}
export interface IFilledField {
    [key: string]: string | number
}

export enum TypesOfVerification {
    MAIL = "mail_verification"
}