import { put, takeLatest } from 'redux-saga/effects';
import { FetchUser } from '../../apis/user';
import { ICodes } from '../../models/ICodes';
import {
    FetchUser as TFetchUser,
    UserActionEnum,
} from '../reducers/user/types';
import { UserActionCreators } from '../reducers/user/action-creators';
import { IErrorResponse, ISuccessResponse } from '../../models/IResponse';
import { IUserGlobal } from '../../models/IUser';

export function* workerFetchUser({ payload }: TFetchUser): Generator {
    const user: ISuccessResponse<IUserGlobal> | IErrorResponse | any =
        yield FetchUser(payload);
    if (user.status !== ICodes.OK) {
        return;
    }

    yield put(UserActionCreators.updateUser(user.data.data));
}

export function* watchUser() {
    yield takeLatest(UserActionEnum.FETCH_USER, workerFetchUser);
}
