import { Button, Table } from 'antd';
import 'antd/dist/antd.css';
import { PaperClipOutlined } from '@ant-design/icons';
import '../../../pending-cases/styles/pendingCases.css';
import { useEffect, useState } from 'react';
import {
    ITempObjMessage,
    IHistoryMessagesPending,
} from '../../../../../models/IPendingCaseLawyer';
import MessageModal from './MessageModal/MessageModal';
import { PostOpenMessage } from '../../../../../apis/pendingCases';
import WriteCustomerModal from './WriteCustomerModal/WriteCustomerModal';
import InvoicePaymentModal from './InvoicePaymentModal/InvoicePaymentModal';

type TProps = {
    data: IHistoryMessagesPending[];
    chatId?: number;
    userId?: number;
    onReloadTableAfterSend: () => void;
    onShowModalInvoice?: () => void;
    onReloadTablePayment?: () => void;
    invoicePayment?: boolean;
};

const HistoryMessagePendingCase = ({
    data,
    onReloadTableAfterSend,
    onShowModalInvoice,
    onReloadTablePayment,
    invoicePayment,
}: TProps) => {
    const [dataMessages, setDataMessages] =
        useState<IHistoryMessagesPending | null>(null);
    const [dataMessagesForTable, setDataMessagesForTable] = useState<
        ITempObjMessage[]
    >([]);
    const [dataForMessageModal, setDataForMessageModal] =
        useState<ITempObjMessage>();
    const [showWriteLawyerModal, setShowWriteCustomerModal] =
        useState<boolean>(false);
    const [showMessageModal, setShowMessageModal] = useState<boolean>(false);
    const [resetDataDocumentsInMessage, setResetDataDocumentsInMessage] =
        useState<boolean>(false);
    const [roleWriteToCustomer, setRoleWriteToCustomer] = useState<number>(0);

    useEffect(() => {
        setDataMessages(data[0]);
    }, [data]);

    useEffect(() => {
        let tempDataMessages: ITempObjMessage[] = [];
        let tempNumberMessages: number = 0;
        if (dataMessages) {
            tempNumberMessages = dataMessages?.messages?.length + 1;
        }
        if (dataMessages) {
            dataMessages.messages?.forEach(item => {
                let tempObj: ITempObjMessage = {
                    id: 0,
                    key: 0,
                    number: 0,
                    sent: '',
                    title: '',
                    description: '',
                    descriptionFull: '',
                    date: '',
                    name: '',
                    open: '',
                    documents: [],
                    attachedDocuments: false,
                    viewed: 0,
                };
                tempObj.id = item.id;
                tempObj.key = tempNumberMessages--;
                tempObj.number = tempNumberMessages;
                if (item.documents.length === 0) {
                    tempObj.attachedDocuments = false;
                } else {
                    tempObj.attachedDocuments = true;
                }
                tempObj.title = String(item.title);
                tempObj.description = String(item.content);
                tempObj.descriptionFull = String(item.content);
                tempObj.date = item.date_created_human;
                tempObj.documents = item.documents;
                dataMessages.user_name
                    ? (tempObj.name = dataMessages.user_name)
                    : (tempObj.name = 'unnamed');
                dataMessages.user_id === item.id_created
                    ? (tempObj.sent = 'Customer')
                    : (tempObj.sent = 'You');
                tempObj.open = 'Open';
                tempObj.viewed = item.viewed;
                tempDataMessages.push(tempObj);
            });
        }
        setDataMessagesForTable(tempDataMessages);
    }, [dataMessages]);

    const columns = [
        {
            title: 'Number',
            dataIndex: 'number',
            align: 'center' as 'center',
            className: 'table-number-history-message',
        },
        {
            title: 'Sent',
            dataIndex: 'sent',
            align: 'center' as 'center',
            ellipsis: true,
        },
        {
            title: 'Attached',
            dataIndex: 'attachedDocuments',
            align: 'center' as 'center',
            render: (attachedDocuments: boolean) =>
                attachedDocuments === true ? <PaperClipOutlined /> : null,
        },
        {
            title: 'Title message',
            dataIndex: 'title',
            align: 'center' as 'center',
            className: 'table-title-message-history-message',
            ellipsis: true,
        },
        {
            title: 'Short description',
            dataIndex: 'description',
            align: 'center' as 'center',
            ellipsis: true,
            className: 'table-description-history-message',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            align: 'center' as 'center',
            className: 'table-date-history-message',
        },
        {
            title: 'Name Customer',
            dataIndex: 'name',
            align: 'center' as 'center',
            ellipsis: true,
            className: 'table-name-customer-history-message',
        },

        {
            title: 'Open',
            dataIndex: 'open',
            id: 'key',
            render: (text: any, key: any) => (
                <Button onClick={() => onShowMessageModal(key)} danger>
                    {text}
                </Button>
            ),
            align: 'center' as 'center',
        },
    ];

    const onShowWriteLawyerModal = (num: number) => {
        setRoleWriteToCustomer(num);
        setShowWriteCustomerModal(true);
        setShowMessageModal(false);
    };

    const onCloseMessageModal = () => {
        setShowMessageModal(false);
    };

    const onShowMessageModal = (key: any) => {
        if (key.viewed === 0) {
            PostOpenMessage(key.id)
                .then(res => {
                    console.log(res);
                })
                .then(() => {
                    onReloadTableAfterSend();
                });
        }
        setDataForMessageModal(key);
        setShowMessageModal(true);
    };

    return (
        <div className="messages-pending-case-details card">
            <div className="messages-pending-case-details-table-box">
                <div className="messages-pending-case-details-table-title">
                    History message
                </div>
                <div className="messages-pending-case-details-table-body">
                    <Table
                        columns={columns}
                        dataSource={
                            dataMessages?.messages ? dataMessagesForTable : []
                        }
                        rowClassName={(record: any, index: any) => {
                            if (
                                record.viewed === 0 &&
                                record.sent === 'Customer'
                            ) {
                                return 'message-viewed';
                            }
                            return '';
                        }}
                        bordered
                    />
                </div>
            </div>
            {data[0]?.status === 2 ? (
                <div className="messages-pending-case-details-payment-arrears-box">
                    <div className="messages-pending-case-details-payment-arrears-write">
                        <Button
                            onClick={() => {
                                onShowWriteLawyerModal(1);
                            }}
                            type="primary"
                        >
                            Write to a customer
                        </Button>
                    </div>
                </div>
            ) : null}

            <InvoicePaymentModal
                showMessageModal={invoicePayment}
                onShowWriteLawyerModal={onShowModalInvoice}
                dataId={dataMessages?.id}
                onReloadTablePayment={onReloadTablePayment}
            />
            <MessageModal
                showMessageModal={showMessageModal}
                onCloseMessageModal={onCloseMessageModal}
                onShowWriteLawyerModal={onShowWriteLawyerModal}
                dataForMessageModal={dataForMessageModal}
                caseNumber={dataMessages?.id}
                status={data[0]?.status}
            />
            <WriteCustomerModal
                showWriteLawyerModal={showWriteLawyerModal}
                setShowWriteCustomerModal={setShowWriteCustomerModal}
                setShowMessageModal={setShowMessageModal}
                setResetDataDocumentsInMessage={setResetDataDocumentsInMessage}
                chatId={dataMessages?.chat_id}
                userId={dataMessages?.general_lawyer}
                messageNumber={dataMessagesForTable.length}
                onReloadTableAfterSend={onReloadTableAfterSend}
                resetDataDocumentsInMessage={resetDataDocumentsInMessage}
                roleWriteToCustomer={roleWriteToCustomer}
                messagesTitle={dataForMessageModal?.title}
                messagesTitleNumber={dataForMessageModal?.number}
                setRoleWriteToCustomer={setRoleWriteToCustomer}
            />
        </div>
    );
};

export default HistoryMessagePendingCase;
