import React, { useState, useEffect } from 'react';
import { GetPaymentsPending } from '../../../../apis/payments';
import { DataPaymentsPendingType } from '../../../../models/IPayments';
import { Button, Table } from 'antd';
import { Link, NavLink } from 'react-router-dom';
import { DashboardRouteNames } from '../../../../router/Dashboard';
import PendingPaymentsCustomerModal from './PendingPaymentsCustomerModal/PendingPaymentsCustomerModal';
import preloaderImg from '../../../../assets/images/preloader.gif';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { useSubscription } from 'mqtt-react-hooks';
import moment from 'moment';

const PendingPaymentsCustomer = () => {
    const { user } = useTypedSelector(state => state.user);
    const [dataPaymentsPending, setDataPaymentsPending] = useState<
        DataPaymentsPendingType[]
    >([]);
    const [selectedDataPaymnetsPending, setSelectedDataPaymnetsPending] =
        useState<any[]>([]);
    const [selectedDataForModal, setSelectedDataForModal] = useState<any>(null);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [preloader, setPreloader] = useState<boolean>(false);
    const messageMqtt = useSubscription([
        `${process.env.REACT_APP_ENVIRONMENT}/topic/user/${user?.user_id}`,
    ]);

    useEffect(() => {
        setPreloader(true);
        GetPaymentsPending(user?.user_id).then(res => {
            setDataPaymentsPending(res.data.data);
            setPreloader(false);
        });
    }, []);

    useEffect(() => {
        let messageMqttJSON: any = messageMqtt?.message;
        let objSubscriptionMqtt =
            messageMqttJSON && JSON.parse(messageMqttJSON?.message);
        if (objSubscriptionMqtt?.action === 'create-invoice') {
            GetPaymentsPending(user?.user_id).then(res => {
                setDataPaymentsPending(res.data.data);
            });
        }
    }, [messageMqtt?.message]);

    useEffect(() => {
        let tempArrayForTable: any[] = [];
        let countForTable = 0;
        dataPaymentsPending.forEach(item => {
            let tempObjForTable: any = {};
            tempObjForTable.key = ++countForTable;
            tempObjForTable.dateAndTime = item.date_create_human;
            tempObjForTable.caseNumber = item.case_id;
            tempObjForTable.description = item.invoice_description;
            tempObjForTable.amount = `${item.price} AED`;
            tempObjForTable.status = 'Pending';
            tempObjForTable.more = 'More';
            tempObjForTable.pay = 'Pay';
            tempObjForTable.nameCase = item.name;
            tempObjForTable.invoiceId = item.invoice_id;
            tempObjForTable.invoiceTransaction = item.invoiceTransaction;
            tempArrayForTable.push(tempObjForTable);
        });
        tempArrayForTable.sort(
            (a, b) =>
                moment(b.dateAndTime).unix() - moment(a.dateAndTime).unix(),
        );
        setSelectedDataPaymnetsPending(tempArrayForTable);
    }, [dataPaymentsPending]);

    const columns = [
        {
            title: 'Opening Date',
            dataIndex: 'dateAndTime',
            key: 'dateAndTime',
            className: 'table-date',
            align: 'center' as 'center',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center' as 'center',
        },
        {
            title: 'Description Case',
            dataIndex: 'description',
            key: 'description',
            align: 'center' as 'center',
            className: 'table-description',
            ellipsis: true,
        },
        {
            title: 'Payment title',
            dataIndex: 'caseNumber',
            key: 'caseNumber',
            align: 'center' as 'center',
            id: 'key',
            className: 'table-payment-title',
            render: (caseNumber: number, key: any) => (
                <div>
                    <Link
                        to={{
                            pathname: `${DashboardRouteNames.PENDING_CASE_DETAILS}`,
                            state: { id: key },
                        }}
                    >
                        # {caseNumber}
                    </Link>
                    <div>{key.nameCase}</div>
                </div>
            ),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            align: 'center' as 'center',
        },
        {
            title: 'More',
            dataIndex: 'more',
            key: 'more',
            id: 'key',
            align: 'center' as 'center',
            render: (more: string, key: any) => (
                <Button
                    danger
                    onClick={() => {
                        onShowModal(key);
                    }}
                >
                    {more}
                </Button>
            ),
        },
        {
            title: 'Pay',
            dataIndex: 'pay',
            key: 'pay',
            id: 'key',
            align: 'center' as 'center',
            render: (pay: string, key: any) => (
                <Button type="primary">
                    <NavLink
                        to={{
                            pathname: `${DashboardRouteNames.INVOICE_PAY_PENDING_CASE}`,
                            state: {
                                id: key.invoiceId,
                            },
                        }}
                    >
                        {pay}
                    </NavLink>
                </Button>
            ),
        },
    ];

    const onShowModal = (key: any) => {
        setSelectedDataForModal(key);
        setShowModal(true);
    };

    const onCloseModal = () => {
        setShowModal(false);
    };

    return (
        <div className="service-container">
            <div className="service__title">Pending Payments</div>
            <div className="pending-payments-customer-table">
                {preloader ? (
                    <div className="preloader-box-payments">
                        <img
                            className="preloader-img"
                            src={preloaderImg}
                            alt="Loading..."
                        />
                    </div>
                ) : (
                    <Table
                        columns={columns}
                        dataSource={
                            selectedDataPaymnetsPending.length > 0
                                ? selectedDataPaymnetsPending
                                : []
                        }
                    />
                )}
            </div>
            <PendingPaymentsCustomerModal
                showModal={showModal}
                onCloseModal={onCloseModal}
                selectedDataForModal={selectedDataForModal}
            />
        </div>
    );
};

export default PendingPaymentsCustomer;
