import React, { useState, useEffect } from 'react';
import { Modal, Button, Image, Table } from 'antd';
import pdfLogo from '../../../../../../assets/images/pdf.png';
import docLogo from '../../../../../../assets/images/doc.png';

const MessageModalPendingCase = (props: any) => {
    const [dataDocumentsForTable, setDataDocumentsForTable] = useState<any[]>(
        [],
    );

    useEffect(() => {
        let tempDataDocumentsForTable: any[] = [];
        props.dataForMessageModal?.documents.forEach(
            (item: any, index: number) => {
                let tempObjDocumentForTable: any = {};
                tempObjDocumentForTable.key = index;
                tempObjDocumentForTable.document_url = item.document_url;
                if (item.description) {
                    tempObjDocumentForTable.description = item.description;
                } else {
                    tempObjDocumentForTable.description = 'No description';
                }
                tempDataDocumentsForTable.push(tempObjDocumentForTable);
            },
        );
        setDataDocumentsForTable(tempDataDocumentsForTable);
    }, [props.dataForMessageModal?.documents]);

    const columns = [
        {
            title: 'Document',
            dataIndex: 'document_url',
            render: (text: string) => {
                if (text.split('.').reverse()[0] === 'pdf') {
                    return (
                        <div>
                            <Image width={50} src={pdfLogo} />
                            <div>
                                <a href={text} target="_blank" rel="noreferrer">
                                    Open document
                                </a>
                            </div>
                        </div>
                    );
                } else if (
                    text.split('.').reverse()[0] === 'doc' ||
                    text.split('.').reverse()[0] === 'docx'
                ) {
                    return (
                        <div>
                            <Image width={50} src={docLogo} />
                            <div>
                                <a href={text} target="_blank" rel="noreferrer">
                                    Open document
                                </a>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div>
                            <Image width={50} src={text} />
                            <div>
                                <a href={text} target="_blank" rel="noreferrer">
                                    Open document
                                </a>
                            </div>
                        </div>
                    );
                }
            },
            align: 'center' as 'center',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            align: 'center' as 'center',
            className: 'documents-table-pending-case-details',
            ellipsis: true
        },
    ];

    return (
        <>
            <Modal
                visible={props.showMessageModal}
                title={props.dataForMessageModal?.name}
                onCancel={props.onCloseMessageModal}
                footer={[
                    <Button
                        key="Back"
                        danger
                        onClick={props.onCloseMessageModal}
                    >
                        Back
                    </Button>,
                    <Button
                        key="Write"
                        type="primary"
                        onClick={() => {
                            props.onShowWriteLawyerModal(2);
                        }}
                        disabled={props.caseStatus !== 2}
                    >
                        Resend
                    </Button>,
                ]}
            >
                <div className="message-modal-pending-case-subtitle">
                    Message Number {props.dataForMessageModal?.number} for Case{' '}
                    {props.caseNumber}
                </div>
                <div className="message-modal-pending-case-subtitle-box">
                    <div className="message-modal-pending-case-subtitle-title">
                        Title:
                    </div>
                    <div className="message-modal-pending-case-subtitle-text">
                        {props.dataForMessageModal?.title}
                    </div>
                </div>

                <div className="message-modal-pending-case-description">
                    <div className="message-modal-pending-case-description-title">
                        Description:
                    </div>
                    <div className="message-modal-pending-case-description-text">
                        {props.dataForMessageModal?.descriptionFull}
                    </div>
                </div>
                {dataDocumentsForTable && dataDocumentsForTable.length > 0 && (
                    <div className="message-modal-pending-case-description">
                        <div className="message-modal-pending-case-description-title">
                            Documents:
                        </div>
                        <Table
                            columns={columns}
                            dataSource={
                                dataDocumentsForTable
                                    ? dataDocumentsForTable
                                    : []
                            }
                            bordered
                        />
                    </div>
                )}
                <div className="message-modal-pending-case-date">
                    {props.dataForMessageModal?.date}
                    {props.dataForMessageModal?.sent === 'Lawyer'
                        ? ' From Lawyer to You'
                        : ' From You to Lawyer'}
                </div>
            </Modal>
        </>
    );
};

export default MessageModalPendingCase;
