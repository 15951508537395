import { FC, useRef } from 'react';
import { Row } from 'react-bootstrap';
import { ICategoryField, IFieldFront } from '../../models/IField';
import FieldWithValidation from './FieldWithValidation';

interface FieldBlockProps {
    category: ICategoryField;
    setDisabledAgreed?: React.Dispatch<React.SetStateAction<boolean>>;
}

const FieldBlock: FC<FieldBlockProps> = ({ category, setDisabledAgreed }) => {
    const fieldIframeRef = useRef<HTMLDivElement>(null);

    const handleScroll = (event: any) => {
        const fieldIframe = document.querySelector('.field-iframe');
        if (fieldIframeRef !== null && fieldIframe !== null) {
            if (fieldIframeRef.current !== null) {
                const scrolledAmount =
                    fieldIframe?.scrollTop +
                    fieldIframeRef.current.offsetHeight;
                if (scrolledAmount >= 752) {
                    setDisabledAgreed && setDisabledAgreed(false);
                } else {
                    setDisabledAgreed && setDisabledAgreed(true);
                }
            }
        }
    };

    if (category.fields && category.fields[0].id === 'agreement_pdf') {
        return (
            <div className="field-block">
                <div className="field-box">
                    <div
                        className="field-iframe"
                        onScroll={handleScroll}
                        ref={fieldIframeRef}
                    >
                        <object
                            id="iframe-pdf"
                            title="PDF"
                            data={`${category?.fields[0]?.url}#toolbar=0&statusbar=0&navpanes=0#zoom=85`}
                            type="application/pdf"
                            height="800px"
                            width="100%"
                        ></object>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="field-block">
                <Row>
                    {category.fields &&
                        category.fields.map(
                            (field: IFieldFront) =>
                                field.id !== 'agreement_pdf' && (
                                    <FieldWithValidation
                                        key={field.id}
                                        field={field}
                                    />
                                ),
                        )}
                </Row>
            </div>
        );
    }
};

export default FieldBlock;
