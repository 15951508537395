import React, { useState } from 'react';
import { Modal, Button, notification } from 'antd';
import { PostRejectCase } from '../../../../../../apis/openCase';

const RejectOpenCaseLawyerModal = (props: any) => {
    const [emptyInput, setEmptyInput] = useState<boolean>(false);
    const [showCyrillic, setShowCyrillic] = useState<boolean>(false);

    const onDescriptionReject = (event: any) => {
        props.setDescriptionRejectValue(event.target.value);
        const cyrillicPattern = /[\u0400-\u04FF]/;
        if (cyrillicPattern.test(event.target.value)) {
            setShowCyrillic(true);
        } else {
            setShowCyrillic(false);
        }
    };

    const sendReject = () => {
        if (props.descriptionRejectValue !== '' && showCyrillic !== true) {
            PostRejectCase(
                props.descriptionRejectValue,
                props.dataForRejectOpenCaseLawyerModal,
            ).then(value => {
                if (value.data.data.status) {
                    notification.success({
                        message: `Case #${props.dataForRejectOpenCaseLawyerModal} successfully rejected`,
                    });
                    props.onCloseRejectOpenCaseLawyerModal();
                } else {
                    props.onCloseRejectOpenCaseLawyerModal();
                }
            });
            setInterval(() => {
                props.onReloadAfterTakeToWork();
            }, 2000);
        } else {
            setEmptyInput(true);
            setInterval(() => {
                setEmptyInput(false);
            }, 2000);
        }
    };

    return (
        <Modal
            visible={props.showRejectOpenCaseLawyerModal}
            title="Reject This Case"
            onCancel={props.showConfirm}
            footer={[
                <Button key="Reject" danger onClick={props.showConfirm}>
                    Back
                </Button>,
                <Button key="Send" type="primary" onClick={sendReject}>
                    Send
                </Button>,
            ]}
        >
            <div className="card-title">Description:</div>
            <textarea
                className={emptyInput ? 'card-textarea-error' : 'card-textarea'}
                placeholder="example..."
                value={props.descriptionRejectValue}
                onChange={onDescriptionReject}
            ></textarea>
            {
                <div
                    className={
                        showCyrillic
                            ? 'card-upload-box-input-message-error'
                            : 'card-upload-box-input-message'
                    }
                >
                    Enter only latin characters
                </div>
            }
        </Modal>
    );
};

export default RejectOpenCaseLawyerModal;
