import React from 'react';



const ClosedPayments = () => {
    return(
        <div>
            Coming Soon
        </div>
    );
}


export default ClosedPayments;