import * as types from './types';

const initialState: types.ProfileState = {
    profileValidation: null,
    profileFields: null,
    profileValues: null,
    securityFields: null,
    token: '',
    repeatingTransaction: null,
}

export const profileReducer = (state = initialState, action: types.ProfileAction): types.ProfileState => {
    switch (action.type) {
        case types.ProfileActionEnum.SET_FIELDS:
            return {
                ...state,
                profileFields: action.payload,
            }

        case types.ProfileActionEnum.SET_VALIDATION:
            return {
                ...state,
                profileValidation: action.payload,
            }

        case types.ProfileActionEnum.SET_VALUES:
            return {
                ...state,
                profileValues: action.payload,
            }

        case types.ProfileActionEnum.SET_SECURITY_FIELDS:
            return {
                ...state,
                securityFields: action.payload,
            }

        case types.ProfileActionEnum.SET_TOKEN:
            return {
                ...state,
                token: action.payload,
            }

        case types.ProfileActionEnum.SET_REPEATING_TRANSACTION:
            return {
                ...state,
                repeatingTransaction: action.payload,
            }

        default:
            return state;
    }
}

export default profileReducer;