import { Upload, Button, message, Table } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DeleteOutlined } from '@ant-design/icons';

const AddDocuments = (props: any) => {
    const propsUpload = {
        name: 'documents',
        action: `${process.env.REACT_APP_BASE_URL}/save-documents`,
        headers: {
            authorization: 'documents',
        },
        beforeUpload: (file: any) => {
            const isJPG = file.type === 'image/jpg';
            const isJPEG = file.type === 'image/jpeg';
            const isPNG = file.type === 'image/png';
            const isPDF = file.type === 'application/pdf';
            const isDOC = file.type === 'application/msword';
            const isDOCX =
                file.type ===
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!(isJPG || isJPEG || isPNG || isPDF || isDOC || isDOCX)) {
                message.error(`jpg, jpeg, png, pdf, doc, docx allowed`);
            } else if (!isLt2M) {
                message.error('File must not be bigger than 5 Mb');
            }
            return (
                isJPG ||
                isJPEG ||
                isPNG ||
                isPDF ||
                isDOC ||
                isDOCX ||
                Upload.LIST_IGNORE
            );
        },
        onChange: (info: any) => {
            if (info.file?.status !== 'uploading') {
                props.setDocumentsFileList([
                    ...props.documentsFileList,
                    info.fileList[info.fileList.length - 1],
                ]);
            }
            if (info.file?.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file?.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onRemove: (file: any) => {
            const index = props.documentsFileList.indexOf(file);
            const newFileList = props.documentsFileList.slice();
            newFileList.splice(index, 1);
            props.setDocumentsFileList(newFileList);
            props.setShowCyrillicDocuments(false);
        },
    };

    const columns = [
        {
            title: 'Uploaded File',
            dataIndex: 'uploadedFile',
            key: 'uploadedFile',
            align: 'center' as 'center',
            ellipsis: true,
        },
        {
            title: props.showCyrillicDocuments ? (
                <div
                    style={{ color: '#f5222d' }}
                >{`Description \n (enter only latin characters)`}</div>
            ) : (
                <div>Description</div>
            ),
            align: 'center' as 'center',
            className:
                'send-message-description-table documents-table-description',
            render: (item: any) => (
                <div>
                    <input
                        id={item.id}
                        className="card-upload-box-input-document-description"
                        type="text"
                        maxLength={256}
                        placeholder="complete description"
                        onChange={event => {
                            props.onDescriptionDocuments(event, item);
                        }}
                    />
                </div>
            ),
        },
        {
            title: 'Delete file',
            dataIndex: 'deleteFile',
            key: 'deleteFile',
            align: 'center' as 'center',
            ellipsis: true,
            render: (deleteFile: any) => (
                <span>
                    <DeleteOutlined
                        className="table-remove"
                        onClick={() => {
                            propsUpload.onRemove(deleteFile);
                        }}
                    />
                </span>
            ),
        },
    ];

    return (
        <div>
            <Upload {...propsUpload} showUploadList={false}>
                <Button type="primary" icon={<UploadOutlined />}>
                    Add Additional Documents
                </Button>
            </Upload>
            <div style={{ marginTop: '15px' }}>
                {props.valueForTable.length > 0 ? (
                    <Table
                        columns={columns}
                        dataSource={
                            props.valueForTable.length > 0
                                ? props.valueForTable
                                : []
                        }
                    />
                ) : null}
            </div>
        </div>
    );
};

export default AddDocuments;
