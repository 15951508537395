import React, { useState, useEffect } from 'react';
import { Modal, Button, Image, Table, notification } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { PostTakeToWork } from '../../../../../apis/openCase';
import RejectOpenCaseLawyerModal from './RejectOpenCaseLawyerModal/RejectOpenCaseLawyerModal';
import { DashboardRouteNames } from '../../../../../router/Dashboard';
import pdfLogo from '../../../../../assets/images/pdf.png';
import docLogo from '../../../../../assets/images/doc.png';

const DetailsOpenCaseLawyerModal = (props: any) => {
    const [showRejectOpenCaseLawyerModal, setShowRejectOpenCaseLawyerModal] =
        useState<boolean>(false);
    const [
        dataForRejectOpenCaseLawyerModal,
        setDataForRejectOpenCaseLawyerModal,
    ] = useState<number>(0);
    const [dataDocumentsForTable, setDataDocumentsForTable] = useState<any[]>(
        [],
    );
    const [descriptionRejectValue, setDescriptionRejectValue] =
        useState<string>('');

    let history = useHistory();
    const { confirm } = Modal;

    useEffect(() => {
        setDataForRejectOpenCaseLawyerModal(
            props.dataForDetailsOpenCaseLawyerModal?.case_id,
        );
    }, [props.dataForDetailsOpenCaseLawyerModal]);

    useEffect(() => {
        let tempDataDocumentsForTable: any[] = [];
        props.dataForDetailsOpenCaseLawyerModal?.documents?.forEach(
            (item: any, index: number) => {
                let tempObjDocumentForTable: any = {};
                tempObjDocumentForTable.key = index;
                tempObjDocumentForTable.document_url = item.document_url;
                if (item.description) {
                    tempObjDocumentForTable.description = item.description;
                } else {
                    tempObjDocumentForTable.description = 'No description';
                }
                tempDataDocumentsForTable.push(tempObjDocumentForTable);
            },
        );
        setDataDocumentsForTable(tempDataDocumentsForTable);
    }, [props.dataForDetailsOpenCaseLawyerModal?.documents]);

    const onTakeToWork = () => {
        PostTakeToWork(props.dataForDetailsOpenCaseLawyerModal?.case_id).then(
            res => {
                if (res.status !== 422) {
                    notification.success({
                        message: `Case # ${props.dataForDetailsOpenCaseLawyerModal?.case_id} successfully taken to work`,
                    });
                    history.push(
                        `${DashboardRouteNames.PENDING_OPEN_DETAILS_CUSTOMER}`,
                        {
                            key: props.dataForDetailsOpenCaseLawyerModal
                                ?.case_id,
                        },
                    );
                }
            },
        );
        props.onCloseDetailsOpenCaseLawyerModal();
        setTimeout(() => {
            props.onReloadAfterTakeToWork();
        }, 2000);
    };

    const columns = [
        {
            title: 'Document',
            dataIndex: 'document_url',
            render: (text: string) => {
                if (text.split('.').reverse()[0] === 'pdf') {
                    return (
                        <div>
                            <Image width={50} src={pdfLogo} />
                            <div>
                                <a href={text} target="_blank" rel="noreferrer">
                                    Open document
                                </a>
                            </div>
                        </div>
                    );
                } else if (
                    text.split('.').reverse()[0] === 'doc' ||
                    text.split('.').reverse()[0] === 'docx'
                ) {
                    return (
                        <div>
                            <Image width={50} src={docLogo} />
                            <div>
                                <a href={text} target="_blank" rel="noreferrer">
                                    Open document
                                </a>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div>
                            <Image width={50} src={text} />
                            <div>
                                <a href={text} target="_blank" rel="noreferrer">
                                    Open document
                                </a>
                            </div>
                        </div>
                    );
                }
            },
            align: 'center' as 'center',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            align: 'center' as 'center',
            className: 'documents-table-pending-case-details',
            ellipsis: true,
        },
    ];

    const onShowRejectOpenCaseLawyerModal = () => {
        setShowRejectOpenCaseLawyerModal(true);
        props.setShowDetailsOpenCaseLawyerModal(false);
    };

    const onCloseRejectOpenCaseLawyerModal = () => {
        setShowRejectOpenCaseLawyerModal(false);
        setDescriptionRejectValue('');
    };

    const showConfirm = () => {
        confirm({
            title: 'Do you want to close this message?',
            icon: <ExclamationCircleOutlined />,
            content: 'The entered data will be deleted',
            cancelButtonProps: {
                type: 'primary',
            },
            onOk() {
                onCloseRejectOpenCaseLawyerModal();
            },
            onCancel() {
                return;
            },
        });
    };

    return (
        <>
            <Modal
                visible={props.showDetailsOpenCaseLawyerModal}
                title={props.dataForDetailsOpenCaseLawyerModal?.name_services}
                onCancel={props.onCloseDetailsOpenCaseLawyerModal}
                footer={[
                    <Button
                        key="Reject"
                        danger
                        onClick={onShowRejectOpenCaseLawyerModal}
                    >
                        REJECT
                    </Button>,
                    <Button
                        key="Take to work"
                        type="primary"
                        onClick={onTakeToWork}
                    >
                        ACCEPT CASE
                    </Button>,
                ]}
            >
                <div className="open-case-lawyer-modal-header-box">
                    <div className="open-case-lawyer-modal-header-number-case">
                        # {props.dataForDetailsOpenCaseLawyerModal?.number_case}
                    </div>
                    <div className="open-case-lawyer-modal-header-name-customer">
                        {props.dataForDetailsOpenCaseLawyerModal?.name_customer}
                    </div>
                </div>

                <div className="open-case-lawyer-modal-body-box">
                    <div className="open-case-lawyer-modal-body-title">
                        Initial Information
                    </div>
                    <div className="open-case-lawyer-modal-body-description">
                        {
                            props.dataForDetailsOpenCaseLawyerModal
                                ?.initial_information_full
                        }
                    </div>
                </div>

                <div className="open-case-lawyer-modal-documents-box">
                    <div className="open-case-lawyer-modal-documents-title">
                        Added Document:
                    </div>
                    <div>
                        {props.dataForDetailsOpenCaseLawyerModal?.documents ? (
                            <Table
                                columns={columns}
                                dataSource={
                                    dataDocumentsForTable
                                        ? dataDocumentsForTable
                                        : []
                                }
                                bordered
                            />
                        ) : (
                            <div className="open-case-lawyer-modal-documents-text">
                                Customer has not added documents
                            </div>
                        )}
                    </div>
                </div>
            </Modal>
            <RejectOpenCaseLawyerModal
                showRejectOpenCaseLawyerModal={showRejectOpenCaseLawyerModal}
                dataForRejectOpenCaseLawyerModal={
                    dataForRejectOpenCaseLawyerModal
                }
                showConfirm={showConfirm}
                onCloseRejectOpenCaseLawyerModal={
                    onCloseRejectOpenCaseLawyerModal
                }
                descriptionRejectValue={descriptionRejectValue}
                setDescriptionRejectValue={setDescriptionRejectValue}
                onReloadAfterTakeToWork={props.onReloadAfterTakeToWork}
            />
        </>
    );
};

export default DetailsOpenCaseLawyerModal;
