import { Axios } from '../plugins/helpers';
import { notificationError } from '../plugins/requestNotification';

export const GetPendingLawyerCases = async () => {
    try {
        const { data } = await Axios().get(
            `${process.env.REACT_APP_BASE_URL}/get-lawyer-cases?status=2`,
        );
        return data;
    } catch (e: any) {
        notificationError(e);
        return e?.response;
    }
};

export const GetPendingLawyerCaseDetails = async (id: string) => {
    try {
        const { data } = await Axios().get(
            `${process.env.REACT_APP_BASE_URL}/get-lawyer-cases?status=2&service_id=${id}`,
        );
        return data;
    } catch (e: any) {
        notificationError(e);
        return e?.response;
    }
};

export const GetOpenDetailsCase = async (id: string) => {
    try {
        const { data } = await Axios().get(
            `${process.env.REACT_APP_BASE_URL}/get-lawyer-cases?status=2&case_id=${id}`,
        );
        return data;
    } catch (e: any) {
        notificationError(e);
        return e?.response;
    }
};

export const sendMessageClient = async (
    user_id: number,
    chat_id: number,
    description: string,
    parent_id: number,
    title: string,
    message_number: number,
    documents?: any,
) => {
    try {
        const response = await Axios().post(
            `${process.env.REACT_APP_BASE_URL}/add-message`,
            {
                user_id,
                chat_id,
                parent_id,
                title,
                description,
                documents,
                message_number,
            },
        );
        return response;
    } catch (e: any) {
        notificationError(e);
        return e?.response;
    }
};

export const createInvoicePayment = async (
    case_id: number,
    description: string,
    price: number,
) => {
    try {
        const response = await Axios().post(
            `${process.env.REACT_APP_BASE_URL}/create-case-invoice`,
            { case_id, description, price },
        );
        return response;
    } catch (e: any) {
        notificationError(e);
        return e?.response;
    }
};

export const PostCloseCase = async (case_id: number, description: string) => {
    try {
        const response = await Axios().post(
            `${process.env.REACT_APP_BASE_URL}/close-case`,
            { case_id, description },
        );
        return response;
    } catch (e: any) {
        notificationError(e);
        return e?.response;
    }
};
