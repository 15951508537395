import { Axios } from '../plugins/helpers';
import { notificationError } from '../plugins/requestNotification';



export const PostCreateCalendarEvent = async (
    date: number,
    title: string,
    description: string,
    importance: number,
    case_id: number,
) => {
    try {
        const response = await Axios().post(
            `${process.env.REACT_APP_BASE_URL}/create-event-case`,
            { date, title, description, importance, case_id },
        );
        return response;
    } catch (e: any) {
        notificationError(e);
        return e?.response;
    }
};