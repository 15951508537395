import React from 'react';



const PendingPayments = () => {
    return(
        <div>
            PendingPayments
        </div>
    );
}


export default PendingPayments;