import React from 'react';

const ArchiveInformation = (props: any) => {
    return (
        <div
            className="card messages-pending-case-details"
            style={{ backgroundColor: '#ffa90045' }}
        >
            <div className="pending-case-archive-info-box">
                <div>
                    {props.messagesUserCase?.status === 4
                        ? 'This case has been rejected'
                        : 'This case has been closed'}
                </div>
                <div className="pending-case-archive-description">
                    {props.messagesUserCase?.close_description}
                </div>
            </div>
        </div>
    );
};

export default ArchiveInformation;
