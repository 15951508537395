import { Axios } from '../plugins/helpers';
import { notificationError } from '../plugins/requestNotification';

export const PostAddDocuments = async (case_id: number, documents: any) => {
    try {
        const response = await Axios().post(
            `${process.env.REACT_APP_BASE_URL}/add-documents-to-case`,
            { case_id, documents },
        );
        return response;
    } catch (e: any) {
        notificationError(e);
        return e?.response;
    }
};
