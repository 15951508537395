import React from 'react';



const HowItWorksCustomer = () => {
    return(
        <div>
            HowItWorksCustomer
        </div>
    );
}

export default HowItWorksCustomer;