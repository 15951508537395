import React from 'react';
import { Modal, Button } from 'antd';

const ShowDataEventModal = (props: any) => {
    return (
        <>
            <Modal
                visible={props.showDataEvent}
                title="Event Details"
                onCancel={props.onShowDataEvent}
                width="600px"
                footer={[
                    <Button key="Back" danger onClick={props.onShowDataEvent}>
                        Back
                    </Button>,
                ]}
            >
                <div>
                    <div className="message-modal-pending-case-subtitle-box">
                        <div className="message-modal-pending-case-subtitle-title">
                            Date and Time:
                        </div>
                        <div className="message-modal-pending-case-subtitle-text">
                            {props.dataEvent?.date}
                        </div>
                    </div>
                    <div className="message-modal-pending-case-subtitle-box">
                        <div className="message-modal-pending-case-subtitle-title">
                            Importance:
                        </div>
                        <div className="message-modal-pending-case-subtitle-text">
                            {props.dataEvent?.importanceName}
                        </div>
                    </div>
                    <div className="message-modal-pending-case-subtitle-box">
                        <div className="message-modal-pending-case-subtitle-title">
                            Title:
                        </div>
                        <div className="message-modal-pending-case-subtitle-text">
                            {props.dataEvent?.title}
                        </div>
                    </div>
                    <div className="message-modal-pending-case-subtitle-box">
                        <div className="message-modal-pending-case-subtitle-title">
                            Description:
                        </div>
                        <div className="message-modal-pending-case-subtitle-text">
                            {props.dataEvent?.description}
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ShowDataEventModal;
