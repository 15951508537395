import { Row, Col, Image, Button } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Card from '../../../components/Card';

// img
import auth1 from '../../../assets/images/auth/01.webp';
import { useActions } from '../../../hooks/useActions';
import { AuthRouteNames } from '../../../router/Auth';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { Formik, Form as FormicForm } from 'formik';
import React, { useEffect, useState } from 'react';
import { IUserResetPassword } from '../../../models/IUser';
import FieldWithValidation from '../../../components/uikit/FieldWithValidation';
import { TypesOfInput } from '../../../models/IField';
import * as Yup from 'yup';
import { notification } from 'antd';
import { Regulars } from '../../../constants';
import logoSign from '../../../assets/images/logo-sign.png';
import Helmet from 'react-helmet';

const RecoverpwUpdate = () => {
    const title = 'MY.EMIRATES.LAW - Update Password';
    const search = useLocation().search;
    const history = useHistory();
    const { updatePassword } = useActions();
    const { errors } = useTypedSelector(state => state.app);
    const [formikActions, setFormicActions] = useState<any>(null);

    useEffect(() => {
        if (formikActions && errors && Object.values(errors).length) {
            formikActions.setErrors(errors);
        }
    }, [errors]);

    const handleUpdate = (values: IUserResetPassword, actions: any) => {
        setFormicActions(actions);
        const token = new URLSearchParams(search).get('token');

        if (!token) {
            notification.error({
                message: 'Error',
                description: 'Invalid token',
            });
            return;
        }

        updatePassword({
            data: {
                ...values,
                token,
            },
            history,
        });
    };

    const schema = Yup.object().shape({
        password: Yup.string()
            .matches(Regulars.password.reg, Regulars.password.text)
            .required('Required'),

        confirm_password: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Required'),
    });

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <section className="login-content">
                <Row className="m-0 bg-white vh-100">
                    <Col
                        md="6"
                        className="d-md-block d-none  p-0 mt-n1 vh-100 overflow-hidden relative"
                    >
                        <Image
                            src={logoSign}
                            alt="logo"
                            className="logo-sign"
                        />
                        <Image
                            src={auth1}
                            className="Image-fluid gradient-main animated-scaleX"
                            alt="images"
                        />
                    </Col>
                    <Col md="6">
                        <Row className="justify-content-center align-items-center vh-100">
                            <Col md="12" lg="12">
                                <Card className="card-transparent shadow-none d-flex justify-content-center mb-0 auth-card">
                                    <Card.Body>
                                        <h2 className="mb-2 text-center">
                                            Update Password
                                        </h2>
                                        <Formik
                                            initialValues={{
                                                password: '',
                                                confirm_password: '',
                                            }}
                                            validationSchema={schema}
                                            onSubmit={(values, actions) =>
                                                handleUpdate(values, actions)
                                            }
                                        >
                                            <FormicForm>
                                                <Row>
                                                    <FieldWithValidation
                                                        field={{
                                                            id: 'password',
                                                            required: true,
                                                            type: TypesOfInput.PASSWORD,
                                                            title: 'New Password',
                                                            size: 12,
                                                            category_id: 1,
                                                        }}
                                                    />
                                                    <FieldWithValidation
                                                        field={{
                                                            id: 'confirm_password',
                                                            required: true,
                                                            type: TypesOfInput.PASSWORD,
                                                            title: 'Confirm Password',
                                                            size: 12,
                                                            category_id: 1,
                                                        }}
                                                    />
                                                </Row>
                                                <div className="d-flex justify-content-center">
                                                    <Button
                                                        type="submit"
                                                        variant="primary"
                                                    >
                                                        Update
                                                    </Button>
                                                </div>
                                            </FormicForm>
                                        </Formik>
                                        <p className="mt-3 text-center">
                                            Already have an Account{' '}
                                            <Link
                                                to={AuthRouteNames.SIGN_IN}
                                                className="text-underline"
                                            >
                                                Sign In
                                            </Link>
                                        </p>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </section>
        </>
    );
};

export default RecoverpwUpdate;
