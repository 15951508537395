import React from "react";





const CalendarHeader = () => {
    return(
        <div className="header-wrapper">
                Calendar of events
        </div>
    );
}

export default CalendarHeader;