import React from 'react';
import '../../../pending-cases/styles/pendingCases.css';
import { IHistoryMessagesPending } from '../../../../../models/IPendingCaseLawyer';
import { Button } from 'antd';

type TProps = {
    data: IHistoryMessagesPending[];
    chatId?: number;
    userId?: number;
    onReloadTableAfterSend?: () => void;
    onShowModalInvoice?: () => void;
    invoicePayment?: boolean;
};

const DocumentsPendingCase = ({ data, onShowModalInvoice }: TProps) => {
    return (
        <div>
            {data[0]?.status === 2 ? (
                <div className="card documents-pending-case-details">
                    <div className="messages-pending-case-details-payment-arrears-title">
                        Check out the payment
                    </div>
                    <div className="messages-pending-case-details-payment-arrears-pay">
                        <Button onClick={onShowModalInvoice} danger>
                            Issue an invoice for payment
                        </Button>
                    </div>
                </div>
            ) : null}

            <div className="card documents-pending-case-details-info">
                <div className="documents-pending-case-details-description">
                    <div className="documents-pending-case-details-description-title">
                        Initial information
                    </div>
                    <div className="documents-pending-case-details-description-text">
                        {data[0]?.description}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DocumentsPendingCase;
