import React from 'react'
import { Link } from 'react-router-dom'
import { DashboardRouteNames } from '../../router/Dashboard'

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-body">
                <ul className="left-panel list-inline mb-0 p-0">
                    <li className="list-inline-item"><Link to={DashboardRouteNames.PRIVACY_POLICY}>Privacy Policy</Link></li>
                    <li className="list-inline-item"><Link to={DashboardRouteNames.TERMS_OF_USE}>Terms of Use</Link></li>
                </ul>
            </div>
        </footer>
    )
}

export default Footer
