import React, { useEffect, useState } from 'react';
import { Button, Table, Image } from 'antd';
import { GetLawyerPage } from '../../../../apis/user';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import '../styles/openCase.css';
import {
    DataOpenCaseLawyerType,
    TempObjCase,
} from '../../../../models/IOpenCase';
import DetailsOpenCaseLawyerModal from './DetailsOpenCaseLawyerModal/DetailsOpenCaseLawyerModal';
import customerAvatarDefault from '../../../../assets/images/avatars/01.png';
import preloaderImg from '../../../../assets/images/preloader.gif';
import { useSubscription, IMessage } from 'mqtt-react-hooks';

const SelectedOpenCaseLawyer = (props: any) => {
    const { user } = useTypedSelector(state => state.user);
    const [dataOpenCaseLawyer, setDataOpenCaseLawyer] = useState<
        DataOpenCaseLawyerType[] | undefined | null
    >(null);
    const [dataCaseForTable, setDataCaseForTable] = useState<TempObjCase[]>([]);
    const [showDetailsOpenCaseLawyerModal, setShowDetailsOpenCaseLawyerModal] =
        useState<boolean>(false);
    const [
        dataForDetailsOpenCaseLawyerModal,
        setDataForDetailsOpenCaseLawyerModal,
    ] = useState<TempObjCase>();
    const [reloadAfterTakeToWork, setReloadAfterTakeToWork] =
        useState<boolean>(false);
    const [actionMessageMqtt, setActionMessageMqtt] = useState<
        IMessage | undefined
    >(undefined);
    const [preloader, setPreloader] = useState<boolean>(false);

    const messageMqtt = useSubscription([
        `${process.env.REACT_APP_ENVIRONMENT}/topic/user/${user?.user_id}`,
    ]);
    let messageMqttJSON: any = actionMessageMqtt?.message;
    let objSubscriptionMqtt = messageMqttJSON && JSON.parse(messageMqttJSON);

    useEffect(() => {
        window.onbeforeunload = () => {
            window.sessionStorage.setItem(
                'idForSelectedOpenCaseLawyer',
                JSON.stringify(props.location.state.id),
            );
            window.sessionStorage.setItem(
                'lastRoute',
                JSON.stringify(window.location.pathname),
            );
        };
    }, []);

    useEffect(() => {
        setActionMessageMqtt(messageMqtt?.message);
    }, [messageMqtt?.message]);

    useEffect(() => {
        setPreloader(true);
        GetLawyerPage(
            props.location.state?.id
                ? props.location.state.id
                : JSON.parse(
                      window.sessionStorage.getItem(
                          'idForSelectedOpenCaseLawyer',
                      ) || '{}',
                  ),
        ).then(value => {
            setDataOpenCaseLawyer(value.data.data);
            setPreloader(false);
        });
    }, []);

    useEffect(() => {
        if (
            objSubscriptionMqtt?.action === 'close-case' ||
            objSubscriptionMqtt?.action === 'take-to-work' ||
            objSubscriptionMqtt?.action === 'open-case' ||
            objSubscriptionMqtt?.action === 'lawyer-has-work-case' ||
            objSubscriptionMqtt?.action === 'lawyer-has-close-case' ||
            dataOpenCaseLawyer !== null
        ) {
            GetLawyerPage(
                props.location.state?.id
                    ? props.location.state.id
                    : JSON.parse(
                          window.sessionStorage.getItem(
                              'idForSelectedOpenCaseLawyer',
                          ) || '{}',
                      ),
            ).then(value => {
                setDataOpenCaseLawyer(value.data.data);
                setActionMessageMqtt(undefined);
            });
        }
    }, [reloadAfterTakeToWork, objSubscriptionMqtt]);

    useEffect(() => {
        let tempDataCase: TempObjCase[] = [];
        if (dataOpenCaseLawyer !== null) {
            dataOpenCaseLawyer?.forEach(item => {
                let tempObj: TempObjCase = {
                    key: 0,
                    case_id: 0,
                    number_case: 0,
                    name_services: '',
                    name_customer: '',
                    initial_information: '',
                    initial_information_full: '',
                    button: '',
                    avatar_cutomer: '',
                };
                tempObj.key = item.id;
                tempObj.case_id = item.id;
                tempObj.number_case = item.id;
                tempObj.name_services = item.service.name;
                if (item.user_photo) {
                    tempObj.avatar_cutomer = item.user_photo;
                } else {
                    tempObj.avatar_cutomer = customerAvatarDefault;
                }
                tempObj.name_customer = item.user_name;
                tempObj.initial_information = item.description;
                tempObj.initial_information_full = item.description;
                tempObj.button = 'More';
                if (
                    item.documents !== undefined &&
                    item.documents?.length > 0
                ) {
                    tempObj.documents = item.documents;
                }
                tempDataCase.push(tempObj);
            });
        }
        tempDataCase.sort((a, b) => b.number_case - a.number_case);
        setDataCaseForTable(tempDataCase);
    }, [dataOpenCaseLawyer]);

    const columns = [
        {
            title: 'Case Number',
            dataIndex: 'number_case',
            align: 'center' as 'center',
        },
        {
            title: 'Services',
            dataIndex: 'name_services',
            align: 'center' as 'center',
        },
        {
            title: 'Customer',
            dataIndex: 'avatar_cutomer',
            align: 'center' as 'center',
            id: 'key',
            render: (avatar_cutomer: string, key: any) => (
                <div>
                    <Image width={50} src={avatar_cutomer} />
                    <div style={{ marginTop: '10px' }}>{key.name_customer}</div>
                </div>
            ),
        },
        {
            title: 'Initial Information',
            dataIndex: 'initial_information',
            align: 'center' as 'center',
            ellipsis: true,
        },
        {
            title: 'Show More',
            dataIndex: 'button',
            id: 'key',
            render: (text: any, key: any) => (
                <Button
                    onClick={() => onShowDetailsOpenCaseLawyerModal(key)}
                    danger
                >
                    {text}
                </Button>
            ),
            align: 'center' as 'center',
        },
    ];

    const onShowDetailsOpenCaseLawyerModal = (key: any) => {
        setDataForDetailsOpenCaseLawyerModal(key);
        setShowDetailsOpenCaseLawyerModal(true);
    };

    const onCloseDetailsOpenCaseLawyerModal = () => {
        setShowDetailsOpenCaseLawyerModal(false);
    };

    const onReloadAfterTakeToWork = () => {
        setReloadAfterTakeToWork(!reloadAfterTakeToWork);
    };

    return (
        <div className="service-container service-page">
            <div className="service__title">Pending Cases</div>
            {preloader ? (
                <div className="preloader-box">
                    <img
                        className="preloader-img"
                        src={preloaderImg}
                        alt="Loading..."
                    />
                </div>
            ) : (
                <div className="archive-cases-table">
                    <Table
                        columns={columns}
                        dataSource={dataOpenCaseLawyer ? dataCaseForTable : []}
                        bordered
                    />
                </div>
            )}

            <DetailsOpenCaseLawyerModal
                showDetailsOpenCaseLawyerModal={showDetailsOpenCaseLawyerModal}
                onCloseDetailsOpenCaseLawyerModal={
                    onCloseDetailsOpenCaseLawyerModal
                }
                dataForDetailsOpenCaseLawyerModal={
                    dataForDetailsOpenCaseLawyerModal
                }
                onReloadAfterTakeToWork={onReloadAfterTakeToWork}
                setShowDetailsOpenCaseLawyerModal={
                    setShowDetailsOpenCaseLawyerModal
                }
            />
        </div>
    );
};

export default SelectedOpenCaseLawyer;
