import React, { useEffect, useState } from 'react';
import { Input, Table, Button, Image } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import './styles/pendingCases.css';
import { NavLink } from 'react-router-dom';
import { GetPendingUserCases } from '../../../apis/pendingCases';
import {
    UserPendingCasesType,
    TempObjType,
} from '../../../models/IPendingCases';
import { DashboardRouteNames } from '../../../router/Dashboard';
import lawyerAvatarUndefined from '../../../../src/assets/images/avatars/06.png';
import preloaderImg from '../../../assets/images/preloader.gif';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useSubscription } from 'mqtt-react-hooks';

const PendingCases: React.FC = () => {
    const { user } = useTypedSelector(state => state.user);
    const [userPendingCases, setUserPendingCases] = useState<
        UserPendingCasesType[] | null
    >(null);
    const [userPendingCasesWithDate, setUserPendingCasesWithDate] = useState<
        UserPendingCasesType[] | null | undefined
    >(null);
    const [dataCases, setDataCases] = useState<TempObjType[]>([]);
    const [dateStart, setDateStart] = useState<string>('');
    const [dateEnd, setDateEnd] = useState<string>('');
    const [searchForLawyer, setSearchForLawyer] = useState<TempObjType[]>([]);
    const [valueSearchLawyer, setValueSearchLawyer] = useState<string>('');
    const [preloader, setPreloader] = useState<boolean>(false);
    const messageMqtt = useSubscription([
        `${process.env.REACT_APP_ENVIRONMENT}/topic/user/${user?.user_id}`,
    ]);
    const [showCyrillic, setShowCyrillic] = useState<boolean>(false);

    useEffect(() => {
        setPreloader(true);
        GetPendingUserCases().then(value => {
            setUserPendingCases(value.data.data);
            setUserPendingCasesWithDate(value.data.data);
            setPreloader(false);
        });
    }, [dateStart, dateEnd]);

    useEffect(() => {
        let messageMqttJSON: any = messageMqtt?.message;
        let objSubscriptionMqtt =
            messageMqttJSON && JSON.parse(messageMqttJSON?.message);
        if (
            objSubscriptionMqtt?.action === 'close-case' ||
            objSubscriptionMqtt?.action === 'take-to-work'
        ) {
            GetPendingUserCases().then(value => {
                setUserPendingCases(value.data.data);
                setUserPendingCasesWithDate(value.data.data);
            });
        }
    }, [messageMqtt?.message]);

    useEffect(() => {
        let cases: TempObjType[] = [];
        if (userPendingCases !== null) {
            userPendingCases.sort((a, b) => b.id - a.id);
            userPendingCases.forEach(item => {
                let tempObj: TempObjType = {
                    key: 0,
                    caseNumber: 0,
                    nameServices: '',
                    dateAndTime: '',
                    avatarLawyer: '',
                    nameLawyer: '',
                    showCase: '',
                    description: '',
                    isLawyer: false,
                };
                tempObj.key = item.id;
                tempObj.caseNumber = item.id;
                tempObj.dateAndTime = item.date_create_human;
                tempObj.nameServices = item.service.name;
                tempObj.description = item.description;
                if (!item.lawyer_photo) {
                    tempObj.avatarLawyer = lawyerAvatarUndefined;
                } else {
                    tempObj.avatarLawyer = item.lawyer_photo;
                }
                if (!item.lawyer_name) {
                    tempObj.nameLawyer = 'A lawyer has not yet been appointed';
                    tempObj.isLawyer = false;
                } else {
                    tempObj.nameLawyer = item.lawyer_name;
                    tempObj.isLawyer = true;
                }
                tempObj.showCase = 'Open details';
                cases.push(tempObj);
            });
        }
        setDataCases(cases);
    }, [userPendingCases]);

    useEffect(() => {
        if (dateStart !== '' && dateEnd === '') {
            let filterStart = userPendingCasesWithDate?.filter(
                item => new Date(item.date_create_human) > new Date(dateStart),
            );
            let cases: TempObjType[] = [];
            filterStart?.forEach(item => {
                let tempObj: TempObjType = {
                    key: 0,
                    caseNumber: 0,
                    nameServices: '',
                    dateAndTime: '',
                    avatarLawyer: '',
                    nameLawyer: '',
                    showCase: '',
                    description: '',
                    isLawyer: false,
                };
                tempObj.key = item.id;
                tempObj.caseNumber = item.id;
                tempObj.dateAndTime = item.date_create_human;
                tempObj.nameServices = item.service.name;
                tempObj.description = item.description;
                if (!item.lawyer_photo) {
                    tempObj.avatarLawyer = lawyerAvatarUndefined;
                } else {
                    tempObj.avatarLawyer = item.lawyer_photo;
                }
                if (!item.lawyer_name) {
                    tempObj.nameLawyer = 'A lawyer has not yet been appointed';
                    tempObj.isLawyer = false;
                } else {
                    tempObj.nameLawyer = item.lawyer_name;
                    tempObj.isLawyer = true;
                }
                tempObj.showCase = 'Open details';
                cases.push(tempObj);
            });
            setDataCases(cases);
        } else if (dateStart === '' && dateEnd !== '') {
            let inclusiveDate = Number(dateEnd.split('-')[2]) + 1;
            let editDateEnd = dateEnd.split('-');
            editDateEnd.splice(2, 1, String(inclusiveDate));
            let filterEnd = userPendingCasesWithDate?.filter(
                item =>
                    new Date(item.date_create_human) <=
                    new Date(editDateEnd.join('-')),
            );
            let cases: TempObjType[] = [];
            filterEnd?.forEach(item => {
                let tempObj: TempObjType = {
                    key: 0,
                    caseNumber: 0,
                    nameServices: '',
                    dateAndTime: '',
                    avatarLawyer: '',
                    nameLawyer: '',
                    showCase: '',
                    description: '',
                    isLawyer: false,
                };
                tempObj.key = item.id;
                tempObj.caseNumber = item.id;
                tempObj.dateAndTime = item.date_create_human;
                tempObj.nameServices = item.service.name;
                tempObj.description = item.description;
                if (!item.lawyer_photo) {
                    tempObj.avatarLawyer = lawyerAvatarUndefined;
                } else {
                    tempObj.avatarLawyer = item.lawyer_photo;
                }
                if (!item.lawyer_name) {
                    tempObj.nameLawyer = 'A lawyer has not yet been appointed';
                    tempObj.isLawyer = false;
                } else {
                    tempObj.nameLawyer = item.lawyer_name;
                    tempObj.isLawyer = true;
                }
                tempObj.showCase = 'Open details';
                cases.push(tempObj);
            });
            setDataCases(cases);
        } else if (dateStart !== '' && dateEnd !== '') {
            let filterStart = userPendingCasesWithDate?.filter(
                item => new Date(item.date_create_human) > new Date(dateStart),
            );
            let inclusiveDate = Number(dateEnd.split('-')[2]) + 1;
            let editDateEnd = dateEnd.split('-');
            editDateEnd.splice(2, 1, String(inclusiveDate));
            let filterStartAndEnd = filterStart?.filter(
                item =>
                    new Date(item.date_create_human) <=
                    new Date(editDateEnd.join('-')),
            );
            let cases: TempObjType[] = [];
            filterStartAndEnd?.forEach(item => {
                let tempObj: TempObjType = {
                    key: 0,
                    caseNumber: 0,
                    nameServices: '',
                    dateAndTime: '',
                    avatarLawyer: '',
                    nameLawyer: '',
                    showCase: '',
                    description: '',
                    isLawyer: false,
                };
                tempObj.key = item.id;
                tempObj.caseNumber = item.id;
                tempObj.dateAndTime = item.date_create_human;
                tempObj.nameServices = item.service.name;
                tempObj.description = item.description;
                if (!item.lawyer_photo) {
                    tempObj.avatarLawyer = lawyerAvatarUndefined;
                } else {
                    tempObj.avatarLawyer = item.lawyer_photo;
                }
                if (!item.lawyer_name) {
                    tempObj.nameLawyer = 'A lawyer has not yet been appointed';
                    tempObj.isLawyer = false;
                } else {
                    tempObj.nameLawyer = item.lawyer_name;
                    tempObj.isLawyer = true;
                }
                tempObj.showCase = 'Open details';
                cases.push(tempObj);
            });
            setDataCases(cases);
        }
    }, [userPendingCasesWithDate]);

    const onDateStart = (event: any) => {
        setDateStart(event.target.value);
    };

    const onDateEnd = (event: any) => {
        setDateEnd(event.target.value);
    };

    const onSearchLawyer = (event: any) => {
        setValueSearchLawyer(event.target.value);
        const cyrillicPattern = /[\u0400-\u04FF]/;
        if (cyrillicPattern.test(event.target.value)) {
            setShowCyrillic(true);
        } else {
            setShowCyrillic(false);
        }
        let searchLawyer = dataCases?.filter(item =>
            item['nameLawyer']
                .toLowerCase()
                .includes(event.target.value.toLowerCase())
                ? item
                : false,
        );
        setSearchForLawyer(searchLawyer);
    };

    const columns = [
        {
            title: 'Case Number',
            dataIndex: 'caseNumber',
            key: 'caseNumber',
            align: 'center' as 'center',
        },
        {
            title: 'Service',
            dataIndex: 'nameServices',
            key: 'nameServices',
            filters: [
                {
                    text: 'Civil Law',
                    value: 'Civil Law',
                },
                {
                    text: 'Commercial Law',
                    value: 'Commercial Law',
                },
                {
                    text: 'Criminal Law',
                    value: 'Criminal Law',
                },
                {
                    text: 'Family Law',
                    value: 'Family Law',
                },
                {
                    text: 'Banking & Finance',
                    value: 'Banking & Finance',
                },
                {
                    text: 'Insurance',
                    value: 'Insurance',
                },
                {
                    text: 'Labour Law',
                    value: 'Labour Law',
                },
                {
                    text: 'Maritime Law',
                    value: 'Maritime Law',
                },
                {
                    text: 'Real Estate',
                    value: 'Real Estate',
                },
            ],
            onFilter: (value: any, record: any): boolean =>
                record.nameServices.indexOf(value) === 0,
            align: 'center' as 'center',
        },
        {
            title: 'Opening date',
            dataIndex: 'dateAndTime',
            key: 'dateAndTime',
            align: 'center' as 'center',
        },
        {
            title: 'Lawyer',
            dataIndex: 'avatarLawyer',
            key: 'avatarLawyer',
            id: 'key',
            render: (avatarLawyer: string, key: any) => (
                <div>
                    <Image width={50} src={avatarLawyer} />
                    <div style={{ marginTop: '10px' }}>{key.nameLawyer}</div>
                </div>
            ),
            align: 'center' as 'center',
        },
        {
            title: 'Show Case',
            dataIndex: 'showCase',
            key: 'showCase',
            id: 'key',
            render: (showCase: string, key: any) => (
                <Button danger>
                    <NavLink
                        to={{
                            pathname: `${DashboardRouteNames.PENDING_CASE_DETAILS}`,
                            state: { id: key },
                        }}
                    >
                        {showCase}
                    </NavLink>
                </Button>
            ),
            align: 'center' as 'center',
        },
    ];

    return (
        <div className="service-container service-page">
            <div className="service__title">PENDING CASES</div>
            <div className="archive-cases-table">
                <div className="pending-cases-sort-box">
                    <div className="calendar-box">
                        <div className="calendar-box-start">
                            <Input
                                type="date"
                                onInput={onDateStart}
                                className="calendar-box-input"
                            />
                        </div>
                        <div className="calendar-box-end">
                            <Input
                                type="date"
                                onInput={onDateEnd}
                                className="calendar-box-input"
                            />
                        </div>
                    </div>
                    <div className="search-lawyer-box">
                        <Input
                            prefix={
                                <UserOutlined
                                    style={{ color: 'rgba(0, 0, 0, 0.87)' }}
                                />
                            }
                            placeholder="Search Name"
                            value={valueSearchLawyer}
                            type="search"
                            onChange={onSearchLawyer}
                            style={{ marginBottom: '20px' }}
                        />
                        {
                            <div
                                className={
                                    showCyrillic
                                        ? 'card-upload-box-input-message-error'
                                        : 'card-upload-box-input-message'
                                }
                            >
                                Enter only latin characters
                            </div>
                        }
                    </div>
                </div>
                {preloader ? (
                    <div className="preloader-box">
                        <img
                            className="preloader-img"
                            src={preloaderImg}
                            alt="Loading..."
                        />
                    </div>
                ) : (
                    <Table
                        columns={columns}
                        dataSource={
                            searchForLawyer.length > 0
                                ? searchForLawyer
                                : searchForLawyer.length === 0 &&
                                  valueSearchLawyer.length > 0
                                ? []
                                : dataCases
                        }
                    />
                )}
            </div>
        </div>
    );
};

export default PendingCases;
