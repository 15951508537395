import { Axios } from '../plugins/helpers';
import { notificationError } from '../plugins/requestNotification';

export const GetAllCustomers = async () => {
    try {
        const { data } = await Axios().get(
            `${process.env.REACT_APP_BASE_URL}/get-customers`,
        );
        return data;
    } catch (e: any) {
        notificationError(e);
        return e?.response;
    }
};

export const PostAssignedCustomer = async (
    mail: string,
    case_id: number,
) => {
    try {
        const response = await Axios().post(
            `${process.env.REACT_APP_BASE_URL}/assign-customer-to-case`,
            { mail, case_id },
        );
        return response;
    } catch (e: any) {
        notificationError(e);
        return e?.response;
    }
};