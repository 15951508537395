import React, { useState, useEffect } from 'react';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { GetMenuCount } from '../../apis/menu';
import { IMessage, useSubscription } from 'mqtt-react-hooks';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { useActions } from '../../hooks/useActions';

const MessagesMqtt = (props: any) => {
    const { user } = useTypedSelector(state => state.user);
    const [actionMessageMqtt, setActionMessageMqtt] = useState<
        IMessage | undefined
    >(undefined);
    const { handleLoader } = useActions();
    const messageMqtt = useSubscription([
        `${process.env.REACT_APP_ENVIRONMENT}/topic/user/${user?.user_id}`,
    ]);
    let messageMqttJSON: any = actionMessageMqtt?.message;
    let objSubscriptionMqtt = messageMqttJSON && JSON.parse(messageMqttJSON);
    let history = useHistory();

    useEffect(() => {
        setActionMessageMqtt(messageMqtt?.message);
    }, [messageMqtt?.message]);

    useEffect(() => {
        GetMenuCount().then(value => {
            props.setCountMenu(value.data.data);
            handleLoader(false);
        });
        if (
            objSubscriptionMqtt?.action === 'add-message' &&
            history.location.pathname !==
                '/dashboard/new-cases-lawyer/open-details' &&
            history.location.pathname !== '/dashboard/pending-case-details'
        ) {
            notification.success({
                message: `${objSubscriptionMqtt.message.split(' ')[0]} ${
                    objSubscriptionMqtt.message.split(' ')[1]
                }: a new message!`,
            });
            setActionMessageMqtt(undefined);
        } else if (
            objSubscriptionMqtt?.action === 'add-document' &&
            history.location.pathname !==
                '/dashboard/new-cases-lawyer/open-details' &&
            history.location.pathname !== '/dashboard/pending-case-details'
        ) {
            notification.success({
                message: objSubscriptionMqtt.message,
            });
            setActionMessageMqtt(undefined);
        } else if (
            objSubscriptionMqtt?.action === 'add-document' &&
            (history.location.pathname ===
                '/dashboard/new-cases-lawyer/open-details' ||
                history.location.pathname === '/dashboard/pending-case-details')
        ) {
            setActionMessageMqtt(undefined);
        } else if (
            objSubscriptionMqtt?.action === 'add-message' &&
            history.location.pathname ===
                '/dashboard/new-cases-lawyer/open-details'
        ) {
            setActionMessageMqtt(undefined);
        } else if (
            objSubscriptionMqtt?.action === 'add-message' &&
            history.location.pathname !== '/dashboard/pending-case-details' &&
            history.location.pathname !==
                '/dashboard/new-cases-lawyer/open-details'
        ) {
            notification.success({
                message: `${objSubscriptionMqtt.message.split(' ')[0]} ${
                    objSubscriptionMqtt.message.split(' ')[1]
                }: a new message!`,
            });
            setActionMessageMqtt(undefined);
        } else if (
            objSubscriptionMqtt?.action === 'add-message' &&
            history.location.pathname === '/dashboard/pending-case-details'
        ) {
            setActionMessageMqtt(undefined);
        } else if (
            objSubscriptionMqtt?.action === 'take-to-work' &&
            user?.role.id === 3
        ) {
            notification.success({
                message: objSubscriptionMqtt.message,
            });
            setActionMessageMqtt(undefined);
        } else if (
            objSubscriptionMqtt?.action === 'take-to-work' &&
            user?.role.id === 2
        ) {
            setActionMessageMqtt(undefined);
        } else if (
            objSubscriptionMqtt?.action === 'close-case' &&
            user?.role.id === 3
        ) {
            notification.warning({
                message: objSubscriptionMqtt.message,
            });
            setActionMessageMqtt(undefined);
        } else if (
            objSubscriptionMqtt?.action === 'close-case' &&
            user?.role.id === 2
        ) {
            setActionMessageMqtt(undefined);
        } else if (objSubscriptionMqtt?.action === 'create-invoice') {
            notification.warning({
                message: objSubscriptionMqtt.message,
            });
            setActionMessageMqtt(undefined);
        } else if (objSubscriptionMqtt?.action === 'pay-invoice') {
            notification.success({
                message: objSubscriptionMqtt.message,
            });
            setActionMessageMqtt(undefined);
        } else if (objSubscriptionMqtt?.action === 'open-case') {
            notification.success({
                message: objSubscriptionMqtt.message,
            });
            setActionMessageMqtt(undefined);
        } else if (objSubscriptionMqtt?.action === 'lawyer-has-work-case') {
            notification.success({
                message: objSubscriptionMqtt.message,
            });
            setActionMessageMqtt(undefined);
        } else if (objSubscriptionMqtt?.action === 'lawyer-has-close-case') {
            notification.success({
                message: objSubscriptionMqtt.message,
            });
            setActionMessageMqtt(undefined);
        } else if (objSubscriptionMqtt?.action === 'notification-event') {
            notification.success({
                message: objSubscriptionMqtt.message,
                duration: 99999,
            });
            setActionMessageMqtt(undefined);
        } else {
            return;
        }
    }, [objSubscriptionMqtt]);

    return <></>;
};

export default MessagesMqtt;
