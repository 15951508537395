import React, { FC, useEffect, useRef, useState } from 'react';

import { Row, Col, Tab, Button, Card } from 'react-bootstrap';
import { Image } from 'antd';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import avatar1 from '../../assets/images/avatars/01.png';
import { FetchProfileFields, VerifyUser } from '../../apis/profile';
import { useActions } from '../../hooks/useActions';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { ICategoryField } from '../../models/IField';
import { IUserInfo } from '../../models/IUser';
import { handleDisableButton } from '../../plugins/helpers';
import FieldBlock from '../uikit/FieldBlock';
import { notificationError } from '../../plugins/requestNotification';
import { notification } from 'antd';
import { messages } from '../../constants';
import preloaderImg from '../../assets/images/preloader.gif';

import { useHistory } from 'react-router-dom';

interface ProfileViewProps {
    typeOfPage: number;
}

const ProfileView: FC<ProfileViewProps> = ({ typeOfPage }) => {
    const { user } = useTypedSelector(state => state.user);
    const { errors } = useTypedSelector(state => state.app);
    const { updateProfile } = useActions();
    const formButtonRef = useRef(null);
    const [formikActions, setFormicActions] = useState<any>(null);

    const [status, setStatus] = useState<any>(null);
    const [validation, setValidation] = useState<any>(null);
    const [fieldValues, setFieldValues] = useState<any>(null);
    const [sortedFieldsByCategory, setSortedFieldsByCategory] =
        useState<any>(null);
    const [preloader, setPreloader] = useState<boolean>(false);

    let history = useHistory();

    useEffect(() => {
        window.onbeforeunload = () => {
            window.sessionStorage.setItem(
                'lastRoute',
                JSON.stringify(window.location.pathname),
            );
        };
    }, []);

    useEffect(() => {
        if (formikActions && errors && Object.values(errors).length) {
            formikActions.setErrors(errors);
        }
    }, [errors]);

    useEffect(() => {
        setPreloader(true);
        fetchFields();
    }, [user]);

    const fetchFields = async () => {
        if (!user) return;
        const response = await FetchProfileFields(user.user_id, typeOfPage);
        setValidation(response.validations);
        setFieldValues(response.initialValues);
        setSortedFieldsByCategory(response.sortedFieldsByCategory);

        const getStatus =
            response.sortedFields[response.sortedFields.length - 1];
        setStatus(getStatus);
        setPreloader(false);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [fieldValues]);

    const schema = Yup.object().shape(validation || {});

    const handleSave = async (values: IUserInfo, form: any) => {
        setFormicActions(form);
        handleDisableButton(formButtonRef);
        updateProfile(values);
    };

    const verify = async () => {
        const { data } = await VerifyUser();

        if (status) {
            await fetchFields();
        }

        if (!data.data.status) {
            try {
                let description = data.data.fields;

                if (description) {
                    description = Object.keys(description);

                    description = description
                        ?.map((field: string) => {
                            if (field.length < 6)
                                return (
                                    field.charAt(0).toUpperCase() +
                                    field.slice(1)
                                );
                            const str = field
                                .replace(new RegExp('_', 'g'), ' ')
                                .split('shipping ')[1];
                            return str.charAt(0).toUpperCase() + str.slice(1);
                        })
                        .join(', ');
                }
                notification.error({
                    message: `${messages.VERIFY_USER_ERROR}: ${description}`,
                });
            } catch (e) {
                notificationError(e);
            }
        } else if (data.data.status && status.name === 'Verify') {
            notification.success({
                message: messages.VERIFY_USER_SUCCESS,
            });
        } else {
            notification.warning({
                message: messages.VERIFY_USER_PENDING,
            });
        }
    };

    if (preloader) {
        return (
            <div className="preloader-box">
                <img
                    className="preloader-img"
                    src={preloaderImg}
                    alt="Loading..."
                />
            </div>
        );
    }

    return (
        <>
            <Tab.Container defaultActiveKey="first">
                <Row>
                    <Col lg="12">
                        <Card>
                            <Card.Body>
                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                    <div className="d-flex flex-wrap align-items-center">
                                        <div className="profile-img position-relative me-3 mb-3 mb-lg-0">
                                            <Image
                                                src={
                                                    user?.userInfo?.photo ||
                                                    avatar1
                                                }
                                                className="img-fluid rounded-pill avatar avatar-100"
                                                alt="profile-image"
                                            />
                                        </div>
                                        <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                                            <h4 className="me-2 h4">
                                                {user?.userInfo?.first_name}{' '}
                                                {user?.userInfo?.last_name}
                                            </h4>
                                        </div>
                                    </div>
                                    {status && !(status.name === 'Verified') ? (
                                        <div onClick={verify}>
                                            <Button
                                                type="submit"
                                                variant="primary"
                                            >
                                                {status &&
                                                status.name === 'Registered'
                                                    ? 'Get Verify'
                                                    : status &&
                                                      status.name === 'Pending'
                                                    ? 'Pending'
                                                    : 'Verify'}
                                            </Button>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="12">
                        <Card>
                            <Card.Body>
                                <div>
                                    {fieldValues && (
                                        <Formik
                                            initialValues={fieldValues}
                                            validationSchema={schema}
                                            onSubmit={handleSave}
                                        >
                                            {({ isValid }) => (
                                                <Form>
                                                    <Row>
                                                        {sortedFieldsByCategory &&
                                                            sortedFieldsByCategory.map(
                                                                (
                                                                    category: ICategoryField,
                                                                    index: number,
                                                                ) =>
                                                                    history
                                                                        .location
                                                                        .pathname ===
                                                                    '/dashboard/user/profile' ? (
                                                                        <FieldBlock
                                                                            key={
                                                                                category.id
                                                                            }
                                                                            category={
                                                                                category
                                                                            }
                                                                        />
                                                                    ) : history
                                                                          .location
                                                                          .pathname ===
                                                                      '/dashboard/user/identification' ? (
                                                                        index ===
                                                                            0 && (
                                                                            <div
                                                                                key={
                                                                                    index
                                                                                }
                                                                                className="verification-box"
                                                                            >
                                                                                <div className="verification-box-image">
                                                                                    <div className="verification-box-image-title">
                                                                                        {
                                                                                            category
                                                                                                .fields[0]
                                                                                                .title
                                                                                        }
                                                                                    </div>
                                                                                    <div className="verification-box-image-value">
                                                                                        <Image
                                                                                            src={String(
                                                                                                category
                                                                                                    .fields[0]
                                                                                                    .value,
                                                                                            )}
                                                                                            width={
                                                                                                250
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="verification-box-document">
                                                                                    <div className="verification-box-document-title">
                                                                                        {
                                                                                            category
                                                                                                .fields[1]
                                                                                                .title
                                                                                        }
                                                                                    </div>
                                                                                    <div className="verification-box-document-value">
                                                                                        {
                                                                                            category
                                                                                                .fields[1]
                                                                                                .value
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    ) : null,
                                                            )}
                                                    </Row>
                                                    {history.location
                                                        .pathname ===
                                                    '/dashboard/user/profile' ? (
                                                        <div className="d-flex justify-content-center">
                                                            <Button
                                                                type="submit"
                                                                variant="primary"
                                                                ref={
                                                                    formButtonRef
                                                                }
                                                                disabled={
                                                                    !isValid
                                                                }
                                                            >
                                                                Save
                                                            </Button>
                                                        </div>
                                                    ) : null}
                                                </Form>
                                            )}
                                        </Formik>
                                    )}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Tab.Container>
        </>
    );
};

export default ProfileView;
