import { FC, useEffect } from 'react';
import { FieldInputProps, useFormikContext } from 'formik';
import { IFieldFront } from '../../../models/IField';

interface CustomProps {
    field: FieldInputProps<any>;
    input: IFieldFront;
}

const Custom: FC<CustomProps> = ({
    field, // { name, value, onChange, onBlur }
    input,
}) => {
    const { setFieldValue, validateField } = useFormikContext() ?? {};

    const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = '';
        if (field.name === 'expiration_date') {
            value = e.target.value
                .replace(/\D/g, '')
                .substring(0, 4)
                .split('')
                .reduce((acc, cur, idx) => {
                    const isEveryFourth =
                        (idx + 2) % 3 === 0 && idx > 0 && idx < 4;
                    const spacer = isEveryFourth ? '/' : '';
                    return acc + cur + spacer;
                }, '')
                .replace(/([-]*)$/g, '');

            setFieldValue(field.name, value);

            return;
        }

        if (field.name === 'card_number') {
            value = e.target.value
                .substring(0, 19)
                .replace(/[^a-z0-9]+/gi, '')
                .replace(/(.{4})/g, '$1 ');

            setFieldValue(field.name, value);

            return;
        }

        if (!input.formatter && !input.customOnChange) {
            field.onChange(e);
            return;
        }

        if (input.formatter) {
            value = input.formatter(e);
            setFieldValue(field.name, value);
        }
        if (input.customOnChange) {
            input.customOnChange(value);
        }
    };

    useEffect(() => {
        if (!input.dependencies || !field.value) return;

        if (input.customOnChange) {
            input.customOnChange(field.value);
        }
        validateField(field.name);
    }, input.dependencies);

    return (
        <div className="input">
            <input
                {...field}
                onChange={handleChangeValue}
                disabled={input.disabled}
                type={input.type}
                placeholder={input.placeholder}
                className="input__field"
            />
        </div>
    );
};

export default Custom;
