import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Input, Table } from 'antd';
import 'antd/dist/antd.css';
import '../../pending-cases/styles/pendingCases.css';
import { UserOutlined } from '@ant-design/icons';
import { GetPendingLawyerCaseDetails } from '../../../../apis/pendingCasesLawyer';
import {
    ILocationDetailsCase,
    INewCaseDetails,
    IPendingCaseDetails,
} from '../../../../models/IPendingCaseLawyer';
import customerAvatarDefault from '../../../../assets/images/avatars/01.png';
import columnsPendingCaseTable from '../../../../helpers/columnsPendingCaseTable';
import preloaderImg from '../../../../assets/images/preloader.gif';
import moment from 'moment';

const PendingCaseLawyerDetails: React.FC = () => {
    let { state } = useLocation<ILocationDetailsCase>();
    const [infoCases, setInfoCases] = useState<IPendingCaseDetails[] | []>([]);
    const [userPendingCasesWithDate, setUserPendingCasesWithDate] = useState<
        IPendingCaseDetails[] | []
    >([]);
    const [valueSearchCustomer, setValueSearchCustomer] = useState<string>('');
    const [searchCustomer, setSearchForCustomer] = useState<INewCaseDetails[]>(
        [],
    );
    const [dataCases, setDataCases] = useState<INewCaseDetails[]>([]);
    const [dateStart, setDateStart] = useState<string>('');
    const [dateEnd, setDateEnd] = useState<string>('');
    const [preloader, setPreloader] = useState<boolean>(false);
    const [showCyrillic, setShowCyrillic] = useState<boolean>(false);

    useEffect(() => {
        window.onbeforeunload = () => {
            window.sessionStorage.setItem(
                'idForSelectedPendingCaseLawyerDetails',
                JSON.stringify(state.key),
            );
            window.sessionStorage.setItem(
                'lastRoute',
                JSON.stringify(window.location.pathname),
            );
        };
    }, []);

    useEffect(() => {
        setPreloader(true);
        GetPendingLawyerCaseDetails(
            state?.key
                ? state.key
                : JSON.parse(
                      window.sessionStorage.getItem(
                          'idForSelectedPendingCaseLawyerDetails',
                      ) || '{}',
                  ),
        ).then(({ data }) => {
            setInfoCases(data);
            setUserPendingCasesWithDate(data);
            setPreloader(false);
        });
    }, [dateStart, dateEnd]);

    useEffect(() => {
        let cases: INewCaseDetails[] = [];
        if (infoCases.length > 0) {
            infoCases.forEach(item => {
                let obj: INewCaseDetails = {
                    key: 0,
                    caseNumber: 0,
                    nameServices: '',
                    timeAndDate: '',
                    description: '',
                    avatarCustomer: '',
                    nameCustomer: '',
                    showCase: '',
                };
                obj.key = item.id;
                obj.caseNumber = item.id;
                obj.nameServices = item.service.name;
                obj.timeAndDate = item.date_create_human;
                obj.description = item.description;
                obj.avatarCustomer = item.user_photo
                    ? item.user_photo
                    : customerAvatarDefault;
                item.user_name
                    ? (obj.nameCustomer = item.user_name)
                    : (obj.nameCustomer =
                          'A lawyer has not yet been appointed');
                obj.showCase = 'Open details';

                cases.push(obj);
            });
            cases.sort(
                (a, b) =>
                    moment(b.timeAndDate).unix() - moment(a.timeAndDate).unix(),
            );
        }
        setDataCases(cases);
    }, [infoCases]);

    useEffect(() => {
        if (dateStart !== '' && dateEnd === '') {
            let filterStart = userPendingCasesWithDate?.filter(
                item => new Date(item.date_create_human) > new Date(dateStart),
            );
            let cases: INewCaseDetails[] = [];
            filterStart?.forEach(item => {
                let tempObj: INewCaseDetails = {
                    key: 0,
                    caseNumber: 0,
                    nameServices: '',
                    timeAndDate: '',
                    avatarCustomer: '',
                    nameCustomer: '',
                    showCase: '',
                    description: '',
                };
                tempObj.key = item.id;
                tempObj.caseNumber = item.id;
                tempObj.timeAndDate = item.date_create_human;
                tempObj.nameServices = item.service.name;
                tempObj.description = item.description;
                tempObj.avatarCustomer = item.user_photo
                    ? item.user_photo
                    : customerAvatarDefault;
                tempObj.nameCustomer = item.user_name
                    ? item.user_name
                    : 'unnamed';

                tempObj.showCase = 'Open details';
                cases.push(tempObj);
            });
            setDataCases(cases);
        } else if (dateStart === '' && dateEnd !== '') {
            let inclusiveDate = Number(dateEnd.split('-')[2]) + 1;
            let editDateEnd = dateEnd.split('-');
            editDateEnd.splice(2, 1, String(inclusiveDate));
            let filterEnd = userPendingCasesWithDate?.filter(
                item =>
                    new Date(item.date_create_human) <=
                    new Date(editDateEnd.join('-')),
            );
            let cases: INewCaseDetails[] = [];
            filterEnd?.forEach(item => {
                let tempObj: INewCaseDetails = {
                    key: 0,
                    caseNumber: 0,
                    nameServices: '',
                    timeAndDate: '',
                    avatarCustomer: '',
                    nameCustomer: '',
                    showCase: '',
                    description: '',
                };
                tempObj.key = item.id;
                tempObj.caseNumber = item.id;
                tempObj.timeAndDate = item.date_create_human;
                tempObj.nameServices = item.service.name;
                tempObj.description = item.description;
                tempObj.avatarCustomer = item.user_photo
                    ? item.user_photo
                    : customerAvatarDefault;
                tempObj.nameCustomer = item.user_name
                    ? item.user_name
                    : 'unnamed';

                tempObj.showCase = 'Open details';
                cases.push(tempObj);
            });
            cases.sort(
                (a, b) =>
                    moment(b.timeAndDate).unix() - moment(a.timeAndDate).unix(),
            );
            setDataCases(cases);
        } else if (dateStart !== '' && dateEnd !== '') {
            let filterStart = userPendingCasesWithDate?.filter(
                item => new Date(item.date_create_human) > new Date(dateStart),
            );
            let inclusiveDate = Number(dateEnd.split('-')[2]) + 1;
            let editDateEnd = dateEnd.split('-');
            editDateEnd.splice(2, 1, String(inclusiveDate));
            let filterStartAndEnd = filterStart?.filter(
                item =>
                    new Date(item.date_create_human) <=
                    new Date(editDateEnd.join('-')),
            );
            let cases: INewCaseDetails[] = [];
            filterStartAndEnd?.forEach(item => {
                let tempObj: INewCaseDetails = {
                    key: 0,
                    caseNumber: 0,
                    nameServices: '',
                    timeAndDate: '',
                    avatarCustomer: '',
                    nameCustomer: '',
                    showCase: '',
                    description: '',
                };
                tempObj.key = item.id;
                tempObj.caseNumber = item.id;
                tempObj.timeAndDate = item.date_create_human;
                tempObj.nameServices = item.service.name;
                tempObj.description = item.description;
                tempObj.avatarCustomer = item.user_photo
                    ? item.user_photo
                    : customerAvatarDefault;
                tempObj.nameCustomer = item.user_name
                    ? item.user_name
                    : 'unnamed';

                tempObj.showCase = 'Open details';
                cases.push(tempObj);
            });
            cases.sort(
                (a, b) =>
                    moment(b.timeAndDate).unix() - moment(a.timeAndDate).unix(),
            );
            setDataCases(cases);
        }
    }, [userPendingCasesWithDate]);

    const handlerSearchCustomer: React.ChangeEventHandler<
        HTMLInputElement
    > = event => {
        setValueSearchCustomer(event.target.value);
        const cyrillicPattern = /[\u0400-\u04FF]/;
        if (cyrillicPattern.test(event.target.value)) {
            setShowCyrillic(true);
        } else {
            setShowCyrillic(false);
        }
        let searchCustomer = dataCases?.filter(item =>
            item['nameCustomer']
                .toLowerCase()
                .includes(event.target.value.toLowerCase())
                ? item
                : false,
        );
        setSearchForCustomer(searchCustomer);
    };

    const onDateStart = (event: any) => {
        setDateStart(event.target.value);
    };

    const onDateEnd = (event: any) => {
        setDateEnd(event.target.value);
    };

    return (
        <div className="service-container service-page">
            <div className="service__title">Active Cases</div>
            <div className="archive-cases-table">
                <div className="pending-cases-sort-box">
                    <div className="calendar-box">
                        <div className="calendar-box-start">
                            <Input
                                type="date"
                                onInput={onDateStart}
                                className="calendar-box-input"
                            />
                        </div>
                        <div className="calendar-box-end">
                            <Input
                                type="date"
                                onInput={onDateEnd}
                                className="calendar-box-input"
                            />
                        </div>
                    </div>
                    <div className="search-lawyer-box">
                        <Input
                            prefix={
                                <UserOutlined
                                    style={{ color: 'rgba(0, 0, 0, 0.87)' }}
                                />
                            }
                            placeholder="Search Customer"
                            value={valueSearchCustomer}
                            type="search"
                            onChange={handlerSearchCustomer}
                            style={{ marginBottom: '20px' }}
                        />
                        {
                            <div
                                className={
                                    showCyrillic
                                        ? 'card-upload-box-input-message-error'
                                        : 'card-upload-box-input-message'
                                }
                            >
                                Enter only latin characters
                            </div>
                        }
                    </div>
                </div>
                {preloader ? (
                    <div className="preloader-box">
                        <img
                            className="preloader-img"
                            src={preloaderImg}
                            alt="Loading..."
                        />
                    </div>
                ) : (
                    <Table
                        columns={columnsPendingCaseTable}
                        dataSource={
                            searchCustomer.length > 0
                                ? searchCustomer
                                : searchCustomer.length === 0 &&
                                  valueSearchCustomer.length > 0
                                ? []
                                : dataCases
                        }
                    />
                )}
            </div>
        </div>
    );
};

export default PendingCaseLawyerDetails;
