import { useEffect } from 'react';
//router
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
//layoutpages
import Dashboard from '../layouts/Dashboard';
import Auth, { AuthRouteNames, authRoutes } from './Auth';
import Errors, { errorRoutes } from './Errors';
import { IRoute } from '../models/IRoute';
import { useTypedSelector } from '../hooks/useTypedSelector';
import { DashboardRouteNames, dashboardRoutes } from './Dashboard';
import Loader from '../components/Loader';
import { getCookie } from '../plugins/helpers';

export enum MainRouteNames {
    DASHBOARD = '/dashboard',
    AUTH = '/auth',
    ERRORS = '/errors',
}

export const publicRoutes: IRoute[] = [
    { path: MainRouteNames.AUTH, component: Auth },
    { path: MainRouteNames.ERRORS, component: Errors },
    ...authRoutes,
    ...errorRoutes,
];

export const privateRoutes: IRoute[] = [
    { path: MainRouteNames.DASHBOARD, component: Dashboard },
    { path: MainRouteNames.ERRORS, component: Errors },
    ...errorRoutes,
    ...dashboardRoutes,
];

const IndexRouters = () => {
    let history = useHistory();
    const token = getCookie('auth_token');

    useEffect(() => {
        if (
            window.location.pathname.split('?')[0] !==
                '/auth/recoverpw-update' &&
            token
        ) {
            history.push(
                JSON.parse(window.sessionStorage.getItem('lastRoute') || '{}'),
            );
            window.onbeforeunload = () => {
                window.sessionStorage.setItem(
                    'lastRoute',
                    JSON.stringify(window.location.pathname),
                );
            };
        }
    }, []);

    const { isAuth } = useTypedSelector(state => state.auth);
    const userRoleId: number | undefined = useTypedSelector(
        state => state.user.user?.role?.id,
    );

    return (
        <>
            <Loader />
            {isAuth && userRoleId ? (
                <Switch>
                    {privateRoutes.map(route => (
                        <Route
                            path={route.path}
                            exact={route.exact}
                            component={route.component}
                            key={route.path}
                        />
                    ))}
                    <Redirect
                        to={
                            userRoleId === 3
                                ? DashboardRouteNames.SERVICE
                                : DashboardRouteNames.OPEN_CASE
                        }
                    />
                </Switch>
            ) : (
                <Switch>
                    {publicRoutes.map(route => (
                        <Route
                            path={route.path}
                            exact={route.exact}
                            component={route.component}
                            key={route.path}
                        />
                    ))}
                    <Redirect to={AuthRouteNames.SIGN_IN} />
                </Switch>
            )}
        </>
    );
};

export default IndexRouters;
