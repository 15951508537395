import React from 'react';



const HelpCenterCustomer = () => {
    return(
        <div>
            HelpCenterCustomer
        </div>
    );
}


export default HelpCenterCustomer;