import { Link, useLocation } from 'react-router-dom';
import { Accordion, useAccordionButton } from 'react-bootstrap';
import { FC, useState, useEffect } from 'react';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { Page } from '../../models/IMenu';
import { DashboardRouteNames } from '../../router/Dashboard';
import MessagesMqtt from './MessagesMqtt';
import ModalToken from './ModalToken';
import { getCookie } from '../../plugins/helpers';
import jwt_decode from 'jwt-decode';
import moment from 'moment';

interface ToggleMap {
    name: string;
    route: string;
    key?: string;
    miniIcon: string;
}

interface Toggle {
    children: any;
    eventKey: any;
    onClick: any;
    to: any;
}

const CustomToggle: FC<Toggle> = ({ children, eventKey, onClick, to }) => {
    const decoratedOnClick = useAccordionButton(eventKey, active =>
        onClick({ state: !active, eventKey: eventKey }),
    );

    const activeNav = JSON.parse(
        window.sessionStorage.getItem('eventKey') || '{}',
    );

    const isCurrentEventKey = activeNav === eventKey;

    //window.scrollTo(0, 0);
    return (
        <Link
            to={to}
            aria-expanded={isCurrentEventKey ? 'true' : 'false'}
            className="nav-link"
            onClick={e => {
                // @ts-ignore
                decoratedOnClick(isCurrentEventKey);
            }}
        >
            {children}
        </Link>
    );
};

const VerticalNav = () => {
    const userRoleId: number | undefined = useTypedSelector(
        state => state.user.user?.role?.id,
    );

    const [countMenu, setCountMenu] = useState<any>(null);
    const [showModalExpiredToken, setShowModalExpiredToken] =
        useState<boolean>(false);
    const token = getCookie('auth_token');

    useEffect(() => {
        if (token) {
            let decodedToken: any = jwt_decode(token);
            let expToken = moment(decodedToken.exp * 1000).unix();
            setInterval(() => {
                const validToken = getCookie('auth_token');
                if (moment().unix() > expToken) {
                    setShowModalExpiredToken(true);
                }
                if (!validToken) {
                    setShowModalExpiredToken(true);
                }
            }, 3000);
        }
    }, []);

    const location = useLocation();

    const [, setActiveMenu] = useState(false);

    const handleLink = () => {
        window.scrollTo(0, 0);
    };

    const pagesCustomer: Page[] = [
        {
            route: DashboardRouteNames.SERVICE,
            name: 'Services',
            toggle: true,
            key: 'service',
            toggleLink: [
                {
                    route: DashboardRouteNames.SERVICE_CRIMINAL_LAW,
                    name: 'Criminal Law',
                    key: 'criminal-law',
                    miniIcon: 'CL',
                },
                {
                    route: DashboardRouteNames.SERVICE_CIVIL_LAW,
                    name: 'Civil Law',
                    key: 'civil-law',
                    miniIcon: 'CL',
                },
                {
                    route: DashboardRouteNames.SERVICE_REAL_ESTATE,
                    name: 'Real Estate',
                    key: 'real-estate',
                    miniIcon: 'RE',
                },
                {
                    route: DashboardRouteNames.SERVICE_COMMERCIAL_LAW,
                    name: 'Commercial Law',
                    key: 'commercial-law',
                    miniIcon: 'CL',
                },
                {
                    route: DashboardRouteNames.SERVICE_INSURANCE,
                    name: 'Insurance',
                    key: 'insurance',
                    miniIcon: 'I',
                },
                {
                    route: DashboardRouteNames.SERVICE_FAMILY_LAW,
                    name: 'Family Law',
                    key: 'family-law',
                    miniIcon: 'FL',
                },
                {
                    route: DashboardRouteNames.SERVICE_MARITIME_LAW,
                    name: 'Maritime Law',
                    key: 'maritime-law',
                    miniIcon: 'ML',
                },
                {
                    route: DashboardRouteNames.SERVICE_LABOUR_LAW,
                    name: 'Labour Law',
                    key: 'labour-law',
                    miniIcon: 'LL',
                },
                {
                    route: DashboardRouteNames.SERVICE_FINANCE,
                    name: 'Banking & Finance',
                    key: 'banking-finance',
                    miniIcon: 'BF',
                },
                {
                    route: DashboardRouteNames.SERVICE_INVESTMENT,
                    name: 'Investment Fund Services',
                    key: 'investment-fund-services',
                    miniIcon: 'IFS',
                },
            ],
            icon: (
                <i className="icon">
                    <svg
                        width="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            opacity="0.4"
                            d="M13.3051 5.88243V6.06547C12.8144 6.05584 12.3237 6.05584 11.8331 6.05584V5.89206C11.8331 5.22733 11.2737 4.68784 10.6064 4.68784H9.63482C8.52589 4.68784 7.62305 3.80152 7.62305 2.72254C7.62305 2.32755 7.95671 2 8.35906 2C8.77123 2 9.09508 2.32755 9.09508 2.72254C9.09508 3.01155 9.34042 3.24276 9.63482 3.24276H10.6064C12.0882 3.2524 13.2953 4.43736 13.3051 5.88243Z"
                            fill="currentColor"
                        ></path>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M15.164 6.08279C15.4791 6.08712 15.7949 6.09145 16.1119 6.09469C19.5172 6.09469 22 8.52241 22 11.875V16.1813C22 19.5339 19.5172 21.9616 16.1119 21.9616C14.7478 21.9905 13.3837 22.0001 12.0098 22.0001C10.6359 22.0001 9.25221 21.9905 7.88813 21.9616C4.48283 21.9616 2 19.5339 2 16.1813V11.875C2 8.52241 4.48283 6.09469 7.89794 6.09469C9.18351 6.07542 10.4985 6.05615 11.8332 6.05615C12.3238 6.05615 12.8145 6.05615 13.3052 6.06579C13.9238 6.06579 14.5425 6.07427 15.164 6.08279ZM10.8518 14.7459H9.82139V15.767C9.82139 16.162 9.48773 16.4896 9.08538 16.4896C8.67321 16.4896 8.34936 16.162 8.34936 15.767V14.7459H7.30913C6.90677 14.7459 6.57311 14.4279 6.57311 14.0233C6.57311 13.6283 6.90677 13.3008 7.30913 13.3008H8.34936V12.2892C8.34936 11.8942 8.67321 11.5667 9.08538 11.5667C9.48773 11.5667 9.82139 11.8942 9.82139 12.2892V13.3008H10.8518C11.2542 13.3008 11.5878 13.6283 11.5878 14.0233C11.5878 14.4279 11.2542 14.7459 10.8518 14.7459ZM15.0226 13.1177H15.1207C15.5231 13.1177 15.8567 12.7998 15.8567 12.3952C15.8567 12.0002 15.5231 11.6727 15.1207 11.6727H15.0226C14.6104 11.6727 14.2866 12.0002 14.2866 12.3952C14.2866 12.7998 14.6104 13.1177 15.0226 13.1177ZM16.7007 16.4318H16.7988C17.2012 16.4318 17.5348 16.1139 17.5348 15.7092C17.5348 15.3143 17.2012 14.9867 16.7988 14.9867H16.7007C16.2885 14.9867 15.9647 15.3143 15.9647 15.7092C15.9647 16.1139 16.2885 16.4318 16.7007 16.4318Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                </i>
            ),
        },
        // Case profile Customer
        {
            route: DashboardRouteNames.CASE_PROFILE_CUSTOMER,
            name: `Case profile (${
                countMenu?.cases.pending ? countMenu?.cases.pending : 0
            }/${countMenu?.cases.archive ? countMenu?.cases.archive : 0})`,
            toggle: true,
            key: 'case-profile-customer',
            toggleLink: [
                {
                    route: DashboardRouteNames.OPEN_NEW_CASE,
                    name: 'Open New case',
                    key: 'open-new-case',
                    miniIcon: 'OC',
                },
                {
                    route: DashboardRouteNames.PENDING_CASES,
                    name: `Pending Cases (${
                        countMenu?.cases.pending ? countMenu?.cases.pending : 0
                    })`,
                    key: 'pending-case',
                    miniIcon: 'PC',
                },
                {
                    route: DashboardRouteNames.ARCHIVE_CASES_CUSTOMER,
                    name: `Archive Cases (${
                        countMenu?.cases.archive ? countMenu?.cases.archive : 0
                    })`,
                    key: 'archive-case',
                    miniIcon: 'AC',
                },
            ],
            icon: (
                <i className="icon">
                    <svg
                        width="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            opacity="0.4"
                            d="M2 11.0786C2.05 13.4166 2.19 17.4156 2.21 17.8566C2.281 18.7996 2.642 19.7526 3.204 20.4246C3.986 21.3676 4.949 21.7886 6.292 21.7886C8.148 21.7986 10.194 21.7986 12.181 21.7986C14.176 21.7986 16.112 21.7986 17.747 21.7886C19.071 21.7886 20.064 21.3566 20.836 20.4246C21.398 19.7526 21.759 18.7896 21.81 17.8566C21.83 17.4856 21.93 13.1446 21.99 11.0786H2Z"
                            fill="currentColor"
                        ></path>
                        <path
                            d="M11.2451 15.3843V16.6783C11.2451 17.0923 11.5811 17.4283 11.9951 17.4283C12.4091 17.4283 12.7451 17.0923 12.7451 16.6783V15.3843C12.7451 14.9703 12.4091 14.6343 11.9951 14.6343C11.5811 14.6343 11.2451 14.9703 11.2451 15.3843Z"
                            fill="currentColor"
                        ></path>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10.211 14.5565C10.111 14.9195 9.762 15.1515 9.384 15.1015C6.833 14.7455 4.395 13.8405 2.337 12.4815C2.126 12.3435 2 12.1075 2 11.8555V8.38949C2 6.28949 3.712 4.58149 5.817 4.58149H7.784C7.972 3.12949 9.202 2.00049 10.704 2.00049H13.286C14.787 2.00049 16.018 3.12949 16.206 4.58149H18.183C20.282 4.58149 21.99 6.28949 21.99 8.38949V11.8555C21.99 12.1075 21.863 12.3425 21.654 12.4815C19.592 13.8465 17.144 14.7555 14.576 15.1105C14.541 15.1155 14.507 15.1175 14.473 15.1175C14.134 15.1175 13.831 14.8885 13.746 14.5525C13.544 13.7565 12.821 13.1995 11.99 13.1995C11.148 13.1995 10.433 13.7445 10.211 14.5565ZM13.286 3.50049H10.704C10.031 3.50049 9.469 3.96049 9.301 4.58149H14.688C14.52 3.96049 13.958 3.50049 13.286 3.50049Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                </i>
            ),
        },
        {
            route: DashboardRouteNames.PAYMENTS_CUSTOMER,
            name: `Payments (${
                countMenu?.payments?.pending ? countMenu?.payments?.pending : 0
            }/${
                countMenu?.payments?.closed ? countMenu?.payments?.closed : 0
            })`,
            toggle: true,
            key: 'payments-customer',
            toggleLink: [
                {
                    route: DashboardRouteNames.PAYMENTS_CUSTOMER_PENDING,
                    name: `Pending payments ${
                        countMenu?.payments?.pending !== 0
                            ? countMenu?.payments?.pending
                            : '(0)'
                    }`,
                    key: 'payments-customer-pending',
                    miniIcon: 'PP',
                },
                {
                    route: DashboardRouteNames.PAYMENTS_CUSTOMER_CLOSED,
                    name: `Closed payments (${
                        countMenu?.payments?.closed
                            ? countMenu?.payments?.closed
                            : 0
                    })`,
                    key: 'payments-customer-closed',
                    miniIcon: 'CP',
                },
            ],
            icon: (
                <i className="icon">
                    <svg
                        width="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M11.997 15.1746C7.684 15.1746 4 15.8546 4 18.5746C4 21.2956 7.661 21.9996 11.997 21.9996C16.31 21.9996 19.994 21.3206 19.994 18.5996C19.994 15.8786 16.334 15.1746 11.997 15.1746Z"
                            fill="currentColor"
                        ></path>
                        <path
                            opacity="0.4"
                            d="M11.9971 12.5838C14.9351 12.5838 17.2891 10.2288 17.2891 7.29176C17.2891 4.35476 14.9351 1.99976 11.9971 1.99976C9.06008 1.99976 6.70508 4.35476 6.70508 7.29176C6.70508 10.2288 9.06008 12.5838 11.9971 12.5838Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                </i>
            ),
        },
        {
            route: DashboardRouteNames.MESSAGE_CENTER_CUSTOMER,
            name: `Message center (${
                countMenu?.message_center?.lawyer
                    ? countMenu?.message_center?.lawyer
                    : 0
            }/${
                countMenu?.message_center?.payments
                    ? countMenu?.message_center?.payments
                    : 0
            }/${
                countMenu?.message_center?.system
                    ? countMenu?.message_center?.system
                    : 0
            })`,
            toggle: true,
            key: 'message-center-customer',
            toggleLink: [
                {
                    route: DashboardRouteNames.LAWYER_MESSAGES,
                    name: `Lawyer messages (${
                        countMenu?.message_center?.lawyer
                            ? countMenu?.message_center?.lawyer
                            : 0
                    })`,
                    key: 'lawyer-message',
                    miniIcon: 'LM',
                },
                {
                    route: DashboardRouteNames.PAYMENT_MESSAGES_CUSTOMER,
                    name: `Payment messages (${
                        countMenu?.message_center?.payments
                            ? countMenu?.message_center?.payments
                            : 0
                    })`,
                    key: 'payment-message',
                    miniIcon: 'PM',
                },
                {
                    route: DashboardRouteNames.SYSTEM_MESSAGES_CUSTOMER,
                    name: `System messages (${
                        countMenu?.message_center?.system
                            ? countMenu?.message_center?.system
                            : 0
                    })`,
                    key: 'system-message',
                    miniIcon: 'SM',
                },
            ],
            icon: (
                <i className="icon">
                    <svg
                        width="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            opacity="0.4"
                            d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2Z"
                            fill="currentColor"
                        ></path>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.07996 6.6499V6.6599C7.64896 6.6599 7.29996 7.0099 7.29996 7.4399C7.29996 7.8699 7.64896 8.2199 8.07996 8.2199H11.069C11.5 8.2199 11.85 7.8699 11.85 7.4289C11.85 6.9999 11.5 6.6499 11.069 6.6499H8.07996ZM15.92 12.7399H8.07996C7.64896 12.7399 7.29996 12.3899 7.29996 11.9599C7.29996 11.5299 7.64896 11.1789 8.07996 11.1789H15.92C16.35 11.1789 16.7 11.5299 16.7 11.9599C16.7 12.3899 16.35 12.7399 15.92 12.7399ZM15.92 17.3099H8.07996C7.77996 17.3499 7.48996 17.1999 7.32996 16.9499C7.16996 16.6899 7.16996 16.3599 7.32996 16.1099C7.48996 15.8499 7.77996 15.7099 8.07996 15.7399H15.92C16.319 15.7799 16.62 16.1199 16.62 16.5299C16.62 16.9289 16.319 17.2699 15.92 17.3099Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                </i>
            ),
        },
        {
            route: DashboardRouteNames.USER_PROFILE_EMPTY,
            name: 'Personal profile',
            toggle: true,
            key: 'personal-profile',
            toggleLink: [
                {
                    route: DashboardRouteNames.USER_PROFILE,
                    name: 'My profile',
                    key: 'personal-profile2',
                    miniIcon: 'MP',
                },
                {
                    route: DashboardRouteNames.USER_SECURITY,
                    name: 'Security',
                    key: 'security',
                    miniIcon: 'S',
                },
                {
                    route: DashboardRouteNames.USER_IDENTIFICATION,
                    name: 'Verification data',
                    key: 'verification-data',
                    miniIcon: 'VD',
                },
            ],
            icon: (
                <i className="icon">
                    <svg
                        width="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M11.997 15.1746C7.684 15.1746 4 15.8546 4 18.5746C4 21.2956 7.661 21.9996 11.997 21.9996C16.31 21.9996 19.994 21.3206 19.994 18.5996C19.994 15.8786 16.334 15.1746 11.997 15.1746Z"
                            fill="currentColor"
                        ></path>
                        <path
                            opacity="0.4"
                            d="M11.9971 12.5838C14.9351 12.5838 17.2891 10.2288 17.2891 7.29176C17.2891 4.35476 14.9351 1.99976 11.9971 1.99976C9.06008 1.99976 6.70508 4.35476 6.70508 7.29176C6.70508 10.2288 9.06008 12.5838 11.9971 12.5838Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                </i>
            ),
        },
        {
            route: DashboardRouteNames.HOW_IT_WORKS,
            name: 'How it works',
            icon: (
                <i className="icon">
                    <svg
                        width="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            opacity="0.4"
                            d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2Z"
                            fill="currentColor"
                        ></path>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.07996 6.6499V6.6599C7.64896 6.6599 7.29996 7.0099 7.29996 7.4399C7.29996 7.8699 7.64896 8.2199 8.07996 8.2199H11.069C11.5 8.2199 11.85 7.8699 11.85 7.4289C11.85 6.9999 11.5 6.6499 11.069 6.6499H8.07996ZM15.92 12.7399H8.07996C7.64896 12.7399 7.29996 12.3899 7.29996 11.9599C7.29996 11.5299 7.64896 11.1789 8.07996 11.1789H15.92C16.35 11.1789 16.7 11.5299 16.7 11.9599C16.7 12.3899 16.35 12.7399 15.92 12.7399ZM15.92 17.3099H8.07996C7.77996 17.3499 7.48996 17.1999 7.32996 16.9499C7.16996 16.6899 7.16996 16.3599 7.32996 16.1099C7.48996 15.8499 7.77996 15.7099 8.07996 15.7399H15.92C16.319 15.7799 16.62 16.1199 16.62 16.5299C16.62 16.9289 16.319 17.2699 15.92 17.3099Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                </i>
            ),
        },
        {
            route: DashboardRouteNames.HELP_CENTER,
            name: 'Help center',
            icon: (
                <i className="icon">
                    <svg
                        width="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            opacity="0.4"
                            d="M6.70555 12.8906C6.18944 12.8906 5.77163 13.3146 5.77163 13.8384L5.51416 18.4172C5.51416 19.0847 6.04783 19.6251 6.70555 19.6251C7.36328 19.6251 7.89577 19.0847 7.89577 18.4172L7.63947 13.8384C7.63947 13.3146 7.22167 12.8906 6.70555 12.8906Z"
                            fill="currentColor"
                        ></path>
                        <path
                            d="M7.98037 3.67345C7.98037 3.67345 7.71236 3.39789 7.54618 3.27793C7.30509 3.09264 7.00783 3 6.71173 3C6.37936 3 6.07039 3.10452 5.81877 3.30169C5.77313 3.34801 5.57886 3.5226 5.41852 3.68532C4.41204 4.6367 2.76539 7.12026 2.26215 8.42083C2.18257 8.618 2.01053 9.11685 2 9.38409C2 9.63827 2.05618 9.88294 2.17087 10.1145C2.3312 10.4044 2.58282 10.6372 2.88009 10.7642C3.08606 10.8462 3.70282 10.9733 3.71453 10.9733C4.38981 11.1016 5.48757 11.1704 6.70003 11.1704C7.85514 11.1704 8.90727 11.1016 9.59308 10.997C9.60478 10.9852 10.3702 10.8581 10.6335 10.7179C11.1133 10.4626 11.4118 9.96371 11.4118 9.43041V9.38409C11.4001 9.03608 11.1016 8.30444 11.0911 8.30444C10.5879 7.07394 9.02079 4.64858 7.98037 3.67345Z"
                            fill="currentColor"
                        ></path>
                        <path
                            opacity="0.4"
                            d="M17.2949 11.1096C17.811 11.1096 18.2288 10.6856 18.2288 10.1618L18.4851 5.58296C18.4851 4.91543 17.9526 4.375 17.2949 4.375C16.6372 4.375 16.1035 4.91543 16.1035 5.58296L16.361 10.1618C16.361 10.6856 16.7788 11.1096 17.2949 11.1096Z"
                            fill="currentColor"
                        ></path>
                        <path
                            d="M21.8293 13.8853C21.6689 13.5955 21.4173 13.3638 21.1201 13.2356C20.9141 13.1536 20.2961 13.0265 20.2856 13.0265C19.6103 12.8982 18.5126 12.8293 17.3001 12.8293C16.145 12.8293 15.0929 12.8982 14.4071 13.0028C14.3954 13.0146 13.63 13.1429 13.3666 13.2819C12.8856 13.5373 12.5884 14.0361 12.5884 14.5706V14.6169C12.6001 14.9649 12.8973 15.6954 12.909 15.6954C13.4123 16.9259 14.9782 19.3525 16.0198 20.3265C16.0198 20.3265 16.2878 20.6021 16.454 20.7208C16.6939 20.9073 16.9911 21 17.2896 21C17.6208 21 17.9286 20.8954 18.1814 20.6983C18.227 20.652 18.4213 20.4774 18.5816 20.3158C19.5869 19.3632 21.2347 16.8796 21.7368 15.5802C21.8176 15.383 21.9896 14.883 22.0001 14.6169C22.0001 14.3616 21.944 14.1169 21.8293 13.8853Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                </i>
            ),
        },
    ];

    const pagesLawyer: Page[] = [
        {
            route: DashboardRouteNames.CASE_PROFILE_CUSTOMER,
            name: `Case Profile (${
                countMenu?.cases?.new ? countMenu?.cases?.new : 0
            }/${countMenu?.cases?.pending ? countMenu?.cases?.pending : 0}/${
                countMenu?.cases?.archive ? countMenu?.cases?.archive : 0
            })`,
            toggle: true,
            key: 'user-profile',
            toggleLink: [
                {
                    route: DashboardRouteNames.CREATE_CASE_LAWYER,
                    name: 'Create Case',
                    key: 'create-case-lawyer',
                    miniIcon: 'CC',
                },
                {
                    route: DashboardRouteNames.OPEN_CASE,
                    name: `Pending Cases (${
                        countMenu?.cases?.new ? countMenu?.cases?.new : 0
                    })`,
                    key: 'open-case',
                    miniIcon: 'PC',
                },
                {
                    route: DashboardRouteNames.PENDING_CASES_LAWYER,
                    name: `Active Cases (${
                        countMenu?.cases?.pending
                            ? countMenu?.cases?.pending
                            : 0
                    })`,
                    key: 'pending-case-lawyer',
                    miniIcon: 'AC',
                },
                {
                    route: DashboardRouteNames.ARCHIVE_CASES_LAWYER,
                    name: `Archive Cases (${
                        countMenu?.cases?.archive
                            ? countMenu?.cases?.archive
                            : 0
                    })`,
                    key: 'archive-cases-lawyer',
                    miniIcon: 'AC',
                },
            ],
            icon: (
                <i className="icon">
                    <svg
                        width="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M11.997 15.1746C7.684 15.1746 4 15.8546 4 18.5746C4 21.2956 7.661 21.9996 11.997 21.9996C16.31 21.9996 19.994 21.3206 19.994 18.5996C19.994 15.8786 16.334 15.1746 11.997 15.1746Z"
                            fill="currentColor"
                        ></path>
                        <path
                            opacity="0.4"
                            d="M11.9971 12.5838C14.9351 12.5838 17.2891 10.2288 17.2891 7.29176C17.2891 4.35476 14.9351 1.99976 11.9971 1.99976C9.06008 1.99976 6.70508 4.35476 6.70508 7.29176C6.70508 10.2288 9.06008 12.5838 11.9971 12.5838Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                </i>
            ),
        },
        {
            route: DashboardRouteNames.USER_PROFILE_EMPTY,
            name: 'Personal Profile',
            toggle: true,
            key: 'personal-profile-lawyer',
            toggleLink: [
                {
                    route: DashboardRouteNames.USER_PROFILE,
                    name: 'My Profile',
                    key: 'personal-profile-lawyer',
                    miniIcon: 'MP',
                },
                {
                    route: DashboardRouteNames.USER_SECURITY,
                    name: 'Security',
                    key: 'security',
                    miniIcon: 'S',
                },
                {
                    route: DashboardRouteNames.EDUCATION_DOCUMENT,
                    name: 'Education document',
                    key: 'education-document',
                    miniIcon: 'ED',
                },
            ],
            icon: (
                <i className="icon">
                    <svg
                        width="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            opacity="0.4"
                            d="M13.3051 5.88243V6.06547C12.8144 6.05584 12.3237 6.05584 11.8331 6.05584V5.89206C11.8331 5.22733 11.2737 4.68784 10.6064 4.68784H9.63482C8.52589 4.68784 7.62305 3.80152 7.62305 2.72254C7.62305 2.32755 7.95671 2 8.35906 2C8.77123 2 9.09508 2.32755 9.09508 2.72254C9.09508 3.01155 9.34042 3.24276 9.63482 3.24276H10.6064C12.0882 3.2524 13.2953 4.43736 13.3051 5.88243Z"
                            fill="currentColor"
                        ></path>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M15.164 6.08279C15.4791 6.08712 15.7949 6.09145 16.1119 6.09469C19.5172 6.09469 22 8.52241 22 11.875V16.1813C22 19.5339 19.5172 21.9616 16.1119 21.9616C14.7478 21.9905 13.3837 22.0001 12.0098 22.0001C10.6359 22.0001 9.25221 21.9905 7.88813 21.9616C4.48283 21.9616 2 19.5339 2 16.1813V11.875C2 8.52241 4.48283 6.09469 7.89794 6.09469C9.18351 6.07542 10.4985 6.05615 11.8332 6.05615C12.3238 6.05615 12.8145 6.05615 13.3052 6.06579C13.9238 6.06579 14.5425 6.07427 15.164 6.08279ZM10.8518 14.7459H9.82139V15.767C9.82139 16.162 9.48773 16.4896 9.08538 16.4896C8.67321 16.4896 8.34936 16.162 8.34936 15.767V14.7459H7.30913C6.90677 14.7459 6.57311 14.4279 6.57311 14.0233C6.57311 13.6283 6.90677 13.3008 7.30913 13.3008H8.34936V12.2892C8.34936 11.8942 8.67321 11.5667 9.08538 11.5667C9.48773 11.5667 9.82139 11.8942 9.82139 12.2892V13.3008H10.8518C11.2542 13.3008 11.5878 13.6283 11.5878 14.0233C11.5878 14.4279 11.2542 14.7459 10.8518 14.7459ZM15.0226 13.1177H15.1207C15.5231 13.1177 15.8567 12.7998 15.8567 12.3952C15.8567 12.0002 15.5231 11.6727 15.1207 11.6727H15.0226C14.6104 11.6727 14.2866 12.0002 14.2866 12.3952C14.2866 12.7998 14.6104 13.1177 15.0226 13.1177ZM16.7007 16.4318H16.7988C17.2012 16.4318 17.5348 16.1139 17.5348 15.7092C17.5348 15.3143 17.2012 14.9867 16.7988 14.9867H16.7007C16.2885 14.9867 15.9647 15.3143 15.9647 15.7092C15.9647 16.1139 16.2885 16.4318 16.7007 16.4318Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                </i>
            ),
        },
        {
            route: DashboardRouteNames.MESSAGE_CENTER_LAWYER,
            name: `Message center (${
                countMenu?.message_center?.customer
                    ? countMenu?.message_center?.customer
                    : 0
            }/${
                countMenu?.message_center?.payments
                    ? countMenu?.message_center?.payments
                    : 0
            }/${
                countMenu?.message_center?.system
                    ? countMenu?.message_center?.system
                    : 0
            })`,
            toggle: true,
            key: 'message-center-lawyer',
            toggleLink: [
                {
                    route: DashboardRouteNames.CUSTOMER_MESSAGES,
                    name: `Customer messages (${
                        countMenu?.message_center?.customer
                            ? countMenu?.message_center?.customer
                            : 0
                    })`,
                    key: 'customer-messages',
                    miniIcon: 'CM',
                },
                {
                    route: DashboardRouteNames.PAYMENT_MESSAGES,
                    name: `Payment messages (${
                        countMenu?.message_center?.payments
                            ? countMenu?.message_center?.payments
                            : 0
                    })`,
                    key: 'payment-messages',
                    miniIcon: 'PM',
                },
                {
                    route: DashboardRouteNames.SYSTEM_MESSAGES,
                    name: `System messages (${
                        countMenu?.message_center?.system
                            ? countMenu?.message_center?.system
                            : 0
                    })`,
                    key: 'system-messages',
                    miniIcon: 'SM',
                },
            ],
            icon: (
                <i className="icon">
                    <svg
                        width="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            opacity="0.4"
                            d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2Z"
                            fill="currentColor"
                        ></path>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.07996 6.6499V6.6599C7.64896 6.6599 7.29996 7.0099 7.29996 7.4399C7.29996 7.8699 7.64896 8.2199 8.07996 8.2199H11.069C11.5 8.2199 11.85 7.8699 11.85 7.4289C11.85 6.9999 11.5 6.6499 11.069 6.6499H8.07996ZM15.92 12.7399H8.07996C7.64896 12.7399 7.29996 12.3899 7.29996 11.9599C7.29996 11.5299 7.64896 11.1789 8.07996 11.1789H15.92C16.35 11.1789 16.7 11.5299 16.7 11.9599C16.7 12.3899 16.35 12.7399 15.92 12.7399ZM15.92 17.3099H8.07996C7.77996 17.3499 7.48996 17.1999 7.32996 16.9499C7.16996 16.6899 7.16996 16.3599 7.32996 16.1099C7.48996 15.8499 7.77996 15.7099 8.07996 15.7399H15.92C16.319 15.7799 16.62 16.1199 16.62 16.5299C16.62 16.9289 16.319 17.2699 15.92 17.3099Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                </i>
            ),
        },
        {
            route: DashboardRouteNames.SEARCH_LAWYER,
            name: 'Search',
            icon: (
                <i className="icon">
                    <svg
                        width="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            opacity="0.4"
                            d="M6.70555 12.8906C6.18944 12.8906 5.77163 13.3146 5.77163 13.8384L5.51416 18.4172C5.51416 19.0847 6.04783 19.6251 6.70555 19.6251C7.36328 19.6251 7.89577 19.0847 7.89577 18.4172L7.63947 13.8384C7.63947 13.3146 7.22167 12.8906 6.70555 12.8906Z"
                            fill="currentColor"
                        ></path>
                        <path
                            d="M7.98037 3.67345C7.98037 3.67345 7.71236 3.39789 7.54618 3.27793C7.30509 3.09264 7.00783 3 6.71173 3C6.37936 3 6.07039 3.10452 5.81877 3.30169C5.77313 3.34801 5.57886 3.5226 5.41852 3.68532C4.41204 4.6367 2.76539 7.12026 2.26215 8.42083C2.18257 8.618 2.01053 9.11685 2 9.38409C2 9.63827 2.05618 9.88294 2.17087 10.1145C2.3312 10.4044 2.58282 10.6372 2.88009 10.7642C3.08606 10.8462 3.70282 10.9733 3.71453 10.9733C4.38981 11.1016 5.48757 11.1704 6.70003 11.1704C7.85514 11.1704 8.90727 11.1016 9.59308 10.997C9.60478 10.9852 10.3702 10.8581 10.6335 10.7179C11.1133 10.4626 11.4118 9.96371 11.4118 9.43041V9.38409C11.4001 9.03608 11.1016 8.30444 11.0911 8.30444C10.5879 7.07394 9.02079 4.64858 7.98037 3.67345Z"
                            fill="currentColor"
                        ></path>
                        <path
                            opacity="0.4"
                            d="M17.2949 11.1096C17.811 11.1096 18.2288 10.6856 18.2288 10.1618L18.4851 5.58296C18.4851 4.91543 17.9526 4.375 17.2949 4.375C16.6372 4.375 16.1035 4.91543 16.1035 5.58296L16.361 10.1618C16.361 10.6856 16.7788 11.1096 17.2949 11.1096Z"
                            fill="currentColor"
                        ></path>
                        <path
                            d="M21.8293 13.8853C21.6689 13.5955 21.4173 13.3638 21.1201 13.2356C20.9141 13.1536 20.2961 13.0265 20.2856 13.0265C19.6103 12.8982 18.5126 12.8293 17.3001 12.8293C16.145 12.8293 15.0929 12.8982 14.4071 13.0028C14.3954 13.0146 13.63 13.1429 13.3666 13.2819C12.8856 13.5373 12.5884 14.0361 12.5884 14.5706V14.6169C12.6001 14.9649 12.8973 15.6954 12.909 15.6954C13.4123 16.9259 14.9782 19.3525 16.0198 20.3265C16.0198 20.3265 16.2878 20.6021 16.454 20.7208C16.6939 20.9073 16.9911 21 17.2896 21C17.6208 21 17.9286 20.8954 18.1814 20.6983C18.227 20.652 18.4213 20.4774 18.5816 20.3158C19.5869 19.3632 21.2347 16.8796 21.7368 15.5802C21.8176 15.383 21.9896 14.883 22.0001 14.6169C22.0001 14.3616 21.944 14.1169 21.8293 13.8853Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                </i>
            ),
        },
    ];

    const activeKeyValue = JSON.parse(
        window.sessionStorage.getItem('eventKey') || '{}',
    );

    if (userRoleId === 3) {
        return (
            <div>
                <MessagesMqtt setCountMenu={setCountMenu} />
                <ModalToken showModalExpiredToken={showModalExpiredToken} />
                <Accordion
                    as="ul"
                    className="navbar-nav iq-main-menu"
                    activeKey={activeKeyValue ? activeKeyValue : ''}
                >
                    {pagesCustomer.map((page: Page, index: number) =>
                        page.toggle && page.key ? (
                            <Accordion.Item
                                as="li"
                                key={page?.key}
                                eventKey={page?.key}
                                bsPrefix="nav-item"
                            >
                                <CustomToggle
                                    eventKey={page?.key}
                                    onClick={(activeKey: any) => {
                                        setActiveMenu(activeKey);
                                        const activeKeyNow = JSON.parse(
                                            window.sessionStorage.getItem(
                                                'eventKey',
                                            ) || '{}',
                                        );
                                        if (activeKeyNow === page?.key) {
                                            window.sessionStorage.setItem(
                                                'eventKey',
                                                JSON.stringify(''),
                                            );
                                        } else {
                                            window.sessionStorage.setItem(
                                                'eventKey',
                                                JSON.stringify(page?.key),
                                            );
                                        }
                                    }}
                                    to={
                                        page.route ===
                                            '/dashboard/message-center-customer' ||
                                        page.route ===
                                            '/dashboard/case-profile-customer' ||
                                        page.route ===
                                            '/dashboard/user/profile-empty' ||
                                        page.route ===
                                            '/dashboard/payments-customer'
                                            ? location.pathname
                                            : page.route
                                    }
                                >
                                    {page.icon}
                                    {countMenu?.payments?.pending !== 0 &&
                                    index === 2 ? (
                                        <span className="item-name">
                                            {
                                                <>
                                                    {page.name.split(' ')[0]} (
                                                    <span
                                                        style={{
                                                            color: '#ff0000',
                                                            marginLeft: '0px',
                                                        }}
                                                    >
                                                        {
                                                            page.name
                                                                .split(' ')[1]
                                                                .split('/')[0]
                                                                .split('')[1]
                                                        }
                                                        {countMenu?.payments
                                                            ?.pending > 9 &&
                                                            page.name
                                                                .split(' ')[1]
                                                                .split('/')[0]
                                                                .split('')[2]}
                                                        {countMenu?.payments
                                                            ?.pending > 99 &&
                                                            page.name
                                                                .split(' ')[1]
                                                                .split('/')[0]
                                                                .split('')[3]}
                                                        {countMenu?.payments
                                                            ?.pending > 999 &&
                                                            page.name
                                                                .split(' ')[1]
                                                                .split('/')[0]
                                                                .split('')[4]}
                                                    </span>
                                                    /
                                                    {
                                                        page.name
                                                            .split(' ')[1]
                                                            .split('/')[1]
                                                    }
                                                </>
                                            }
                                        </span>
                                    ) : (
                                        <span className="item-name">
                                            {page.name}
                                        </span>
                                    )}

                                    <i className="right-icon">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M9 5l7 7-7 7"
                                            />
                                        </svg>
                                    </i>
                                </CustomToggle>
                                <Accordion.Collapse eventKey={page?.key}>
                                    <ul className="sub-nav">
                                        {page.toggleLink.map(
                                            (item: ToggleMap, num: number) => (
                                                <li
                                                    key={item.key}
                                                    className="nav-item"
                                                >
                                                    <Link
                                                        to={item.route}
                                                        className={`${
                                                            location.pathname ===
                                                            item.route
                                                                ? 'active'
                                                                : ''
                                                        } nav-link `}
                                                    >
                                                        <i className="icon">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="10"
                                                                viewBox="0 0 24 24"
                                                                fill="currentColor"
                                                            >
                                                                <g>
                                                                    <circle
                                                                        cx="12"
                                                                        cy="12"
                                                                        r="8"
                                                                        fill="currentColor"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </i>
                                                        <i className="sidenav-mini-icon">
                                                            {item.miniIcon}
                                                        </i>
                                                        {countMenu?.payments
                                                            ?.pending !== 0 &&
                                                        num === 0 &&
                                                        item.key ===
                                                            'payments-customer-pending' ? (
                                                            <span className="item-name">
                                                                {
                                                                    <>
                                                                        {
                                                                            item.name.split(
                                                                                ' ',
                                                                            )[0]
                                                                        }{' '}
                                                                        {
                                                                            item.name.split(
                                                                                ' ',
                                                                            )[1]
                                                                        }{' '}
                                                                        (
                                                                        <span
                                                                            style={{
                                                                                color: '#ff0000',
                                                                                marginLeft:
                                                                                    '0px',
                                                                            }}
                                                                        >
                                                                            {
                                                                                item.name.split(
                                                                                    ' ',
                                                                                )[2]
                                                                            }
                                                                        </span>
                                                                        )
                                                                    </>
                                                                }
                                                            </span>
                                                        ) : (
                                                            <span className="item-name">
                                                                {item.name}
                                                            </span>
                                                        )}
                                                    </Link>
                                                </li>
                                            ),
                                        )}
                                    </ul>
                                </Accordion.Collapse>
                            </Accordion.Item>
                        ) : (
                            <li key={page.name} className="nav-item">
                                <Link
                                    className={`${
                                        location.pathname === page.route
                                            ? 'active'
                                            : ''
                                    } nav-link `}
                                    onClick={handleLink}
                                    to={
                                        page.route ===
                                            '/dashboard/message-center-customer' ||
                                        page.route ===
                                            '/dashboard/case-profile-customer' ||
                                        page.route ===
                                            '/dashboard/user/profile-empty' ||
                                        page.route ===
                                            '/dashboard/payments-customer'
                                            ? location.pathname
                                            : page.route
                                    }
                                >
                                    {page.icon}
                                    <span className="item-name">
                                        {page.name}
                                    </span>
                                </Link>
                            </li>
                        ),
                    )}
                </Accordion>
            </div>
        );
    } else if (userRoleId === 2) {
        return (
            <div>
                <MessagesMqtt setCountMenu={setCountMenu} />
                <ModalToken showModalExpiredToken={showModalExpiredToken} />
                <Accordion
                    as="ul"
                    className="navbar-nav iq-main-menu"
                    activeKey={activeKeyValue ? activeKeyValue : ''}
                >
                    {pagesLawyer.map((page: Page) =>
                        page.toggle && page.key ? (
                            <Accordion.Item
                                as="li"
                                key={page?.key}
                                eventKey={page?.key}
                                bsPrefix="nav-item"
                            >
                                <CustomToggle
                                    eventKey={page?.key}
                                    onClick={(activeKey: any) => {
                                        setActiveMenu(activeKey);
                                        const activeKeyNow = JSON.parse(
                                            window.sessionStorage.getItem(
                                                'eventKey',
                                            ) || '{}',
                                        );
                                        if (activeKeyNow === page?.key) {
                                            window.sessionStorage.setItem(
                                                'eventKey',
                                                JSON.stringify(''),
                                            );
                                        } else {
                                            window.sessionStorage.setItem(
                                                'eventKey',
                                                JSON.stringify(page?.key),
                                            );
                                        }
                                    }}
                                    to={
                                        page.route ===
                                            '/dashboard/message-center-lawyer' ||
                                        page.route ===
                                            '/dashboard/case-profile-customer' ||
                                        page.route ===
                                            '/dashboard/user/profile-empty' ||
                                        page.route ===
                                            '/dashboard/payments-customer'
                                            ? location.pathname
                                            : page.route
                                    }
                                >
                                    {page.icon}
                                    <span className="item-name">
                                        {page.name}
                                    </span>
                                    <i className="right-icon">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M9 5l7 7-7 7"
                                            />
                                        </svg>
                                    </i>
                                </CustomToggle>
                                <Accordion.Collapse eventKey={page?.key}>
                                    <ul className="sub-nav">
                                        {page.toggleLink.map(
                                            (item: ToggleMap) => (
                                                <li
                                                    key={item.key}
                                                    className="nav-item"
                                                >
                                                    <Link
                                                        to={item.route}
                                                        className={`${
                                                            location.pathname ===
                                                            item.route
                                                                ? 'active'
                                                                : ''
                                                        } nav-link `}
                                                    >
                                                        <i className="icon">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="10"
                                                                viewBox="0 0 24 24"
                                                                fill="currentColor"
                                                            >
                                                                <g>
                                                                    <circle
                                                                        cx="12"
                                                                        cy="12"
                                                                        r="8"
                                                                        fill="currentColor"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </i>
                                                        <i className="sidenav-mini-icon">
                                                            {item.miniIcon}
                                                        </i>
                                                        <span className="item-name">
                                                            {item.name}
                                                        </span>
                                                    </Link>
                                                </li>
                                            ),
                                        )}
                                    </ul>
                                </Accordion.Collapse>
                            </Accordion.Item>
                        ) : (
                            <li key={page.name} className="nav-item">
                                <Link
                                    className={`${
                                        location.pathname === page.route
                                            ? 'active'
                                            : ''
                                    } nav-link `}
                                    onClick={handleLink}
                                    to={
                                        page.route ===
                                            '/dashboard/case-profile-customer' ||
                                        page.route ===
                                            '/dashboard/user/profile-empty' ||
                                        page.route ===
                                            '/dashboard/payments-customer'
                                            ? location.pathname
                                            : page.route
                                    }
                                >
                                    {page.icon}
                                    <span className="item-name">
                                        {page.name}
                                    </span>
                                </Link>
                            </li>
                        ),
                    )}
                </Accordion>
            </div>
        );
    } else {
        return null; // 1 - admin
    }
};

export default VerticalNav;
