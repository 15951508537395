import { Axios } from '../plugins/helpers';
import { notificationError } from '../plugins/requestNotification';

export const GetPendingUserCases = async () => {
    try {
        const response = await Axios().get(
            `${process.env.REACT_APP_BASE_URL}/get-user-cases`,
        );
        return response;
    } catch (e: any) {
        notificationError(e);
        return e?.response;
    }
};

export const GetPendingUserCase = async (id: number | string) => {
    try {
        const response = await Axios().get(
            `${process.env.REACT_APP_BASE_URL}/get-user-cases/${id}`,
        );
        return response;
    } catch (e: any) {
        notificationError(e);
        return e?.response;
    }
};

export const PostMessageLawyerWithDocuments = async (
    user_id: number,
    chat_id: number,
    description: string,
    parent_id: number,
    title: string,
    documents: any,
    message_number: number,
) => {
    try {
        const response = await Axios().post(
            `${process.env.REACT_APP_BASE_URL}/add-message`,
            {
                user_id,
                chat_id,
                parent_id,
                title,
                description,
                documents,
                message_number,
            },
        );
        return response;
    } catch (e: any) {
        notificationError(e);
        return e?.response;
    }
};

export const PostMessageLawyerWithOutDocuments = async (
    user_id: number,
    chat_id: number,
    description: string,
    parent_id: number,
    title: string,
    message_number: number,
) => {
    try {
        const response = await Axios().post(
            `${process.env.REACT_APP_BASE_URL}/add-message`,
            { user_id, chat_id, parent_id, title, description, message_number },
        );
        return response;
    } catch (e: any) {
        notificationError(e);
        return e?.response;
    }
};

export const PostPayInvoicePandingCase = async (
    invoice_id: number,
    cardholder_name: string,
    card_number: number,
    expiration_date: number | string,
    cvv: number | string,
    billing_address: string,
    city: string,
    zip_code: string,
) => {
    try {
        const response = await Axios().post(
            `${process.env.REACT_APP_BASE_URL}/pay-case-invoice`,
            { invoice_id, cardholder_name, card_number, expiration_date, cvv, billing_address, city, zip_code },
        );
        return response;
    } catch (e: any) {
        // notificationError(e);
        return e?.response;
    }
};

export const PostOpenMessage = async (
    id: number
) => {
    try {
        const response = await Axios().put(
            `${process.env.REACT_APP_BASE_URL}/view-message`,
            { id },
        );
        return response;
    } catch (e: any) {
        notificationError(e);
        return e?.response;
    }
};

