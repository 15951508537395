import { Button, Image } from 'antd';
import 'antd/dist/antd.css';
import { NavLink } from 'react-router-dom';
import { DashboardRouteNames } from '../router/Dashboard';

const columnsPendingCaseTable = [
    {
        title: 'Case Number',
        dataIndex: 'caseNumber',
        key: 'caseNumber',
        align: 'center' as 'center',
    },
    {
        title: 'Service',
        dataIndex: 'nameServices',
        key: 'nameServices',
        align: 'center' as 'center',
    },
    {
        title: 'Opening date',
        dataIndex: 'timeAndDate',
        key: 'timeAndDate',
        align: 'center' as 'center',
    },
    {
        title: 'Customer',
        dataIndex: 'avatarCustomer',
        key: 'avatarCustomer',
        id: 'key',
            render: (avatarCustomer: string, key:any) => (
                <div>
                    <Image width={50} src={avatarCustomer} />
                    <div style={{marginTop: '10px'}}>
                    {key.nameCustomer}
                    </div>
                </div>
            ),
        align: 'center' as 'center',
    },
    {
        title: 'Show Case',
        dataIndex: 'showCase',
        key: 'showCase',
        id: 'key',
        render: (showCase: string, key: any) => (
            <Button danger>
                <NavLink
                    to={{
                        pathname: `${DashboardRouteNames.PENDING_OPEN_DETAILS_CUSTOMER}`,
                        state: { key: key.key },
                    }}
                >
                    {showCase}
                </NavLink>
            </Button>
        ),
        align: 'center' as 'center',
    },
];

export default columnsPendingCaseTable;
