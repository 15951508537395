import React from 'react';



const PaymentsLawyer = () => {
    return(
        <div>
            PaymentsLawyer
        </div>
    );
}


export default PaymentsLawyer;