import React, { useState, useEffect } from 'react';
import moment from 'moment';
import './calendarStyles/calendarStyles.css';
import CalendarHeader from './CalendarHeader/CalendarHeader';
import Monitor from './Monitor/Monitor';
import CalendarGrid from './CalendarGrid/CalendarGrid';

const EventCaledar = (props: any) => {
    moment.updateLocale('en', { week: { dow: 1 } });
    const [today, setToday] = useState<any>(moment());
    const [events, setEvents] = useState<any[]>([]);
    const startDay = today.clone().startOf('month').startOf('week');

    useEffect(() => {
        setEvents(props.events);
    }, [props.events]);

    const prevHandler = () => {
        setToday((prev: any) => prev.clone().subtract(1, 'month'));
    };
    const todayHandler = () => {
        setToday(moment());
    };
    const nextHandler = () => {
        setToday((prev: any) => prev.clone().add(1, 'month'));
    };

    return (
        <div className="calendar-wrapper card">
            <CalendarHeader />
            <Monitor
                today={today}
                prevHandler={prevHandler}
                todayHandler={todayHandler}
                nextHandler={nextHandler}
                id={props.id}
                status={props.status}
                onReloadTableAfterSend={props.onReloadTableAfterSend}
            />
            <CalendarGrid startDay={startDay} today={today} events={events} />
        </div>
    );
};

export default EventCaledar;
