import React, { useState, useEffect } from 'react';
import {
    DataPaymentsPendingType,
    DataPaymentsClosedType,
    // InvoiceTransactionType,
} from '../../../../../models/IPayments';
import {
    GetPaymentsPending,
    GetPaymentsClose,
} from '../../../../../apis/payments';
import { Table } from 'antd';
import moment from 'moment';

const PaymentsInCase = (props: any) => {
    const [dataPaymentsPending, setDataPaymentsPending] = useState<
        DataPaymentsPendingType[]
    >([]);
    const [dataPaymentsClosed, setDataPaymentsClosed] = useState<
        DataPaymentsClosedType[]
    >([]);
    const [generalArrayPayments, setGeneralArrayPayments] = useState<
        DataPaymentsPendingType[] | DataPaymentsClosedType[]
    >([]);
    const [selectedPayments, setSelectedPayments] = useState<
        DataPaymentsPendingType[] | DataPaymentsClosedType[]
    >([]);
    // const [invoiceTransactions, setInvoiceTransactions] = useState<
    //     InvoiceTransactionType[]
    // >([]);
    const [invoiceTransactionForTable, setInvoiceTransactionForTable] =
        useState<any[]>([]);

    useEffect(() => {
        if (props?.customerId) {
            GetPaymentsPending(props?.customerId).then(res =>
                setDataPaymentsPending(res.data.data),
            );
            GetPaymentsClose(props?.customerId).then(res =>
                setDataPaymentsClosed(res.data.data),
            );
        }
    }, [props?.customerId, props.reloadTablePayment]);

    useEffect(() => {
        if (props?.customerId) {
            let messageMqttJSON: any = props?.messageMqttMessage;
            let objSubscriptionMqtt =
                messageMqttJSON && JSON.parse(messageMqttJSON?.message);
            if (
                objSubscriptionMqtt?.action === 'pay-invoice' ||
                objSubscriptionMqtt?.action === 'create-invoice'
            ) {
                GetPaymentsPending(props?.customerId).then(res =>
                    setDataPaymentsPending(res.data.data),
                );
                GetPaymentsClose(props?.customerId).then(res =>
                    setDataPaymentsClosed(res.data.data),
                );
            }
        }
    }, [props?.messageMqttMessage]);

    useEffect(() => {
        setGeneralArrayPayments([
            ...dataPaymentsPending,
            ...dataPaymentsClosed,
        ]);
    }, [dataPaymentsPending, dataPaymentsClosed]);

    useEffect(() => {
        const tempSelectedPayments = generalArrayPayments.filter(
            item => item.case_id === props?.caseNumber,
        );
        setSelectedPayments(tempSelectedPayments);
    }, [generalArrayPayments]);

    useEffect(() => {
        const tempInvoiceTransactions: any[] = [];
        let tempNumber = 0;
        selectedPayments.forEach(item => {
            if (item.invoiceTransaction?.length > 0) {
                item.invoiceTransaction.forEach(el => {
                    let tempObjInvoiceTransaction: any = {};
                    tempObjInvoiceTransaction.key = ++tempNumber;
                    tempObjInvoiceTransaction.paymentId = el.hash;
                    tempObjInvoiceTransaction.dateAndTime = el.created_date;
                    tempObjInvoiceTransaction.price = `${el.price} AED`;
                    if (el.status === 1) {
                        tempObjInvoiceTransaction.status = 'Approved';
                    } else if (el.status === 2) {
                        tempObjInvoiceTransaction.status = 'Decline';
                    } else if (el.status === 0) {
                        tempObjInvoiceTransaction.status = 'Pending';
                    }
                    tempObjInvoiceTransaction.transactionDescription =
                        item.invoice_description;
                    tempInvoiceTransactions.push(tempObjInvoiceTransaction);
                });
            } else {
                if (item.price !== null) {
                    let tempObjInvoiceTransaction: any = {};
                    tempObjInvoiceTransaction.key = ++tempNumber;
                    if (item.payment_id) {
                        tempObjInvoiceTransaction.paymentId = item.payment_id;
                    } else {
                        tempObjInvoiceTransaction.paymentId = 'Did not pay';
                    }
                    if (item.date_create_human) {
                        tempObjInvoiceTransaction.dateAndTime =
                            item.date_create_human;
                    } else {
                        tempObjInvoiceTransaction.dateAndTime =
                            item.date_close_human;
                    }
                    tempObjInvoiceTransaction.price = `${item.price} AED`;
                    if (item.status === 1) {
                        tempObjInvoiceTransaction.status = 'Approved';
                    } else if (item.status === 2) {
                        tempObjInvoiceTransaction.status = 'Decline';
                    } else if (item.status === 0) {
                        tempObjInvoiceTransaction.status = 'Pending';
                    }
                    if (item.invoice_description) {
                        tempObjInvoiceTransaction.transactionDescription =
                            item.invoice_description;
                    } else {
                        tempObjInvoiceTransaction.transactionDescription =
                            'Paid at opening';
                    }
                    tempInvoiceTransactions.push(tempObjInvoiceTransaction);
                } else {
                    let tempObjInvoiceTransaction: any = {};
                    tempObjInvoiceTransaction.key = ++tempNumber;

                    tempObjInvoiceTransaction.paymentId = '-';

                    tempObjInvoiceTransaction.dateAndTime = '-';

                    tempObjInvoiceTransaction.price = '-';
                    if (item.status === 1) {
                        tempObjInvoiceTransaction.status = 'Approved';
                    } else if (item.status === 2) {
                        tempObjInvoiceTransaction.status = 'Decline';
                    } else if (item.status === 0) {
                        tempObjInvoiceTransaction.status = 'Pending';
                    }

                    tempObjInvoiceTransaction.transactionDescription =
                        'Case had opened by lawyer';

                    tempInvoiceTransactions.push(tempObjInvoiceTransaction);
                }
            }
        });
        tempInvoiceTransactions.sort(
            (a, b) =>
                moment(b.dateAndTime).unix() - moment(a.dateAndTime).unix(),
        );
        setInvoiceTransactionForTable(tempInvoiceTransactions);
    }, [selectedPayments]);

    // useEffect(() => {
    //     const tempInvoiceTransactions: InvoiceTransactionType[] = [];
    //     selectedPayments.forEach(item => {
    //         for (let key of Object.entries(item)) {
    //             if (key[0] === 'invoiceTransaction') {
    //                 if (key[1].length === 0) {
    //                     let tempNewInvoiceTransaction: InvoiceTransactionType =
    //                         {
    //                             created_date: item.date_create_human,
    //                             hash: 'Did not pay',
    //                             id: item.case_id,
    //                             invoice_id: item.invoice_id,
    //                             price: item.price,
    //                             status: item.status,
    //                             transaction_description:
    //                                 item.invoice_description,
    //                         };
    //                     tempInvoiceTransactions.push(tempNewInvoiceTransaction);
    //                 }
    //                 tempInvoiceTransactions.push(key[1]);
    //             }
    //         }
    //     });
    //     setInvoiceTransactions(tempInvoiceTransactions.flat());
    // }, [selectedPayments]);

    // useEffect(() => {
    //     let tempArrayInvoiceTransaction: any[] = [];
    //     let tempNumber = 0;
    //     invoiceTransactions.forEach(item => {
    //         let tempObjInvoiceTransaction: any = {};
    //         tempObjInvoiceTransaction.key = ++tempNumber;
    //         tempObjInvoiceTransaction.paymentId = item.hash;
    //         tempObjInvoiceTransaction.dateAndTime = item.created_date;
    //         tempObjInvoiceTransaction.price = `${item.price} AED`;
    //         if (item.status === 1) {
    //             tempObjInvoiceTransaction.status = 'Approved';
    //         } else if (item.status === 2) {
    //             tempObjInvoiceTransaction.status = 'Decline';
    //         } else if (item.status === 0) {
    //             tempObjInvoiceTransaction.status = 'Pending';
    //         }
    //         if (item.transaction_description && item.status !== 0) {
    //             tempObjInvoiceTransaction.transactionDescription =
    //                 item.transaction_description;
    //         } else {
    //             tempObjInvoiceTransaction.transactionDescription =
    //                 'No description';
    //         }

    //         tempArrayInvoiceTransaction.push(tempObjInvoiceTransaction);
    //     });
    //     tempArrayInvoiceTransaction.sort(
    //         (a, b) => Date.parse(b.dateAndTime) - Date.parse(a.dateAndTime),
    //     );
    //     setInvoiceTransactionForTable(tempArrayInvoiceTransaction);
    // }, [invoiceTransactions]);

    const columns = [
        {
            title: 'Payment ID',
            dataIndex: 'paymentId',
            key: 'paymentId',
            align: 'center' as 'center',
            className: 'table-payment-id',
        },
        {
            title: 'Opening/Closing Date',
            dataIndex: 'dateAndTime',
            key: 'dateAndTime',
            align: 'center' as 'center',
            className: 'table-date',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            align: 'center' as 'center',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center' as 'center',
        },
        {
            title: 'Description',
            dataIndex: 'transactionDescription',
            key: 'transactionDescription',
            align: 'center' as 'center',
            ellipsis: true,
            className: 'transaction-description-table',
        },
    ];

    return (
        <div className="messages-pending-case-details card">
            <div className="messages-pending-case-details-table-title">
                Payment for this case
            </div>
            <Table
                columns={columns}
                dataSource={
                    invoiceTransactionForTable ? invoiceTransactionForTable : []
                }
                bordered
            />
        </div>
    );
};

export default PaymentsInCase;
