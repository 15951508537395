import { Modal, Button, Form } from 'react-bootstrap';
import { Button as ButtonAnt } from 'antd';
import React, { useRef, useState, useEffect } from 'react';
import { useActions } from '../../hooks/useActions';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { handleDisableButton } from '../../plugins/helpers';
import { PostResetPasswordEmail } from '../../apis/user';
import { notification } from 'antd';

const AuthModal = () => {
    const { handleHideModal, handleLoader } = useActions();
    const { isShowModal, modalTitle, modalDescription, modalImage } =
        useTypedSelector(state => state.app);
    const [text, setText] = useState('');
    const modalButtonRef = useRef(null);

    const [resetValueCode, setResetValueCode] = useState<string>('');
    const [dataInLocalStorage, setDataLocalStorage] = useState<any>(null);
    const [status, setStatus] = useState<any>(false);

    const dataInLocalStorageJSON = sessionStorage.getItem('resetPassword');

    useEffect(() => {
        setDataLocalStorage(
            dataInLocalStorageJSON ? JSON.parse(dataInLocalStorageJSON) : null,
        );
    }, [dataInLocalStorageJSON]);

    useEffect(() => {
        if (status === true) {
            notification.success({
                message:
                    'If this email address was used to create an account, instructions to reset your password will be sent to you. Please check your email.',
            });
        }
        setStatus(false);
    }, [status]);

    const handleClose = () => {
        handleHideModal('');
        setText('');
        handleLoader(false);
    };

    const handleModal = () => {
        handleDisableButton(modalButtonRef);
        handleHideModal(text);
        setText('');
        handleLoader(false);
    };

    const onSetResetValueCode = (event: any) => {
        setText(event.target.value);
        setResetValueCode(event.target.value);
    };

    const handleModalReset = () => {
        PostResetPasswordEmail(
            dataInLocalStorage.action,
            dataInLocalStorage.hash,
            dataInLocalStorage.status,
            dataInLocalStorage.user_id,
            resetValueCode,
        ).then(value => setStatus(value.data.data.status));
        handleHideModal('');
        setText('');
        handleLoader(false);
    };

    return (
        <Modal show={isShowModal} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{modalDescription}</p>
                <div className="modal__number">Code Number</div>
                {modalTitle ===
                'The reset code was sent to you on the connected two-factor authentication' ? (
                    <Form.Control
                        className="mb-4"
                        onChange={onSetResetValueCode}
                        value={text}
                    />
                ) : (
                    <Form.Control
                        className="mb-4"
                        onChange={e => setText(e.target.value)}
                        value={text}
                    />
                )}
                {modalImage && (
                    <img
                        className="auth-modal__img"
                        src={modalImage}
                        alt="google qr"
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className="modal__button"
                    variant="secondary"
                    onClick={handleClose}
                >
                    Close
                </Button>
                {modalTitle ===
                'The reset code was sent to you on the connected two-factor authentication' ? (
                    <ButtonAnt
                        className="modal__button"
                        onClick={handleModalReset}
                        type="primary"
                    >
                        Next
                    </ButtonAnt>
                ) : (
                    <Button
                        variant="primary"
                        className="modal__button"
                        onClick={handleModal}
                        ref={modalButtonRef}
                    >
                        Next
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default AuthModal;
