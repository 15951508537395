import React, { useState } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import CreateEventModal from '../CreateEventModal/CreateEventModal';

const Monitor = (props: any) => {
    const [showCreateEventModal, setShowCreateEventModal] =
        useState<boolean>(false);

    const onShowCreateEventModal = () => {
        setShowCreateEventModal(true);
    };

    return (
        <div className="div-wrapper">
            <div>
                <span className="text-wrapper-month">
                    {props.today.format('MMMM')}
                </span>
                <span className="text-wrapper-year">
                    {props.today.format('YYYY')}
                </span>
            </div>
            <div>
                {props.status === 2 ? (
                    <button
                        className="button-create-event"
                        onClick={onShowCreateEventModal}
                    >
                        Create event
                    </button>
                ) : null}
                <button className="button-wrapper" onClick={props.prevHandler}>
                    <LeftOutlined />
                </button>
                <button
                    className="button-wrapper-today"
                    onClick={props.todayHandler}
                >
                    Today
                </button>
                <button className="button-wrapper" onClick={props.nextHandler}>
                    <RightOutlined />
                </button>
            </div>
            <CreateEventModal
                showCreateEventModal={showCreateEventModal}
                setShowCreateEventModal={setShowCreateEventModal}
                onShowCreateEventModal={onShowCreateEventModal}
                id={props.id}
                onReloadTableAfterSend={props.onReloadTableAfterSend}
            />
        </div>
    );
};

export default Monitor;
