import { Axios } from '../plugins/helpers';
import { notificationError } from '../plugins/requestNotification';

export const GetPrices = async () => {
    try {
        const response = await Axios().get(
            `${process.env.REACT_APP_BASE_URL}/services/get-services`,
        );
        return response;
    } catch (e: any) {
        notificationError(e);
        return e?.response;
    }
};

export const GetPricesWithId = async (id: number) => {
    try {
        const response = await Axios().get(
            `${process.env.REACT_APP_BASE_URL}/services/get-services/${id}`,
        );
        return response;
    } catch (e: any) {
        notificationError(e);
        return e?.response;
    }
};

export const PostOpenPricesWithDocuments = async (
    service_id: number,
    user_id: number,
    description: string,
    documents: any,
) => {
    try {
        const response = await Axios().post(
            `${process.env.REACT_APP_BASE_URL}/open-case`,
            { service_id, user_id, description, documents },
        );
        return response;
    } catch (e: any) {
        notificationError(e);
        return e?.response;
    }
};

export const PostOpenPricesWithOutDocuments = async (
    service_id: number,
    user_id: number,
    description: string,
) => {
    try {
        const response = await Axios().post(
            `${process.env.REACT_APP_BASE_URL}/open-case`,
            { service_id, user_id, description },
        );
        return response;
    } catch (e: any) {
        notificationError(e);
        return e?.response;
    }
};

export const PostPayPrices = async (
    case_id: number,
    cardholder_name: string,
    card_number: number,
    expiration_date: number | string,
    cvv: number | string,
    billing_address: string,
    city: string,
    zip_code: string,
) => {
    try {
        const response = await Axios().post(
            `${process.env.REACT_APP_BASE_URL}/pay-case`,
            {
                case_id,
                cardholder_name,
                card_number,
                expiration_date,
                cvv,
                billing_address,
                city,
                zip_code,
            },
        );
        return response;
    } catch (e: any) {
        // notificationError(e);
        return e?.response;
    }
};
