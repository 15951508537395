import { Row, Col, Image, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import Card from '../../../components/Card';

// img
import auth1 from '../../../assets/images/auth/01.webp';
import { AuthRouteNames } from '../../../router/Auth';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { Formik, Form as FormicForm } from 'formik';
import React, { useEffect, useState } from 'react';
import FieldWithValidation from '../../../components/uikit/FieldWithValidation';
import { TypesOfInput } from '../../../models/IField';
import * as Yup from 'yup';
import { useActions } from '../../../hooks/useActions';
import { Regulars } from '../../../constants';
import logoSign from '../../../assets/images/logo-sign.png';
import Helmet from 'react-helmet';

const RecoverpwUpdate = () => {
    const title = 'MY.EMIRATES.LAW - Forgot Password?';
    const { resetPassword } = useActions();
    const { errors } = useTypedSelector(state => state.app);
    const history = useHistory();

    const [formikActions, setFormicActions] = useState<any>(null);

    useEffect(() => {
        if (formikActions && errors && Object.values(errors).length) {
            formikActions.setErrors(errors);
        }
    }, [errors]);

    const handleReset = async (values: { mail: string }, actions: any) => {
        setFormicActions(actions);

        resetPassword({
            data: values,
            history,
        });
    };

    const schema = Yup.object().shape({
        mail: Yup.string()
            .matches(Regulars.email.reg, Regulars.email.text)
            .required('Required'),
    });

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <section className="login-content">
                <Row className="m-0 bg-white vh-100">
                    <Col
                        md="6"
                        className="d-md-block d-none  p-0 mt-n1 vh-100 overflow-hidden relative"
                    >
                        <Image
                            src={logoSign}
                            alt="logo"
                            className="logo-sign"
                        />
                        <Image
                            src={auth1}
                            className="Image-fluid gradient-main animated-scaleX"
                            alt="images"
                        />
                    </Col>
                    <Col md="6">
                        <Row className="justify-content-center align-items-center vh-100">
                            <Col md="10">
                                <Card className="card-transparent shadow-none d-flex justify-content-center mb-0 auth-card">
                                    <Card.Body>
                                        <h2 className="mb-2 text-center">
                                            Forgot Password
                                        </h2>
                                        <p className="text-center step__description">
                                            Please enter your email address and
                                            we'll send you an email with
                                            instructions to reset your
                                            password..
                                        </p>
                                        <Formik
                                            initialValues={{
                                                mail: '',
                                            }}
                                            validationSchema={schema}
                                            onSubmit={(values, actions) =>
                                                handleReset(values, actions)
                                            }
                                        >
                                            <FormicForm>
                                                <Row>
                                                    <FieldWithValidation
                                                        field={{
                                                            id: 'mail',
                                                            required: true,
                                                            type: TypesOfInput.TEXT,
                                                            title: 'Email',
                                                            size: 12,
                                                            category_id: 1,
                                                        }}
                                                    />
                                                </Row>
                                                <div className="d-flex justify-content-center">
                                                    <Button
                                                        type="submit"
                                                        className="step__button"
                                                        variant="primary"
                                                    >
                                                        Reset
                                                    </Button>
                                                </div>
                                            </FormicForm>
                                        </Formik>
                                        <p className="mt-3 text-center">
                                            Already have an Account{' '}
                                            <Link
                                                to={AuthRouteNames.SIGN_IN}
                                                className="text-underline"
                                            >
                                                Sign In
                                            </Link>
                                        </p>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </section>
        </>
    );
};

export default RecoverpwUpdate;
