import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="service-container service-page">
            <div className="service__title">PRIVACY POLICY</div>
            <div style={{ paddingTop: '50px' }}>
                <h2>
                    <strong>Privacy Policy</strong>
                </h2>
                <p>Last updated: June 20, 2022</p>
                <p>
                    This Privacy Policy describes Our policies and procedures on
                    the collection, use and disclosure of Your information when
                    You use the Service and tells You about Your privacy rights
                    and how the law protects You.
                </p>
                <p>
                    We use Your Personal data to provide and improve the
                    Service. By using the Service, You agree to the collection
                    and use of information in accordance with this Privacy
                    Policy.
                </p>
                <p>
                    While using Our Service, We may ask You to provide Us with
                    certain personally identifiable information that can be used
                    to contact or identify You. Personally identifiable
                    information may include, but is not limited to:
                </p>
                <p>
                    <span>•</span> Email address
                </p>
                <p>
                    <span>•</span> First name and last name
                </p>
                <p>
                    <span>•</span> Phone number
                </p>
                <p>
                    <span>•</span> Usage Data
                </p>
                <p>
                    Usage Data is collected automatically when using the
                    Service.
                </p>
                <p>
                    Usage Data may include information such as Your Device's
                    Internet Protocol address (e.g. IP address), browser type,
                    browser version, the pages of our Service that You visit,
                    the time and date of Your visit, the time spent on those
                    pages, unique device identifiers and other diagnostic data.
                </p>
                <p>
                    When You access the Service by or through a mobile device,
                    We may collect certain information automatically, including,
                    but not limited to, the type of mobile device You use, Your
                    mobile device unique ID, the IP address of Your mobile
                    device, Your mobile operating system, the type of mobile
                    Internet browser You use, unique device identifiers and
                    other diagnostic data.
                </p>
                <p>
                    We may also collect information that Your browser sends
                    whenever You visit our Service or when You access the
                    Service by or through a mobile device.
                </p>
                <p>
                    We use Cookies and similar tracking technologies to track
                    the activity on Our Service and store certain information.
                    Tracking technologies used are beacons, tags, and scripts to
                    collect and track information and to improve and analyze Our
                    Service. The technologies We use may include:
                </p>
                <p>
                    <span>•</span> <b>Cookies or Browser Cookies.</b> A cookie
                    is a small file placed on Your Device. You can instruct Your
                    browser to refuse all Cookies or to indicate when a Cookie
                    is being sent. However, if You do not accept Cookies, You
                    may not be able to use some parts of our Service. Unless you
                    have adjusted Your browser setting so that it will refuse
                    Cookies, our Service may use Cookies.
                </p>
                <p>
                    Cookies can be "Persistent" or "Session" Cookies. Persistent
                    Cookies remain on Your personal computer or mobile device
                    when You go offline, while Session Cookies are deleted as
                    soon as You close Your web browser. Learn more about cookies
                    on the Privacy Policies website article.
                </p>
                <h3>Use of Your Personal Data</h3>
                <p>
                    The Company may use Personal Data for the following
                    purposes:
                </p>
                <p>
                    <span>•</span> <b>To provide and maintain our Service,</b>{' '}
                    including to monitor the usage of our Service.
                </p>
                <p>
                    <span>•</span> <b>To manage Your Account:</b> to manage Your
                    registration as a user of the Service. The Personal Data You
                    provide can give You access to different functionalities of
                    the Service that are available to You as a registered user.
                </p>
                <p>
                    <span>•</span> <b>For the performance of a contract:</b> the
                    development, compliance and undertaking of the purchase
                    contract for the products, items or services You have
                    purchased or of any other contract with Us through the
                    Service.
                </p>
                <p>
                    <span>•</span>
                    <b>To contact You:</b> To contact You by email, telephone
                    calls, SMS, or other equivalent forms of electronic
                    communication, such as a mobile application's push
                    notifications regarding updates or informative
                    communications related to the functionalities, products or
                    contracted services, including the security updates, when
                    necessary or reasonable for their implementation.
                </p>
                <p>
                    <span>•</span>
                    <b>To provide You</b> with news, special offers and general
                    information about other goods, services and events which we
                    offer that are similar to those that you have already
                    purchased or enquired about unless You have opted not to
                    receive such information.
                </p>
                <p>
                    <span>•</span>
                    <b>To manage Your requests:</b> To attend and manage Your
                    requests to Us.
                </p>
                <p>
                    <span>•</span>
                    <b>For other purposes:</b> We may use Your information for
                    other purposes, such as data analysis, identifying usage
                    trends, determining the effectiveness of our promotional
                    campaigns and to evaluate and improve our Service, products,
                    services, marketing and your experience.
                </p>
                <p>
                    The Company will retain Your Personal Data only for as long
                    as is necessary for the purposes set out in this Privacy
                    Policy. We will retain and use Your Personal Data to the
                    extent necessary to comply with our legal obligations (for
                    example, if we are required to retain your data to comply
                    with applicable laws), resolve disputes, and enforce our
                    legal agreements and policies.
                </p>
                <p>
                    The Company will also retain Usage Data for internal
                    analysis purposes. Usage Data is generally retained for a
                    shorter period of time, except when this data is used to
                    strengthen the security or to improve the functionality of
                    Our Service, or We are legally obligated to retain this data
                    for longer time periods.
                </p>
                <h3>Transfer of Your Personal Data</h3>
                <p>
                    Your information, including Personal Data, is processed at
                    the Company's operating offices and in any other places
                    where the parties involved in the processing are located. It
                    means that this information may be transferred to — and
                    maintained on — computers located outside of Your state,
                    province, country or other governmental jurisdiction where
                    the data protection laws may differ than those from Your
                    jurisdiction.
                </p>
                <p>
                    Your consent to this Privacy Policy followed by Your
                    submission of such information represents Your agreement to
                    that transfer.
                </p>
                <p>
                    The Company will take all steps reasonably necessary to
                    ensure that Your data is treated securely and in accordance
                    with this Privacy Policy and no transfer of Your Personal
                    Data will take place to an organization or a country unless
                    there are adequate controls in place including the security
                    of Your data and other personal information.
                </p>
                <h3>Disclosure of Your Personal Data</h3>
                <h4>Business Transactions</h4>
                <p>
                    If the Company is involved in a merger, acquisition or asset
                    sale, Your Personal Data may be transferred. We will provide
                    notice before Your Personal Data is transferred and becomes
                    subject to a different Privacy Policy.
                </p>
                <h4>Law enforcement</h4>
                <p>
                    Under certain circumstances, the Company may be required to
                    disclose Your Personal Data if required to do so by law or
                    in response to valid requests by public authorities (e.g. a
                    court or a government agency).
                </p>
                <h4>Other legal requirements</h4>
                <p>
                    The Company may disclose Your Personal Data in the good
                    faith belief that such action is necessary to:
                </p>
                <p>
                    <span>•</span> Comply with a legal obligation
                </p>
                <p>
                    <span>•</span> Protect and defend the rights or property of
                    the Company
                </p>
                <p>
                    <span>•</span> Prevent or investigate possible wrongdoing in
                    connection with the Service
                </p>
                <p>
                    <span>•</span> Protect the personal safety of Users of the
                    Service or the public
                </p>
                <p>
                    <span>•</span> Protect against legal liability
                </p>
                <h3>Security of Your Personal Data</h3>
                <p>
                    The security of Your Personal Data is important to Us, but
                    remember that no method of transmission over the Internet,
                    or method of electronic storage is 100% secure. While We
                    strive to use commercially acceptable means to protect Your
                    Personal Data, We cannot guarantee its absolute security.
                </p>
                <h2>
                    <strong>Children's Privacy</strong>
                </h2>
                <p>
                    Our Service does not address anyone under the age of 18. We
                    do not knowingly collect personally identifiable information
                    from anyone under the age of 18. If You are a parent or
                    guardian and You are aware that Your child has provided Us
                    with Personal Data, please contact Us. If We become aware
                    that We have collected Personal Data from anyone under the
                    age of 18 without verification of parental consent, We take
                    steps to remove that information from Our servers.
                </p>
                <p>
                    If We need to rely on consent as a legal basis for
                    processing Your information and Your country requires
                    consent from a parent, We may require Your parent's consent
                    before We collect and use that information.
                </p>
                <h2>
                    <strong>Links to Other Websites</strong>
                </h2>
                <p>
                    Our Service may contain links to other websites that are not
                    operated by Us. If You click on a third party link, You will
                    be directed to that third party's site. We strongly advise
                    You to review the Privacy Policy of every site You visit.
                </p>
                <p>
                    We have no control over and assume no responsibility for the
                    content, privacy policies or practices of any third party
                    sites or services.
                </p>
                <h2>
                    <strong>Changes to this Privacy Policy</strong>
                </h2>
                <p>
                    We may update Our Privacy Policy from time to time. We will
                    notify You of any changes by posting the new Privacy Policy
                    on this page.
                </p>
                <p>
                    We will let You know via email and/or a prominent notice on
                    Our Service, prior to the change becoming effective and
                    update the "Last updated" date at the top of this Privacy
                    Policy.
                </p>
                <p>
                    You are advised to review this Privacy Policy periodically
                    for any changes. Changes to this Privacy Policy are
                    effective when they are posted on this page.
                </p>
                <h2>
                    <strong>Contact Us</strong>
                </h2>
                <p>
                    If you have any questions about this Privacy Policy, You can
                    contact us:
                </p>
                <p>
                    <span>•</span> By email:{' '}
                    <a href="mailto:legal@emirates.law ">legal@emirates.law</a>
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
