import { Switch, Route } from 'react-router-dom';
import { IRoute } from '../models/IRoute';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

// Profile user
import ProfileView from '../components/dashboard/profile-view';
import UserSecurity from '../views/dashboard/user/security';

// How It Works
import HowItWorks from '../views/dashboard/how-it-works/HowItWorks';

// Message center customer
import MessageCenterCustomer from '../views/dashboard/message-center-customer/MessageCenterCustomer';
import LawyerMessages from '../views/dashboard/message-center-customer/LawyerMessages/LawyerMessages';
import PaymentMessagesCustomer from '../views/dashboard/message-center-customer/PaymentMessages/PaymentMessagesCustomer';
import SystemMessagesCustomer from '../views/dashboard/message-center-customer/SystemMessagesCustomer/SystemMessagesCustomer';

//Services
import {
    CivilLaw,
    CriminalLaw,
    RealEstate,
    CommercialLaw,
    Insurance,
    FamilyLaw,
    MaritimeLaw,
    LabourLaw,
    Finance,
    Investment,
} from '../views/dashboard/services/ServicesContainer';
import Services from '../views/dashboard/services/services';

// Payments Customer
import PaymentsCustomer from '../views/dashboard/payments-customer/PaymentsCustomer';
import PendingPaymentsCustomer from '../views/dashboard/payments-customer/PendingPaymentsCustomer/PendingPaymentsCustomer';
import ClosedPaymentsCustomer from '../views/dashboard/payments-customer/ClosedPaymentsCustomer/ClosedPaymentsCustomer';

// Case profile Customer
import CaseProfileCustomer from '../views/dashboard/case-profile-customer/CaseProfileCustomer';
import OpenCaseCustomer from '../views/dashboard/case-profile-customer/OpenCaseCustomer/OpenCaseCustomer';
import OpenCase from '../views/dashboard/open-case/open-case';
import PendingCases from '../views/dashboard/pending-cases/PendingCases';
import PendingCaseLawyer from '../views/dashboard/pending-case-lawyer/PendingCaseLawyer';
import CreateCaseLawyer from '../views/dashboard/create-case-lawyer/CreateCaseLawyer';
import ArchiveCasesCustomer from '../views/dashboard/case-profile-customer/ArchiveCases/ArchiveCasesCustomer';

// How it works Customer
import HowItWorksCustomer from '../views/dashboard/how-it-works-customer/HowItWorksCustomer';

//Personal profile customer and education document
import PersonalProfileCustomer from '../views/dashboard/personal-profile-customer/PersonalProfileCustomer';
import EducationDocument from '../views/dashboard/personal-profile-customer/EducationDocument/EducationDocument';

//Pending Case Details
import PendingCaseDetails from '../views/dashboard/pending-cases/PendingCaseDetails/PendingCaseDetails';
import PendingCaseLawyerDetails from '../views/dashboard/pending-case-lawyer/PendingCaseLawyerDetails/PendingCaseLawyerDetails';
import OpenDetailsCustomer from '../views/dashboard/pending-case-lawyer/OpenDetailsCustomer/OpenDetailsCustomer';

// Invoice Pay Pending Case
import InvoicePayPendingCase from '../views/dashboard/pending-cases/PendingCaseDetails/MessagesPendingCaseDetails/InvoicePayPendingCase/InvoicePayPendingCase';

// Help center
import HelpCenter from '../views/dashboard/help-center/HelpCenter';

// Message center lawyer
import MessageCenterLawyer from '../views/dashboard/message-center-lawyer/MessageCenterLawyer';
import CustomerMessages from '../views/dashboard/message-center-lawyer/CustomerMessages/CustomerMessages';
import PaymentMessages from '../views/dashboard/message-center-lawyer/PaymentMessages/PaymentMessages';
import SystemMessages from '../views/dashboard/message-center-lawyer/SystemMessages/SystemMessages';

//Search lawyer
import SearchLawyer from '../views/dashboard/search-lawyer/SearchLawyer';

// Payments lawyer
import PaymentsLawyer from '../views/dashboard/payments-lawyer/PaymentsLawyer';
import PendingPayments from '../views/dashboard/payments-lawyer/PendingPayments/PendingPayments';
import ClosedPayments from '../views/dashboard/payments-lawyer/ClosedPayments/ClosedPayments';
// Help center customer
import HelpCenterCustomer from '../views/dashboard/help-center-customer/HelpCenterCustomer';

import ArchiveCasesLawyer from '../views/dashboard/archive-cases/ArchiveCasesLawyer';
import ArchiveCasesLawyerDetails from '../views/dashboard/archive-cases/archiveCasesLawyerDetails/ArchiveCasesLawyerDetails';
import SelectedOpenCaseLawyer from '../views/dashboard/open-case/SelectedOpenCaseLawyer/SelectedOpenCaseLawyer';
import UserProfileEmpty from './UserProfileEmpty';

import TermsOfUse from '../views/dashboard/rules/TermsOfUse';
import PrivacyPolicy from '../views/dashboard/rules/PrivacyPolicy';

export enum DashboardRouteNames {
    // How It Works
    HOW_IT_WORKS = '/dashboard/how-it-works',

    //How it works customer
    HOW_IT_WORKS_CUSTOMER = '/dashboard/how-it-works-customer',

    //Personal profile customer and education document
    PERSONAL_PROFILE_LAWYER = '/dashboard/personal-profile-lawyer',
    EDUCATION_DOCUMENT = '/dashboard/personal-profile-lawyer/education-document',

    // Users
    USER_PROFILE_EMPTY = '/dashboard/user/profile-empty',
    USER_PROFILE = '/dashboard/user/profile',
    USER_SECURITY = '/dashboard/user/security',
    USER_IDENTIFICATION = '/dashboard/user/identification',

    // Case profile Customer
    CASE_PROFILE_CUSTOMER = '/dashboard/case-profile-customer',
    OPEN_NEW_CASE = '/dashboard/case-profile-customer/open-new-case',
    OPEN_CASE_CUSTOMER = '/dashboard/case-profile-customer/open-case',
    // PENDING_CASES_CUSTOMER = "/dashboard/case-profile-customer/pending-cases",
    ARCHIVE_CASES_CUSTOMER = '/dashboard/case-profile-customer/archive-cases',
    // Services

    //Open case Pending cases Archive Cases
    OPEN_CASE = '/dashboard/pending-cases-lawyer',
    SELECTED_OPEN_CASE_LAWYER = '/dashboard/selected-pending-case-lawyer',
    PENDING_CASES = '/dashboard/pending-cases',
    PENDING_CASES_LAWYER = '/dashboard/new-cases-lawyer',
    CREATE_CASE_LAWYER = '/dashboard/create-case-lawyer',
    ARCHIVE_CASES_LAWYER = '/dashboard/archive-cases-lawyer',
    ARCHIVE_CASES_LAWYER_DETAILS = '/dashboard/archive-cases-lawyer-details',

    //Pending case details
    PENDING_CASE_DETAILS = '/dashboard/pending-case-details',
    PENDING_CASE_DETAILS_LAWYER = '/dashboard/selected-new-cases-lawyer',
    PENDING_OPEN_DETAILS_CUSTOMER = '/dashboard/new-cases-lawyer/open-details',

    //Invoice pay pending case
    INVOICE_PAY_PENDING_CASE = '/dashboard/invoice-pay-pending-case',

    // Message center customer
    MESSAGE_CENTER_CUSTOMER = '/dashboard/message-center-customer',
    LAWYER_MESSAGES = '/dashboard/message-center-customer/lawyer',
    PAYMENT_MESSAGES_CUSTOMER = '/dashboard/message-center-customer/payment',
    SYSTEM_MESSAGES_CUSTOMER = '/dashboard/message-center-customer/system',

    // Message center lawyer
    MESSAGE_CENTER_LAWYER = '/dashboard/message-center-lawyer',
    CUSTOMER_MESSAGES = '/dashboard/message-center-lawyer/customer-messages',
    PAYMENT_MESSAGES = '/dashboard/message-center-lawyer/payment-messages',
    SYSTEM_MESSAGES = '/dashboard/message-center-lawyer/system-messages',

    // Search lawyer
    SEARCH_LAWYER = '/dashboard/search-lawyer',

    //Payments lawyer
    PAYMENTS_LAWYER = '/dashboard/payments-lawyer',
    PENDING_PAYMENTS = '/dashboard/payments-lawyer/pending-payments',
    CLOSED_PAYMENTS = '/dashboard/payments-lawyer/closed-payments',

    // Help center customer
    HELP_CENTER_CUSTOMER = '/dashboard/help-center-customer',

    SERVICE_CRIMINAL_LAW = '/dashboard/service/criminal-law',
    SERVICE_CIVIL_LAW = '/dashboard/service/civil-law',
    SERVICE_REAL_ESTATE = '/dashboard/service/real-estate',
    SERVICE_COMMERCIAL_LAW = '/dashboard/service/commercial',
    SERVICE_INSURANCE = '/dashboard/service/insurance',
    SERVICE_FAMILY_LAW = '/dashboard/service/family-law',
    SERVICE_MARITIME_LAW = '/dashboard/service/maritime-law',
    SERVICE_LABOUR_LAW = '/dashboard/service/labour-law',
    SERVICE_FINANCE = '/dashboard/service/finance',
    SERVICE_INVESTMENT = '/dashboard/service/investment-fund-services',
    SERVICE = '/dashboard/service/service',

    // Payments Customer
    PAYMENTS_CUSTOMER = '/dashboard/payments-customer',
    PAYMENTS_CUSTOMER_PENDING = '/dashboard/payments-customer/pending',
    PAYMENTS_CUSTOMER_CLOSED = '/dashboard/payments-customer/closed',
    // Help Center
    HELP_CENTER = '/dashboard/help-center',

    // Rules
    TERMS_OF_USE = '/dashboard/terms-of-use',
    PRIVACY_POLICY = '/dashboard/privacy-policy',
}

export const dashboardRoutes: IRoute[] = [
    {
        path: DashboardRouteNames.HOW_IT_WORKS,
        exact: true,
        component: HowItWorks,
    },
    {
        path: DashboardRouteNames.USER_PROFILE_EMPTY,
        exact: true,
        component: UserProfileEmpty,
    },
    {
        path: DashboardRouteNames.USER_PROFILE,
        exact: true,
        component: () => <ProfileView typeOfPage={1} />,
    },

    {
        path: DashboardRouteNames.USER_SECURITY,
        exact: true,
        component: UserSecurity,
    },

    {
        path: DashboardRouteNames.USER_IDENTIFICATION,
        exact: true,
        component: () => <ProfileView typeOfPage={3} />,
    },

    // Case profile Customer
    {
        path: DashboardRouteNames.CASE_PROFILE_CUSTOMER,
        exact: true,
        component: CaseProfileCustomer,
    },
    {
        path: DashboardRouteNames.OPEN_NEW_CASE,
        exact: true,
        component: OpenCaseCustomer,
    },
    {
        path: DashboardRouteNames.PENDING_CASES,
        exact: true,
        component: PendingCases,
    },
    {
        path: DashboardRouteNames.PENDING_CASES_LAWYER,
        exact: true,
        component: PendingCaseLawyer,
    },
    {
        path: DashboardRouteNames.CREATE_CASE_LAWYER,
        exact: true,
        component: CreateCaseLawyer,
    },
    {
        path: DashboardRouteNames.ARCHIVE_CASES_CUSTOMER,
        exact: true,
        component: ArchiveCasesCustomer,
    },
    {
        path: DashboardRouteNames.PENDING_CASE_DETAILS,
        exact: true,
        component: PendingCaseDetails,
    },

    {
        path: DashboardRouteNames.PENDING_CASE_DETAILS_LAWYER,
        exact: true,
        component: PendingCaseLawyerDetails,
    },
    {
        path: DashboardRouteNames.PENDING_OPEN_DETAILS_CUSTOMER,
        exact: true,
        component: OpenDetailsCustomer,
    },

    {
        path: DashboardRouteNames.INVOICE_PAY_PENDING_CASE,
        exact: true,
        component: InvoicePayPendingCase,
    },

    // Message center customer
    {
        path: DashboardRouteNames.MESSAGE_CENTER_CUSTOMER,
        exact: true,
        component: MessageCenterCustomer,
    },
    {
        path: DashboardRouteNames.LAWYER_MESSAGES,
        exact: true,
        component: LawyerMessages,
    },
    {
        path: DashboardRouteNames.PAYMENT_MESSAGES,
        exact: true,
        component: PaymentMessages,
    },
    {
        path: DashboardRouteNames.PAYMENT_MESSAGES_CUSTOMER,
        exact: true,
        component: PaymentMessagesCustomer,
    },
    {
        path: DashboardRouteNames.SYSTEM_MESSAGES_CUSTOMER,
        exact: true,
        component: SystemMessagesCustomer,
    },
    // Service
    {
        path: DashboardRouteNames.SERVICE_CIVIL_LAW,
        exact: true,
        component: CivilLaw,
    },
    {
        path: DashboardRouteNames.SERVICE_CRIMINAL_LAW,
        exact: true,
        component: CriminalLaw,
    },
    {
        path: DashboardRouteNames.SERVICE_REAL_ESTATE,
        exact: true,
        component: RealEstate,
    },
    {
        path: DashboardRouteNames.SERVICE_COMMERCIAL_LAW,
        exact: true,
        component: CommercialLaw,
    },
    {
        path: DashboardRouteNames.SERVICE_INSURANCE,
        exact: true,
        component: Insurance,
    },
    {
        path: DashboardRouteNames.SERVICE_FAMILY_LAW,
        exact: true,
        component: FamilyLaw,
    },
    {
        path: DashboardRouteNames.SERVICE_MARITIME_LAW,
        exact: true,
        component: MaritimeLaw,
    },
    {
        path: DashboardRouteNames.SERVICE_LABOUR_LAW,
        exact: true,
        component: LabourLaw,
    },
    {
        path: DashboardRouteNames.SERVICE_FINANCE,
        exact: true,
        component: Finance,
    },
    {
        path: DashboardRouteNames.SERVICE_INVESTMENT,
        exact: true,
        component: Investment,
    },
    {
        path: DashboardRouteNames.HOW_IT_WORKS_CUSTOMER,
        exact: true,
        component: HowItWorksCustomer,
    },

    {
        path: DashboardRouteNames.PERSONAL_PROFILE_LAWYER,
        exact: true,
        component: PersonalProfileCustomer,
    },
    {
        path: DashboardRouteNames.EDUCATION_DOCUMENT,
        exact: true,
        component: EducationDocument,
    },

    {
        path: DashboardRouteNames.USER_PROFILE,
        exact: true,
        component: () => <ProfileView typeOfPage={1} />,
    },
    {
        path: DashboardRouteNames.USER_SECURITY,
        exact: true,
        component: UserSecurity,
    },
    {
        path: DashboardRouteNames.USER_IDENTIFICATION,
        exact: true,
        component: () => <ProfileView typeOfPage={3} />,
    },

    { path: DashboardRouteNames.OPEN_CASE, exact: true, component: OpenCase },

    {
        path: DashboardRouteNames.SELECTED_OPEN_CASE_LAWYER,
        exact: true,
        component: SelectedOpenCaseLawyer,
    },

    {
        path: DashboardRouteNames.MESSAGE_CENTER_LAWYER,
        exact: true,
        component: MessageCenterLawyer,
    },
    {
        path: DashboardRouteNames.CUSTOMER_MESSAGES,
        exact: true,
        component: CustomerMessages,
    },
    {
        path: DashboardRouteNames.SEARCH_LAWYER,
        exact: true,
        component: SearchLawyer,
    },
    {
        path: DashboardRouteNames.PAYMENTS_LAWYER,
        exact: true,
        component: PaymentsLawyer,
    },
    {
        path: DashboardRouteNames.PENDING_PAYMENTS,
        exact: true,
        component: PendingPayments,
    },
    {
        path: DashboardRouteNames.PAYMENTS_CUSTOMER_PENDING,
        exact: true,
        component: PendingPaymentsCustomer,
    },
    {
        path: DashboardRouteNames.CLOSED_PAYMENTS,
        exact: true,
        component: ClosedPayments,
    },
    {
        path: DashboardRouteNames.PAYMENTS_CUSTOMER_CLOSED,
        exact: true,
        component: ClosedPaymentsCustomer,
    },

    {
        path: DashboardRouteNames.HELP_CENTER_CUSTOMER,
        exact: true,
        component: HelpCenterCustomer,
    },
    { path: DashboardRouteNames.SERVICE, exact: true, component: Services },

    {
        path: DashboardRouteNames.ARCHIVE_CASES_LAWYER,
        exact: true,
        component: ArchiveCasesLawyer,
    },
    {
        path: DashboardRouteNames.ARCHIVE_CASES_LAWYER_DETAILS,
        exact: true,
        component: ArchiveCasesLawyerDetails,
    },
    {
        path: DashboardRouteNames.SYSTEM_MESSAGES,
        exact: true,
        component: SystemMessages,
    },

    // Payments Customer
    {
        path: DashboardRouteNames.PAYMENTS_CUSTOMER,
        exact: true,
        component: PaymentsCustomer,
    },
    {
        path: DashboardRouteNames.PAYMENTS_CUSTOMER_CLOSED,
        exact: true,
        component: ClosedPayments,
    },

    // Help Center
    {
        path: DashboardRouteNames.HELP_CENTER,
        exact: true,
        component: HelpCenter,
    },

    //Rules
    {
        path: DashboardRouteNames.TERMS_OF_USE,
        exact: true,
        component: TermsOfUse,
    },
    {
        path: DashboardRouteNames.PRIVACY_POLICY,
        exact: true,
        component: PrivacyPolicy,
    },
];

const Dashboard = () => {
    return (
        <TransitionGroup>
            <CSSTransition classNames="fadein" timeout={300}>
                <Switch>
                    {dashboardRoutes.map(route => (
                        <Route
                            path={route.path}
                            exact={route.exact}
                            component={route.component}
                            key={route.path}
                        />
                    ))}
                </Switch>
            </CSSTransition>
        </TransitionGroup>
    );
};

export default Dashboard;
