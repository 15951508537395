import { useEffect, useState } from 'react';
import { GetPaymentsMessage } from '../../../../apis/messageCenter';
import { IMessageCenter } from '../../../../models/IMessageCenter';
import MessageCenterUser from '../../../../components/MessageCenterUser';
import style from '../../../../components/MessageCenterUser/MessageCenterUser.module.css';
import preloaderImg from '../../../../assets/images/preloader.gif';

const SystemMessagesCustomer = () => {
    const [data, setData] = useState<IMessageCenter[]>([]);
    const [preloader, setPreloader] = useState<boolean>(false);

    useEffect(() => {
        setPreloader(true);
        GetPaymentsMessage(3).then(({ data }) => {
            setData(data.data);
            setPreloader(false);
        });
    }, []);

    if (preloader) {
        return (
            <div className={style.preloaderBox}>
                <img
                    className={style.preloaderImg}
                    src={preloaderImg}
                    alt="Loading..."
                />
            </div>
        );
    }

    return <MessageCenterUser title="System messages" data={data} />;
};
export default SystemMessagesCustomer;
