import React from 'react';
import { Navbar, Container, Nav, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CustomToggle from '../dropdowns';
import InfoPendingVerify from '../InfoPendingVerify';

//img
import avatars1 from '../../assets/images/avatars/01.png';

// logo
import Logo from '../logo';
import logoImg from '../../assets/images/logo.png';

import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useActions } from '../../hooks/useActions';
import { DashboardRouteNames } from '../../router/Dashboard';

const Header = () => {
    const { user } = useTypedSelector(state => state.user);
    const { logout } = useActions();

    const minisidebar = () => {
        document
            .getElementsByTagName('ASIDE')[0]
            .classList.toggle('sidebar-mini');
    };

    return (
        <>
            <Navbar expand="lg" variant="light" className="nav iq-navbar">
                <Container fluid className="navbar-inner">
                    <Link
                        to="/dashboard/service/service"
                        className="navbar-brand"
                    >
                        <Logo src={logoImg} />
                    </Link>
                    <div
                        className="sidebar-toggle"
                        data-toggle="sidebar"
                        data-active="true"
                        onClick={minisidebar}
                    >
                        <i className="icon">
                            <svg width="20px" height="20px" viewBox="0 0 24 24">
                                <path
                                    fill="currentColor"
                                    d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"
                                />
                            </svg>
                        </i>
                    </div>
                    <Navbar.Collapse id="navbarSupportedContent">
                        {user?.userStatus?.name === 'Pending' && (
                            <InfoPendingVerify />
                        )}
                        <Nav
                            as="ul"
                            className="ms-auto navbar-list mb-2 mb-lg-0"
                        >
                            <Dropdown as="li" className="nav-item">
                                <Dropdown.Toggle
                                    as={CustomToggle}
                                    variant=" nav-link py-0 d-flex align-items-center"
                                    href="#"
                                    id="navbarDropdown"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <img
                                        src={user?.userInfo?.photo || avatars1}
                                        alt="User-Profile"
                                        className="img-fluid avatar avatar-70 avatar-rounded"
                                    />
                                    <div className="caption ms-3 d-none d-md-block ">
                                        <h6 className="mb-0 caption-title">
                                            {user?.userInfo?.first_name}
                                        </h6>
                                        <p className="mb-0 caption-sub-title">
                                            {user?.userInfo?.last_name}
                                        </p>
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                    className="dropdown-menu-end"
                                    aria-labelledby="navbarDropdown"
                                >
                                    <Dropdown.Item>
                                        <Link
                                            className="nav-link"
                                            to={
                                                DashboardRouteNames.USER_PROFILE
                                            }
                                        >
                                            Profile
                                        </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <Link
                                            className="nav-link"
                                            to={
                                                DashboardRouteNames.USER_SECURITY
                                            }
                                        >
                                            Security
                                        </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={logout}>
                                        Logout
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};

export default Header;
