import { put, takeLatest, select, call } from 'redux-saga/effects';
import { AuthActionCreators } from '../reducers/auth/action-creators';
import {
    AuthActionEnum,
    Login,
    Login2Auth,
    Register,
    ResetPassword,
    UpdatePassword,
} from '../reducers/auth/types';
import {
    ResetPasswordEmail,
    ResetPasswordUpdate,
    User2Auth,
    UserLogin,
    UserInit,
    UserRegister,
    UserLogout,
} from '../../apis/user';
import { AppActionCreators } from '../reducers/app/action-creators';
import delay from '../../helpers/delay';
import { ICodes } from '../../models/ICodes';
import { IErrorResponse, ISuccessResponse } from '../../models/IResponse';
import { IFieldError, TypesOfVerification } from '../../models/IField';
import { IUser2Auth, IUserAuth, IUserGlobal } from '../../models/IUser';
import { RootState } from '..';
import { removeToCookies, saveToCookies } from '../../plugins/helpers';
import { AuthRouteNames } from '../../router/Auth';
import { UserActionCreators } from '../reducers/user/action-creators';
import { ProfileActionCreators } from '../reducers/profile/action-creators';
import { notification } from 'antd';
import { messages } from '../../constants';

export function* workerLogout(): Generator {
    UserLogout();
    yield put(AppActionCreators.handleLoader(true));
    removeToCookies('auth_token');
    removeToCookies('refresh_token');
    yield put(AuthActionCreators.setAuth(false));
    yield put(UserActionCreators.updateUser(null));
    yield put(ProfileActionCreators.setFields(null));
    yield put(ProfileActionCreators.setFieldsValues(null));
    yield call(delay, 1500);
    yield put(AppActionCreators.handleLoader(false));
}

export function* workerLogoutSession(): Generator {
    removeToCookies('auth_token');
    removeToCookies('refresh_token');
    yield put(AuthActionCreators.setAuth(false));
    yield put(UserActionCreators.updateUser(null));
    yield put(ProfileActionCreators.setFields(null));
    yield put(ProfileActionCreators.setFieldsValues(null));
}

export function* workerLoginInit(): Generator {
    const login:
        | ISuccessResponse<{ user_id: string }>
        | IErrorResponse<IFieldError>
        | any = yield UserInit();

    if (login.status === ICodes.UNAUTHORIZED) {
        removeToCookies('auth_token');
        window.history.pushState({}, '', '/auth/sign-in');
        window.location.reload();
        return;
    }

    if (login.status !== ICodes.OK) {
        return;
    }

    yield put(UserActionCreators.fetchUser(login.data.data.user_id));
    yield put(UserActionCreators.fetchFinances(login.data.data.user_id));
    yield put(AuthActionCreators.setAuth());
}

export function* workerLogin({ payload }: Login): Generator {
    interface ILogin extends IUserGlobal {
        has_2fa?: boolean;
        step?: number;
        hash?: string;
        user_id: number;
        tokens: IUserAuth;
    }

    const login: ISuccessResponse<ILogin> | IErrorResponse<IFieldError> | any =
        yield UserLogin(payload.data);
    yield put(AppActionCreators.handleLoader(true));

    if (login.status === ICodes.VALIDATION_ERROR) {
        notification.error({
            message: messages.LOGIN_VALIDATION_ERROR,
        });
        yield put(AppActionCreators.handleLoader(false));
    }

    if (login.status !== ICodes.OK) {
        yield put(AppActionCreators.setErrors(login.data.data));
        return;
    }

    const loginData: ILogin = login.data.data;

    if (loginData.step) {
        yield put(AuthActionCreators.setStepOfRegist(loginData.step + 1));
        yield put(UserActionCreators.updateUser(loginData));
        payload.history.push(AuthRouteNames.SIGN_UP);
        return;
    }

    if (loginData.has_2fa) {
        yield put(UserActionCreators.updateUser(loginData));
        yield put(
            AppActionCreators.handleShowModal({
                title: messages.SHOW_MODAL_TITLE_2FA,
            }),
        );
        return;
    }

    if (payload.data.remember) {
        localStorage.setItem('auth_token', loginData.tokens.auth_token);
        saveToCookies('auth_token', loginData.tokens.auth_token, true);
        saveToCookies('refresh_token', loginData.tokens.refresh_token, true);
    } else {
        localStorage.setItem('auth_token', loginData.tokens.auth_token);
        saveToCookies('auth_token', loginData.tokens.auth_token, false);
        saveToCookies('refresh_token', loginData.tokens.refresh_token, false);
    }

    notification.success({
        message: messages.SUCCESS_LOGIN,
    });

    yield put(UserActionCreators.updateUser(loginData));
    yield put(UserActionCreators.fetchUser(loginData.user_id));
    yield put(UserActionCreators.fetchFinances(loginData.user_id));
    yield put(AuthActionCreators.setAuth());
}

export function* workerLogin2Auth({ payload }: Login2Auth): Generator {
    const user: IUser2Auth | any = yield select(
        (state: RootState) => state.user.user,
    );
    const code: IUser2Auth | any = yield select(
        (state: RootState) => state.app.modalValue,
    );

    const user2Auth:
        | ISuccessResponse<IUserAuth>
        | IErrorResponse<IFieldError>
        | any = yield User2Auth({
        user_id: user.user_id,
        hash: user.hash,
        code,
    });

    if (user2Auth.status !== ICodes.OK) {
        return;
    }

    // const user2AuthData: IUserAuth = user2Auth.data.data;

    // if (payload) {
    //     saveToCookies('auth_token', user2AuthData.auth_token, false);
    //     saveToCookies('refresh_token', user2AuthData.refresh_token, false);
    // } else {
    //     localStorage.setItem('auth_token', user2AuthData.auth_token);
    // }

    notification.success({
        message: messages.SUCCESS_LOGIN,
    });

    yield put(UserActionCreators.fetchUser(user.user_id));
    yield put(UserActionCreators.fetchFinances(user.user_id));
    yield put(AuthActionCreators.setAuth());
    yield put(AppActionCreators.handleHideModal(''));
}

export function* workerRegister({ payload }: Register): Generator {
    const stepOfRegist: number | any = yield select(
        (state: RootState) => state.auth.stepOfRegist,
    );
    const totalStep: number | any = yield select(
        (state: RootState) => state.auth.totalStep,
    );

    const response:
        | ISuccessResponse<{ verifications: string | string[] }>
        | IErrorResponse<IFieldError>
        | any = yield UserRegister(payload);

    const registerData: {
        verifications?: string[] | string;
        tokens: IUserAuth;
        user_id: number;
        hash: string;
    } = response.data.data;

    if (response.status !== ICodes.OK) {
        yield put(AppActionCreators.setErrors(response.data.data));
        return;
    }

    const showModal =
        registerData.verifications &&
        (registerData.verifications === TypesOfVerification.MAIL ||
            registerData.verifications.includes(TypesOfVerification.MAIL));

    if (showModal) {
        yield put(
            AppActionCreators.handleShowModal({
                title: messages.SHOW_MODAL_TITLE_EMAIL,
                description: messages.SHOW_MODAL_DES_EMAIL,
            }),
        );
        return;
    }

    if (payload.step === totalStep) {
        yield put(AppActionCreators.handleLoader(true));
        localStorage.setItem('auth_token', registerData.tokens.auth_token);
        yield put(UserActionCreators.fetchUser(registerData.user_id));
        yield put(UserActionCreators.fetchFinances(registerData.user_id));
        yield put(AuthActionCreators.setAuth());
        yield put(AuthActionCreators.setStepOfRegist(1));
        notification.success({
            message: messages.SUCCESS_REGISTER,
        });
    } else {
        yield put(AppActionCreators.handleLoader(true));
        yield put(AuthActionCreators.setStepOfRegist(stepOfRegist + 1));
    }

    yield put(UserActionCreators.updateUser(response.data.data));
    yield call(delay, 2000);
    yield put(AppActionCreators.handleLoader(false));
}

export function* workerResetPassword({ payload }: ResetPassword): Generator {
    const response: ISuccessResponse | IErrorResponse<IFieldError> | any =
        yield ResetPasswordEmail(payload.data);

    if (response.data.data['two-factor']) {
        yield put(
            AppActionCreators.handleShowModal({
                title: messages.SHOW_MODAL_TITLE_2FA_RESET,
            }),
        );
        sessionStorage.setItem(
            'resetPassword',
            JSON.stringify(response.data.data),
        );
        yield payload.history.push(AuthRouteNames.SIGN_IN);
        return;
    }

    if (response.status !== ICodes.OK) {
        yield put(AppActionCreators.setErrors(response.data.data));
        return;
    }

    notification.info({
        message: messages.SUCCESS_PASSWORD_RESET_TITLE,
        description: messages.SUCCESS_PASSWORD_RESET,
    });
    payload.history.push(AuthRouteNames.SIGN_IN);
}

export function* workerUpdatePassword({ payload }: UpdatePassword): Generator {
    const response: ISuccessResponse | IErrorResponse<IFieldError> | any =
        yield ResetPasswordUpdate(payload.data);

    if (response.status !== ICodes.OK) {
        yield put(AppActionCreators.setErrors(response.data.data));
        return;
    }
    notification.success({
        message: 'Your password has been successfully updated',
    });

    payload.history.push(AuthRouteNames.SIGN_IN);
}

export function* watchAuth() {
    yield takeLatest(AuthActionEnum.LOGOUT, workerLogout);
    yield takeLatest(AuthActionEnum.LOGOUT_SESSION, workerLogoutSession);
    yield takeLatest(AuthActionEnum.LOGIN, workerLogin);
    yield takeLatest(AuthActionEnum.LOGIN_INIT, workerLoginInit);
    yield takeLatest(AuthActionEnum.LOGIN_2_AUTH, workerLogin2Auth);
    yield takeLatest(AuthActionEnum.REGISTER, workerRegister);
    yield takeLatest(AuthActionEnum.RESET_PASSWORD, workerResetPassword);
    yield takeLatest(AuthActionEnum.UPDATE_PASSWORD, workerUpdatePassword);
}
