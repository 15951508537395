import { Axios } from '../plugins/helpers';
import { notificationError } from '../plugins/requestNotification';

export const GetSearchCasesCustomer = async (name:string) => {
    try {
        const { data } = await Axios().get(
            `${process.env.REACT_APP_BASE_URL}/search?query=${name}`,
        );
        return data;
    } catch (e: any) {
        notificationError(e);
        return e?.response;
    }
};

 