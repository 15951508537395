import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { GetPendingLawyerCases } from '../../../apis/pendingCasesLawyer';
import {
    IServicesPendingCase,
    ILawyerPendingCase,
} from '../../../models/IPendingCaseLawyer';
import Card from '../../../components/Card';
import pendingCasesService from '../../../helpers/menuServicePendingCaseLawyer';
import { DashboardRouteNames } from '../../../router/Dashboard';
import preloaderImg from '../../../assets/images/preloader.gif';

const PendingCaseLawyer: React.FC = () => {
    const [lawyerPendingCases, setLawyerPendingCases] = useState<
        ILawyerPendingCase[]
    >([]);
    const [preloader, setPreloader] = useState<boolean>(false);

    useEffect(() => {
        window.onbeforeunload = () => {
            window.sessionStorage.setItem(
                'lastRoute',
                JSON.stringify(window.location.pathname),
            );
        };
    }, []);

    useEffect(() => {
        setPreloader(true);
        GetPendingLawyerCases().then(({ data }) => {
            setLawyerPendingCases(data);
            setPreloader(false);
        });
    }, []);

    if (preloader) {
        return (
            <div className="preloader-box">
                <img
                    className="preloader-img"
                    src={preloaderImg}
                    alt="Loading..."
                />
            </div>
        );
    }

    return (
        <>
            <div className="service-container service-page">
                <div className="service__title">Active Cases</div>
                <Row className="justify-content-md-center row">
                    {pendingCasesService.map(
                        (service: IServicesPendingCase) => (
                            <Col key={service.name} sm="3">
                                <Card className="br-8">
                                    <NavLink
                                        to={{
                                            pathname: `${DashboardRouteNames.PENDING_CASE_DETAILS_LAWYER}`,
                                            state: {
                                                key: service.id,
                                            },
                                        }}
                                        className="service-block"
                                    >
                                        <Card.Body className="p-10 d-flex justify-content-center align-items-center flex-column">
                                            {lawyerPendingCases
                                                .filter(
                                                    (
                                                        item: ILawyerPendingCase,
                                                    ) => item.id === service.id,
                                                )
                                                .map(
                                                    (i: ILawyerPendingCase) => (
                                                        <div
                                                            key={i.id}
                                                            className="service-block__count"
                                                        >
                                                            {i.count_cases}
                                                        </div>
                                                    ),
                                                )}
                                            {service.icon}
                                            <div className="service-block__title">
                                                {service.name}
                                            </div>
                                        </Card.Body>
                                    </NavLink>
                                </Card>
                            </Col>
                        ),
                    )}
                </Row>
            </div>
        </>
    );
};

export default PendingCaseLawyer;
