import React, { useState, useEffect } from 'react';
import { Button, notification } from 'antd';
import { PostPayInvoicePandingCase } from '../../../../../../apis/pendingCases';
import { useHistory } from 'react-router-dom';
import cardsImg from '../../../../../../assets/images/cards.png';
import preloaderImg from '../../../../../../assets/images/preloader.gif';

const InvoicePayPendingCase: React.ComponentType = (props: any) => {
    const [invoiceId, ,] = useState<number>(
        props.location.state?.id
            ? props.location.state?.id
            : JSON.parse(
                  window.sessionStorage.getItem('idInvoicePayPendingCase') ||
                      '{}',
              ),
    );
    const [fullName, setFullName] = useState<string>('');
    const [numberCard, setNumberCard] = useState<number | null>(null);
    const [cvvCard, setCvvCard] = useState<string>('');
    const [billing, setBilling] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [postcode, setPostcode] = useState<string>('');
    const [fullNameValue, setFullNameValue] = useState<string>('');
    const [numberCardValue, setNumberCardValue] = useState<string>('');
    const [billingValue, setBillingValue] = useState<string>('');
    const [cityCardValue, setCityCardValue] = useState<string>('');
    const [postcodeCardValue, setPostcodeCardValue] = useState<string>('');
    const [cvvCardValue, setCvvCardValue] = useState<string>('');
    const [endDateCard, setEndDateCard] = useState<number>(0);
    const [endDateCardValue, setEndDateCardValue] = useState<string>('');
    const [emptyNumberCard, setEmptyNumberCard] = useState<boolean>(false);
    const [emptyFullNameCard, setEmptyFullNameCard] = useState<boolean>(false);
    const [emptyCvvCard, setEmptyCvvCard] = useState<boolean>(false);
    const [emptyEndDateCard, setEmptyEndDateCard] = useState<boolean>(false);
    const [emptyBillingCard, setEmptyBillingCard] = useState<boolean>(false);
    const [emptyCityCard, setEmptyCityCard] = useState<boolean>(false);
    const [emptyPostcodeCard, setEmptyPostcodeCard] = useState<boolean>(false);
    const [errorFullName, setErrorFullName] = useState(true);
    const [errorCity, setErrorCity] = useState(true);
    const [showCyrillicFullName, setShowCyrillicFullName] =
        useState<boolean>(false);
    const [showCyrillicBilling, setShowCyrillicBilling] =
        useState<boolean>(false);
    const [showCyrillicCity, setShowCyrillicCity] = useState<boolean>(false);
    const [statusPay, setStatusPay] = useState<boolean | null | string>(null);
    const [preloaderModal, setPreloaderModal] = useState<boolean>(false);

    let history = useHistory();

    useEffect(() => {
        window.onbeforeunload = () => {
            window.sessionStorage.setItem(
                'idInvoicePayPendingCase',
                JSON.stringify(invoiceId),
            );
            window.sessionStorage.setItem(
                'lastRoute',
                JSON.stringify(window.location.pathname),
            );
        };
    }, [invoiceId]);

    useEffect(() => {
        let year: number = Number(20 + endDateCardValue.split('/')[1]);
        let month: number = Number(endDateCardValue.split('/')[0]);
        let newDate = new Date(year, month, 0).getTime();
        setEndDateCard(newDate);
    }, [endDateCardValue]);

    useEffect(() => {
        if (statusPay === true) {
            setFullName('');
            setFullNameValue('');
            setNumberCard(null);
            setCvvCard('');
            setEndDateCard(0);
            setEndDateCardValue('');
            setNumberCardValue('');
            setCvvCardValue('');
            setBilling('');
            setBillingValue('');
            setCity('');
            setCityCardValue('');
            setPostcode('');
            setPostcodeCardValue('');
            setStatusPay(null);
            history.goBack();
        } else if (statusPay === false || statusPay === undefined) {
            setStatusPay(null);
            notification.error({
                message: 'Unsuccessful payment',
                description:
                    "We haven't received your payment. Check that your card information is correct and try to pay again.",
                duration: 5,
            });
        }
    }, [statusPay]);

    const validateFullName = (value: string) => {
        const FULL_NAME_REGEXP = /^[a-zA-Z-'` ]+$/;
        return FULL_NAME_REGEXP.test(value);
    };

    const onFullNameCard = (event: any): void => {
        setFullName(event.target.value);
        setFullNameValue(event.target.value);
        const cyrillicPattern = /[\u0400-\u04FF]/;
        if (cyrillicPattern.test(event.target.value)) {
            setShowCyrillicFullName(true);
        } else {
            setShowCyrillicFullName(false);
        }
        validateFullName(event.target.value)
            ? setErrorFullName(false)
            : setErrorFullName(true);
    };

    const onBillingCard = (event: any): void => {
        setBilling(event.target.value);
        setBillingValue(event.target.value);
        const cyrillicPattern = /[\u0400-\u04FF]/;
        if (cyrillicPattern.test(event.target.value)) {
            setShowCyrillicBilling(true);
        } else {
            setShowCyrillicBilling(false);
        }
    };

    const validateCity = (value: string) => {
        const FULL_CITY_REGEXP = /^[a-zA-Z- ]+$/;
        return FULL_CITY_REGEXP.test(value);
    };

    const onCity = (event: any): void => {
        setCity(event.target.value);
        setCityCardValue(event.target.value);
        const cyrillicPattern = /[\u0400-\u04FF]/;
        if (cyrillicPattern.test(event.target.value)) {
            setShowCyrillicCity(true);
        } else {
            setShowCyrillicCity(false);
        }
        validateCity(event.target.value)
            ? setErrorCity(false)
            : setErrorCity(true);
    };

    const onPostcodeCard = (event: any): void => {
        let temp = event.target.value.replace(/[^\d]/g, '');
        setPostcode(temp);
        setPostcodeCardValue(temp);
    };

    const onNumberCard = (event: any): void => {
        let temp = event.target.value.replace(/[^\d]/g, '').substring(0, 16);
        temp = temp.match(/.{1,4}/g);
        if (temp === null) {
            setNumberCardValue('');
        } else {
            temp = temp.join(' ');
            setNumberCardValue(temp);
        }
        setNumberCard(Number(event.target.value.split(' ').join('')));
    };

    const onCvvCard = (event: any): void => {
        setCvvCard(event.target.value);
        setCvvCardValue(event.target.value);
    };

    const onEndDate = (event: any): void => {
        if (
            event.target.value.length === 4 &&
            event.target.value.indexOf('/') === -1
        ) {
            setEndDateCardValue(
                event.target.value.slice(0, 2) +
                    '/' +
                    event.target.value.slice(2, 4),
            );
        } else if (
            event.target.value.length === 4 &&
            event.target.value.indexOf('/') !== -1
        ) {
            let date: string = event.target.value.replace('/', '');
            setEndDateCardValue(date);
        } else if (event.target.value.length < 4) {
            setEndDateCardValue(event.target.value);
        }
    };

    const handlePayOk = (): void => {
        if (
            fullName.trim().length > 1 &&
            errorFullName === false &&
            numberCard !== null &&
            cvvCard.trim().length > 2 &&
            endDateCard !== 0 &&
            billing.trim().length !== 0 &&
            city.trim().length > 1 &&
            errorCity === false &&
            postcode.trim().length === 5 &&
            showCyrillicFullName !== true &&
            showCyrillicBilling !== true &&
            showCyrillicCity !== true
        ) {
            setPreloaderModal(true);
            PostPayInvoicePandingCase(
                invoiceId,
                fullName,
                numberCard,
                endDateCard,
                cvvCard,
                billing,
                city,
                postcode,
            )
                .then(res => {
                    setStatusPay(res.data.data.status);
                })
                .then(() => {
                    setPreloaderModal(false);
                });
        } else if (fullName.trim().length < 2 || errorFullName === true) {
            setEmptyFullNameCard(true);
            setInterval(() => {
                setEmptyFullNameCard(false);
            }, 2000);
        } else if (numberCard === null || numberCard === 0) {
            setEmptyNumberCard(true);
            setInterval(() => {
                setEmptyNumberCard(false);
            }, 2000);
        } else if (isNaN(endDateCard) === true) {
            setEmptyEndDateCard(true);
            setInterval(() => {
                setEmptyEndDateCard(false);
            }, 2000);
        } else if (cvvCard.trim().length < 3) {
            setEmptyCvvCard(true);
            setInterval(() => {
                setEmptyCvvCard(false);
            }, 2000);
        } else if (billing.trim().length === 0) {
            setEmptyBillingCard(true);
            setInterval(() => {
                setEmptyBillingCard(false);
            }, 2000);
        } else if (city.trim().length < 2 || errorCity === true) {
            setEmptyCityCard(true);
            setInterval(() => {
                setEmptyCityCard(false);
            }, 2000);
        } else if (postcode.trim().length !== 5) {
            setEmptyPostcodeCard(true);
            setInterval(() => {
                setEmptyPostcodeCard(false);
            }, 2000);
        }
    };

    return (
        <div className="service-container">
            <div className="invoice-pay-pending-case">
                <div className="invoice-pay-pending-case-title">
                    Pay By Invoice:
                </div>

                {!preloaderModal ? (
                    <>
                        <div className="invoice-pay-pending-case-subtitle">
                            <div>
                                <label
                                    htmlFor="card-fullname"
                                    className="card-title"
                                >
                                    Cardholder Full Name:
                                </label>
                            </div>
                            <input
                                className={
                                    emptyFullNameCard
                                        ? 'card-input-error'
                                        : 'card-input'
                                }
                                style={{ width: '75%' }}
                                id="card-fullname"
                                name="card_fullname"
                                type="text"
                                maxLength={24}
                                value={fullNameValue}
                                onChange={onFullNameCard}
                            />
                            {
                                <div
                                    className={
                                        showCyrillicFullName
                                            ? 'card-upload-box-input-fullname-error'
                                            : 'card-upload-box-input-fullname'
                                    }
                                >
                                    Enter only latin characters
                                </div>
                            }
                        </div>

                        <div className="invoice-pay-pending-case-subtitle">
                            <div>
                                <label
                                    htmlFor="card-number"
                                    className="card-title"
                                >
                                    Card Number:
                                </label>
                            </div>
                            <input
                                className={
                                    emptyNumberCard
                                        ? 'card-input-number-error'
                                        : 'card-input-number'
                                }
                                style={{ width: '50%' }}
                                id="card-number"
                                name="card_number"
                                type="text"
                                maxLength={19}
                                value={numberCardValue}
                                onChange={onNumberCard}
                            />
                            <img
                                className="card-input-number-img"
                                style={{ marginLeft: '5%' }}
                                src={cardsImg}
                                alt="cards"
                            />
                        </div>
                        <div className="invoice-pay-pending-case-subtitle">
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <label
                                        htmlFor="card-expiration"
                                        className="card-title"
                                    >
                                        Expiration Date:
                                    </label>
                                    <div>
                                        <input
                                            className={
                                                emptyEndDateCard
                                                    ? 'card-input-expiration-error'
                                                    : 'card-input-expiration'
                                            }
                                            style={{ width: '40%' }}
                                            id="card-expiration"
                                            name="card_expiration"
                                            type="text"
                                            placeholder="MM/YY"
                                            value={endDateCardValue}
                                            maxLength={5}
                                            onChange={onEndDate}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label
                                        htmlFor="card-cvv"
                                        className="card-title"
                                    >
                                        Security code:
                                    </label>
                                    <div>
                                        <input
                                            className={
                                                emptyCvvCard
                                                    ? 'card-input-security-error'
                                                    : 'card-input-security'
                                            }
                                            style={{ width: '50%' }}
                                            id="card-cvv"
                                            name="card_cvv"
                                            type="password"
                                            maxLength={4}
                                            value={cvvCardValue}
                                            onChange={onCvvCard}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="invoice-pay-pending-case-subtitle">
                            <div>
                                <label
                                    htmlFor="card-billing"
                                    className="card-title"
                                >
                                    Billing address:
                                </label>
                            </div>
                            <input
                                className={
                                    emptyBillingCard
                                        ? 'card-input-error'
                                        : 'card-input'
                                }
                                style={{ width: '75%' }}
                                id="card-billing"
                                name="card_billing"
                                type="text"
                                maxLength={128}
                                value={billingValue}
                                onChange={onBillingCard}
                            />
                            {
                                <div
                                    className={
                                        showCyrillicBilling
                                            ? 'card-upload-box-input-billing-error'
                                            : 'card-upload-box-input-billing'
                                    }
                                >
                                    Enter only latin characters
                                </div>
                            }
                        </div>
                        <div
                            className="invoice-pay-pending-case-subtitle"
                            style={{ display: 'flex' }}
                        >
                            <div>
                                <label
                                    htmlFor="card-city"
                                    className="card-title"
                                >
                                    Town/City:
                                </label>
                                <div>
                                    <input
                                        className={
                                            emptyCityCard
                                                ? 'card-input-city-error'
                                                : 'card-input-city'
                                        }
                                        style={{ width: '60%' }}
                                        id="card-city"
                                        name="card_city"
                                        type="text"
                                        value={cityCardValue}
                                        maxLength={32}
                                        onChange={onCity}
                                    />
                                    {
                                        <div
                                            className={
                                                showCyrillicCity
                                                    ? 'card-upload-box-input-city-error'
                                                    : 'card-upload-box-input-city'
                                            }
                                        >
                                            Enter only latin characters
                                        </div>
                                    }
                                </div>
                            </div>
                            <div>
                                <label
                                    htmlFor="card-postcode"
                                    className="card-title"
                                >
                                    Postcode:
                                </label>
                                <div>
                                    <input
                                        className={
                                            emptyPostcodeCard
                                                ? 'card-input-postcode-error'
                                                : 'card-input-postcode'
                                        }
                                        style={{ width: '50%' }}
                                        id="card-postcode"
                                        name="card_postcode"
                                        type="text"
                                        maxLength={5}
                                        value={postcodeCardValue}
                                        onChange={onPostcodeCard}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div
                        className="card-text"
                        style={{ textAlign: 'center', marginBottom: '30px' }}
                    >
                        <img
                            className="card-text-preloader"
                            src={preloaderImg}
                            alt="Loading..."
                        />
                    </div>
                )}
                <div>
                    <Button
                        type="primary"
                        onClick={handlePayOk}
                        disabled={preloaderModal}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default InvoicePayPendingCase;
