import React from 'react';
import { Modal } from 'antd';

const OpenCaseCustomerPayModal = (props: any) => {
    return (
        <div>
            <Modal
                title="Initial Consultation Fees"
                width="50%"
                visible={props.isModalPayVisible}
                onOk={props.handlePayOk}
                onCancel={props.preloaderModal ? () => {} : props.showConfirm}
                okText="Submit"
                okButtonProps={{
                    style: {
                        backgroundColor: '#B99972',
                        color: '#fff',
                        fontSize: '16px',
                        border: 'none',
                    },
                    disabled: props.preloaderModal,
                }}
                cancelText="Close"
                cancelButtonProps={{
                    style: {
                        backgroundColor: 'transparent',
                        color: '#B99972',
                        fontSize: '16px',
                        border: '1px solid #B99972',
                        borderRadius: '2px',
                    },
                    disabled: props.preloaderModal,
                }}
            >
                {!props.preloaderModal ? (
                    <>
                        <div>
                            <div>
                                <label
                                    htmlFor="card-fullname"
                                    className="card-title"
                                >
                                    Cardholder Full Name:
                                </label>
                            </div>
                            <input
                                className={
                                    props.emptyFullNameCard
                                        ? 'card-input-error'
                                        : 'card-input'
                                }
                                id="card-fullname"
                                name="card_fullname"
                                type="text"
                                maxLength={24}
                                value={props.fullNameValue}
                                onChange={props.onFullNameCard}
                            />
                            {
                                <div
                                    className={
                                        props.showCyrillicFullName
                                            ? 'card-upload-box-input-fullname-error'
                                            : 'card-upload-box-input-fullname'
                                    }
                                >
                                    Enter only latin characters
                                </div>
                            }
                        </div>
                        <div>
                            <div>
                                <label
                                    htmlFor="card-number"
                                    className="card-title"
                                >
                                    Card Number:
                                </label>
                            </div>
                            <input
                                className={
                                    props.emptyNumberCard
                                        ? 'card-input-number-error'
                                        : 'card-input-number'
                                }
                                id="card-number"
                                name="card_number"
                                type="text"
                                maxLength={19}
                                value={props.numberCardValue}
                                onChange={props.onNumberCard}
                            />
                            <img
                                className="card-input-number-img"
                                src={props.cardsImg}
                                alt="cards"
                            />
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div>
                                <label
                                    htmlFor="card-expiration"
                                    className="card-title"
                                >
                                    Expiration Date:
                                </label>
                                <div>
                                    <input
                                        className={
                                            props.emptyEndDateCard
                                                ? 'card-input-expiration-error'
                                                : 'card-input-expiration'
                                        }
                                        id="card-expiration"
                                        name="card_expiration"
                                        type="text"
                                        placeholder="MM/YY"
                                        value={props.endDateCardValue}
                                        maxLength={5}
                                        onChange={props.onEndDate}
                                    />
                                </div>
                            </div>
                            <div>
                                <label
                                    htmlFor="card-cvv"
                                    className="card-title"
                                >
                                    Security code:
                                </label>
                                <div>
                                    <input
                                        className={
                                            props.emptyCvvCard
                                                ? 'card-input-security-error'
                                                : 'card-input-security'
                                        }
                                        id="card-cvv"
                                        name="card_cvv"
                                        type="password"
                                        maxLength={4}
                                        value={props.cvvCardValue}
                                        onChange={props.onCvvCard}
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <label
                                    htmlFor="card-billing"
                                    className="card-title"
                                >
                                    Billing address:
                                </label>
                            </div>
                            <input
                                className={
                                    props.emptyBillingCard
                                        ? 'card-input-error'
                                        : 'card-input'
                                }
                                id="card-billing"
                                name="card_billing"
                                type="text"
                                maxLength={128}
                                value={props.billingValue}
                                onChange={props.onBillingCard}
                            />
                            {
                                <div
                                    className={
                                        props.showCyrillicBilling
                                            ? 'card-upload-box-input-billing-error'
                                            : 'card-upload-box-input-billing'
                                    }
                                >
                                    Enter only latin characters
                                </div>
                            }
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div>
                                <label
                                    htmlFor="card-city"
                                    className="card-title"
                                >
                                    Town/City:
                                </label>
                                <div>
                                    <input
                                        className={
                                            props.emptyCityCard
                                                ? 'card-input-city-error'
                                                : 'card-input-city'
                                        }
                                        id="card-city"
                                        name="card_city"
                                        type="text"
                                        value={props.cityCardValue}
                                        maxLength={32}
                                        onChange={props.onCity}
                                    />
                                    {
                                        <div
                                            className={
                                                props.showCyrillicCity
                                                    ? 'card-upload-box-input-city-error'
                                                    : 'card-upload-box-input-city'
                                            }
                                        >
                                            Enter only latin characters
                                        </div>
                                    }
                                </div>
                            </div>
                            <div>
                                <label
                                    htmlFor="card-postcode"
                                    className="card-title"
                                >
                                    Postcode:
                                </label>
                                <div>
                                    <input
                                        className={
                                            props.emptyPostcodeCard
                                                ? 'card-input-postcode-error'
                                                : 'card-input-postcode'
                                        }
                                        id="card-postcode"
                                        name="card_postcode"
                                        type="text"
                                        maxLength={5}
                                        value={props.postcodeCardValue}
                                        onChange={props.onPostcodeCard}
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className="card-text"
                            style={{
                                fontSize: 16,
                                color: '#232d42',
                                fontWeight: 500,
                            }}
                        >
                            Initial Consultation Fee: AED {props.pricePay}
                        </div>
                    </>
                ) : (
                    <div className="card-text" style={{ textAlign: 'center' }}>
                        <img
                            className="card-text-preloader"
                            src={props.preloaderImg}
                            alt="Loading..."
                        />
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default OpenCaseCustomerPayModal;
