import React from 'react';
import { Modal, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { AuthRouteNames } from '../../router/Auth';
import { useActions } from '../../hooks/useActions';

const ModalToken = (props: any) => {
    let history = useHistory();
    const { handleLoader, logout } = useActions();
    const onRedirectToSignIn = () => {
        logout();
        history.push(`${AuthRouteNames.SIGN_IN}`);
        handleLoader(false);
    };

    return (
        <>
            <Modal
                visible={props.showModalExpiredToken}
                title="Session timed out"
                onCancel={onRedirectToSignIn}
                footer={[
                    <Button
                        key="Write"
                        type="primary"
                        onClick={onRedirectToSignIn}
                    >
                        Sign In
                    </Button>,
                ]}
            >
                <div className="message-modal-pending-case-description">
                    <div className="message-modal-pending-case-description-title">
                        Session timed out! You need to go to the Sign In page to
                        re-authorize.
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ModalToken;
