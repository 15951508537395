import React, { useState, useEffect } from 'react';
import { Image, Table, Button } from 'antd';
import { DocumentsUserCaseType } from '../../../../../models/IPendingCases';
import pdfLogo from '../../../../../assets/images/pdf.png';
import docLogo from '../../../../../assets/images/doc.png';
import AddDocumenetsLawyer from './AddDocumentsLawyer/AddDocumentsLawyer';
import moment from 'moment';

const DocumentsTablePendingCaseDetailsLawyer = (props: any) => {
    const [dataDocumentsForTable, setDataDocumentsForTable] = useState<
        DocumentsUserCaseType[]
    >([]);
    const [showAddDocumentsModal, setShowAddDocumentsModal] =
        useState<boolean>(false);

    useEffect(() => {
        let tempDataDocumentsForTable: DocumentsUserCaseType[] = [];
        let tempNumber = props.documentsUserCase[0]?.documents.length;
        let sortArrId = props.documentsUserCase[0]?.documents.sort(
            (a: any, b: any) => b.id - a.id,
        );
        sortArrId
            ?.sort(
                (a: any, b: any) =>
                    moment(b.date_created).unix() - moment(a.date_created).unix(),
            )
            .forEach((item: any) => {
                let tempObjDocumentForTable: DocumentsUserCaseType = {
                    key: tempNumber,
                    number: tempNumber,
                    date: '',
                    description: '',
                    document_url: '',
                    attachedTo: '',
                };
                tempObjDocumentForTable.number = tempNumber--;
                tempObjDocumentForTable.key = tempNumber;
                if (item.type === 1) {
                    tempObjDocumentForTable.attachedTo = 'Open Case';
                } else if (item.type === 2) {
                    tempObjDocumentForTable.attachedTo = `Message # ${item.message_number}`;
                } else {
                    tempObjDocumentForTable.attachedTo = 'Added by Lawyer';
                }
                tempObjDocumentForTable.date = item.date_created;
                tempObjDocumentForTable.description = item.description;
                tempObjDocumentForTable.document_url = item.document_url;
                tempDataDocumentsForTable.push(tempObjDocumentForTable);
            });
        setDataDocumentsForTable(tempDataDocumentsForTable);
    }, [props.documentsUserCase]);

    const onShowModal = () => {
        setShowAddDocumentsModal(true);
    };

    const columns = [
        {
            title: 'Number',
            dataIndex: 'number',
            align: 'center' as 'center',
        },
        {
            title: 'Attached to',
            dataIndex: 'attachedTo',
            align: 'center' as 'center',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            align: 'center' as 'center',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            align: 'center' as 'center',
            className: 'documents-table-pending-case-details-customer',
            ellipsis: true,
        },
        {
            title: 'Preview',
            dataIndex: 'document_url',
            render: (text: string) => {
                if (text.split('.').reverse()[0] === 'pdf') {
                    return (
                        <div>
                            <Image width={50} src={pdfLogo} />
                            <div>
                                <a href={text} target="_blank" rel="noreferrer">
                                    Open document
                                </a>
                            </div>
                        </div>
                    );
                } else if (
                    text.split('.').reverse()[0] === 'doc' ||
                    text.split('.').reverse()[0] === 'docx'
                ) {
                    return (
                        <div>
                            <Image width={50} src={docLogo} />
                            <div>
                                <a href={text} target="_blank" rel="noreferrer">
                                    Open document
                                </a>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div>
                            <Image width={50} src={text} />
                            <div>
                                <a href={text} target="_blank" rel="noreferrer">
                                    Open document
                                </a>
                            </div>
                        </div>
                    );
                }
            },
            align: 'center' as 'center',
        },
    ];

    return (
        <div className="messages-pending-case-details card">
            <div className="documents-pending-case-details-documents">
                <div className="documents-pending-case-details-documents-title">
                    Case Documents
                </div>
                <Table
                    columns={columns}
                    dataSource={
                        dataDocumentsForTable ? dataDocumentsForTable : []
                    }
                    bordered
                />
                {props.data[0]?.status === 2 ? (
                    <div className="documents-pending-case-details-button">
                        <Button type="primary" onClick={onShowModal}>
                            Add Additional Documents
                        </Button>
                    </div>
                ) : null}
            </div>
            <AddDocumenetsLawyer
                showAddDocumentsModal={showAddDocumentsModal}
                setShowAddDocumentsModal={setShowAddDocumentsModal}
                caseId={props.data[0]?.id}
                onReloadTableAfterSend={props.onReloadTableAfterSend}
            />
        </div>
    );
};

export default DocumentsTablePendingCaseDetailsLawyer;
