import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import AddDocuments from './AddDocuments/AddDocuments';
import { PostAddDocuments } from '../../../../../../apis/addDocumentsLawyer';

const AddDocumenetsLawyer = (props: any) => {
    const [valueForTable, setValueForTable] = useState<any[]>([]);
    const [documentsFileList, setDocumentsFileList] = useState<any[]>([]);
    const [documentsMessageValue, setDocumentsMessageValue] =
        useState<DocumentType | null>(null);
    const [imageDocumentArray, setImageDocumentArray] = useState<any[]>([]);
    const [showCyrillicDocuments, setShowCyrillicDocuments] =
        useState<boolean>(false);
    const [emptyDocuments, setEmptyDocuments] = useState<boolean>(false);

    const { confirm } = Modal;
    const cyrillicPatternDocuments = /[\u0400-\u04FF]/;

    useEffect(() => {
        let tempArrayDocumentsSend: any = [];
        valueForTable?.forEach((item: any, index: number) => {
            if (cyrillicPatternDocuments.test(item.description)) {
                setShowCyrillicDocuments(true);
            }
            let tempObjDocumentsSend: any = {};
            tempObjDocumentsSend[String(index)] = {
                url: item.urlFile,
                description: item.description,
            };
            tempArrayDocumentsSend.push(tempObjDocumentsSend);
        });
        setImageDocumentArray(tempArrayDocumentsSend);
    }, [valueForTable]);

    useEffect(() => {
        let tempArrayValueForTable: any[] = [];
        documentsFileList.forEach((item: any) => {
            if (!item.response.code) {
                let tempObjValueForTable: any = {};
                tempObjValueForTable.key = item.uid;
                tempObjValueForTable.id = item.uid;
                tempObjValueForTable.uploadedFile = item.name;
                tempObjValueForTable.urlFile = item.response.data[0];
                if (item.description) {
                    tempObjValueForTable.description = item.description;
                } else {
                    tempObjValueForTable.description = '';
                }
                tempObjValueForTable.deleteFile = item;
                tempArrayValueForTable.push(tempObjValueForTable);
            }
        });
        setValueForTable(tempArrayValueForTable);
    }, [documentsFileList]);

    useEffect(() => {
        let newObjDocuments: any = {};
        let count = 0;
        imageDocumentArray.forEach(item => {
            for (let value of Object.values(item)) {
                newObjDocuments[String(count++)] = value;
            }
        });
        setDocumentsMessageValue(newObjDocuments);
    }, [imageDocumentArray]);

    const sendDocuments = () => {
        if (valueForTable.length > 0 && showCyrillicDocuments !== true) {
            PostAddDocuments(props.caseId, documentsMessageValue).then(() => {
                setValueForTable([]);
                setDocumentsFileList([]);
                setDocumentsMessageValue(null);
                onCloseModal();
                setTimeout(() => {
                    props.onReloadTableAfterSend();
                }, 2000);
            });
        } else if (valueForTable.length === 0) {
            setEmptyDocuments(true);
            setTimeout(() => {
                setEmptyDocuments(false);
            }, 2000);
        }
    };

    const onDescriptionDocuments = (event: any, file: any): void => {
        let index = documentsFileList.indexOf(file.deleteFile);
        documentsFileList[index].description = event.target.value;
        file.description = event.target.value;
        if (cyrillicPatternDocuments.test(event.target.value)) {
            setShowCyrillicDocuments(true);
        } else {
            setShowCyrillicDocuments(false);
        }
        setDocumentsFileList([...documentsFileList]);
        setValueForTable([...valueForTable]);
    };

    const onCloseModal = () => {
        props.setShowAddDocumentsModal(false);
        setValueForTable([]);
        setDocumentsFileList([]);
        setDocumentsMessageValue(null);
    };

    const showConfirm = () => {
        confirm({
            title: 'Do you want to close this modal?',
            icon: <ExclamationCircleOutlined />,
            content: 'The entered data will be deleted',
            cancelButtonProps: {
                type: 'primary',
            },
            onOk() {
                onCloseModal();
            },
            onCancel() {
                return;
            },
        });
    };

    return (
        <>
            <Modal
                visible={props.showAddDocumentsModal}
                title="Add Documents"
                onCancel={showConfirm}
                width="600px"
                footer={[
                    <Button key="Back" danger onClick={showConfirm}>
                        Back
                    </Button>,
                    <Button key="Send" type="primary" onClick={sendDocuments}>
                        Send
                    </Button>,
                ]}
            >
                <div>
                    <AddDocuments
                        valueForTable={valueForTable}
                        documentsFileList={documentsFileList}
                        setDocumentsFileList={setDocumentsFileList}
                        setDocumentsMessageValue={setDocumentsMessageValue}
                        onDescriptionDocuments={onDescriptionDocuments}
                        setShowCyrillicDocuments={setShowCyrillicDocuments}
                        showCyrillicDocuments={showCyrillicDocuments}
                    />
                </div>
                {
                    <div
                        style={{ marginTop: '10px' }}
                        className={
                            emptyDocuments
                                ? 'card-upload-box-input-message-error'
                                : 'card-upload-box-input-message'
                        }
                    >
                        You didn't add document
                    </div>
                }
            </Modal>
        </>
    );
};

export default AddDocumenetsLawyer;
