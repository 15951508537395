import React, { useState } from 'react';
import { Modal, Button, notification } from 'antd';
import { PostCloseCase } from '../../../../../../apis/pendingCasesLawyer';
import { useHistory } from 'react-router-dom';
import { DashboardRouteNames } from '../../../../../../router/Dashboard';

const CloseCaseModal = (props: any) => {
    const [descriptionCloseCaseModal, setDescriptionCloseCaseModal] =
        useState<string>('');
    const [inputDescriptionCloseCaseEmpty, setInputDescriptionCloseCaseEmpty] =
        useState<boolean>(false);
    const [showCyrillic, setShowCyrillic] = useState<boolean>(false);

    const history = useHistory();

    const sendCloseCase = () => {
        if (descriptionCloseCaseModal !== '' && showCyrillic !== true) {
            PostCloseCase(props.id, descriptionCloseCaseModal).then(value => {
                if (value.data.data.status) {
                    notification.success({
                        message: `Case #${props.id} successfully closed`,
                    });
                    props.onCloseCloseCaseModal();
                    history.push(`${DashboardRouteNames.PENDING_CASES_LAWYER}`);
                }
            });
        } else {
            setInputDescriptionCloseCaseEmpty(true);
            setTimeout(() => {
                setInputDescriptionCloseCaseEmpty(false);
            }, 2000);
        }
    };

    const onDescriptionCloseCaseValue = (event: any) => {
        setDescriptionCloseCaseModal(event.target.value);
        const cyrillicPattern = /[\u0400-\u04FF]/;
        if (cyrillicPattern.test(event.target.value)) {
            setShowCyrillic(true);
        } else {
            setShowCyrillic(false);
        }
    };

    return (
        <>
            <Modal
                visible={props.showCloseCaseModal}
                title="Close Case"
                onCancel={props.onCloseCloseCaseModal}
                footer={[
                    <Button
                        key="Back"
                        danger
                        onClick={props.onCloseCloseCaseModal}
                    >
                        Back
                    </Button>,
                    <Button key="Send" type="primary" onClick={sendCloseCase}>
                        Send
                    </Button>,
                ]}
            >
                <div>
                    <div>Description:</div>
                    <div>
                        <textarea
                            className="card-textarea"
                            onChange={onDescriptionCloseCaseValue}
                            value={descriptionCloseCaseModal}
                            placeholder="example..."
                            maxLength={256}
                        />
                        {
                            <div
                                className={
                                    showCyrillic
                                        ? 'card-upload-box-input-message-error'
                                        : 'card-upload-box-input-message'
                                }
                            >
                                Enter only latin characters
                            </div>
                        }
                        {
                            <div
                                className={
                                    inputDescriptionCloseCaseEmpty &&
                                    showCyrillic !== true
                                        ? 'card-upload-box-textarea-message-error'
                                        : 'card-upload-box-textarea-message'
                                }
                            >
                                This field is required
                            </div>
                        }
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default CloseCaseModal;
