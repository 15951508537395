import React from 'react';
import { Modal, Button, Form, Input, InputNumber, notification } from 'antd';
import 'antd/dist/antd.css';
import { createInvoicePayment } from '../../../../../../apis/pendingCasesLawyer';
import '../../../../pending-cases/styles/pendingCases.css';

const InvoicePaymentModal = (props: any) => {
    const [form] = Form.useForm();

    const onFinish = ({ description, price }: any) => {
        createInvoicePayment(props.dataId, description, price).then(res => {
            console.log(res);
            props.onReloadTablePayment();
        });
        openNotificationWithIcon();
        props.onShowWriteLawyerModal();
    };

    const onReset = () => {
        form.resetFields();
    };

    const openNotificationWithIcon = () => {
        notification['success']({
            message: 'Successfully',
            description: 'Operation sent back successfully customer',
        });
    };

    const validateMessages = {
        /* eslint-disable no-template-curly-in-string */
        required: '${label} is required!',
        types: {
            number: '${label} must be a number!',
        },
        number: {
            range: '${label} must be between ${min}',
        },
    };

    return (
        <>
            <Modal
                visible={props.showMessageModal}
                title={'Case Number #' + props.dataId}
                onCancel={props.onShowWriteLawyerModal}
                footer={''}
            >
                <Form
                    form={form}
                    layout={'vertical'}
                    onFinish={onFinish}
                    validateMessages={validateMessages}
                >
                    <Form.Item
                        required
                        name={['description']}
                        label="Description"
                        rules={[
                            {
                                required: true,
                                min: 8,
                                max: 256,
                            },
                            {
                                pattern: /^[^а-яёіїє]+$/iu,
                                message: 'Enter only latin characters',
                            },
                        ]}
                    >
                        <Input.TextArea placeholder="Enter a description" />
                    </Form.Item>
                    <Form.Item
                        required
                        name={['price']}
                        label="Entering the amount owed"
                        rules={[
                            {
                                type: 'number',
                                required: true,
                                min: 1,
                            },
                        ]}
                    >
                        <InputNumber
                            min={1}
                            style={{ width: 250 }}
                            placeholder="Enter amount                        AED"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            style={{ marginLeft: 150, marginRight: 15 }}
                            type="primary"
                            htmlType="submit"
                        >
                            Check Out
                        </Button>
                        <Button htmlType="button" onClick={onReset}>
                            Reset
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default InvoicePaymentModal;
