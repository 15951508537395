import { Alert } from 'antd';
import 'antd/dist/antd.css';
import style from './MessageCenterUser.module.css';
import { IMessageCenter } from '../../models/IMessageCenter';
import { Link } from 'react-router-dom';
import { DashboardRouteNames } from '../../router/Dashboard';
import { useTypedSelector } from '../../hooks/useTypedSelector';

interface IProps {
    title: string;
    data: IMessageCenter[];
}

const MessageCenterUser = ({ data, title }: IProps) => {
    const userRoleId: number | undefined = useTypedSelector(
        state => state.user.user?.role?.id,
    );
    const newDescription = (desc: string, key: number) => {
        if (!key) return desc;

        const newDesc = desc.replace(`Case #${key}`, '');

        if (key && userRoleId === 2) {
            return (
                <>
                    <p>
                        Case{' '}
                        <Link
                            to={{
                                pathname: `${DashboardRouteNames.PENDING_OPEN_DETAILS_CUSTOMER}`,
                                state: { id: key },
                            }}
                        >
                            #{key}
                        </Link>
                        {newDesc}
                    </p>
                </>
            );
        }

        if (key && userRoleId === 3) {
            return (
                <>
                    <p>
                        Case{' '}
                        <Link
                            to={{
                                pathname: `${DashboardRouteNames.PENDING_CASE_DETAILS}`,
                                state: { id: { caseNumber: key } },
                            }}
                        >
                            #{key}
                        </Link>
                        {newDesc}
                    </p>
                </>
            );
        }
    };
    return (
        <>
            <div className={style.title}>{title}</div>
            <div className={style.alert}>
                {data.length > 0 ? (
                    data
                        .sort((a, b) => b.create_at - a.create_at)
                        .map((i: IMessageCenter) => (
                            <Alert
                                key={i.create_at}
                                className={style.alertText}
                                showIcon
                                banner
                                message={i.create_at_human}
                                description={newDescription(
                                    i.description,
                                    i.case_id,
                                )}
                            />
                        ))
                ) : (
                    <Alert
                        banner
                        className={style.alertText + ' ' + style.paddingBottom}
                        description="No notifications!"
                    />
                )}
            </div>
        </>
    );
};

export default MessageCenterUser;
