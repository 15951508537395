import { FieldInputProps, useFormikContext } from 'formik';
import { useState, FC, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { PostImage } from '../../../apis/fields';
import { ICodes } from '../../../models/ICodes';
import { IFieldFront } from '../../../models/IField';
import preloaderImg from '../../../assets/images/preloader.gif';

import pdfIcon from '../../../assets/images/icons/pdf.svg';

interface FileDropProps {
    field: FieldInputProps<any>;
    input: IFieldFront;
}

const MaxFileMb = 5;
const MaxFileSize = MaxFileMb * 1000000;

const FileDrop: FC<FileDropProps> = ({ field, input }) => {
    const [imgUrl, setImgUrl] = useState<string>('');
    const [preloader, setPreloader] = useState<boolean>(false);
    const { setFieldValue, setFieldError, setFieldTouched } =
        useFormikContext() ?? {};

    useEffect(() => {
        if (input.value && typeof input.value === 'string') {
            setImgUrl(input.value);
        }
    }, [input.value]);

    const onDrop = async (acceptedFiles: File[]) => {
        setPreloader(true);
        if (acceptedFiles[0].size > MaxFileSize) {
            setFieldError(
                field.name,
                `File must not be bigger than ${MaxFileMb} Mb`,
            );
            setFieldTouched(field.name, true, false);
            return;
        }
        if (acceptedFiles[0].type === 'video/mp4') {
            setFieldError(field.name, `Unable to load format video/mp4`);
            setFieldTouched(field.name, true, false);
            return;
        }
        if (acceptedFiles[0].type === 'image/tiff') {
            setFieldError(field.name, `Unable to load format image/tiff`);
            setFieldTouched(field.name, true, false);
            return;
        }
        const res = await PostImage(field.name, acceptedFiles[0]);
        if (res.status === ICodes.VALIDATION_ERROR && res.data.data) {
            const error: string = res.data.data[field.name].join(', ');
            setFieldError(field.name, error);
            setFieldTouched(field.name, true, false);
            return;
        }
        const ulr: string[] = Object.values(res);
        setImgUrl(ulr[0]);
        setFieldValue(field.name, ulr[0]);
        setTimeout(() => {
            setPreloader(false);
        }, 500);
    };

    const renderFilePreview = () => {
        if (imgUrl.endsWith('.pdf')) {
            return (
                <div className="file-drop-input__preview">
                    <img src={pdfIcon} alt="dropped" />
                    <a
                        target="_blank"
                        rel="noreferrer"
                        onClick={e => e.stopPropagation()}
                        href={imgUrl}
                    >
                        Preview
                    </a>
                </div>
            );
        }
        return <img src={imgUrl} alt="dropped" />;
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    });

    return (
        <div {...getRootProps()} className="file-drop-input">
            <input {...getInputProps()} accept={input.accept} />
            {imgUrl && preloader === false ? (
                renderFilePreview()
            ) : isDragActive ? (
                <div className="file-drop-input__text--active">
                    Let's insert your file into me...
                </div>
            ) : preloader ? (
                <div className="file-drop-input__preloader">
                    <img src={preloaderImg} alt="loading..." />
                </div>
            ) : (
                <div className="file-drop-input__text">
                    <svg
                        width="33"
                        height="33"
                        viewBox="0 0 33 33"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M16.5 5.5C17.1558 5.5 17.6875 6.03166 17.6875 6.6875V26.3125C17.6875 26.9683 17.1558 27.5 16.5 27.5C15.8442 27.5 15.3125 26.9683 15.3125 26.3125V6.6875C15.3125 6.03166 15.8442 5.5 16.5 5.5Z"
                            fill="#3A57E8"
                        />
                        <path
                            d="M6 16.5C6 15.8442 6.53166 15.3125 7.1875 15.3125H25.8125C26.4683 15.3125 27 15.8442 27 16.5C27 17.1558 26.4683 17.6875 25.8125 17.6875H7.1875C6.53166 17.6875 6 17.1558 6 16.5Z"
                            fill="#3A57E8"
                        />
                    </svg>
                    Upload
                    <div className="file-drop-input__description">
                        JPG, JPEG, PNG, PDF format is allowed. Maximum file size to upload is 3MB.
                    </div>
                </div>
            )}
        </div>
    );
};

export default FileDrop;
