import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

//router
import { BrowserRouter } from 'react-router-dom'
//store

import { Provider } from 'react-redux';
//reducer
import { store } from './store'

if (process.env.REACT_APP_ENVIRONMENT !== 'dev') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN_KEY,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENVIRONMENT,
  });
}


ReactDOM.render(
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);