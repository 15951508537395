import { useState, useEffect } from 'react';
import { Modal, Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { sendMessageClient } from '../../../../../../apis/pendingCasesLawyer';
import { DocumentType } from '../../../../../../models/IPrice';
import UploadDocuments from './UploadDocuments/UploadDocuments';

const WriteCustomerModal = (props: any) => {
    const [titleMessageValue, setTitleMessageValue] = useState<string>('');
    const [descriptionMessageValue, setDescriptionMessageValue] =
        useState<string>('');
    const [documentsMessageValue, setDocumentsMessageValue] =
        useState<DocumentType | null>(null);
    const [userId, setUserId] = useState<number>(0);
    const [chatId, setChatId] = useState<number>(0);
    const [valueForTable, setValueForTable] = useState<any[]>([]);
    const [imageDocumentArray, setImageDocumentArray] = useState<any[]>([]);
    const [inputTitleEmpty, setInputTitleEmpty] = useState<boolean>(false);
    const [inputDescriptionEmpty, setInputDescriptionEmpty] =
        useState<boolean>(false);
    const [documentsFileList, setDocumentsFileList] = useState<any[]>([]);
    const [showCyrillicTitle, setShowCyrillicTitle] = useState<boolean>(false);
    const [showCyrillicDescription, setShowCyrillicDescription] =
        useState<boolean>(false);
    const [showCyrillicDocuments, setShowCyrillicDocuments] =
        useState<boolean>(false);

    const { confirm } = Modal;
    const cyrillicPatternDocuments = /[\u0400-\u04FF]/;

    useEffect(() => {
        setUserId(props.userId);
        setChatId(props.chatId);
    }, [props.userId, props.chatId]);

    useEffect(() => {
        if (props.roleWriteToCustomer === 2) {
            if (props.messagesTitle.length > 248) {
                setTitleMessageValue(
                    `Re: ${
                        props.messagesTitleNumber
                    } ${props.messagesTitle.slice(0, 248)}`,
                );
            } else {
                setTitleMessageValue(
                    `Re: ${props.messagesTitleNumber} ${props.messagesTitle}`,
                );
            }
        } else {
            setTitleMessageValue('');
        }
    }, [props.messagesTitle, props.roleWriteToCustomer]);

    useEffect(() => {
        let tempArrayDocumentsSend: any = [];
        valueForTable?.forEach((item: any, index: number) => {
            if (cyrillicPatternDocuments.test(item.description)) {
                setShowCyrillicDocuments(true);
            }
            let tempObjDocumentsSend: any = {};
            tempObjDocumentsSend[String(index)] = {
                url: item.urlFile,
                description: item.description,
            };
            tempArrayDocumentsSend.push(tempObjDocumentsSend);
        });
        setImageDocumentArray(tempArrayDocumentsSend);
    }, [valueForTable]);

    useEffect(() => {
        let tempArrayValueForTable: any[] = [];
        documentsFileList.forEach((item: any) => {
            if (!item.response.code) {
                let tempObjValueForTable: any = {};
                tempObjValueForTable.key = item.uid;
                tempObjValueForTable.id = item.uid;
                tempObjValueForTable.uploadedFile = item.name;
                tempObjValueForTable.urlFile = item.response.data[0];
                if (item.description) {
                    tempObjValueForTable.description = item.description;
                } else {
                    tempObjValueForTable.description = '';
                }
                tempObjValueForTable.deleteFile = item;
                tempArrayValueForTable.push(tempObjValueForTable);
            }
        });
        setValueForTable(tempArrayValueForTable);
    }, [documentsFileList]);

    useEffect(() => {
        let newObjDocuments: any = {};
        let count = 0;
        imageDocumentArray.forEach(item => {
            for (let value of Object.values(item)) {
                newObjDocuments[String(count++)] = value;
            }
        });
        setDocumentsMessageValue(newObjDocuments);
    }, [imageDocumentArray]);

    const onTitleMessageValue = (event: any) => {
        setTitleMessageValue(event.target.value);
        const cyrillicPattern = /[\u0400-\u04FF]/;
        if (cyrillicPattern.test(event.target.value)) {
            setShowCyrillicTitle(true);
        } else {
            setShowCyrillicTitle(false);
        }
    };

    const onDescriptionMessageValue = (event: any) => {
        setDescriptionMessageValue(event.target.value);
        const cyrillicPattern = /[\u0400-\u04FF]/;
        if (cyrillicPattern.test(event.target.value)) {
            setShowCyrillicDescription(true);
        } else {
            setShowCyrillicDescription(false);
        }
    };

    const sendMessageCustomer = () => {
        if (
            titleMessageValue !== '' &&
            descriptionMessageValue !== '' &&
            valueForTable.length === 0 &&
            showCyrillicTitle !== true &&
            showCyrillicDescription !== true &&
            showCyrillicDocuments !== true
        ) {
            sendMessageClient(
                userId,
                chatId,
                descriptionMessageValue,
                0,
                titleMessageValue,
                props.messageNumber + 1,
            );
            setTitleMessageValue('');
            setDescriptionMessageValue('');
            setValueForTable([]);
            setDocumentsFileList([]);
            setTimeout(() => {
                props.onReloadTableAfterSend();
            }, 3000);
            onCloseWriteCustomerModal();
        } else if (
            titleMessageValue !== '' &&
            descriptionMessageValue !== '' &&
            valueForTable.length > 0 &&
            showCyrillicTitle !== true &&
            showCyrillicDescription !== true &&
            showCyrillicDocuments !== true
        ) {
            sendMessageClient(
                userId,
                chatId,
                descriptionMessageValue,
                0,
                titleMessageValue,
                props.messageNumber + 1,
                documentsMessageValue,
            );
            setTitleMessageValue('');
            setDescriptionMessageValue('');
            setDocumentsMessageValue(null);
            setValueForTable([]);
            setDocumentsFileList([]);
            setTimeout(() => {
                props.onReloadTableAfterSend();
            }, 3000);
            onCloseWriteCustomerModal();
        } else if (!titleMessageValue) {
            setInputTitleEmpty(true);
            setTimeout(() => {
                setInputTitleEmpty(false);
            }, 2000);
        } else if (!descriptionMessageValue) {
            setInputDescriptionEmpty(true);
            setTimeout(() => {
                setInputDescriptionEmpty(false);
            }, 2000);
        }
    };

    const onDescriptionDocuments = (event: any, file: any): void => {
        let index = documentsFileList.indexOf(file.deleteFile);
        documentsFileList[index].description = event.target.value;
        file.description = event.target.value;
        if (cyrillicPatternDocuments.test(event.target.value)) {
            setShowCyrillicDocuments(true);
        } else {
            setShowCyrillicDocuments(false);
        }
        setDocumentsFileList([...documentsFileList]);
        setValueForTable([...valueForTable]);
    };

    const onCloseWriteCustomerModal = () => {
        props.setShowWriteCustomerModal(false);
        props.setShowMessageModal(false);
        props.setResetDataDocumentsInMessage(
            !props.resetDataDocumentsInMessage,
        );
        if (props.roleWriteToCustomer === 1) {
            setTitleMessageValue('');
        }
        props.setRoleWriteToCustomer(0);
        setDescriptionMessageValue('');
        setDocumentsFileList([]);
    };

    const showConfirm = () => {
        confirm({
            title: 'Do you want to close this message?',
            icon: <ExclamationCircleOutlined />,
            content: 'The entered data will be deleted',
            cancelButtonProps: {
                type: 'primary',
            },
            onOk() {
                onCloseWriteCustomerModal();
            },
            onCancel() {
                return;
            },
        });
    };

    return (
        <>
            <Modal
                visible={props.showWriteLawyerModal}
                title="New Message"
                onCancel={showConfirm}
                width="600px"
                footer={[
                    <Button key="Back" danger onClick={showConfirm}>
                        Back
                    </Button>,
                    <Button
                        key="Send"
                        type="primary"
                        onClick={sendMessageCustomer}
                    >
                        Send
                    </Button>,
                ]}
            >
                <div>
                    <div>
                        <div>Title message:</div>
                        <div>
                            <input
                                className="card-upload-box-input"
                                style={{ width: '100%' }}
                                onChange={onTitleMessageValue}
                                value={titleMessageValue}
                                type="text"
                                disabled={props.roleWriteToCustomer === 2}
                                maxLength={255}
                            />
                            {
                                <div
                                    className={
                                        showCyrillicTitle
                                            ? 'card-upload-box-input-message-error'
                                            : 'card-upload-box-input-message'
                                    }
                                >
                                    Enter only latin characters
                                </div>
                            }
                            {
                                <div
                                    className={
                                        inputTitleEmpty
                                            ? 'card-upload-box-input-message-error'
                                            : 'card-upload-box-input-message'
                                    }
                                >
                                    This field is required
                                </div>
                            }
                        </div>
                    </div>
                    <div>
                        <div>Description:</div>
                        <div>
                            <textarea
                                className="card-textarea"
                                onChange={onDescriptionMessageValue}
                                value={descriptionMessageValue}
                                placeholder="example..."
                            />
                            {
                                <div
                                    className={
                                        showCyrillicDescription
                                            ? 'card-upload-box-input-message-error'
                                            : 'card-upload-box-input-message'
                                    }
                                >
                                    Enter only latin characters
                                </div>
                            }
                            {
                                <div
                                    className={
                                        inputDescriptionEmpty
                                            ? 'card-upload-box-textarea-message-error'
                                            : 'card-upload-box-textarea-message'
                                    }
                                >
                                    This field is required
                                </div>
                            }
                        </div>
                    </div>

                    <UploadDocuments
                        valueForTable={valueForTable}
                        documentsFileList={documentsFileList}
                        setDocumentsFileList={setDocumentsFileList}
                        setDocumentsMessageValue={setDocumentsMessageValue}
                        onDescriptionDocuments={onDescriptionDocuments}
                        resetDataDocumentsInMessage={
                            props.resetDataDocumentsInMessage
                        }
                        setShowCyrillicDocuments={setShowCyrillicDocuments}
                        showCyrillicDocuments={showCyrillicDocuments}
                    />
                </div>
            </Modal>
        </>
    );
};

export default WriteCustomerModal;
