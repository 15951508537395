const InfoPendingVerify = () => {
    return (
        <div
            data-v-7a83d4a4=""
            data-show="true"
            className="ant-alert ant-alert-warning ant-alert-with-description"
            style={{ width: 700 }}
        >
            <i
                aria-label="icon: exclamation-circle"
                className="anticon anticon-exclamation-circle ant-alert-icon"
            >
                <svg
                    viewBox="64 64 896 896"
                    data-icon="exclamation-circle"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                    focusable="false"
                >
                    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                    <path d="M464 688a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm24-112h48c4.4 0 8-3.6 8-8V296c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8z"></path>
                </svg>
            </i>
            <span>
                Your account goes through moderation it will take a little time.
                As verification will be passed, we will notify you by e-mail or
                you will change the status in your personal cabinet to Verify.
            </span>
        </div>
    );
};

export default InfoPendingVerify;
