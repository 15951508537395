import React from 'react';
import '../../styles/pendingCases.css';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';

const HeaderPendingCaseDetails = (props: any) => {
    let history = useHistory();
    return (
        <div className="header-pending-case-details">
            <div className="header-pending-case-details-titles">
                <Button
                    type="primary"
                    style={{ marginRight: '10px' }}
                    onClick={() => {
                        history.goBack();
                    }}
                >
                    Back
                </Button>
                Case Number # {props.messagesUserCase?.id}
            </div>
            <div className="header-pending-case-details-titles header-pending-case-details-titles-text">
                {props.messagesUserCase?.service.name}
                <div style={{color: '#ff000091'}}>
                    {
                        props.messagesUserCase?.lawyer_name ? null : 'A lawyer has not yet been appointed'
                    }
                </div>
            </div>
            <div className="header-pending-case-details-titles">
                {props.messagesUserCase?.date_create_human}
            </div>
        </div>
    );
};

export default HeaderPendingCaseDetails;
