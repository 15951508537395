import { put, select, takeLatest } from 'redux-saga/effects';
import { ProfileActionCreators } from '../reducers/profile/action-creators';
import { UpdateProfile } from '../../apis/profile';
import {
    IUser2Auth,
    IUserChangePassword,
    IUserGlobal,
    IUserInfo,
} from '../../models/IUser';
import { RootState } from '..';
import {
    Change2Auth,
    ChangePassword,
    ProfileActionEnum,
    RequestChange2Auth,
    UpdateProfile as TUpdateProfile,
} from '../reducers/profile/types';
import { ISuccessResponse, IErrorResponse } from '../../models/IResponse';
import { UserActionCreators } from '../reducers/user/action-creators';
import { ICodes } from '../../models/ICodes';
import { notification } from 'antd';
import {
    IChangeTwoFactor,
    ITwoFactor,
    ITwoFactorRequest,
} from '../../models/IProfile';
import { AppActionCreators } from '../reducers/app/action-creators';
import { messages } from '../../constants';

export function* workerUpdateProfile({ payload }: TUpdateProfile): Generator {
    const user: IUser2Auth | any = yield select(
        (state: RootState) => state.user.user,
    );

    const sendingData: IUserInfo = {
        ...payload,
        user_id: user.user_id,
    };

    const response: ISuccessResponse<IUserInfo> | IErrorResponse | any =
        yield UpdateProfile(sendingData);

    if (response.status !== ICodes.OK) {
        yield put(AppActionCreators.setErrors(response.data.data));
        return;
    }

    notification.success({
        message: 'You have successfully updated your profile',
    });

    yield put(UserActionCreators.updateUser(response.data.data));
}

export function* workerRequestChange2Auth({
    payload,
}: RequestChange2Auth): Generator {
    const user: IUser2Auth | any = yield select(
        (state: RootState) => state.user.user,
    );

    const sendingData: IChangeTwoFactor<ITwoFactorRequest> = {
        two_factor: {
            ...payload,
        },
        user_id: user.user_id,
    };

    const response:
        | ISuccessResponse<{
              hash: string;
              status: number;
              user_id: number;
          }>
        | IErrorResponse
        | any = yield UpdateProfile(sendingData);

    if (response.status !== ICodes.OK) {
        return;
    }

    yield put(
        AppActionCreators.handleShowModal({
            title: messages.CHANGE_2FA_TITLE,
            description: messages.CHANGE_2FA_DES,
            image: response.data.data.qr_code_url,
        }),
    );
    yield put(ProfileActionCreators.setToken(response.data.data.hash));
}

export function* workerChange2Auth({ payload }: Change2Auth): Generator {
    const user: IUser2Auth | any = yield select(
        (state: RootState) => state.user.user,
    );

    const sendingData: IChangeTwoFactor<ITwoFactor> = {
        two_factor: {
            ...payload,
        },
        user_id: user.user_id,
    };

    yield put(AppActionCreators.handleHideModal(''));

    const response: ISuccessResponse<IUserGlobal> | IErrorResponse | any =
        yield UpdateProfile(sendingData);

    if (response.status !== ICodes.OK) {
        return;
    }

    yield put(UserActionCreators.updateUser(response.data.data));
    yield put(ProfileActionCreators.setToken(''));
}

export function* workerChangePassword({ payload }: ChangePassword): Generator {
    const user: IUser2Auth | any = yield select(
        (state: RootState) => state.user.user,
    );

    const sendingData: IUserChangePassword = {
        ...payload.data,
        user_id: user.user_id,
    };

    const response: ISuccessResponse<IUserInfo> | IErrorResponse | any =
        yield UpdateProfile(sendingData);

    if (response.status !== ICodes.OK) {
        yield put(AppActionCreators.setErrors(response.data.data));
        return;
    }

    notification.success({
        message: messages.SUCCESS_PASSWORD_CHANGE,
    });

    yield put(UserActionCreators.updateUser(response.data.data));
    payload.callback();
}

export function* watchProfile() {
    yield takeLatest(ProfileActionEnum.UPDATE_PROFILE, workerUpdateProfile);
    yield takeLatest(
        ProfileActionEnum.REQUEST_CHANGE_2_AUTH,
        workerRequestChange2Auth,
    );
    yield takeLatest(ProfileActionEnum.CHANGE_2_AUTH, workerChange2Auth);
    yield takeLatest(ProfileActionEnum.CHANGE_PASSWORD, workerChangePassword);
}
