const RegexpStrMastercard =
    '^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$';
const RegexpStrVisa = '^4[0-9]{12}(?:[0-9]{3})?$';
const RegexpStrAmex = '^3[47][0-9]{13}$';
const RegexpStrMaestro = '^(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}$';
const cardRegexp = new RegExp(
    `${RegexpStrMastercard}|${RegexpStrVisa}|${RegexpStrAmex}|${RegexpStrMaestro}`,
    'i',
);

export const Regulars = {
    password: {
        reg: /^.{8,}$/,
        text: 'At least 8 characters',
    },
    email: {
        reg: /^(?!^.{65})(([^<>()[\]\\.+\-,#%^&`?/()~<>={}|*$!;:\s@"]+(\.[^<> ()[\]\\.,#%^&`?/()~<>={}|*$!;:\s@"]+)*)|(".+"))@((\[[0-9 ]{1,3}\.[0-9 ]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        text: 'Email is invalid',
    },
    cardholder: {
        reg: /^(?!^.{49})^[a-zA-Z]+(([' -][a-zA-Z ])?[a-zA-Z]*)*$/,
        text: 'Cardholder is invalid',
    },
    cardNumber: {
        reg: cardRegexp,
        text: 'Card is invalid',
    },
    cvv: {
        reg: /^[0-9]{3,4}$/,
        text: 'CVV is invalid',
    },
    expirationDate: {
        reg: /^(0[1-9]|1[0-2])\/([0-9]{2})$/,
        text: 'Date is invalid',
    },
};

export const messages = {
    SHOW_MODAL_TITLE_EMAIL: 'We sent a code to your email address.',
    VERIFY_USER_ERROR: 'You must fill in all fields for verification',
    VERIFY_USER_PENDING: 'You are in pending status.',
    VERIFY_USER_SUCCESS: 'You are verified.',
    SHOW_MODAL_DES_EMAIL: 'This code is for verifying your email.',
    SHOW_MODAL_TITLE_2FA:
        'The login code was sent to you on the connected two-factor authentication',
    SHOW_MODAL_TITLE_2FA_RESET:
        'The reset code was sent to you on the connected two-factor authentication',
    SUCCESS_LOGIN: 'You have successfully logged into the Members Area.',
    SUCCESS_REGISTER: 'You have successfully registered in the cabinet',
    SUCCESS_PASSWORD_CHANGE: 'You have successfully changed your password',
    SUCCESS_PASSWORD_RESET_TITLE: 'Password Reset',
    SUCCESS_PASSWORD_RESET:
        'If this email address was used to create an account, instructions to reset your password will be sent to you. Please check your email.',
    LOGIN_VALIDATION_ERROR:
        'The account login was incorrect or wrong password has been typed. Please try again.',
    GOOGLE_2FA: 'Google authenticator is still in development',
    CHANGE_2FA_TITLE:
        'We sent you the code to connect two-factor authorization by email',
    CHANGE_2FA_DES:
        'When you enter the code, you will be able to enter the cabinet only with the code that we will send you to the email that you entered during registration',
    REMOVE_SESSIONS: 'You only have one active session',
    REMOVE_SESSIONS_SUCCESS:
        'All active sessions have been crossed except the current one',
    MEETING_ROOMS:
        'MEETING ROOMS service is available in all locations: VIB - BRATTLEBORO, VIB2 - DOWNTOWN BRATTLEBORO, VIB3 - DOWNTOWN BENNINGTON. This service is only available for long-term rentals. To arrange the Meeting rooms service, please fill out the form and send us your details.',
    PRIVATE_OFFICE:
        'PRIVATE OFFICE service is available in all locations: VIB - BRATTLEBORO, VIB2 - DOWNTOWN BRATTLEBORO, VIB3 - DOWNTOWN BENNINGTON. This service is only available for long-term rentals. To arrange the Private office service, please fill out the form and send us your details.',
    VIB2: 'In location VIB2 - DOWNTOWN BRATTLEBORO only Private Office service is available. This service is available only for long-term rentals. To arrange the service Private Office click on the button Send Request and we will contact you.',
};
