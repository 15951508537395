import { ObjectShape } from 'yup/lib/object';
import { IFieldFront } from '../../../models/IField';
import { ITwoFactor, ITwoFactorRequest } from '../../../models/IProfile';
import {
    ICompTransaction,
    IUserChangePassword,
    IUserInfo,
} from '../../../models/IUser';
import * as types from './types';

export const ProfileActionCreators = {
    setSecurityFields: (
        payload: types.IProfileFields | null,
    ): types.SetSecurityFields => ({
        type: types.ProfileActionEnum.SET_SECURITY_FIELDS,
        payload,
    }),
    setFields: (payload: IFieldFront[] | null): types.SetFields => ({
        type: types.ProfileActionEnum.SET_FIELDS,
        payload,
    }),
    setFieldsValues: (payload: IUserInfo | null): types.SetValues => ({
        type: types.ProfileActionEnum.SET_VALUES,
        payload,
    }),
    setFieldsValidation: (payload: ObjectShape): types.SetValidation => ({
        type: types.ProfileActionEnum.SET_VALIDATION,
        payload,
    }),
    updateProfile: (payload: IUserInfo): types.UpdateProfile => ({
        type: types.ProfileActionEnum.UPDATE_PROFILE,
        payload,
    }),
    setToken: (payload: string): types.SetToken => ({
        type: types.ProfileActionEnum.SET_TOKEN,
        payload,
    }),
    requestToChange2Auth: (
        payload: ITwoFactorRequest,
    ): types.RequestChange2Auth => ({
        type: types.ProfileActionEnum.REQUEST_CHANGE_2_AUTH,
        payload,
    }),
    change2Auth: (payload: ITwoFactor): types.Change2Auth => ({
        type: types.ProfileActionEnum.CHANGE_2_AUTH,
        payload,
    }),
    changePassword: (payload: {
        data: IUserChangePassword;
        callback: () => void;
    }): types.ChangePassword => ({
        type: types.ProfileActionEnum.CHANGE_PASSWORD,
        payload,
    }),
    fetchPaymentSystems: (): types.FetchPaymentSystems => ({
        type: types.ProfileActionEnum.FETCH_PAYMENT_SYSTEMS,
    }),
    setRepeatingTransaction: (
        payload: null | ICompTransaction,
    ): types.SetRepeatingTransaction => ({
        type: types.ProfileActionEnum.SET_REPEATING_TRANSACTION,
        payload,
    }),
};
