import React, { useState, useEffect } from 'react';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { GetPaymentsClose } from '../../../../apis/payments';
import { DataPaymentsClosedType } from '../../../../models/IPayments';
import { Table, Button } from 'antd';
import { Link } from 'react-router-dom';
import { DashboardRouteNames } from '../../../../router/Dashboard';
import preloaderImg from '../../../../assets/images/preloader.gif';
import moment from 'moment';

const ClosedPaymentsCustomer = () => {
    const { user } = useTypedSelector(state => state.user);
    const [dataPaymentsClosed, setDataPaymentsClosed] = useState<
        DataPaymentsClosedType[]
    >([]);
    const [selectedDataPaymnetsClosed, setSelectedDataPaymnetsClosed] =
        useState<any[]>([]);
    const [preloader, setPreloader] = useState<boolean>(false);

    useEffect(() => {
        setPreloader(true);
        if (user) {
            GetPaymentsClose(user.user_id).then(res => {
                setDataPaymentsClosed(res.data.data);
                setPreloader(false);
            });
        }
    }, []);

    useEffect(() => {
        let tempArrayForTable: any[] = [];
        let countForTable = 0;
        dataPaymentsClosed.forEach(item => {
            let tempObjForTable: any = {};
            if (item.invoiceTransaction) {
                tempObjForTable.key = ++countForTable;
                tempObjForTable.dateAndTime = item.date_close_human;
                tempObjForTable.amount = `${item.price} AED`;
                tempObjForTable.status = 'Approved';
                tempObjForTable.type = item.type;
                if (item.type === 'Registration payment') {
                    tempObjForTable.caseNumber = 'Registration fee';
                } else {
                    tempObjForTable.caseNumber = item.case_id;
                }
                tempObjForTable.caseName = item.name;
                tempObjForTable.paymentId =
                    item.invoiceTransaction[
                        item.invoiceTransaction.length - 1
                    ]?.hash;
                tempArrayForTable.push(tempObjForTable);
            } else {
                tempObjForTable.key = ++countForTable;
                tempObjForTable.dateAndTime = item.date_close_human;
                tempObjForTable.amount = `${item.price} AED`;
                tempObjForTable.status = 'Approved';
                tempObjForTable.type = item.type;
                tempObjForTable.caseNumber = item.case_id;
                tempObjForTable.caseName = item.service_name;
                tempObjForTable.paymentId = item.payment_id;
                tempArrayForTable.push(tempObjForTable);
            }
        });
        tempArrayForTable.sort(
            (a, b) =>
                moment(b.dateAndTime).unix() - moment(a.dateAndTime).unix(),
        );
        setSelectedDataPaymnetsClosed(tempArrayForTable);
    }, [dataPaymentsClosed]);

    const columns = [
        {
            title: 'Closing Date',
            dataIndex: 'dateAndTime',
            key: 'dateAndTime',
            className: 'table-date',
            align: 'center' as 'center',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center' as 'center',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            align: 'center' as 'center',
        },
        {
            title: 'Payment Title',
            dataIndex: 'caseNumber',
            key: 'caseNumber',
            id: 'key',
            align: 'center' as 'center',
            render: (caseNumber: number, key: any) => (
                <div>
                    <Link
                        to={{
                            pathname: `${DashboardRouteNames.PENDING_CASE_DETAILS}`,
                            state: { id: key },
                        }}
                    >
                        # {caseNumber}
                    </Link>
                    <div>{key.caseName}</div>
                </div>
            ),
        },
        {
            title: 'Payment ID',
            dataIndex: 'paymentId',
            key: 'paymentId',
            align: 'center' as 'center',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            align: 'center' as 'center',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            id: 'key',
            align: 'center' as 'center',
            render: () => (
                <div>
                    <div style={{ marginBottom: '10px' }}>
                        <Button>Print</Button>
                    </div>
                    <div>
                        <Button type="link">Download</Button>
                    </div>
                </div>
            ),
        },
    ];

    return (
        <div className="service-container">
            <div className="service__title">Closed Payments</div>
            <div className="pending-payments-customer-table">
                {preloader ? (
                    <div className="preloader-box-payments">
                        <img
                            className="preloader-img"
                            src={preloaderImg}
                            alt="Loading..."
                        />
                    </div>
                ) : (
                    <Table
                        columns={columns}
                        dataSource={
                            selectedDataPaymnetsClosed.length > 0
                                ? selectedDataPaymnetsClosed
                                : []
                        }
                    />
                )}
            </div>
        </div>
    );
};

export default ClosedPaymentsCustomer;
