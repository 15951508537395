import React from 'react';
import '../../styles/pendingCases.css';
import { NavLink } from 'react-router-dom';
import { Button } from 'antd';
import { DashboardRouteNames } from '../../../../../router/Dashboard';

const DocumentsPendingCaseDetails = (props: any) => {
    return (
        <div>
            <div
                className="card messages-pending-case-details"
                style={{ backgroundColor: '#ff000045' }}
            >
                <div className="messages-pending-case-details-payment-arrears-box">
                    <div className="messages-pending-case-details-payment-arrears-title">
                        Payment arrears
                    </div>
                    <div className="messages-pending-case-details-payment-arrears-price">
                        {props.messagesUserCase?.invoice
                            ? `${props.messagesUserCase.invoice.price} AED`
                            : `You aren't in arrears`}
                    </div>
                    <div className="messages-pending-case-details-payment-arrears-description">
                        {props.messagesUserCase?.invoice?.description
                            ? props.messagesUserCase?.invoice?.description
                            : 'No description'}
                    </div>
                    <div className="messages-pending-case-details-payment-arrears-pay">
                        <Button disabled={!props.messagesUserCase?.invoice}>
                            <NavLink
                                to={{
                                    pathname: `${DashboardRouteNames.INVOICE_PAY_PENDING_CASE}`,
                                    state: {
                                        id: props.messagesUserCase?.invoice?.id,
                                    },
                                }}
                            >
                                Ability to pay
                            </NavLink>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DocumentsPendingCaseDetails;
