import React, { useState, useEffect } from 'react';
import { Modal, Button, Table } from 'antd';
import { NavLink } from 'react-router-dom';
import { DashboardRouteNames } from '../../../../../router/Dashboard';
import moment from 'moment';

const PendingPaymentsCustomerModal = (props: any) => {
    const [dataForTableModal, setDataForTableModal] = useState<any[]>([]);

    useEffect(() => {
        let tempArrayForTableModal: any[] = [];
        let countForTable = 0;
        props.selectedDataForModal?.invoiceTransaction?.forEach((item: any) => {
            let tempObjForTableModal: any = {};
            tempObjForTableModal.key = ++countForTable;
            tempObjForTableModal.paymentId = item.hash;
            tempObjForTableModal.dateAndTime = item.created_date;
            if (item.status === 0) {
                tempObjForTableModal.status = 'Pending';
            } else if (item.status === 2) {
                tempObjForTableModal.status = 'Decline';
            }
            tempObjForTableModal.description =
                props.selectedDataForModal.description;
            tempObjForTableModal.caseNumber =
                props.selectedDataForModal.caseNumber;
            tempObjForTableModal.amount = props.selectedDataForModal.amount;
            tempObjForTableModal.invoiceId = item.invoice_id;
            tempObjForTableModal.pay = 'Pay';
            tempArrayForTableModal.push(tempObjForTableModal);
        });
        tempArrayForTableModal.sort(
            (a, b) =>
                moment(b.dateAndTime).unix() - moment(a.dateAndTime).unix(),
        );
        setDataForTableModal(tempArrayForTableModal);
    }, [props.selectedDataForModal]);

    const columns = [
        {
            title: 'Payment ID',
            dataIndex: 'paymentId',
            key: 'paymentId',
            className: 'table-payment-id',
            align: 'center' as 'center',
        },
        {
            title: 'Opening Date',
            dataIndex: 'dateAndTime',
            key: 'dateAndTime',
            className: 'table-date',
            align: 'center' as 'center',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center' as 'center',
        },
        {
            title: 'Description Case',
            dataIndex: 'description',
            key: 'description',
            align: 'center' as 'center',
            className: 'table-description',
            ellipsis: true,
        },
        {
            title: 'Payment title',
            dataIndex: 'caseNumber',
            key: 'caseNumber',
            className: 'table-payments-title',
            align: 'center' as 'center',
            id: 'key',
            render: (caseNumber: number, key: any) => (
                <div>
                    <div># {caseNumber}</div>
                    <div>{props.selectedDataForModal.nameCase}</div>
                </div>
            ),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            align: 'center' as 'center',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            id: 'key',
            align: 'center' as 'center',
            render: () => (
                <div>
                    <div style={{ marginBottom: '10px' }}>
                        <Button>Print</Button>
                    </div>
                    <div>
                        <Button type="link">Download</Button>
                    </div>
                </div>
            ),
        },
        {
            title: 'Pay',
            dataIndex: 'pay',
            key: 'pay',
            id: 'key',
            align: 'center' as 'center',
            render: (pay: string, key: any) => (
                <Button type="primary">
                    <NavLink
                        to={{
                            pathname: `${DashboardRouteNames.INVOICE_PAY_PENDING_CASE}`,
                            state: {
                                id: key.invoiceId,
                            },
                        }}
                    >
                        {pay}
                    </NavLink>
                </Button>
            ),
        },
    ];

    return (
        <div>
            <Modal
                visible={props.showModal}
                title="Payment View"
                onCancel={props.onCloseModal}
                width={'90%'}
                footer={[
                    <Button key="Back" danger onClick={props.onCloseModal}>
                        Back
                    </Button>,
                ]}
            >
                {props.selectedDataForModal?.invoiceTransaction.length > 0 ? (
                    <Table
                        columns={columns}
                        dataSource={
                            dataForTableModal.length > 0
                                ? dataForTableModal
                                : []
                        }
                    />
                ) : (
                    <div className="modal-closed-case-title">
                        You have not had transactions for this case yet!
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default PendingPaymentsCustomerModal;
