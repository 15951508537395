import React, { useState, useEffect } from 'react';
import { GetArchiveLawyerCase } from '../../../../apis/archiveLawyer';
import { Table, Button, Image } from 'antd';
import { DataSelectedArhiveCaseType } from '../../../../models/IArchiveCase';
import customerAvatarDefault from '../../../../assets/images/avatars/01.png';
import { Link } from 'react-router-dom';
import { DashboardRouteNames } from '../../../../router/Dashboard';
import preloaderImg from '../../../../assets/images/preloader.gif';
import moment from 'moment';

const ArchiveCasesLawyerDetails = (props: any) => {
    const [dataSelectedCase, setDataSelectedCase] = useState<
        DataSelectedArhiveCaseType[]
    >([]);
    const [dataSelectedCaseForTable, setDataSelectedCaseForTable] = useState<
        any[]
    >([]);
    const [preloader, setPreloader] = useState<boolean>(false);

    useEffect(() => {
        window.onbeforeunload = () => {
            window.sessionStorage.setItem(
                'idForSelectedArchiveCaseLawyerDetails',
                JSON.stringify(props.location?.state.key),
            );
            window.sessionStorage.setItem(
                'lastRoute',
                JSON.stringify(window.location.pathname),
            );
        };
    }, []);

    useEffect(() => {
        setPreloader(true);
        GetArchiveLawyerCase(
            props.location?.state?.key
                ? props.location?.state?.key
                : JSON.parse(
                      window.sessionStorage.getItem(
                          'idForSelectedArchiveCaseLawyerDetails',
                      ) || '{}',
                  ),
        ).then(value => {
            setDataSelectedCase(value.data);
            setPreloader(false);
        });
    }, []);

    useEffect(() => {
        const tempArrayArchiveCase: any[] = [];
        dataSelectedCase.forEach(item => {
            const tempObjArchiveCase: any = {};
            tempObjArchiveCase.key = item.id;
            tempObjArchiveCase.caseNumberArchive = `# ${item.id}`;
            tempObjArchiveCase.dateAndTime = item.date_close_human;
            tempObjArchiveCase.caseName = item.service.name;
            if (item.user_photo) {
                tempObjArchiveCase.avatarCustomer = item.user_photo;
            } else {
                tempObjArchiveCase.avatarCustomer = customerAvatarDefault;
            }
            tempObjArchiveCase.nameCustomer = item.user_name;
            tempObjArchiveCase.description = item.close_description;
            tempObjArchiveCase.view = 'View';
            tempArrayArchiveCase.push(tempObjArchiveCase);
        });
        tempArrayArchiveCase.sort(
            (a, b) =>
                moment(b.dateAndTime).unix() - moment(a.dateAndTime).unix(),
        );
        setDataSelectedCaseForTable(tempArrayArchiveCase);
    }, [dataSelectedCase]);

    const columns = [
        {
            title: 'Case Number',
            dataIndex: 'caseNumberArchive',
            key: 'caseNumberArchive',
            align: 'center' as 'center',
        },
        {
            title: 'Closing Date',
            dataIndex: 'dateAndTime',
            key: 'dateAndTime',
            className: 'table-date',
            align: 'center' as 'center',
        },
        {
            title: 'Service',
            dataIndex: 'caseName',
            key: 'caseName',
            align: 'center' as 'center',
        },
        {
            title: 'Customer',
            dataIndex: 'avatarCustomer',
            key: 'avatarCustomer',
            align: 'center' as 'center',
            id: 'key',
            render: (avatarCustomer: string, key: any) => (
                <div>
                    <Image width={50} src={avatarCustomer} />
                    <div style={{ marginTop: '10px' }}>{key.nameCustomer}</div>
                </div>
            ),
        },
        {
            title: 'Short Description',
            dataIndex: 'description',
            key: 'description',
            align: 'center' as 'center',
            ellipsis: true,
            className: 'documents-table-pending-case-details-customer-short',
        },
        {
            title: 'View',
            dataIndex: 'view',
            key: 'view',
            id: 'key',
            align: 'center' as 'center',
            render: (view: string, key: any) => (
                <div>
                    <Button danger>
                        <Link
                            to={{
                                pathname: `${DashboardRouteNames.PENDING_OPEN_DETAILS_CUSTOMER}`,
                                state: { id: key.key },
                            }}
                        >
                            {view}
                        </Link>
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <div className="service-container service-page">
            <div className="service__title">Archive Cases</div>
            <div className="archive-cases-table">
                {preloader ? (
                    <div className="preloader-box">
                        <img
                            className="preloader-img"
                            src={preloaderImg}
                            alt="Loading..."
                        />
                    </div>
                ) : (
                    <Table
                        columns={columns}
                        dataSource={
                            dataSelectedCaseForTable.length > 0
                                ? dataSelectedCaseForTable
                                : []
                        }
                    />
                )}
            </div>
        </div>
    );
};

export default ArchiveCasesLawyerDetails;
