import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { GetOpenDetailsCase } from '../../../../apis/pendingCasesLawyer';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import HeaderPendingCaseLawyerDetails from './HeaderPendingCaseLawyerDetails/HeaderPendingCaseLawyerDetails';
import {
    IHistoryMessagesPending,
    //ILocationState,
} from '../../../../models/IPendingCaseLawyer';
import DocumentsPendingCase from './DocumentsPendingCase/DocumentsPendingCase';
import HistoryMessagePendingCase from './HistoryMessagePendingCase/HistoryMessagePendingCase';
import DocumentsTablePendingCaseDetailsLawyer from './DocumentsTablePendingCaseDetailsLawyer/DocumentsTablePendingCaseDetailsLawyer';
import PaymentsInCase from './PaymentsInCase/PaymentsInCase';
import CloseCase from './CloseCase/CloseCase';
import EventCaledar from './EventCalendar/EventCalendar';
import { IMessage, useSubscription } from 'mqtt-react-hooks';
import { notification } from 'antd';
import preloaderImg from '../../../../assets/images/preloader.gif';
import ArchiveInformationLawyer from './ArchiveInformationLawyer/ArchiveInformationLawyer';

const OpenDetailsCustomer = () => {
    const { user } = useTypedSelector(state => state.user);
    const [actionMessageMqtt, setActionMessageMqtt] = useState<
        IMessage | undefined
    >(undefined);
    const location = useLocation<any>();
    const [data, setData] = useState<IHistoryMessagesPending[]>([]);
    const [reloadAfterSendMessage, setReloadAfterSendMessage] =
        useState<boolean>(false);
    const [reloadTablePayment, setReloadTablePayment] =
        useState<boolean>(false);
    const [invoicePayment, setInvoicePayment] = useState<boolean>(false);
    const [preloader, setPreloader] = useState<boolean>(false);
    const [idForRequest, ,] = useState<number>(
        location.state?.key
            ? location.state?.key
            : location.state?.id
            ? location.state?.id
            : JSON.parse(
                  window.sessionStorage.getItem('idForOpenDetailsCustomer') ||
                      '{}',
              ),
    );

    useEffect(() => {
        window.onbeforeunload = () => {
            window.sessionStorage.setItem(
                'idForOpenDetailsCustomer',
                JSON.stringify(idForRequest),
            );
            window.sessionStorage.setItem(
                'lastRoute',
                JSON.stringify(window.location.pathname),
            );
        };
    }, []);

    useEffect(() => {
        if (data.length === 0) {
            setPreloader(true);
            window.scrollTo(0, 0);
            GetOpenDetailsCase(
                idForRequest
                    ? idForRequest
                    : JSON.parse(
                          window.sessionStorage.getItem(
                              'idForOpenDetailsCustomer',
                          ) || '{}',
                      ),
            ).then(({ data }) => {
                setData(data);
                setPreloader(false);
            });
        } else {
            GetOpenDetailsCase(
                idForRequest
                    ? idForRequest
                    : JSON.parse(
                          window.sessionStorage.getItem(
                              'idForOpenDetailsCustomer',
                          ) || '{}',
                      ),
            ).then(({ data }) => {
                setData(data);
            });
        }
    }, [reloadAfterSendMessage]);

    const messageMqtt = useSubscription([
        `${process.env.REACT_APP_ENVIRONMENT}/topic/user/${user?.user_id}`,
    ]);
    let messageMqttJSON: any = actionMessageMqtt?.message;
    let objSubscriptionMqtt = messageMqttJSON && JSON.parse(messageMqttJSON);

    useEffect(() => {
        setActionMessageMqtt(messageMqtt?.message);
    }, [messageMqtt?.message]);

    useEffect(() => {
        if (
            objSubscriptionMqtt?.action === 'add-message' &&
            objSubscriptionMqtt?.case_id === Number(idForRequest) &&
            location.pathname === '/dashboard/new-cases-lawyer/open-details'
        ) {
            notification.success({
                message: `${objSubscriptionMqtt.message.split(' ')[0]} ${
                    objSubscriptionMqtt.message.split(' ')[1]
                }: a new message!`,
            });
            onReloadTableAfterSend();
            setActionMessageMqtt(undefined);
        } else if (
            objSubscriptionMqtt?.action === 'add-message' &&
            objSubscriptionMqtt?.case_id !== Number(idForRequest) &&
            location.pathname === '/dashboard/new-cases-lawyer/open-details'
        ) {
            notification.success({
                message: `${objSubscriptionMqtt.message.split(' ')[0]} ${
                    objSubscriptionMqtt.message.split(' ')[1]
                }: a new message!`,
            });
            setActionMessageMqtt(undefined);
        } else if (
            objSubscriptionMqtt?.action === 'pay-invoice' &&
            Number(idForRequest) &&
            location.pathname === '/dashboard/new-cases-lawyer/open-details'
        ) {
            setReloadTablePayment(!reloadTablePayment);
            setActionMessageMqtt(undefined);
        } else {
            return;
        }
    }, [objSubscriptionMqtt]);

    const onReloadTableAfterSend = () => {
        setReloadAfterSendMessage(!reloadAfterSendMessage);
    };

    const onReloadTablePayment = () => {
        setReloadTablePayment(!reloadTablePayment);
    };

    const onShowModalInvoice = () => {
        setInvoicePayment(!invoicePayment);
    };

    if (preloader) {
        return (
            <div className="preloader-box">
                <img
                    className="preloader-img"
                    src={preloaderImg}
                    alt="Loading..."
                />
            </div>
        );
    }

    return (
        <div className="service-container">
            <HeaderPendingCaseLawyerDetails
                data={data}
                onReloadTableAfterSend={onReloadTableAfterSend}
            />
            {data[0]?.status === 3 || data[0]?.status === 4 ? (
                <ArchiveInformationLawyer data={data[0]} />
            ) : null}
            <DocumentsPendingCase
                data={data}
                onShowModalInvoice={onShowModalInvoice}
            />
            <EventCaledar
                events={data[0]?.events}
                id={data[0]?.id}
                status={data[0]?.status}
                onReloadTableAfterSend={onReloadTableAfterSend}
            />
            <DocumentsTablePendingCaseDetailsLawyer
                documentsUserCase={data}
                data={data}
                onReloadTableAfterSend={onReloadTableAfterSend}
            />
            <HistoryMessagePendingCase
                data={data}
                onReloadTableAfterSend={onReloadTableAfterSend}
                invoicePayment={invoicePayment}
                onShowModalInvoice={onShowModalInvoice}
                onReloadTablePayment={onReloadTablePayment}
            />
            <PaymentsInCase
                customerId={data[0]?.user_id}
                caseNumber={data[0]?.id}
                messageMqttMessage={messageMqtt?.message}
                reloadTablePayment={reloadTablePayment}
            />
            {data[0]?.status === 2 ? <CloseCase data={data[0]} /> : null}
        </div>
    );
};

export default OpenDetailsCustomer;
