import React from 'react';
import { Modal, Upload, Button, Table } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DeleteOutlined } from '@ant-design/icons';

const OpenCaseCustomerDescriptionModal = (props: any) => {
    const columns = [
        {
            title: 'Uploaded File',
            dataIndex: 'uploadedFile',
            key: 'uploadedFile',
            align: 'center' as 'center',
            ellipsis: true,
        },
        {
            title: props.showCyrillicDocuments ? (
                <div
                    style={{ color: '#f5222d' }}
                >{`Description \n (enter only latin characters)`}</div>
            ) : (
                <div>Description</div>
            ),
            align: 'center' as 'center',
            className: 'documents-table-description',
            render: (item: any) => (
                <div>
                    <input
                        id={item.id}
                        className="card-upload-box-input-document-description"
                        type="text"
                        maxLength={256}
                        placeholder="complete description"
                        onChange={event => {
                            props.onDescriptionDocuments(event, item);
                        }}
                    />
                </div>
            ),
        },
        {
            title: 'Delete file',
            dataIndex: 'deleteFile',
            key: 'deleteFile',
            align: 'center' as 'center',
            ellipsis: true,
            render: (deleteFile: any, id: any) => (
                <span>
                    <DeleteOutlined
                        className="table-remove"
                        onClick={() => {
                            props.propsUpload.onRemove(deleteFile);
                        }}
                    />
                </span>
            ),
        },
    ];

    return (
        <div>
            <Modal
                title={'Case Request ' + props.nameSelectedCase}
                width="50%"
                visible={props.isModalDescriprionVisible}
                onOk={props.handleDescriptionModalOk}
                onCancel={props.showConfirm}
                okText="Click here to pay"
                okButtonProps={{
                    style: {
                        backgroundColor: '#B99972',
                        color: '#fff',
                        fontSize: '16px',
                        border: 'none',
                    },
                }}
                cancelButtonProps={{
                    style: {
                        backgroundColor: 'transparent',
                        color: '#B99972',
                        fontSize: '16px',
                        border: '1px solid #B99972',
                        borderRadius: '2px',
                    },
                }}
                cancelText="Close"
            >
                <div className="card-title">How can we help you?</div>
                <textarea
                    className={
                        props.emptyInput
                            ? 'card-textarea-error'
                            : 'card-textarea'
                    }
                    placeholder="example..."
                    value={props.descriptionValue}
                    onChange={props.onDescription}
                ></textarea>
                {
                    <div
                        className={
                            props.showCyrillic
                                ? 'card-upload-box-input-message-error'
                                : 'card-upload-box-input-message'
                        }
                    >
                        Enter only latin characters
                    </div>
                }
                <div>
                    <Upload {...props.propsUpload} showUploadList={false}>
                        <Button type="primary" icon={<UploadOutlined />}>
                            Add Additional Documents, if needed.
                        </Button>
                    </Upload>
                    <div style={{ marginTop: '15px' }}>
                        {props.valueForTable.length > 0 ? (
                            <Table
                                columns={columns}
                                dataSource={
                                    props.valueForTable.length > 0
                                        ? props.valueForTable
                                        : []
                                }
                            />
                        ) : null}
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default OpenCaseCustomerDescriptionModal;
