import React, { useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import '../../styles/pendingCases.css';
import { TempObjMessage } from '../../../../../models/IPendingCases';
import { PostOpenMessage } from '../../../../../apis/pendingCases';
import MessageModalPendingCase from './MessageModalPendingCase/MessageModalPendingCase';
import WriteLawyerModalPendingCase from './WriteLawyerModalPendingCase/WriteLawyerModalPendingCase';

const MessagesPendingCaseDetails = (props: any) => {
    const [dataMessages, setDataMessages] = useState<any | null>(null);
    const [dataMessagesForTable, setDataMessagesForTable] = useState<
        TempObjMessage[]
    >([]);
    const [showMessageModal, setShowMessageModal] = useState<boolean>(false);
    const [showWriteLawyerModal, setShowWriteLawyerModal] =
        useState<boolean>(false);
    const [dataForMessageModal, setDataForMessageModal] =
        useState<TempObjMessage>();
    const [resetDataDocumentsInMessage, setResetDataDocumentsInMessage] =
        useState<boolean>(false);
    const [roleWriteToLawyer, setRoleWriteToLawyer] = useState<number>(0);

    useEffect(() => {
        setDataMessages(props.messagesUserCase);
    }, [props.messagesUserCase]);

    useEffect(() => {
        let tempDataMessages: TempObjMessage[] = [];
        let tempNumberMessages: number = 0;
        if (dataMessages !== null) {
            tempNumberMessages = dataMessages?.messages?.length + 1;
        }
        if (dataMessages !== null) {
            dataMessages?.messages?.forEach((item: any) => {
                let tempObj: TempObjMessage = {
                    id: 0,
                    key: 0,
                    number: 0,
                    sent: '',
                    title: '',
                    description: '',
                    descriptionFull: '',
                    date: '',
                    name: '',
                    open: '',
                    documents: null,
                    attachedDocuments: false,
                    viewed: 0,
                };
                tempObj.id = item.id;
                tempObj.key = tempNumberMessages--;
                tempObj.number = tempNumberMessages;
                tempObj.title = String(item.title);
                if (item.documents.length === 0) {
                    tempObj.attachedDocuments = false;
                } else {
                    tempObj.attachedDocuments = true;
                }
                tempObj.description = String(item.content);
                tempObj.descriptionFull = String(item.content);
                tempObj.date = item.date_created_human;
                if (!dataMessages.lawyer_name) {
                    tempObj.name = 'A lawyer has not yet been appointed';
                } else {
                    tempObj.name = dataMessages.lawyer_name;
                }
                if (dataMessages.user_id === item.id_created) {
                    tempObj.sent = 'You';
                } else {
                    tempObj.sent = 'Lawyer';
                }
                tempObj.open = 'Open';
                tempObj.documents = item.documents;
                tempObj.viewed = item.viewed;
                tempDataMessages.push(tempObj);
            });
        }
        setDataMessagesForTable(tempDataMessages);
    }, [dataMessages]);

    const columns = [
        {
            title: 'Number',
            dataIndex: 'number',
            align: 'center' as 'center',
            className: 'table-number-history-message',
        },
        {
            title: 'Sent',
            dataIndex: 'sent',
            align: 'center' as 'center',
            ellipsis: true,
            className: 'table-sent-history-message',
        },
        {
            title: 'Attached',
            dataIndex: 'attachedDocuments',
            align: 'center' as 'center',
            render: (attachedDocuments: boolean) =>
                attachedDocuments === true ? <PaperClipOutlined /> : null,
            className: 'table-attached-history-message',
        },
        {
            title: 'Title message',
            dataIndex: 'title',
            align: 'center' as 'center',
            className: 'table-title-message-history-message',
            ellipsis: true,
        },
        {
            title: 'Short description',
            dataIndex: 'description',
            align: 'center' as 'center',
            ellipsis: true,
            className: 'table-description-history-message',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            align: 'center' as 'center',
            className: 'table-date-history-message',
            ellipsis: true,
        },
        {
            title: 'Name lawyer',
            dataIndex: 'name',
            align: 'center' as 'center',
            className: 'table-name-customer-history-message',
            ellipsis: true,
        },
        {
            title: 'Open',
            dataIndex: 'open',
            id: 'key',
            render: (text: any, key: any) => (
                <Button onClick={() => onShowMessageModal(key)} danger>
                    {text}
                </Button>
            ),
            align: 'center' as 'center',
            className: 'table-button-history-message',
        },
    ];

    const onShowMessageModal = (key: any) => {
        if (key.viewed === 0) {
            PostOpenMessage(key.id)
                .then(res => {
                    console.log(res);
                })
                .then(() => {
                    props.onReloadTableAfterSend();
                });
        }
        setDataForMessageModal(key);
        setShowMessageModal(true);
    };

    const onCloseMessageModal = () => {
        setShowMessageModal(false);
    };

    const onShowWriteLawyerModal = (num: number) => {
        setRoleWriteToLawyer(num);
        setShowWriteLawyerModal(true);
        setShowMessageModal(false);
    };

    return (
        <div className="messages-pending-case-details card">
            <div className="messages-pending-case-details-table-box">
                <div className="messages-pending-case-details-table-title">
                    History message
                </div>
                <div className="messages-pending-case-details-table-body">
                    <Table
                        columns={columns}
                        dataSource={dataMessages ? dataMessagesForTable : []}
                        rowClassName={(record: any, index: any) => {
                            if (
                                record.viewed === 0 &&
                                record.sent === 'Lawyer'
                            ) {
                                return 'message-viewed';
                            }
                            return '';
                        }}
                        bordered
                    />
                </div>
            </div>
            {props.messagesUserCase?.status === 2 ? (
                <div className="messages-pending-case-details-payment-arrears-box">
                    <div className="messages-pending-case-details-payment-arrears-write">
                        <Button
                            onClick={() => {
                                onShowWriteLawyerModal(1);
                            }}
                            type="primary"
                        >
                            Write to a lawyer
                        </Button>
                    </div>
                </div>
            ) : null}

            <MessageModalPendingCase
                showMessageModal={showMessageModal}
                onCloseMessageModal={onCloseMessageModal}
                onShowWriteLawyerModal={onShowWriteLawyerModal}
                dataForMessageModal={dataForMessageModal}
                caseNumber={props.data?.caseNumber}
                caseStatus={props.messagesUserCase?.status}
            />
            <WriteLawyerModalPendingCase
                showWriteLawyerModal={showWriteLawyerModal}
                setShowWriteLawyerModal={setShowWriteLawyerModal}
                setShowMessageModal={setShowMessageModal}
                setResetDataDocumentsInMessage={setResetDataDocumentsInMessage}
                chatId={props.chatId}
                userId={props.userId}
                messageNumber={dataMessagesForTable.length}
                onReloadTableAfterSend={props.onReloadTableAfterSend}
                resetDataDocumentsInMessage={resetDataDocumentsInMessage}
                roleWriteToLawyer={roleWriteToLawyer}
                messagesTitle={dataForMessageModal?.title}
                messagesTitleNumber={dataForMessageModal?.number}
                setRoleWriteToLawyer={setRoleWriteToLawyer}
            />
        </div>
    );
};

export default MessagesPendingCaseDetails;
