import { Axios } from '../plugins/helpers';
import { notificationError } from '../plugins/requestNotification';

export const GetPaymentsMessage = async (id: number) => {
    try {
        const response = await Axios().get(
            `${process.env.REACT_APP_BASE_URL}/get-messages/type/${id}`,
        );
        return response;
    } catch (e: any) {
        notificationError(e);
        return e?.response;
    }
};
