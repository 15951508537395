import React from 'react';



const MessageCenterLawyer = () => {
    return(
        <div>
            MessageCenterLawyer
        </div>
    );
}

export default MessageCenterLawyer;