import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';

import { NavLink } from 'react-router-dom';

import { notificationError } from '../../../plugins/requestNotification';
import { GetLawyerCase } from '../../../apis/user';

import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { DashboardRouteNames } from '../../../router/Dashboard';

import { ICause } from '../../../models/IOpenCase';

import pendingCasesService from '../../../helpers/menuServicePendingCaseLawyer';
import { IServicesPendingCase } from '../../../models/IPendingCaseLawyer';
import preloaderImg from '../../../assets/images/preloader.gif';
import { useSubscription } from 'mqtt-react-hooks';

const OpenCase = () => {
    const { user } = useTypedSelector(state => state.user);
    const userRoleId: number | undefined = useTypedSelector(
        state => state.user.user?.role?.id,
    );
    const [preloader, setPreloader] = useState<boolean>(false);
    useEffect(() => {
        window.onbeforeunload = () => {
            window.sessionStorage.setItem(
                'lastRoute',
                JSON.stringify(window.location.pathname),
            );
        };
    }, []);

    // logic open case lawyer         //
    const [paymentCases, setPaymentCases] = useState([]);

    const messageMqtt = useSubscription([
        `${process.env.REACT_APP_ENVIRONMENT}/topic/user/${user?.user_id}`,
    ]);


    useEffect(() => {
        fetchLawyerCase();
    }, [messageMqtt?.message]);

    const fetchLawyerCase = async () => {
        if (paymentCases.length === 0) {
            setPreloader(true);
            try {
                const { data } = await GetLawyerCase();
                setPaymentCases(data.data);
                setPreloader(false);
            } catch (e) {
                notificationError(e);
            }
        } else {
            let messageMqttJSON: any = messageMqtt?.message;
            let objSubscriptionMqtt =
                messageMqttJSON && JSON.parse(messageMqttJSON?.message);
            if (
                objSubscriptionMqtt?.action === 'close-case' ||
                objSubscriptionMqtt?.action === 'take-to-work' ||
                objSubscriptionMqtt?.action === 'open-case' ||
                objSubscriptionMqtt?.action === 'lawyer-has-work-case' ||
                objSubscriptionMqtt?.action === 'lawyer-has-close-case'
            ) {
                try {
                    const { data } = await GetLawyerCase();
                    setPaymentCases(data.data);
                } catch (e) {
                    notificationError(e);
                }
            }
        }
    };

    if (preloader) {
        return (
            <div className="preloader-box">
                <img
                    className="preloader-img"
                    src={preloaderImg}
                    alt="Loading..."
                />
            </div>
        );
    }

    if (userRoleId === 2) {
        return (
            <div className="service-container service-page">
                <div className="service__title">Pending Cases</div>
                <Row className="justify-content-md-center row">
                    {pendingCasesService.map(
                        (service: IServicesPendingCase) => (
                            <Col key={service.name} sm="3">
                                <Card className="br-8">
                                    <NavLink
                                        to={{
                                            pathname: `${DashboardRouteNames.SELECTED_OPEN_CASE_LAWYER}`,
                                            state: {
                                                id: service.id,
                                            },
                                        }}
                                        className="service-block"
                                    >
                                        <Card.Body className="p-10 d-flex justify-content-center align-items-center flex-column">
                                            {paymentCases
                                                .filter(
                                                    (item: ICause) =>
                                                        item.id === service.id,
                                                )
                                                .map((i: ICause) => (
                                                    <div
                                                        key={i.id}
                                                        className="service-block__count"
                                                    >
                                                        {i.count_cases}
                                                    </div>
                                                ))}
                                            {service.icon}
                                            <div className="service-block__title">
                                                {service.name}
                                            </div>
                                        </Card.Body>
                                    </NavLink>
                                </Card>
                            </Col>
                        ),
                    )}
                </Row>
            </div>
        );
    } else if (userRoleId === 3) {
        return null;
    } else {
        return null;
    }
};

export default OpenCase;
