import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { message, Upload, Modal, notification } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { IServicesPendingCase } from '../../../models/IPendingCaseLawyer';
import pendingCasesService from '../../../helpers/menuServicePendingCaseLawyer';
import CreateCaseLawyerDescriptionModal from './CreateCaseLawyerDescriptionModal/CreateCaseLawyerDescriptionModal';
import {
    PostCreateCaseWithDocuments,
    PostCreateCaseWithOutDocuments,
} from '../../../apis/createCaseLawyer';
import { DashboardRouteNames } from '../../../router/Dashboard';

const CreateCaseLawyer = () => {
    const userRoleId: number | undefined = useTypedSelector(
        state => state.user.user?.role?.id,
    );
    const { confirm } = Modal;
    let history = useHistory();
    const cyrillicPattern = /[\u0400-\u04FF]/;
    const NAME_REGEXP = /^[a-zA-Z'`\s*\-]{2,24}$/;
    const [nameSelectedCase, setNameSelectedCase] = useState<string>('');
    const [idSelectedCase, setIdSelectedCase] = useState<number>(0);
    const [isModalDescriprionVisible, setIsModalDescriprionVisible] =
        useState<boolean>(false);
    const [documentsMessageValue, setDocumentsMessageValue] =
        useState<DocumentType | null>(null);
    const [description, setDescription] = useState<string>('');
    const [descriptionValue, setDescriptionValue] = useState<string>('');
    const [emptyInput, setEmptyInput] = useState<boolean>(false);
    const [emptyInputFirstName, setEmptyInputFirstName] =
        useState<boolean>(false);
    const [emptyInputLastName, setEmptyInputLastName] =
        useState<boolean>(false);
    const [errorFirstName, setErrorFirstName] = useState<boolean>(false);
    const [errorLastName, setErrorLastName] = useState<boolean>(false);
    const [firstName, setFirstName] = useState<string>('');
    const [firstNameValue, setFirstNameValue] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [lastNameValue, setLastNameValue] = useState<string>('');
    const [documentsFileList, setDocumentsFileList] = useState<any[]>([]);
    const [imageDocumentArray, setImageDocumentArray] = useState<any[]>([]);
    const [valueForTable, setValueForTable] = useState<any[]>([]);
    const [showCyrillic, setShowCyrillic] = useState<boolean>(false);
    const [showCyrillicFirstName, setShowCyrillicFirstName] =
        useState<boolean>(false);
    const [showCyrillicLastName, setShowCyrillicLastName] =
        useState<boolean>(false);
    const [showCyrillicDocuments, setShowCyrillicDocuments] =
        useState<boolean>(false);

    useEffect(() => {
        window.onbeforeunload = () => {
            window.sessionStorage.setItem(
                'lastRoute',
                JSON.stringify(window.location.pathname),
            );
        };
    }, []);

    useEffect(() => {
        let newObjDocuments: any = {};
        let count = 0;
        imageDocumentArray.forEach(item => {
            for (let value of Object.values(item)) {
                newObjDocuments[String(count++)] = value;
            }
        });
        setDocumentsMessageValue(newObjDocuments);
    }, [imageDocumentArray]);

    useEffect(() => {
        let tempArrayDocumentsSend: any = [];
        valueForTable?.forEach((item: any, index: number) => {
            if (cyrillicPattern.test(item.description)) {
                setShowCyrillicDocuments(true);
            }
            let tempObjDocumentsSend: any = {};
            tempObjDocumentsSend[String(index)] = {
                url: item.urlFile,
                description: item.description,
            };
            tempArrayDocumentsSend.push(tempObjDocumentsSend);
        });
        setImageDocumentArray(tempArrayDocumentsSend);
    }, [valueForTable]);

    useEffect(() => {
        let tempArrayValueForTable: any[] = [];
        documentsFileList.forEach((item: any) => {
            if (!item.response.code) {
                let tempObjValueForTable: any = {};
                tempObjValueForTable.key = item.uid;
                tempObjValueForTable.id = item.uid;
                tempObjValueForTable.uploadedFile = item.name;
                tempObjValueForTable.urlFile = item.response.data[0];
                if (item.description) {
                    tempObjValueForTable.description = item.description;
                } else {
                    tempObjValueForTable.description = '';
                }
                tempObjValueForTable.deleteFile = item;
                tempArrayValueForTable.push(tempObjValueForTable);
            }
        });
        setValueForTable(tempArrayValueForTable);
    }, [documentsFileList]);

    const onShowDescriptionModal = (value: IServicesPendingCase) => {
        setNameSelectedCase(value.name);
        setIdSelectedCase(value.id);
        setIsModalDescriprionVisible(true);
    };

    const onDescription = (event: any): void => {
        setDescription(event.target.value);
        setDescriptionValue(event.target.value);
        if (cyrillicPattern.test(event.target.value)) {
            setShowCyrillic(true);
        } else {
            setShowCyrillic(false);
        }
    };

    const validateName = (value: string) => {
        return NAME_REGEXP.test(value);
    };

    const onDescriptionFirstName = (event: any): void => {
        setFirstName(event.target.value);
        setFirstNameValue(event.target.value);
        if (cyrillicPattern.test(event.target.value)) {
            setShowCyrillicFirstName(true);
        } else {
            setShowCyrillicFirstName(false);
        }
        validateName(event.target.value)
            ? setErrorFirstName(false)
            : setErrorFirstName(true);
    };

    const onDescriptionLastName = (event: any): void => {
        setLastName(event.target.value);
        setLastNameValue(event.target.value);
        if (cyrillicPattern.test(event.target.value)) {
            setShowCyrillicLastName(true);
        } else {
            setShowCyrillicLastName(false);
        }
        validateName(event.target.value)
            ? setErrorLastName(false)
            : setErrorLastName(true);
    };

    const handleDescriptionModalOk = (): void => {
        if (
            firstNameValue.trim().length !== 0 &&
            lastNameValue.trim().length !== 0 &&
            errorFirstName !== true &&
            errorLastName !== true &&
            descriptionValue.trim().length !== 0 &&
            valueForTable.length > 0 &&
            showCyrillic !== true &&
            showCyrillicDocuments !== true &&
            showCyrillicFirstName !== true &&
            showCyrillicLastName !== true
        ) {
            PostCreateCaseWithDocuments(
                idSelectedCase,
                firstName,
                lastName,
                description,
                documentsMessageValue,
            ).then(res => {
                if (res.status === 200) {
                    notification.success({
                        message: `Case # ${res.data.data.case_id} successfully created and taken to work`,
                    });
                    history.push(
                        `${DashboardRouteNames.PENDING_OPEN_DETAILS_CUSTOMER}`,
                        {
                            key: res.data.data.case_id,
                        },
                    );
                }
                handleDescriptionModalCancel();
            });
        } else if (
            firstNameValue.trim().length !== 0 &&
            lastNameValue.trim().length !== 0 &&
            errorFirstName !== true &&
            errorLastName !== true &&
            descriptionValue.trim().length !== 0 &&
            valueForTable.length === 0 &&
            showCyrillic !== true &&
            showCyrillicDocuments !== true &&
            showCyrillicFirstName !== true &&
            showCyrillicLastName !== true
        ) {
            PostCreateCaseWithOutDocuments(
                idSelectedCase,
                firstName,
                lastName,
                description,
            ).then(res => {
                if (res.status === 200) {
                    notification.success({
                        message: `Case # ${res.data.data.case_id} successfully created and taken to work`,
                    });
                    history.push(
                        `${DashboardRouteNames.PENDING_OPEN_DETAILS_CUSTOMER}`,
                        {
                            key: res.data.data.case_id,
                        },
                    );
                }
                handleDescriptionModalCancel();
            });
        } else {
            if (
                firstNameValue.trim().length < 2 &&
                showCyrillicFirstName !== true
            ) {
                setEmptyInputFirstName(true);
                setInterval(() => {
                    setEmptyInputFirstName(false);
                }, 2000);
            } else if (
                lastNameValue.trim().length < 2 &&
                showCyrillicLastName !== true
            ) {
                setEmptyInputLastName(true);
                setInterval(() => {
                    setEmptyInputLastName(false);
                }, 2000);
            } else if (
                descriptionValue.trim().length === 0 &&
                showCyrillic !== true
            ) {
                setEmptyInput(true);
                setInterval(() => {
                    setEmptyInput(false);
                }, 2000);
            }
        }
    };

    const handleDescriptionModalCancel = (): void => {
        setIsModalDescriprionVisible(false);
        setDocumentsMessageValue(null);
        setDescription('');
        setDescriptionValue('');
        setFirstName('');
        setFirstNameValue('');
        setLastName('');
        setLastNameValue('');
        setShowCyrillic(false);
        setShowCyrillicFirstName(false);
        setShowCyrillicLastName(false);
        setShowCyrillicDocuments(false);
        setDocumentsFileList([]);
    };

    const onDescriptionDocuments = (event: any, file: any): void => {
        let index = documentsFileList.indexOf(file.deleteFile);
        documentsFileList[index].description = event.target.value;
        file.description = event.target.value;
        if (cyrillicPattern.test(event.target.value)) {
            setShowCyrillicDocuments(true);
        } else {
            setShowCyrillicDocuments(false);
        }
        setDocumentsFileList([...documentsFileList]);
        setValueForTable([...valueForTable]);
    };

    const propsUpload = {
        name: 'documents',
        action: `${process.env.REACT_APP_BASE_URL}/save-documents`,
        headers: {
            authorization: 'documents',
        },
        beforeUpload: (file: any) => {
            const isJPG = file.type === 'image/jpg';
            const isJPEG = file.type === 'image/jpeg';
            const isPNG = file.type === 'image/png';
            const isPDF = file.type === 'application/pdf';
            const isDOC = file.type === 'application/msword';
            const isDOCX =
                file.type ===
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!(isJPG || isJPEG || isPNG || isPDF || isDOC || isDOCX)) {
                message.error(`jpg, jpeg, png, pdf, doc, docx allowed`);
            } else if (!isLt2M) {
                message.error('File must not be bigger than 5 Mb');
            }
            return (
                isJPG ||
                isJPEG ||
                isPNG ||
                isPDF ||
                isDOC ||
                isDOCX ||
                Upload.LIST_IGNORE
            );
        },
        onChange: (info: any) => {
            if (info.file?.status !== 'uploading') {
                setDocumentsFileList([
                    ...documentsFileList,
                    info.fileList[info.fileList.length - 1],
                ]);
            }
            if (info.file?.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file?.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onRemove: (file: any) => {
            const index = documentsFileList.indexOf(file);
            const newFileList = documentsFileList.slice();
            newFileList.splice(index, 1);
            setDocumentsFileList(newFileList);
            setShowCyrillicDocuments(false);
        },
    };

    const showConfirm = () => {
        confirm({
            title: 'Do you want to close this case?',
            icon: <ExclamationCircleOutlined />,
            content: 'The entered data will be deleted',
            cancelButtonProps: {
                type: 'primary',
            },
            onOk() {
                handleDescriptionModalCancel();
            },
            onCancel() {
                return;
            },
        });
    };

    if (userRoleId === 2) {
        return (
            <div className="service-container service-page">
                <div className="service__title">Create Case</div>
                <Row className="justify-content-md-center row">
                    {pendingCasesService.map(
                        (service: IServicesPendingCase) => (
                            <Col key={service.name} sm="3">
                                <Card className="br-8">
                                    <button
                                        className="service-block"
                                        onClick={() => {
                                            onShowDescriptionModal(service);
                                        }}
                                    >
                                        <Card.Body className="p-10 d-flex justify-content-center align-items-center flex-column">
                                            {service.icon}
                                            <div className="service-block__title">
                                                {service.name}
                                            </div>
                                        </Card.Body>
                                    </button>
                                </Card>
                            </Col>
                        ),
                    )}
                </Row>
                <CreateCaseLawyerDescriptionModal
                    showConfirm={showConfirm}
                    isModalDescriprionVisible={isModalDescriprionVisible}
                    handleDescriptionModalOk={handleDescriptionModalOk}
                    emptyInput={emptyInput}
                    emptyInputFirstName={emptyInputFirstName}
                    emptyInputLastName={emptyInputLastName}
                    errorFirstName={errorFirstName}
                    errorLastName={errorLastName}
                    onDescription={onDescription}
                    onDescriptionFirstName={onDescriptionFirstName}
                    onDescriptionLastName={onDescriptionLastName}
                    descriptionValue={descriptionValue}
                    firstNameValue={firstNameValue}
                    lastNameValue={lastNameValue}
                    propsUpload={propsUpload}
                    onDescriptionDocuments={onDescriptionDocuments}
                    nameSelectedCase={nameSelectedCase}
                    idSelectedCase={idSelectedCase}
                    valueForTable={valueForTable}
                    showCyrillic={showCyrillic}
                    showCyrillicFirstName={showCyrillicFirstName}
                    showCyrillicLastName={showCyrillicLastName}
                    showCyrillicDocuments={showCyrillicDocuments}
                />
            </div>
        );
    } else if (userRoleId === 3) {
        return null;
    } else {
        return null;
    }
};

export default CreateCaseLawyer;
