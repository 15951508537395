import React from 'react';
import ServiceFunctions from './ServiceFunctions';

const CivilLaw = () => {
    return (
        <>
            <ServiceFunctions id={2} name="Civil Law" />
        </>
    );
};

const CommercialLaw = () => {
    return (
        <>
            <ServiceFunctions id={4} name="Commercial Law" />
        </>
    );
};

const CriminalLaw = () => {
    return (
        <>
            <ServiceFunctions id={1} name="Criminal Law" />
        </>
    );
};

const FamilyLaw = () => {
    return (
        <>
            <ServiceFunctions id={6} name="Family Law" />
        </>
    );
};

const Finance = () => {
    return (
        <>
            <ServiceFunctions id={9} name="Banking & Finance" />
        </>
    );
};

const Insurance = () => {
    return (
        <>
            <ServiceFunctions id={5} name="Insurance" />
        </>
    );
};

const Investment = () => {
    return (
        <>
            <ServiceFunctions id={10} name="Investment Fund Services" />
        </>
    );
};

const LabourLaw = () => {
    return (
        <>
            <ServiceFunctions id={8} name="Labour Law" />
        </>
    );
};

const MaritimeLaw = () => {
    return (
        <>
            <ServiceFunctions id={7} name="Maritime Law" />
        </>
    );
};

const RealEstate = () => {
    return (
        <>
            <ServiceFunctions id={3} name="Real Estate" />
        </>
    );
};

export {
    CivilLaw,
    CommercialLaw,
    CriminalLaw,
    FamilyLaw,
    Finance,
    Insurance,
    Investment,
    LabourLaw,
    MaritimeLaw,
    RealEstate,
};
