import { useEffect, useState } from 'react';
import { IMessageCenter } from '../../../../models/IMessageCenter';
import { GetPaymentsMessage } from '../../../../apis/messageCenter';
import MessageCenterUser from '../../../../components/MessageCenterUser';
import style from '../../../../components/MessageCenterUser/MessageCenterUser.module.css';
import preloaderImg from '../../../../assets/images/preloader.gif';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { useSubscription } from 'mqtt-react-hooks';

const PaymentMessagesCustomer = () => {
    const { user } = useTypedSelector(state => state.user);
    const [data, setData] = useState<IMessageCenter[]>([]);
    const [preloader, setPreloader] = useState<boolean>(false);
    const messageMqtt = useSubscription([
        `${process.env.REACT_APP_ENVIRONMENT}/topic/user/${user?.user_id}`,
    ]);

    useEffect(() => {
        setPreloader(true);
        GetPaymentsMessage(2).then(({ data }) => {
            setData(data.data);
            setPreloader(false);
        });
    }, []);

    useEffect(() => {
        let messageMqttJSON: any = messageMqtt?.message;
        let objSubscriptionMqtt =
            messageMqttJSON && JSON.parse(messageMqttJSON?.message);
        if (objSubscriptionMqtt?.action === 'create-invoice') {
            GetPaymentsMessage(2).then(({ data }) => {
                setData(data.data);
            });
        }
    }, [messageMqtt?.message]);

    if (preloader) {
        return (
            <div className={style.preloaderBox}>
                <img
                    className={style.preloaderImg}
                    src={preloaderImg}
                    alt="Loading..."
                />
            </div>
        );
    }

    return <MessageCenterUser title="Payment messages" data={data} />;
};
export default PaymentMessagesCustomer;
