import { Axios } from '../plugins/helpers';
import { notificationError } from '../plugins/requestNotification';

export const GetPaymentsPending = async (id: number | undefined) => {
    try {
        const response = await Axios().get(
            `${process.env.REACT_APP_BASE_URL}/get-user-case-payments/${id}`,
        );
        return response;
    } catch (e: any) {
        notificationError(e);
        return e?.response;
    }
};

export const GetPaymentsClose = async (id: number) => {
    try {
        const response = await Axios().get(
            `${process.env.REACT_APP_BASE_URL}/get-user-case-payments/${id}?status=1`,
        );
        return response;
    } catch (e: any) {
        notificationError(e);
        return e?.response;
    }
};
