import React from 'react';



const UserProfileEmpty = () => {
    return(
        <>
        </>
    );
}


export default UserProfileEmpty;