import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { IRoute } from '../models/IRoute'

// Auth
import Recoverpw from '../views/dashboard/auth/recoverpw'
import RecoverpwUpdate from '../views/dashboard/auth/recoverpw-update'
import SignIn from '../views/dashboard/auth/sign-in'
import SignUp from '../views/dashboard/auth/sign-up'
import TermsOfUseAuth from '../views/dashboard/auth/termsOfUseAuth'

export enum AuthRouteNames {
  RECOVERPW = '/auth/recoverpw',
  RECOVERPW_UPDATE = '/auth/recoverpw-update',
  SIGN_IN = '/auth/sign-in',
  SIGN_UP = '/auth/sign-up',
  TERMS_OF_USE_AUTH = '/auth/terms-of-use-auth',
}

export const authRoutes: IRoute[] = [
  { path: AuthRouteNames.RECOVERPW, exact: true, component: Recoverpw },
  { path: AuthRouteNames.RECOVERPW_UPDATE, exact: true, component: RecoverpwUpdate },
  { path: AuthRouteNames.SIGN_IN, exact: true, component: SignIn },
  { path: AuthRouteNames.SIGN_UP, exact: true, component: SignUp },
  { path: AuthRouteNames.TERMS_OF_USE_AUTH, exact: true, component: TermsOfUseAuth },
]

const Auth = () => {
  return (
    <TransitionGroup>
      <CSSTransition classNames="fadein" timeout={300}>
        <Switch>
          {authRoutes.map(route =>
            <Route path={route.path}
              exact={route.exact}
              component={route.component}
              key={route.path}
            />
          )}
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  )
}

export default Auth;
