import {
    ICategoryField,
    IFieldBack,
    IFieldFront,
    IFilledField,
    TypesOfInput,
} from '../models/IField';
import {
    IChangeTwoFactor,
    ISession,
    ITerminateSession,
} from '../models/IProfile';
import { IErrorResponse, ISuccessResponse } from '../models/IResponse';
import { IUserChangePassword, IUserInfo } from '../models/IUser';
import { Axios, groupBy } from '../plugins/helpers';
import { notificationError } from '../plugins/requestNotification';
import { createRulesForField } from './fields';

enum TypesOfProfiles {
    PROFILE = 1,
    IDENTIFICATION = 3,
}

export const FetchProfileFields = async (
    userId: number,
    type: TypesOfProfiles,
): Promise<any> => {
    try {
        interface IData {
            [key: string]: IFieldBack;
        }

        const response: ISuccessResponse<IData> = await Axios().get(
            `${process.env.REACT_APP_BASE_URL}/get-user-profile-fields/${userId}/type/${type}`,
        );

        const listOfFields: string[] = Object.keys(response.data.data);
        const fetchedFileds: [string, IFieldBack][] = Object.entries(response.data.data);
        let sortedFields: IFieldFront[] = fetchedFileds
            .map((field: [string, IFieldBack]) => {
                let value: string | Date | number = field[1].value || '';
                if (
                    field[1].type === TypesOfInput.DATEPICKER &&
                    field[1].value
                ) {
                    value = new Date(field[1].value);
                }

                if (field[0] === 'mail') {
                    return {
                        ...field[1],
                        required: Boolean(field[1].required),
                        id: field[0],
                        value,
                        disabled: true,
                    };
                }

                return {
                    ...field[1],
                    required: Boolean(field[1].required),
                    id: field[0],
                    value,
                };
            })
            .sort((a, b) => Number(a.order) - Number(b.order));

        const validations = createRulesForField(listOfFields, sortedFields);
        const initialValues: any = {};
        sortedFields.forEach(
            (field: IFieldFront) => (initialValues[field.id] = field.value),
        );

        const categoriesNames: { [key: number]: string } = {
            1: 'Personal Info',
            2: 'Identification Type',
            3: 'Shipping Address',
            4: 'Billing Address',
        };

        const groupedFieldsById = groupBy(sortedFields, (i: any) => {
            if (i.category_id) {
                return i.category_id;
            }
        });
        const categoriesIds = Object.keys(groupedFieldsById);

        const sortedFieldsByCategory: ICategoryField[] = categoriesIds.map(
            (key: string) => {
                const idOfCategory = Number(key);

                return {
                    id: idOfCategory,
                    name: categoriesNames[idOfCategory],
                    fields: groupedFieldsById[idOfCategory],
                };
            },
        );

        return {
            sortedFields,
            validations,
            initialValues,
            sortedFieldsByCategory,
        };
    } catch (e: any) {
        notificationError(e);
        return e?.response;
    }
};

export const UpdateProfile = async (
    userData: IUserInfo | IChangeTwoFactor | IUserChangePassword,
): Promise<ISuccessResponse<any> | IErrorResponse> => {
    try {
        const sendingFields: IFilledField = {};

        Object.entries(userData).forEach((value: [string, any]) => {
            if (value[1] instanceof Date) {
                sendingFields[value[0]] = value[1].getTime();
            } else if (typeof value[1] === 'boolean') {
                sendingFields[value[0]] = Number(value[1]);
            } else if (typeof value[1] === 'string') {
                sendingFields[value[0]] = value[1].trim();
            } else {
                sendingFields[value[0]] = value[1];
            }
        });

        const response: ISuccessResponse<any> = await Axios().put(
            `${process.env.REACT_APP_BASE_URL}/user-profile`,
            sendingFields,
        );
        return response;
    } catch (e: any) {
        notificationError(e);
        return e?.response;
    }
};

export const Get2AuthMethods = async (): Promise<
    ISuccessResponse<any> | IErrorResponse
> => {
    try {
        const response: ISuccessResponse<any> = await Axios().get(
            `${process.env.REACT_APP_BASE_URL}/get-two-fa-methods`,
        );
        return response;
    } catch (e: any) {
        notificationError(e);
        return e?.response;
    }
};

export const GetUserSessions = async (
    user_id: number,
): Promise<ISuccessResponse<ISession[]> | IErrorResponse> => {
    try {
        const response: ISuccessResponse<ISession[]> = await Axios().get(
            `${process.env.REACT_APP_BASE_URL}/get-user-sessions/${user_id}`,
        );
        return response;
    } catch (e: any) {
        notificationError(e);
        return e?.response;
    }
};

export const TerminateUserSession = async (
    data: ITerminateSession,
): Promise<ISuccessResponse<any> | IErrorResponse> => {
    try {
        const response: ISuccessResponse<any> = await Axios().post(
            `${process.env.REACT_APP_BASE_URL}/terminate-sessions`,
            data,
        );
        return response;
    } catch (e: any) {
        notificationError(e);
        return e?.response;
    }
};

export const VerifyUser = async (): Promise<
    ISuccessResponse<any> | IErrorResponse
> => {
    try {
        const response: ISuccessResponse<any> = await Axios().get(
            `${process.env.REACT_APP_BASE_URL}/get-verified`,
        );
        return response;
    } catch (e: any) {
        return e?.response;
    }
};
