//router
import IndexRouters from './router/index';
import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
//scss
import './assets/scss/hope-ui.scss';
import AuthModal from './components/uikit/AuthModal';
import { useActions } from './hooks/useActions';
import { getCookie } from './plugins/helpers';

import { Connector } from 'mqtt-react-hooks';

import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { AuthRouteNames } from './router/Auth';

function App() {
    const { handleLoader, loginInit, logout } = useActions();
    const title = 'MY.EMIRATES.LAW';
    let history = useHistory();
    const token = getCookie('auth_token');

    useEffect(() => {
        if (token) {
            let decodedToken: any = jwt_decode(token);
            let expToken = moment(decodedToken.exp * 1000).unix();
            const currentTime = moment().unix();
            if (expToken > currentTime) {
                loginInit();
            } else {
                logout();
                history.push(`${AuthRouteNames.SIGN_IN}`);
                handleLoader(false);
            }
        } else {
            handleLoader(false);
        }
    }, []);

    return (
        <>
            <Connector
                brokerUrl={process.env.REACT_APP_MQTT_URL}
                options={{
                    connectTimeout: 60000,
                    clean: false,
                    clientId:
                        'client-' + Math.random().toString(16).substr(2, 8),
                    username: process.env.REACT_APP_MQTT_USER,
                    password: process.env.REACT_APP_MQTT_PASSWORD,
                }}
            >
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                <div className="App">
                    <AuthModal />
                    <IndexRouters />
                </div>
            </Connector>
        </>
    );
}

export default App;
