import { Axios } from "../plugins/helpers";
import { notificationError } from "../plugins/requestNotification";


export const GetMenuCount = async () => {
    try {
        const response = await Axios().get(
            `${process.env.REACT_APP_BASE_URL}/get-data-menu`,
        );
        return response;
    } catch (e: any) {
        notificationError(e);
        return e?.response;
    }
};