import React from 'react';



const PersonalProfileCustomer = () => {
    return(
        <div>
            PersonalProfileCustomer
        </div>
    );
}


export default PersonalProfileCustomer;