import { Axios } from '../plugins/helpers';
import { notificationError } from '../plugins/requestNotification';


export const PostCreateCaseWithDocuments = async (
    service_id: number,
    first_name: string,
    last_name: string,
    description: string,
    documents: any,
) => {
    try {
        const response = await Axios().post(
            `${process.env.REACT_APP_BASE_URL}/open-case-lawyer`,
            { service_id, first_name, last_name, description, documents },
        );
        return response;
    } catch (e: any) {
        notificationError(e);
        return e?.response;
    }
};

export const PostCreateCaseWithOutDocuments = async (
    service_id: number,
    first_name: string,
    last_name: string,
    description: string,
) => {
    try {
        const response = await Axios().post(
            `${process.env.REACT_APP_BASE_URL}/open-case-lawyer`,
            { service_id, first_name, last_name, description },
        );
        return response;
    } catch (e: any) {
        notificationError(e);
        return e?.response;
    }
};