import { Row, Col, Image, Form, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';

// img
import auth1 from '../../../assets/images/auth/01.webp';
import logoSign from '../../../assets/images/logo-sign.png';

import { useActions } from '../../../hooks/useActions';
import { AuthRouteNames } from '../../../router/Auth';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { Formik, Form as FormicForm } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { IUserLogin } from '../../../models/IUser';
import FieldWithValidation from '../../../components/uikit/FieldWithValidation';
import { TypesOfInput } from '../../../models/IField';
import * as Yup from 'yup';
import { handleDisableButton } from '../../../plugins/helpers';
import { Regulars } from '../../../constants';
import Card from '../../../components/Card';
import Helmet from 'react-helmet';

const SignIn = () => {
    const history = useHistory();
    const { login, login2Auth, setStepOfRegist } = useActions();
    const { errors, modalValue } = useTypedSelector(state => state.app);
    const { user } = useTypedSelector(state => state.user);
    const formButtonRef = useRef(null);
    const [remember, setRemember] = useState(false);
    const [formikActions, setFormicActions] = useState<any>(null);
    const [ip, setIP] = useState('');

    const title = 'Login to MY.EMIRATES.LAW';

    useEffect(() => {
        if (formikActions && errors && Object.values(errors).length) {
            formikActions.setErrors(errors);
        }
    }, [errors]);

    useEffect(() => {
        if (user?.has_2fa && modalValue) {
            login2Auth(remember);
        }
    }, [modalValue]);

    useEffect(() => {
        getIp();
    }, []);

    const getIp = async () => {
        const res: any = await axios.get('https://geolocation-db.com/json/');
        setIP(res.data.IPv4);
    };

    const handleLogin = (values: IUserLogin, actions: any) => {
        setFormicActions(actions);
        handleDisableButton(formButtonRef);
        login({
            data: {
                ...values,
                remember,
                ip,
            },
            history,
        });
    };

    const toSignUp = () => {
        setStepOfRegist(1);
    };

    const schema = Yup.object().shape({
        mail: Yup.string()
            .matches(Regulars.email.reg, Regulars.email.text)
            .required('Required'),

        password: Yup.string()
            .matches(Regulars.password.reg, Regulars.password.text)
            .required('Required'),
    });

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <section className="login-content">
                <Row className="m-0 bg-white vh-100">
                    <Col
                        md="6"
                        className="d-md-block d-none  p-0 mt-n1 vh-100 overflow-hidden relative"
                    >
                        <Image
                            src={logoSign}
                            alt="logo"
                            className="logo-sign"
                        />
                        <Image
                            src={auth1}
                            className="Image-fluid gradient-main animated-scaleX"
                            alt="images"
                        />
                    </Col>
                    <Col md="6">
                        <Row className="justify-content-center align-items-center vh-100">
                            <Col md="10">
                                <Card className="card-transparent shadow-none d-flex justify-content-center mb-0 auth-card">
                                    <Card.Body>
                                        <h2 className="mb-2 text-center">
                                            Sign In
                                        </h2>
                                        <p className="text-center sign-in__description">
                                            Please enter your registered email
                                            address and the password to access
                                            your account.
                                        </p>
                                        <Formik
                                            initialValues={{
                                                mail: '',
                                                password: '',
                                            }}
                                            validationSchema={schema}
                                            onSubmit={(values, actions) =>
                                                handleLogin(values, actions)
                                            }
                                        >
                                            <FormicForm>
                                                <Row>
                                                    <FieldWithValidation
                                                        field={{
                                                            id: 'mail',
                                                            required: true,
                                                            type: TypesOfInput.TEXT,
                                                            title: 'Email',
                                                            size: 12,
                                                            category_id: 1,
                                                        }}
                                                    />
                                                    <FieldWithValidation
                                                        field={{
                                                            id: 'password',
                                                            required: true,
                                                            type: TypesOfInput.PASSWORD,
                                                            title: 'Password',
                                                            size: 12,
                                                            category_id: 1,
                                                        }}
                                                    />
                                                    <Col
                                                        lg="12"
                                                        className="d-flex justify-content-between"
                                                    >
                                                        <Form.Check className="form-check mb-3">
                                                            <Form.Check.Input
                                                                onChange={() =>
                                                                    setRemember(
                                                                        !remember,
                                                                    )
                                                                }
                                                                type="checkbox"
                                                                id="customCheck1"
                                                            />
                                                            <Form.Check.Label htmlFor="customCheck1">
                                                                Remember me?
                                                            </Form.Check.Label>
                                                        </Form.Check>
                                                        <Link
                                                            to={
                                                                AuthRouteNames.RECOVERPW
                                                            }
                                                        >
                                                            Forgot your
                                                            password?
                                                        </Link>
                                                    </Col>
                                                </Row>
                                                <div className="d-flex justify-content-center">
                                                    <Button
                                                        ref={formButtonRef}
                                                        type="submit"
                                                        className="step__button"
                                                        variant="primary"
                                                    >
                                                        Sign In
                                                    </Button>
                                                </div>
                                                <p className="mt-3 text-center">
                                                    Don’t have an Account?{' '}
                                                    <Link
                                                        onClick={toSignUp}
                                                        to={
                                                            AuthRouteNames.SIGN_UP
                                                        }
                                                        className="text-underline"
                                                    >
                                                        Click here to Sign Up.
                                                    </Link>
                                                </p>
                                            </FormicForm>
                                        </Formik>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </section>
        </>
    );
};

export default SignIn;
