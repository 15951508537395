import React, { useEffect, useState } from 'react';
import './styles/styles.css';
import { Table, Image, Button } from 'antd';
import { Link } from 'react-router-dom';
import { DashboardRouteNames } from '../../../../router/Dashboard';
import { GetArchiveCustomerCases } from '../../../../apis/archiveCustomer';
import customerAvatarDefault from '../../../../assets/images/avatars/01.png';
import preloaderImg from '../../../../assets/images/preloader.gif';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { useSubscription } from 'mqtt-react-hooks';
import moment from 'moment';

const ArchiveCasesCustomer = () => {
    const { user } = useTypedSelector(state => state.user);
    const [customerArchiveCases, setCustomerArchiveCases] = useState<any[]>([]);
    const [customerArchiveCasesForTable, setCustomerArchiveCasesForTable] =
        useState<any[]>([]);
    const [preloader, setPreloader] = useState<boolean>(false);
    const messageMqtt = useSubscription([
        `${process.env.REACT_APP_ENVIRONMENT}/topic/user/${user?.user_id}`,
    ]);

    useEffect(() => {
        window.onbeforeunload = () => {
            window.sessionStorage.setItem(
                'lastRoute',
                JSON.stringify(window.location.pathname),
            );
        };
    }, []);

    useEffect(() => {
        setPreloader(true);
        GetArchiveCustomerCases().then(value => {
            setCustomerArchiveCases(value.data);
            setPreloader(false);
        });
    }, []);

    useEffect(() => {
        let messageMqttJSON: any = messageMqtt?.message;
        let objSubscriptionMqtt =
            messageMqttJSON && JSON.parse(messageMqttJSON?.message);
        if (objSubscriptionMqtt?.action === 'close-case') {
            GetArchiveCustomerCases().then(value => {
                setCustomerArchiveCases(value.data);
            });
        }
    }, [messageMqtt?.message]);

    useEffect(() => {
        const tempArrayArchiveCase: any[] = [];
        customerArchiveCases.forEach(item => {
            const tempObjArchiveCase: any = {};
            tempObjArchiveCase.key = item.id;
            tempObjArchiveCase.caseNumberArchive = `# ${item.id}`;
            tempObjArchiveCase.dateAndTime = item.date_close_human;
            tempObjArchiveCase.caseName = item.service.name;
            if (item.lawyer_photo) {
                tempObjArchiveCase.avatarLawyer = item.lawyer_photo;
            } else {
                tempObjArchiveCase.avatarLawyer = customerAvatarDefault;
            }
            if (item.lawyer_name) {
                tempObjArchiveCase.nameLawyer = item.lawyer_name;
            } else {
                tempObjArchiveCase.nameLawyer = 'Rejected case';
            }
            tempObjArchiveCase.description = item.close_description;
            tempObjArchiveCase.view = 'View';
            tempArrayArchiveCase.push(tempObjArchiveCase);
        });
        tempArrayArchiveCase.sort(
            (a, b) =>
                moment(b.dateAndTime).unix() - moment(a.dateAndTime).unix(),
        );
        setCustomerArchiveCasesForTable(tempArrayArchiveCase);
    }, [customerArchiveCases]);

    const columns = [
        {
            title: 'Case Number',
            dataIndex: 'caseNumberArchive',
            key: 'caseNumberArchive',
            align: 'center' as 'center',
        },
        {
            title: 'Сlosing date',
            dataIndex: 'dateAndTime',
            key: 'dateAndTime',
            className: 'table-date',
            align: 'center' as 'center',
        },
        {
            title: 'Service',
            dataIndex: 'caseName',
            key: 'caseName',
            align: 'center' as 'center',
        },
        {
            title: 'Lawyer',
            dataIndex: 'avatarLawyer',
            key: 'avatarLawyer',
            align: 'center' as 'center',
            id: 'key',
            render: (avatarLawyer: string, key: any) => (
                <div>
                    <Image width={50} src={avatarLawyer} />
                    <div style={{ marginTop: '10px' }}>{key.nameLawyer}</div>
                </div>
            ),
        },
        {
            title: 'Short Description',
            dataIndex: 'description',
            key: 'description',
            align: 'center' as 'center',
            ellipsis: true,
            className: 'documents-table-pending-case-details-customer-short',
        },
        {
            title: 'View',
            dataIndex: 'view',
            key: 'view',
            id: 'key',
            align: 'center' as 'center',
            render: (view: string, key: any) => (
                <div>
                    <Button danger>
                        <Link
                            to={{
                                pathname: `${DashboardRouteNames.PENDING_CASE_DETAILS}`,
                                state: { id: key },
                            }}
                        >
                            {view}
                        </Link>
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <div className="service-container service-page">
            <div className="service__title">Archive Cases</div>
            <div className="archive-cases-table">
                {preloader ? (
                    <div className="preloader-box">
                        <img
                            className="preloader-img"
                            src={preloaderImg}
                            alt="Loading..."
                        />
                    </div>
                ) : (
                    <Table
                        columns={columns}
                        dataSource={
                            customerArchiveCasesForTable.length > 0
                                ? customerArchiveCasesForTable
                                : []
                        }
                    />
                )}
            </div>
        </div>
    );
};

export default ArchiveCasesCustomer;
