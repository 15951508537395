import axios, { AxiosInstance } from 'axios';
import moment from 'moment';
import Cookies from 'universal-cookie';

export const handleDisableButton = (
    ref: React.RefObject<HTMLButtonElement>,
    time?: number,
) => {
    ref.current?.setAttribute('disabled', 'true');
    setTimeout(() => {
        ref.current?.removeAttribute('disabled');
    }, (time || 2) * 1000);
};

export const saveToCookies = (key: string, value: string, time: boolean) => {
    if (process.env.REACT_APP_ENVIRONMENT === 'master') {
        if (time) {
            document.cookie = `${key}=${value}; domain=emirates.law; path=/; expires=${new Date(
                moment().add(12, 'hours').toString(),
            ).toUTCString()}`;
        } else {
            document.cookie = `${key}=${value}; domain=emirates.law; path=/; expires=${new Date(
                moment().add(1, 'hours').toString(),
            ).toUTCString()}`;
        }
    } else {
        if (time) {
            document.cookie = `${key}=${value}; path=/; expires=${new Date(
                moment().add(12, 'hours').toString(),
            ).toUTCString()}`;
        } else {
            document.cookie = `${key}=${value}; path=/; expires=${new Date(
                moment().add(1, 'hours').toString(),
            ).toUTCString()}`;
        }
    }
};
// localStorage.setItem(key, value);
export const removeToCookies = (key: string) => {
    if (process.env.REACT_APP_ENVIRONMENT === 'master') {
        document.cookie = `${key}=""; domain=emirates.law; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
    } else {
        document.cookie = `${key}=""; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
    }
    localStorage.removeItem(key);
};
// localStorage.removeItem(key);
export const getCookie = (key: string): string => {
    const cookies = new Cookies();
    const result: string = localStorage.getItem(key) || cookies.get(key) || '';
    return result;
};
// localStorage.getItem(key)

export const Axios = (): AxiosInstance => {
    const token = getCookie('auth_token');
    const instance: AxiosInstance = axios.create({
        headers: { Authorization: token },
    });

    return instance;
};

export const groupBy = <T, K extends keyof any>(
    list: T[],
    getKey: (item: T) => K,
) =>
    list.reduce((previous, currentItem) => {
        const group = getKey(currentItem);
        if (!previous[group]) previous[group] = [];
        previous[group].push(currentItem);
        return previous;
    }, {} as Record<K, T[]>);
