import React, { useState, useEffect } from 'react';
import './styles/searchStyles.css';
import { Button, Table, Image, Select, Input } from 'antd';
import { NavLink } from 'react-router-dom';
import { GetSearchCasesCustomer } from '../../../apis/searchLawyer';
import { GetAllCustomers } from '../../../apis/assignCustomer';
import customerAvatarDefault from '../../../assets/images/avatars/01.png';
import { DashboardRouteNames } from '../../../router/Dashboard';
import moment from 'moment';

const SearchLawyer = () => {
    const [customers, setCustomers] = useState<any[]>([]);
    const [customer, setCustomer] = useState<string>('');
    const [dataCustomerCases, setDataCustomerCases] = useState<any[]>([]);
    const [dataCustomerCasesForTable, setDataCustomerCasesForTable] = useState<
        any[]
    >([]);
    const [сustomerEmpty, setСustomerEmpty] = useState<boolean>(false);
    const [caseNumber, setCaseNumber] = useState<string>('');
    const [dataCases, setDataCases] = useState<any[]>([]);
    const [dataCasesForTable, setDataCasesForTable] = useState<any[]>([]);
    const [сaseEmpty, setСaseEmpty] = useState<boolean>(false);
    const { Option } = Select;

    useEffect(() => {
        window.onbeforeunload = () => {
            window.sessionStorage.setItem(
                'lastRoute',
                JSON.stringify(window.location.pathname),
            );
        };
    }, []);

    useEffect(() => {
        GetAllCustomers().then(res => {
            setCustomers(res.data);
        });
    }, []);

    useEffect(() => {
        let tempArrayForTable: any[] = [];
        dataCases[0]?.forEach((item: any, index: number) => {
            let tempObjForTable: any = {};
            tempObjForTable.key = index;
            tempObjForTable.caseNumber = item.id;
            tempObjForTable.service = item.service?.name;
            tempObjForTable.openingDate = item.date_create_human;
            tempObjForTable.customer = item.user_name;
            if (!item.user_photo) {
                tempObjForTable.avatar = customerAvatarDefault;
            } else {
                tempObjForTable.avatar = item.user_photo;
            }
            tempObjForTable.showCase = 'Open details';
            tempArrayForTable.push(tempObjForTable);
        });
        tempArrayForTable.sort(
            (a, b) =>
                moment(b.openingDate).unix() - moment(a.openingDate).unix(),
        );
        setDataCasesForTable(tempArrayForTable);
    }, [dataCases]);

    useEffect(() => {
        let tempArrayForTable: any[] = [];
        dataCustomerCases[0]?.forEach((item: any, index: number) => {
            let tempObjForTable: any = {};
            tempObjForTable.key = index;
            tempObjForTable.caseNumber = item.id;
            tempObjForTable.service = item.service?.name;
            tempObjForTable.openingDate = item.date_create_human;
            tempObjForTable.customer = item.user_name;
            if (!item.user_photo) {
                tempObjForTable.avatar = customerAvatarDefault;
            } else {
                tempObjForTable.avatar = item.user_photo;
            }
            tempObjForTable.showCase = 'Open details';
            tempArrayForTable.push(tempObjForTable);
        });
        tempArrayForTable.sort(
            (a, b) =>
                moment(b.openingDate).unix() - moment(a.openingDate).unix(),
        );
        setDataCustomerCasesForTable(tempArrayForTable);
    }, [dataCustomerCases]);

    const onSearchCustomer = () => {
        if (customer.trim().length !== 0) {
            GetSearchCasesCustomer(customer).then(res => {
                setDataCustomerCases(res.data);
            });
        } else {
            setСustomerEmpty(true);
            setTimeout(() => {
                setСustomerEmpty(false);
            }, 2000);
        }
    };

    const onSearchCase = () => {
        if (caseNumber.trim().length !== 0) {
            GetSearchCasesCustomer(caseNumber).then(res => {
                setDataCases(res.data);
            });
        } else {
            setСaseEmpty(true);
            setTimeout(() => {
                setСaseEmpty(false);
            }, 2000);
        }
    };

    const onChangeCustomer = (value: string) => {
        setCustomer(value.split(':')[1].trim());
    };

    const onCaseNumber = (event: any) => {
        let temp = event.target.value.replace(/[^\d]/g, '');
        setCaseNumber(temp);
    };

    const showCustomers = customers.map((item, index) => {
        return (
            <Option
                key={index}
                value={`${item.user_name} : ${item.mail}`}
            >{`${item.user_name} - ${item.mail}`}</Option>
        );
    });

    const columns = [
        {
            title: 'Case Number',
            dataIndex: 'caseNumber',
            key: 'caseNumber',
            align: 'center' as 'center',
        },
        {
            title: 'Service',
            dataIndex: 'service',
            key: 'service',
            align: 'center' as 'center',
        },
        {
            title: 'Opening Date',
            dataIndex: 'openingDate',
            key: 'openingDate',
            align: 'center' as 'center',
        },
        {
            title: 'Customer',
            dataIndex: 'avatar',
            key: 'avatar',
            render: (avatar: string, key: any) => (
                <div>
                    <Image width={50} src={avatar} />
                    <div style={{ marginTop: '10px' }}>{key.customer}</div>
                </div>
            ),
            align: 'center' as 'center',
        },
        {
            title: 'Show Case',
            dataIndex: 'showCase',
            key: 'showCase',
            render: (showCase: string, key: any) => (
                <Button danger>
                    <NavLink
                        to={{
                            pathname: `${DashboardRouteNames.PENDING_OPEN_DETAILS_CUSTOMER}`,
                            state: { key: key.caseNumber },
                        }}
                    >
                        {showCase}
                    </NavLink>
                </Button>
            ),
            align: 'center' as 'center',
        },
    ];

    return (
        <div className="service-container">
            <div className="service__title">Search</div>
            <div className="search_box">
                <div className="search_title">
                    Enter user name or search by email:
                </div>
                <div className="search_input_box">
                    <Select
                        showSearch
                        style={{ width: 400 }}
                        placeholder="Search to Select"
                        onChange={onChangeCustomer}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (
                                option!.children.toLowerCase() as unknown as string
                            ).includes(input.toLowerCase())
                        }
                        filterSort={(optionA, optionB) =>
                            (optionA!.children as unknown as string)
                                .toLowerCase()
                                .localeCompare(
                                    (
                                        optionB!.children as unknown as string
                                    ).toLowerCase(),
                                )
                        }
                    >
                        {showCustomers}
                    </Select>
                    <div
                        className={
                            сustomerEmpty
                                ? 'card-upload-box-input-assign-customer-error'
                                : 'card-upload-box-input-assign-customer'
                        }
                    >
                        Need to choose a customer
                    </div>
                    <div className="search_button">
                        <Button type="primary" onClick={onSearchCustomer}>
                            Search
                        </Button>
                    </div>
                </div>
            </div>

            <Table columns={columns} dataSource={dataCustomerCasesForTable} />
            <div className="search_box" style={{ paddingTop: '0px' }}>
                <div className="search_title">Enter case number:</div>
                <div className="search_input_box">
                    <Input
                        style={{ width: 400 }}
                        placeholder="Write to Search"
                        onChange={onCaseNumber}
                        value={caseNumber}
                    />
                    <div
                        className={
                            сaseEmpty
                                ? 'card-upload-box-input-assign-customer-error'
                                : 'card-upload-box-input-assign-customer'
                        }
                    >
                        Need to write a case
                    </div>
                    <div className="search_button">
                        <Button type="primary" onClick={onSearchCase}>
                            Search
                        </Button>
                    </div>
                </div>
            </div>
            <Table columns={columns} dataSource={dataCasesForTable} />
        </div>
    );
};

export default SearchLawyer;
