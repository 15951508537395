import * as types from './types';

const initialState: types.AuthState = {
    isAuth: false,
    stepOfRegist: 1,
    totalStep: 0,
}

export const authReducer = (state = initialState, action: types.AuthAction): types.AuthState => {
    switch (action.type) {
        case types.AuthActionEnum.SET_AUTH:
            return { ...state, isAuth: action.payload !== undefined ? action.payload : true }
            
        case types.AuthActionEnum.SET_STEP_OF_REGIST:
            return { ...state, stepOfRegist: action.payload }

        case types.AuthActionEnum.SET_TOTAL_STEP:
            return { ...state, totalStep: action.payload }

        default:
            return state;
    }
}

export default authReducer;