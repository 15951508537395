import { Axios } from '../plugins/helpers';
import { notificationError } from '../plugins/requestNotification';


export const GetArchiveLawyerCases = async () => {
    try {
        const { data } = await Axios().get(
            `${process.env.REACT_APP_BASE_URL}/get-lawyer-cases?status=3`,
        );
        return data;
    } catch (e: any) {
        notificationError(e);
        return e?.response;
    }
};


export const GetArchiveLawyerCase = async (id: string) => {
    try {
        const { data } = await Axios().get(
            `${process.env.REACT_APP_BASE_URL}/get-lawyer-cases?status=3&service_id=${id}`,
        );
        return data;
    } catch (e: any) {
        notificationError(e);
        return e?.response;
    }
};