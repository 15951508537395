import React, { useEffect, useState } from 'react';
import HeaderPendingCaseDetails from './HeaderPendingCaseDetails/HeaderPendingCaseDetails';
import { GetPendingUserCase } from '../../../../apis/pendingCases';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { DocumentsUserCaseType } from '../../../../models/IPendingCases';
import DocumentsPendingCaseDetails from './DocumentsPendingCaseDetails/DocumentsPendingCaseDetails';
import MessagesPendingCaseDetails from './MessagesPendingCaseDetails/MessagesPendingCaseDetails';
import { MessagesUserCaseType } from '../../../../models/IPendingCases';
import DocumentsTablePendingCaseDetails from './DocumentsTablePendingCaseDetails/DocumentsTablePendingCaseDetails';
import { IMessage, useSubscription } from 'mqtt-react-hooks';
import { notification } from 'antd';
import { useLocation } from 'react-router-dom';
import preloaderImg from '../../../../assets/images/preloader.gif';
import PaymentsInCase from '../../pending-case-lawyer/OpenDetailsCustomer/PaymentsInCase/PaymentsInCase';
import ArchiveInformation from './ArchiveInformation/ArhiveInformation';

const PendingCaseDetails: React.ComponentType = (props: any) => {
    const { user } = useTypedSelector(state => state.user);
    const [actionMessageMqtt, setActionMessageMqtt] = useState<
        IMessage | undefined
    >(undefined);
    const location = useLocation<any>();
    const [documentsUserCase, setDocumentsUserCase] = useState<
        DocumentsUserCaseType | undefined | null
    >(null);
    const [messagesUserCase, setMessagesUserCase] = useState<
        MessagesUserCaseType | undefined | null
    >(null);
    const [reloadAfterSendMessage, setReloadAfterSendMessage] =
        useState<boolean>(false);

    const [preloader, setPreloader] = useState<boolean>(false);
    const [idForRequest, ,] = useState<number>(
        props.location.state?.id.caseNumber
            ? props.location.state?.id.caseNumber
            : props.location.state?.id.key
            ? props.location.state?.id.key
            : JSON.parse(
                  window.sessionStorage.getItem(
                      'idCaseNumberForSelectedPendingCaseCustomerDetails',
                  ) || '{}',
              ),
    );

    const [infoForDetails, ,] = useState<any>(
        props.location.state?.id
            ? props.location.state?.id
            : JSON.parse(
                  window.sessionStorage.getItem(
                      'infoForSelectedPendingCaseCustomerDetails',
                  ) || '{}',
              ),
    );

    useEffect(() => {
        window.onbeforeunload = () => {
            window.sessionStorage.setItem(
                'idCaseNumberForSelectedPendingCaseCustomerDetails',
                JSON.stringify(idForRequest),
            );

            window.sessionStorage.setItem(
                'infoForSelectedPendingCaseCustomerDetails',
                JSON.stringify(infoForDetails),
            );

            window.sessionStorage.setItem(
                'lastRoute',
                JSON.stringify(window.location.pathname),
            );
        };
    }, [idForRequest, infoForDetails]);

    useEffect(() => {
        if (!messagesUserCase && !documentsUserCase) {
            setPreloader(true);
            window.scrollTo(0, 0);
            GetPendingUserCase(
                idForRequest
                    ? idForRequest
                    : JSON.parse(
                          window.sessionStorage.getItem(
                              'idCaseNumberForSelectedPendingCaseCustomerDetails',
                          ) || '{}',
                      ),
            ).then(value => {
                setMessagesUserCase(value.data.data[0]);
                setDocumentsUserCase(value.data.data[0]?.documents);
                setPreloader(false);
            });
        } else {
            GetPendingUserCase(
                idForRequest
                    ? idForRequest
                    : JSON.parse(
                          window.sessionStorage.getItem(
                              'idCaseNumberForSelectedPendingCaseCustomerDetails',
                          ) || '{}',
                      ),
            ).then(value => {
                setMessagesUserCase(value.data.data[0]);
                setDocumentsUserCase(value.data.data[0]?.documents);
            });
        }
    }, [reloadAfterSendMessage]);

    const messageMqtt = useSubscription([
        `${process.env.REACT_APP_ENVIRONMENT}/topic/user/${user?.user_id}`,
    ]);
    let messageMqttJSON: any = actionMessageMqtt?.message;
    let objSubscriptionMqtt = messageMqttJSON && JSON.parse(messageMqttJSON);

    useEffect(() => {
        setActionMessageMqtt(messageMqtt?.message);
    }, [messageMqtt?.message]);

    useEffect(() => {
        if (
            objSubscriptionMqtt?.action === 'add-message' &&
            objSubscriptionMqtt?.case_id === Number(idForRequest) &&
            location.pathname === '/dashboard/pending-case-details'
        ) {
            notification.success({
                message: `${objSubscriptionMqtt.message.split(' ')[0]} ${
                    objSubscriptionMqtt.message.split(' ')[1]
                }: a new message!`,
            });
            onReloadTableAfterSend();
            setActionMessageMqtt(undefined);
        } else if (
            objSubscriptionMqtt?.action === 'add-document' &&
            location.pathname === '/dashboard/pending-case-details'
        ) {
            notification.success({
                message: objSubscriptionMqtt.message,
            });
            onReloadTableAfterSend();
            setActionMessageMqtt(undefined);
        } else if (
            objSubscriptionMqtt?.action === 'add-message' &&
            objSubscriptionMqtt?.case_id !== Number(idForRequest) &&
            location.pathname === '/dashboard/pending-case-details'
        ) {
            notification.success({
                message: `${objSubscriptionMqtt.message.split(' ')[0]} ${
                    objSubscriptionMqtt.message.split(' ')[1]
                }: a new message!`,
            });
            setActionMessageMqtt(undefined);
        } else if (
            objSubscriptionMqtt?.action === 'take-to-work' &&
            location.pathname === '/dashboard/pending-case-details'
        ) {
            onReloadTableAfterSend();
            setActionMessageMqtt(undefined);
        } else if (
            objSubscriptionMqtt?.action === 'close-case' &&
            location.pathname === '/dashboard/pending-case-details'
        ) {
            onReloadTableAfterSend();
            setActionMessageMqtt(undefined);
        } else if (
            objSubscriptionMqtt?.action === 'create-invoice' &&
            location.pathname === '/dashboard/pending-case-details'
        ) {
            onReloadTableAfterSend();
            setActionMessageMqtt(undefined);
        } else {
            return;
        }
    }, [objSubscriptionMqtt]);

    const onReloadTableAfterSend = () => {
        setReloadAfterSendMessage(!reloadAfterSendMessage);
    };

    if (preloader) {
        return (
            <div className="preloader-box">
                <img
                    className="preloader-img"
                    src={preloaderImg}
                    alt="Loading..."
                />
            </div>
        );
    }

    return (
        <div className="service-container">
            <HeaderPendingCaseDetails messagesUserCase={messagesUserCase} />
            {messagesUserCase?.invoice && (
                <DocumentsPendingCaseDetails
                    messagesUserCase={messagesUserCase}
                />
            )}
            {messagesUserCase?.status === 3 ||
            messagesUserCase?.status === 4 ? (
                <ArchiveInformation messagesUserCase={messagesUserCase} />
            ) : null}
            <DocumentsTablePendingCaseDetails
                documentsUserCase={documentsUserCase}
                data={messagesUserCase?.date_create_human}
                messagesUserCase={messagesUserCase}
            />
            <MessagesPendingCaseDetails
                messagesUserCase={messagesUserCase}
                chatId={messagesUserCase?.chat_id}
                userId={messagesUserCase?.user_id}
                onReloadTableAfterSend={onReloadTableAfterSend}
                data={infoForDetails}
            />
            <PaymentsInCase
                customerId={user?.user_id}
                caseNumber={messagesUserCase?.id}
                messageMqttMessage={messageMqtt?.message}
            />
        </div>
    );
};

export default PendingCaseDetails;
