import React, { useEffect } from 'react';

const EducationDocument = () => {
    useEffect(() => {
        window.onbeforeunload = () => {
            window.sessionStorage.setItem(
                'lastRoute',
                JSON.stringify(window.location.pathname),
            );
        };
    }, []);
    return <h1 style={{ marginTop: 50 }}>Coming Soon</h1>;
};

export default EducationDocument;
