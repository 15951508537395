import { Axios } from "../plugins/helpers";
import { notificationError } from "../plugins/requestNotification";


export const PostTakeToWork = async (case_id: number) => {
    try {
        const response = await Axios().post(`${process.env.REACT_APP_BASE_URL}/take-case-to-work`, {case_id});
        return response; 
    } catch(e: any) {
        notificationError(e)
        return e?.response; 
    }
}

export const PostRejectCase = async (description: string, case_id: number) => {
    try {
        const response = await Axios().post(`${process.env.REACT_APP_BASE_URL}/reject-case`, {description, case_id});
        return response; 
    } catch(e: any) {
        notificationError(e)
        return e?.response; 
    }
}