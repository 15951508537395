import React from 'react';

const TermsOfUse = () => {
    return (
        <div className="service-container service-page">
            <div className="service__title">TERMS OF USE</div>
            <div style={{ paddingTop: '50px' }}>
                <h2>
                    <strong>
                        PLEASE READ THE TERMS OF THIS POLICY CAREFULLY BEFORE
                        USING THE SITE
                    </strong>
                </h2>
                <p>
                    <a href="https://emirates.law">https://emirates.law</a> is a
                    site operated by Raya Al Ameri Advocates & Legal Consultants
                    ("We"). We are registered in UAE under company number
                    [COMPANY NUMBER] and have our registered office at 2006 Opal
                    Tower, Burj Khalifa Blvd, Business Bay, P.O. Box 90678,
                    Dubai, UAE. 6 OPAL TOWER BURJ KHALIFA BLVD, BUSINESS BAY
                    P.O. BOX
                </p>

                <p>
                    By using our site, you confirm that you accept the terms of
                    this policy and that you agree to comply with them. If you
                    do not agree to these terms, you must not use our site.
                </p>

                <p>
                    We amend these terms from time to time. Every time you wish
                    to use our site, please check these terms to ensure you
                    understand the terms that apply at that time.
                </p>
                <p>
                    We do not guarantee that our site, or any content on it,
                    will always be available or be uninterrupted. We may suspend
                    or withdraw or restrict the availability of all or any part
                    of our site for business and operational reasons. We will
                    try to give you reasonable notice of any suspension or
                    withdrawal.
                </p>
                <p>
                    You are also responsible for ensuring that all persons who
                    access our site through your internet connection are aware
                    of these terms of use and other applicable terms and
                    conditions, and that they comply with them.
                </p>
                <p>
                    The content on our site is provided for general information
                    only. It is not intended to amount to advice on which you
                    should rely. You must obtain professional or specialist
                    advice before taking, or refraining from, any action on the
                    basis of the content on our site.
                </p>
                <p>
                    Although we make reasonable efforts to update the
                    information on our site, we make no representations,
                    warranties or guarantees, whether express or implied, that
                    the content on our site is accurate, complete or up to date.
                </p>
                <p>
                    We do not guarantee that our site will be secure or free
                    from bugs or viruses.
                </p>
                <p>
                    You are responsible for configuring your information
                    technology, computer programmes and platform to access our
                    site. You should use your own virus protection software.
                </p>
                <p>
                    You may print off one copy, and may download extracts, of
                    any page(s) from our site for your personal use and you may
                    draw the attention of others within your organisation to
                    content posted on our site.
                </p>
                <p>
                    You must not modify the paper or digital copies of any
                    materials you have printed off or downloaded in any way, and
                    you must not use any illustrations, photographs, video or
                    audio sequences or any graphics separately from any
                    accompanying text.
                </p>
                <p>
                    You must not use any part of the content on our site for
                    commercial purposes without obtaining a licence to do so
                    from us or our licensors.
                </p>
                <p>
                    If you print off, copy, download, share or repost any part
                    of our site in breach of these terms of use, your right to
                    use our site will cease immediately and you must, at our
                    option, return or destroy any copies of the materials you
                    have made.
                </p>
                <p>
                    <strong>Breach of this policy</strong>
                </p>
                <p>
                    When we consider that a breach of this acceptable use policy
                    has occurred, we may take such action as we deem
                    appropriate.
                </p>
                <p>
                    If you wish to complain about content of this site, please
                    email{' '}
                    <a href="mailto:legal@emirates.law">legal@emirates.law</a>{' '}
                    or call <a href="tel:+971 4 578 6050">+971 4 578 6050</a>.
                </p>
            </div>
        </div>
    );
};

export default TermsOfUse;
