import React, { useState, useEffect } from 'react';
import { Modal, Button, DatePicker, Select } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import { PostCreateCalendarEvent } from '../../../../../../apis/createCalendarEvent';
import moment from 'moment';

const CreateEventModal = (props: any) => {
    const [titleEventValue, setTitleEventValue] = useState<string>('');
    const [descriptionEventValue, setDescriptionEventValue] =
        useState<string>('');
    const [timeEventValue, setTimeEventValue] = useState<any>(moment().add('hour', 1));
    const [importanceEventValue, setImportanceEventValue] = useState<number>(3);
    const [valueSelect, setValueSelect] = useState<string>('');
    const [inputTitleEmpty, setInputTitleEmpty] = useState<boolean>(false);
    const [inputDescriptionEmpty, setInputDescriptionEmpty] =
        useState<boolean>(false);
    const [inputTimeEmpty, setInputTimeEmpty] = useState<boolean>(false);
    const [showCyrillicTitle, setShowCyrillicTitle] = useState<boolean>(false);
    const [showCyrillicDescription, setShowCyrillicDescription] =
        useState<boolean>(false);
    const { confirm } = Modal;
    const { Option } = Select;
    const cyrillicPattern = /[\u0400-\u04FF]/;

    useEffect(() => {
        if (importanceEventValue === 1) {
            setValueSelect('Low');
        } else if (importanceEventValue === 2) {
            setValueSelect('Medium');
        } else {
            setValueSelect('High');
        }
    }, [importanceEventValue]);

    const onTitleEventValue = (event: any) => {
        setTitleEventValue(event.target.value);
        if (cyrillicPattern.test(event.target.value)) {
            setShowCyrillicTitle(true);
        } else {
            setShowCyrillicTitle(false);
        }
    };

    const onDescriptionEventValue = (event: any) => {
        setDescriptionEventValue(event.target.value);
        if (cyrillicPattern.test(event.target.value)) {
            setShowCyrillicDescription(true);
        } else {
            setShowCyrillicDescription(false);
        }
    };

    const onSelectDate: DatePickerProps['onSelect'] = (
        value: DatePickerProps['value'],
    ) => {
        setTimeEventValue(value);
    };

    const disabledDate: RangePickerProps['disabledDate'] = current => {
        return current.isBefore(moment().subtract(1, 'day'));
    };

    const getDisabledHours = () => {
        let hours = [];
        for (let i = 0; i < moment().hour(); i++) {
            hours.push(i);
        }
        return hours;
    };

    const getDisabledMinutes = () => {
        let minutes = [];
        for (let i = 0; i - 3 < moment().minute(); i++) {
            minutes.push(i);
        }
        return minutes;
    };

    const onChangeImportance = (value: string) => {
        setImportanceEventValue(Number(value));
    };

    const onCreateEvent = () => {
        if (
            titleEventValue !== '' &&
            descriptionEventValue !== '' &&
            timeEventValue !== null &&
            moment(timeEventValue).unix() > moment().unix() &&
            showCyrillicTitle !== true &&
            showCyrillicDescription !== true
        ) {
            PostCreateCalendarEvent(
                Date.parse(timeEventValue),
                titleEventValue,
                descriptionEventValue,
                importanceEventValue,
                props.id,
            ).then(() => {
                setTitleEventValue('');
                setDescriptionEventValue('');
                setImportanceEventValue(3);
                setTimeEventValue(null);
                onCloseCreateEventModal();
                props.onReloadTableAfterSend();
            });
        } else if (!titleEventValue) {
            setInputTitleEmpty(true);
            setTimeout(() => {
                setInputTitleEmpty(false);
            }, 2000);
        } else if (!descriptionEventValue) {
            setInputDescriptionEmpty(true);
            setTimeout(() => {
                setInputDescriptionEmpty(false);
            }, 2000);
        } else if (
            timeEventValue === null ||
            moment(timeEventValue).unix() < moment().unix()
        ) {
            setInputTimeEmpty(true);
            setTimeout(() => {
                setInputTimeEmpty(false);
            }, 2000);
        }
    };

    const onCloseCreateEventModal = () => {
        setTitleEventValue('');
        setDescriptionEventValue('');
        setImportanceEventValue(3);
        setTimeEventValue(null);
        props.setShowCreateEventModal(false);
    };

    const showConfirm = () => {
        confirm({
            title: 'Do you want to close this event?',
            icon: <ExclamationCircleOutlined />,
            content: 'The entered data will be deleted',
            cancelButtonProps: {
                type: 'primary',
            },
            onOk() {
                onCloseCreateEventModal();
            },
            onCancel() {
                return;
            },
        });
    };

    return (
        <>
            <Modal
                visible={props.showCreateEventModal}
                title="Create Event"
                onCancel={showConfirm}
                width="600px"
                footer={[
                    <Button key="Back" danger onClick={showConfirm}>
                        Back
                    </Button>,
                    <Button key="Create" type="primary" onClick={onCreateEvent}>
                        Create
                    </Button>,
                ]}
            >
                <div>
                    <div>Title event:</div>
                    <div>
                        <input
                            className="card-upload-box-input"
                            style={{ width: '100%' }}
                            onChange={onTitleEventValue}
                            value={titleEventValue}
                            type="text"
                            maxLength={254}
                        />
                        {
                            <div
                                className={
                                    showCyrillicTitle
                                        ? 'card-upload-box-input-message-error'
                                        : 'card-upload-box-input-message'
                                }
                            >
                                Enter only latin characters
                            </div>
                        }
                        {
                            <div
                                className={
                                    inputTitleEmpty
                                        ? 'card-upload-box-input-message-error'
                                        : 'card-upload-box-input-message'
                                }
                            >
                                This field is required
                            </div>
                        }
                    </div>
                    <div>
                        <div>Description event:</div>
                        <div>
                            <textarea
                                className="card-textarea"
                                onChange={onDescriptionEventValue}
                                value={descriptionEventValue}
                                placeholder="example..."
                            />
                            {
                                <div
                                    className={
                                        showCyrillicDescription
                                            ? 'card-upload-box-input-message-error'
                                            : 'card-upload-box-input-message'
                                    }
                                >
                                    Enter only latin characters
                                </div>
                            }
                            {
                                <div
                                    className={
                                        inputDescriptionEmpty
                                            ? 'card-upload-box-textarea-message-error'
                                            : 'card-upload-box-textarea-message'
                                    }
                                >
                                    This field is required
                                </div>
                            }
                        </div>
                    </div>
                    <div>
                        <div>Date and time event:</div>
                        <div>
                            <DatePicker
                                showTime
                                className="create-event-calendar"
                                showSecond={false}
                                size={'small'}
                                onSelect={onSelectDate}
                                disabledDate={disabledDate}
                                allowClear={false}
                                disabledHours={
                                    moment().unix() <
                                    moment(timeEventValue).unix()
                                        ? undefined
                                        : getDisabledHours
                                }
                                disabledMinutes={
                                    moment().unix() <
                                    moment(timeEventValue).unix()
                                        ? undefined
                                        : getDisabledMinutes
                                }
                                showNow={false}
                                value={
                                    !timeEventValue
                                        ? moment().add('hour', 1)
                                        : moment(timeEventValue)
                                }
                            />
                        </div>
                        {
                            <div
                                className={
                                    inputTimeEmpty
                                        ? 'card-upload-box-time-event-error'
                                        : 'card-upload-box-time-event'
                                }
                            >
                                {moment(timeEventValue).unix() < moment().unix()
                                    ? 'Choose a time and date that has not passed'
                                    : 'You have to select a date other than the current date'}
                            </div>
                        }
                    </div>
                    <div>
                        <div>Importance event:</div>
                        <div>
                            <Select
                                value={valueSelect}
                                style={{ width: 230 }}
                                onChange={onChangeImportance}
                            >
                                <Option value="3">High</Option>
                                <Option value="2">Medium</Option>
                                <Option value="1">Low</Option>
                            </Select>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default CreateEventModal;
