import React, { useState, useEffect } from 'react';
import { Modal, Button, Select, notification } from 'antd';
import {
    GetAllCustomers,
    PostAssignedCustomer,
} from '../../../../../../apis/assignCustomer';

const AssignCustomer = (props: any) => {
    const [customers, setCustomers] = useState<any[]>([]);
    const [assignedCustomer, setAssignedCustomer] = useState<string>('');
    const [assignedCustomerEmpty, setAssignedCustomerEmpty] =
        useState<boolean>(false);
    const [valueSelect, setValueSelect] = useState<string>('');
    const { Option } = Select;

    useEffect(() => {
        if (props.showAssignModal === true) {
            GetAllCustomers().then(res => {
                setCustomers(res.data);
            });
        }
    }, [props.showAssignModal]);

    useEffect(() => {
        if (assignedCustomer === '') {
            setValueSelect('');
        }
    }, [assignedCustomer]);

    const showCustomers = customers.map((item, index) => {
        return (
            <Option
                key={index}
                value={item.mail}
            >{`${item.user_name} - ${item.mail}`}</Option>
        );
    });

    const onCloseAssignModal = () => {
        props.setShowAssignModal(false);
        setAssignedCustomer('');
    };

    const onChangeAssignCustomer = (value: string) => {
        setAssignedCustomer(value);
        setValueSelect(value);
    };

    const sendAssignCustomer = () => {
        if (assignedCustomer !== '') {
            PostAssignedCustomer(assignedCustomer, props.id).then((res) => {
                props.setShowAssignModal(false);
                setAssignedCustomer('');
                props.onReloadTableAfterSend();
                if(res.data.data.status) {
                    notification.success({
                        message: `Case # ${props.id} was successfully assigned to the customer!`,
                    });
                }
            });
        } else {
            setAssignedCustomerEmpty(true);
            setTimeout(() => {
                setAssignedCustomerEmpty(false);
            }, 2000);
        }
    };

    return (
        <>
            <Modal
                visible={props.showAssignModal}
                title="Assign a customer"
                onCancel={onCloseAssignModal}
                width="600px"
                footer={[
                    <Button key="Back" danger onClick={onCloseAssignModal}>
                        Back
                    </Button>,
                    <Button
                        key="Create"
                        type="primary"
                        onClick={sendAssignCustomer}
                    >
                        Assign
                    </Button>,
                ]}
            >
                <div>
                    <Select
                        showSearch
                        style={{ width: 400 }}
                        placeholder="Search to Select"
                        onChange={onChangeAssignCustomer}
                        value={valueSelect}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (
                                option!.children.toLowerCase() as unknown as string
                            ).includes(input.toLowerCase())
                        }
                        filterSort={(optionA, optionB) =>
                            (optionA!.children as unknown as string)
                                .toLowerCase()
                                .localeCompare(
                                    (
                                        optionB!.children as unknown as string
                                    ).toLowerCase(),
                                )
                        }
                    >
                        {showCustomers}
                    </Select>
                    <div
                        className={
                            assignedCustomerEmpty
                                ? 'card-upload-box-input-assign-customer-error'
                                : 'card-upload-box-input-assign-customer'
                        }
                    >
                        Need to assign a customer
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default AssignCustomer;
